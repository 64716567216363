import React, { Component } from "react";
import { Input, Radio, Select, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Modal from "react-responsive-modal";
import { AddIcon, AddNoteIcon, CloseCircleIcon, MinusCircleIcon, DragDropMoveIcon } from "./../../../helpers/ImagesImport"
class LeadStageManage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            createLeadStage: false,
            Single: true,
            Multiple: false,
            leadSubStatus: [],
        };
    }
    handleCreateLeadStageOpn() {
        this.setState({ createLeadStage: true });
    }
    handleCreateLeadStageClose() {
        this.setState({ createLeadStage: false });
    }
    start = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 1000);
    };
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };
    handleRedioButtonChange(check) {
        if (check === "Single") {
            this.setState({
                Single: true,
                Multiple: false,
            });
        } else {
            this.setState({
                Single: false,
                Multiple: true,
            });
        }
    }
    handleAddNewStatusUi() {
        this.setState((prevState) => ({
            leadSubStatus: [...prevState.leadSubStatus, ""],
        }));
    }
    handleRemoveClick(i) {
        let leadSubStatus = [...this.state.leadSubStatus];
        leadSubStatus.splice(i, 1);
        this.setState({ leadSubStatus });
    }
    render() {
        const data = [
            {
                key: "1",
                leadStatus: (
                    <div className="report">
                        <label>Complaint</label>
                    </div>
                ),
                leadSubStatus: (
                    <div className="report">
                        <label>Resolved</label>
                    </div>
                ),
            },
            {
                key: "2",
                leadStatus: (
                    <div className="report">
                        <label>Complaint</label>
                    </div>
                ),
                leadSubStatus: (
                    <div className="report">
                        <label>Resolved</label>
                    </div>
                ),
            },
        ];
        const { Option } = Select;
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div className="LeadStageMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                Lead Stage Management
                <img
                                    src={AddIcon}
                                    alt="add"
                                    onClick={this.handleCreateLeadStageOpn.bind(this)}
                                />
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                placeholder="Search by name, issue type..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>
                    <div className="LeadStageTable">
                        <Table
                            rowSelection={rowSelection}
                            columns={[
                                {
                                    title: "Lead Status",
                                    dataIndex: "leadStatus",
                                    key: "leadStatus",
                                },
                                {
                                    title: "Lead Sub Status",
                                    dataIndex: "leadSubStatus",
                                    key: "leadSubStatus",
                                },
                                {
                                    title: "Created by",
                                    key: "createdby",
                                    dataIndex: "createdby",
                                    render: (row, item) => {
                                        return (
                                            <div className="report">
                                                <label>Vikash Sharma</label>
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: "Updated by",
                                    key: "updatedby",
                                    dataIndex: "updatedby",
                                    render: (row, item) => {
                                        return (
                                            <div className="report">
                                                <label>Vikash Sharma</label>
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: "Status",
                                    key: "status",
                                    dataIndex: "status",
                                    render: (row, item) => {
                                        return (
                                            <div className="Status">
                                                <Select defaultValue="Active">
                                                    <Option value="Active">Active</Option>
                                                    <Option value="Inactive">Inactive</Option>
                                                </Select>
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: "Actions",
                                    key: "actions",
                                    dataIndex: "actions",
                                    render: (row, item) => {
                                        return (
                                            <div className="Actionic">
                                                <img
                                                    src={AddNoteIcon}
                                                    alt="Edit"
                                                    onClick={this.handleCreateLeadStageOpn.bind(this)}
                                                />
                                                <img src={CloseCircleIcon} alt="delete" />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            dataSource={data}
                            pagination={false}
                        />
                    </div>
                    {/* Create Lead Stage */}
                    <Modal
                        open={this.state.createLeadStage}
                        onClose={this.handleCreateLeadStageClose.bind(this)}
                        modalId="CreateLeadStagManaModal"
                        overlayId="overlay"
                    >
                        <div className="createLeadStag">
                            <div className="hier mb-3">
                                <label>Create Lead Stage</label>
                            </div>
                            <div className="mb-2">
                                <Radio
                                    type="radio"
                                    name="Single"
                                    checked={this.state.Single}
                                    onChange={this.handleRedioButtonChange.bind(this, "Single")}
                                >
                                    Single
                </Radio>
                                <Radio
                                    type="radio"
                                    name="Single"
                                    checked={this.state.Multiple}
                                    onChange={this.handleRedioButtonChange.bind(this, "Multiple")}
                                >
                                    Multiple
                </Radio>
                            </div>
                            {this.state.Single && (
                                <>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-12">
                                            <label className="desig">
                                                Status Name<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <Select defaultValue="Click to Select">
                                                <Option value="active">Active</Option>
                                                <Option value="inactive">Inactive</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-3">
                                            <button className="addStatusBtn">Add Status</button>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6">
                                                <label className="desig">
                                                    Sub status for this status
                          <span style={{ color: "red" }}>*</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-2 pr-0">
                                                <div className="imgdrag">
                                                    <img src={DragDropMoveIcon} alt="DragDropMove" />
                                                    <img src={MinusCircleIcon} alt="Minuscircle" />
                                                </div>
                                            </div>
                                            {/* <div className="col-12 col-md-1">
                        <img src={Minuscircle} alt="Minuscircle" className="imgDragMove"/>
                      </div> */}
                                            <div className="col-12 col-md-10">
                                                <Select defaultValue="Click to Select">
                                                    <Option value="category">Category</Option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.leadSubStatus !== null &&
                                        this.state.leadSubStatus.map((item, i) => (
                                            <div key={i}>
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-2 pr-0">
                                                        <div className="imgdrag">
                                                            <img src={DragDropMoveIcon} alt="DragDropMove" />
                                                            <img src={MinusCircleIcon} alt="Minuscircle" onClick={this.handleRemoveClick.bind(this, i)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-10">
                                                        <Select defaultValue="Click to Select">
                                                            <Option value="category">Category</Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    <div className="row mb-3">
                                        <div className="col-12 col-md-3">
                                            <button
                                                className="addStatusBtn"
                                                onClick={this.handleAddNewStatusUi.bind(this)}
                                            >
                                                Add Sub Status
                      </button>
                                        </div>
                                        {this.state.leadSubStatus.length > 0 && (
                                            <div className="col-12 col-md-4">
                                                <button className="addStatusBtn">
                                                    Add closed lost status
                        </button>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {this.state.Multiple && <div>Upload File Pending</div>}
                        </div>
                        <div className="editorbtn">
                            <button
                                type="button"
                                onClick={this.handleCreateLeadStageClose.bind(this)}
                            >
                                Cancel
              </button>
                            <button type="button">CREATE</button>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default LeadStageManage;
