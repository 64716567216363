import Configuration from "../../helpers/Configuration";
import axios from "axios";

class FBConnectorService {
  constructor() {
    this.config = new Configuration();
  }
    async insertFBSubscription(url, inputData) {
      const response = await axios(this.config.FBConnectorServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
    }

    async getFBSubscription(url, inputData) {
        const response = await axios(this.config.FBConnectorServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async updateFBSubscription(url, inputData) {
        const response = await axios(this.config.FBConnectorServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }


}

export default FBConnectorService;
