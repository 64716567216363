import Configuration from "./../../helpers/Configuration";
import { AuthHeader as authHeader } from "./../../helpers/AuthHeader";
import authService from "../../components/api-authorization/AuthorizeService";
import axios from "axios";


class HierarchyService {
  constructor() {
    this.config = new Configuration();
  }
  /// get Gird data
  //async getHierarchyGridData(url) {
  //  const token = await authService.getAccessToken();
  //  const response = await axios(url, {
  //    headers: !token ? {} : { Authorization: `Bearer ${token}` },
  //  });
  //  return await response.json();
  //  }

    async getHierarchyGridData(url, inputData) {
        const response = await axios(this.config.HierarchyServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

  /// create hierarchy
    async createHierarchy(url, inputData) {
        const response = await axios(this.config.HierarchyServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }
  /// update hierarchy
    async updateHierarchy(url, inputData) {
      const response = await axios(this.config.HierarchyServiceURL + url, {
          method: "POST",
          data: inputData,
      });
      return await response.data;
  }
  /// delete hierarchy
    async deleteHierarchy(url, inputData) {
        const response = await axios(this.config.HierarchyServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }
}

export default HierarchyService;
