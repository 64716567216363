import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Table, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import { NotificationManager } from "react-notifications";

export default class EnquiryMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAccess: true,
            viewAccess: true,
            searchBy: "",
            enquiryInput: {},
            enquiryError: {},
            isEdit: false,
            isSubmitLoading: false,
            loading: false,
            enquiryModel: false,
            brandMasterData: [],
        };
    }
    componentDidMount() {

    }

    handleEnquiryModalOpen = () => {
        this.setState({ enquiryModel: true, enquiryInput: { status: true } });
    };

    handleEnquiryModalClose = () => {
        this.setState({
            enquiryModel: false,
            enquiryInput: {},
            enquiryError: {},
            isEdit: false,
        });
    };
    handleOnChangeInput = async (name, e) => {
        var enquiryInput = this.state.enquiryInput;
        enquiryInput[name] = e.target.value;
        await this.setState({
            enquiryInput,
        });
    };

    handleOnChange = (name, e) => {
        var enquiryInput = this.state.enquiryInput;

        enquiryInput[name] = e;
        this.setState({
            enquiryInput,
        });
    };

    

    handleEnquiryValidation = () => {
        let enquiryInput = this.state.enquiryInput;
        let enquiryError = {};
        let isValid = true;

        if (!enquiryInput["enquiryName"]) {
            isValid = false;
            enquiryError["enquiryName"] = "Please enter enquiry name.";
        }
        if (!enquiryInput["brandName"]) {
            isValid = false;
            enquiryError["brandName"] = "Please select brand name.";
        }
        

        this.setState({
            enquiryError,
        });
        return isValid;
    };







    render() {
        const { Option } = Select;
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                Enquiry Master
                                {this.state.createAccess &&
                                    <img
                                        src={AddIcon}
                                        alt="add"
                                    onClick={this.handleEnquiryModalOpen.bind(this)}
                                    />}
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                name="searchByTeam"
                                value={this.state.searchByTeam}
                                //onChange={this.handleSerchInputChange.bind(this)}
                                placeholder="Search by..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>







                    <Modal
                        closable={false}
                        title={!this.state.isEdit ? "Create Enquiry" : "Edit Enquiry"}
                        visible={this.state.enquiryModel}
                        footer={[
                            <Button key="back" onClick={this.handleEnquiryModalClose.bind(this)}>
                                Cancel
              </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={this.state.isSubmitLoading}
                                onClick={this.handleEnquiryValidation.bind(this)}
                            >
                                {this.state.isEdit ? "Update" : "Create"}
                            </Button>,
                        ]}
                    >
                        <div className="createPrior">
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Enquiry Name</label>
                                    <Input
                                        placeholder="Enter Enquiry Name"
                                        name="enquiryName"
                                        value={this.state.enquiryInput.enquiryName}
                                        onChange={this.handleOnChangeInput.bind(this, "enquiryName")}
                                    />
                                    {this.state.enquiryError ? (
                                        <div className="text-danger">
                                            {this.state.enquiryError["enquiryName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Brand Name</label>

                                    <Select
                                        onChange={this.handleOnChange.bind(this, "brandName")}
                                        value={this.state.enquiryInput.brandName}
                                        placeholder="Select Brand Name"
                                        mode="multiple"
                                        maxTagCount="responsive"
                                    >
                                        {this.state.brandMasterData
                                            ? this.state.brandMasterData.map((item, key) => {
                                                return (
                                                    <Option>

                                                    </Option>
                                                );
                                            })
                                            : null}
                                    </Select>
                                    {this.state.enquiryError ? (
                                        <div className="text-danger">
                                            {this.state.enquiryError["brandName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Status</label>
                                    <Select
                                        value={this.state.enquiryInput.status}
                                        onChange={this.handleOnChange.bind(this, "status")}
                                        placeholder={"Select Status"}
                                    >
                                        <Option value={true} >Active</Option>
                                        <Option value={false}>Inactive</Option>
                                    </Select>
                                    {this.state.enquiryError ? (
                                        <div className="text-danger">
                                            {this.state.enquiryError["status"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                           




                        </div>

                    </Modal>














                </div>
            </div>
        );
    }
}
