//---------------------------PNG Images--------------------------------//
const SettingIcon = require("./../assets/images/Setting.png");
const NotificationIcon = require("./../assets/images/Notification.png");
const EditIcon = require("./../assets/images/pencil.png");
const LogoutIcon = require("./../assets/images/logout.png");
const UploadIcon = require("./../assets/images/upload.png");
const DragDropMoveIcon = require("./../assets/images/DragDropMove.png");
const MinusCircleIcon = require("./../assets/images/minuscircle.png");
const HappinessIcon = require("./../assets/images/happiness.png");
const DropdownIcon = require("./../assets/images/dropdown.png");
const UserIcon = require("./../assets/images/Userr.png");
const ListIcon = require("./../assets/images/list.png");
const GridIcon = require("./../assets/images/grid.png");
const ColumnIcon = require("./../assets/images/columnIcon.png");
const SMSIcon = require("./../assets/images/Sms.png");
const SUserIcon = require("./../assets/images/user.png");
const HierarchyIcon = require("./../assets/images/hierarchy.png");
const SLAIcon = require("./../assets/images/sla.png");
const AlertIcon = require("./../assets/images/alert.png");
const UsersIcon = require("./../assets/images/users.png");
const PriorityIcon = require("./../assets/images/priority.png");
const CRMRolesIcon = require("./../assets/images/crmroles.png");
const LeadCloudIcon = require("./../assets/images/LeadHu.png");
const LocationIcon = require("./../assets/images/Location.png");
const ProfileImg = require("./../assets/images/UserLogin.png");

//---------------------------JPG Images--------------------------------//
const ProfileIcon = require("./../assets/images/Profile.jpg");
//---------------------------SVG Images--------------------------------//
const SearchIcon = require("./../assets/images/Search.svg");
const MenuBarIcon = require("./../assets/images/MenuBar.svg");
const AssetsIcon = require("./../assets/images/assets.svg");
const CampaignIcon = require("./../assets/images/campaign.svg");
const CommunicationIcon = require("./../assets/images/communication.svg");
const DashboardIcon = require("./../assets/images/Dashboard.svg");
const LeadIcon = require("./../assets/images/Lead.svg");
const OpportunityIcon = require("./../assets/images/opportunity.svg");
const OrderIcon = require("./../assets/images/Order.svg");
const ReportIcon = require("./../assets/images/Report.svg");
const WorkflowIcon = require("./../assets/images/workflow.svg");
const CloseCircleIcon = require("./../assets/images/CloseCircle.svg");
const AddNoteIcon = require("./../assets/images/AddNote.svg");
const AddIcon = require("./../assets/images/add.svg");
const DownloadIcon = require("./../assets/images/Download.svg");
const LeftIcon = require("./../assets/images/Lefticon.svg");
const TemplateThIcon = require("./../assets/images/Temp1.svg");
const OptionIcon = require("./../assets/images/optionn.svg");
const TopDownIcon = require("./../assets/images/topdown.svg");
const FilterIcon = require("./../assets/images/Filter.svg");
const CallBlueIcon = require("./../assets/images/CallBlue.svg");
const MeetingBlueIcon = require("./../assets/images/MeetingBlue.svg");
const TodoBlueIcon = require("./../assets/images/TodoBlue.svg");
const ReferalBlueIcon = require("./../assets/images/ReferalBlue.svg");
const BlockBlueIcon = require("./../assets/images/BlockBlue.svg");
const CircleBackIcon = require("./../assets/images/CircleBack.svg");
const MeetIcon = require("./../assets/images/Meet.svg");
const CallIcon = require("./../assets/images/Call.svg");
const EmailIcon = require("./../assets/images/email.svg");
const NotesIcon = require("./../assets/images/Notes.svg");
const TaskIcon = require("./../assets/images/Task.svg");
const HistoryBlueIcon = require("./../assets/images/HistoryBlue.svg");
const DollerIcon = require("./../assets/images/Doller.svg");
const PetrolIcon = require("./../assets/images/Petrol.svg");
const PencilIcon = require("./../assets/images/pencil.png");

export {
  DollerIcon,
  PetrolIcon,
  HistoryBlueIcon,
  TaskIcon,
  NotesIcon,
  EmailIcon,
  MeetIcon,
  CallIcon,
  UserIcon,
  CircleBackIcon,
  BlockBlueIcon,
  ReferalBlueIcon,
  TodoBlueIcon,
  MeetingBlueIcon,
  CallBlueIcon,
  FilterIcon,
  TopDownIcon,
  DropdownIcon,
  OptionIcon,
  HappinessIcon,
  TemplateThIcon,
  LeftIcon,
  MinusCircleIcon,
  DragDropMoveIcon,
  DownloadIcon,
  UploadIcon,
  AddIcon,
  AddNoteIcon,
  CloseCircleIcon,
  LeadCloudIcon,
  EditIcon,
  LogoutIcon,
  NotificationIcon,
  SearchIcon,
  SettingIcon,
  ProfileIcon,
  MenuBarIcon,
  AssetsIcon,
  CampaignIcon,
  CommunicationIcon,
  DashboardIcon,
  LeadIcon,
  OpportunityIcon,
  OrderIcon,
  ReportIcon,
  WorkflowIcon,
  ListIcon,
  GridIcon,
  ColumnIcon,
  SMSIcon,
  SUserIcon,
  HierarchyIcon,
  SLAIcon,
  AlertIcon,
  UsersIcon,
  PriorityIcon,
  CRMRolesIcon,
  LocationIcon,
  ProfileImg,
  PencilIcon
};
