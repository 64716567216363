import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Table, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import { NotificationManager } from "react-notifications";

export default class DoctorMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAccess: true,
            viewAccess: true,
            searchBy: "",
            doctorInput: {},
            doctorError: {},
            isEdit: false,
            isSubmitLoading: false,
            loading: false,
            doctorModel: false,
            unitMasterData: [],
        };
    }
    componentDidMount() {

    }

    handleDoctorModalOpen = () => {
        this.setState({ doctorModel: true, doctorInput: {status:true} });
    };

    handleDoctorModalClose = () => {
        this.setState({
            doctorModel: false,
            doctorInput: {},
            doctorError: {},
            isEdit: false,
        });
    };
    handleOnChangeInput = async (name, e) => {
        var doctorInput = this.state.doctorInput;
        doctorInput[name] = e.target.value;
        await this.setState({
            doctorInput,
        });
    };

    handleOnChange = (name, e) => {
        var doctorInput = this.state.doctorInput;

        doctorInput[name] = e;
        this.setState({
            doctorInput,
        });
    };



    handleDoctorValidation = () => {
        let doctorInput = this.state.doctorInput;
        let doctorError = {};
        let isValid = true;

        if (!doctorInput["doctorName"]) {
            isValid = false;
            doctorError["doctorName"] = "Please enter doctor name.";
        }
        if (!doctorInput["unitName"]) {
            isValid = false;
            doctorError["unitName"] = "Please select unit name.";
        }


        this.setState({
            doctorError,
        });
        return isValid;
    };









    render() {
        const { Option } = Select;
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                Doctor Master
                                {this.state.createAccess &&
                                    <img
                                        src={AddIcon}
                                        alt="add"
                                    onClick={this.handleDoctorModalOpen.bind(this)}
                                    />}
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                name="searchByTeam"
                                value={this.state.searchByTeam}
                                //onChange={this.handleSerchInputChange.bind(this)}
                                placeholder="Search by..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>







                    <Modal
                        closable={false}
                        title={!this.state.isEdit ? "Create Doctor" : "Edit Doctor"}
                        visible={this.state.doctorModel}
                        footer={[
                            <Button key="back" onClick={this.handleDoctorModalClose.bind(this)}>
                                Cancel
              </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={this.state.isSubmitLoading}
                                onClick={this.handleDoctorValidation.bind(this)}
                            >
                                {this.state.isEdit ? "Update" : "Create"}
                            </Button>,
                        ]}
                    >
                        <div className="createPrior">
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Doctor Name</label>
                                    <Input
                                        placeholder="Enter Doctor Name"
                                        name="doctorName"
                                        value={this.state.doctorInput.doctorName}
                                        onChange={this.handleOnChangeInput.bind(this, "doctorName")}
                                    />
                                    {this.state.doctorError ? (
                                        <div className="text-danger">
                                            {this.state.doctorError["doctorName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Unit Name</label>

                                    <Select
                                        onChange={this.handleOnChange.bind(this, "unitName")}
                                        value={this.state.doctorInput.unitName}
                                        placeholder="Select Unit Name"
                                        mode="multiple"
                                        maxTagCount="responsive"
                                    >
                                        {this.state.unitMasterData
                                            ? this.state.unitMasterData.map((item, key) => {
                                                return (
                                                    <Option>

                                                    </Option>
                                                );
                                            })
                                            : null}
                                    </Select>
                                    {this.state.doctorError ? (
                                        <div className="text-danger">
                                            {this.state.doctorError["unitName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Status</label>
                                    <Select
                                        value={this.state.doctorInput.status}
                                        onChange={this.handleOnChange.bind(this, "status")}
                                        placeholder={"Select Status"}
                                    >
                                        <Option value={true} >Active</Option>
                                        <Option value={false}>Inactive</Option>
                                    </Select>
                                   
                                </div>
                            </div>





                        </div>

                    </Modal>

















                </div>
            </div>
        );
    }
}
