import { Button, DatePicker, Radio, Select, Switch } from "antd";
import CKEditor from "ckeditor4-react";
import moment from "moment";
import React, { Fragment, useState, useEffect } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { LeadIcon } from "../../../helpers/ImagesImport";
import LeadsService from "../../../services/Leads/LeadsService";
import CommonService from "../../../services/Common/CommonService";
import AppointmentService from "../../../services/Leads/AppointmentService";
import TemplateService from "../../../services/AdminSetting/TemplateService";

const { Option } = Select;
const leadsService = new LeadsService();
const commonService = new CommonService();
const appointmentService = new AppointmentService();
const templateService = new TemplateService();
export default function AddTaskComponent(props) {
  const [taskTypeList, setTaskTypeList] = useState(props.TaskTypeList);
  const [leadStatusList, setLeadStatusList] = useState(props.leadStatusList);
  const [leadSubStatusList, setLeadSubStatusList] = useState([]);
  const [assignToList, setAssignToList] = useState(props.AssignToList);
  const [selectedDate, setDate] = useState(null);
  const [leadStatus, setLeadStatus] = useState(null);
  const [leadSubStatus, setLeadSubStatus] = useState(null);
  const [assignTo, setAssignTo] = useState(null);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [editNote, setEditNote] = useState("");
  const [isLeadStatus, setIsLeadStatus] = useState(null);
  const [isLeadSubStatus, setIsLeadSubStatus] = useState(null);
  const [isSelectedDate, setIsSelectedDate] = useState(null);
  const [isTitle, setIsTitle] = useState(null);
  const [isAssignTo, setIsAssignTo] = useState(null);
  const [isTaskType, setIsTaskType] = useState(null);
  const [taskType, setTaskType] = useState(null);
  const [durationType, setDurationType] = useState("M");
  const [duration, setDuration] = useState(null);
  const [serviceType, setServiceType] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [Reason, setReason] = useState(null);
  const [IsReason, setIsReason] = useState(null);
  const [notifyProvider, setNotifyProvider] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [templateList, setTempateList] = useState([]);
  const [template, setTempate] = useState([]);
  const [disableTask, setDisableTask] = useState(false);
  const [isFolloUp, setFolloUp] = useState(false);

  //for Appointment
  const [stateData, setStateData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [cityData, setCityData] = useState(null);
  const [specialityData, setSpecialityData] = useState(null);
  const [AppAssignToData, setAppAssignToData] = useState(null);
  const [appointmentTypeData, setAppointmentTypeData] = useState(null);
  const [slotsData, setSlotsData] = useState(null);
  const [name, setName] = useState(null);
  const [emailID, setEmailID] = useState(null);
  const [mobileNo, setMobileNo] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [location, setLocation] = useState(null);
  const [speciality, setSpeciality] = useState(null);
  const [appointmentType, setAppointmentType] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [slotes, setSlots] = useState(null);
  const [appointmentAmount, setAppointmentAmount] = useState(null);
  const [type, setType] = useState(false);
  const [appointementAssignTo, setAppointementAssignTo] = useState(null);

  //Appointment Validation
  const [isName, setIsName] = useState(null);
  const [isEmailID, setIsEmailID] = useState(null);
  const [isMobileNo, setIsMobileNo] = useState(null);
  const [isState, setIsState] = useState(null);
  const [isCity, setIsCity] = useState(null);
  const [isLocation, setIsLocation] = useState(null);
  const [isSpeciality, setISSpeciality] = useState(null);
  const [isAppointmentType, setIsAppointmentType] = useState(null);
  const [isSlots, setIsSlot] = useState(null);
  const [isAppointmentAmount, setIsAppointmentAmount] = useState(null);
  const [isAppointmentDate, setIsAppointmentDate] = useState(null);
  const [isAppointementAssignTo, setIsAppointementAssignTo] = useState(null);

  //for Edit
  const [isEditTask, setIsEditTask] = useState(false);
  const [editLeadID, setEditLeadID] = useState(null);
  const [isCloseTask, setIsCloseTask] = useState(false);
  useEffect(() => {
    handleGetStateByCountryIDList();
    handleGetLocationList();
    handleGetServiceMaster();
    handleGetTemplateMaster();
    if (props.isEditTask) {
      setIsEditTask(true);
      setIsCloseTask(false);
      setEditLeadID(props.editTaskDetails.leadID);
      setLeadStatus(props.editTaskDetails.leadStatusID || null);
      setLeadSubStatus(props.editTaskDetails.leadSubStatusID || null);
      setTaskType(props.editTaskDetails.taskTypeID || null);
      setTitle(props.editTaskDetails.taskTitle || null);
      setNote(props.editTaskDetails.taskDetails || null);
      setDuration(props.editTaskDetails.taskDurationValue || null);
      setDate(
        moment(
          props.editTaskDetails.taskScheduleDate +
            props.editTaskDetails.taskScheduleTime
        )
      );
      setAssignTo(
        props.editTaskDetails.taskAssignedID +
          "-" +
          props.editTaskDetails.isTeamUser || null
      );
      setEditNote(null);
    } else {
      var disableTask = false;

      if (props.leadStatus) {
        if (props.leadStatus) {
          disableTask = props.leadStatusList.filter(
            (x) => x.leadStatusID === props.leadStatus
          )[0].disableTask;

          setDisableTask(disableTask);
        } else {
          setDisableTask(disableTask);
        }
      } else {
        if (props.LeadDetails.leadStatus) {
          disableTask = props.leadStatusList.filter(
            (x) => x.leadStatusID === props.LeadDetails.leadStatus
          )[0].disableTask;

          setDisableTask(disableTask);
        } else {
          setDisableTask(disableTask);
        }
      }

      setIsEditTask(false);
      if (props.leadStatus) {
        setLeadStatus(props.leadStatus);

        if (props.leadSubStatus) {
          setLeadSubStatus(props.leadSubStatus);
          if (props.taskType) {
            setTaskType(props.taskType);
          }
          if (props.selectedDate) {
            //var d = new Date(props.selectedDate);
            setDate(props.selectedDate);
          }
        } else {
          setLeadSubStatus(null);
        }
      } else {
        if (props.LeadDetails.leadStatus) {
          setLeadStatus(props.LeadDetails.leadStatus);
        } else {
          setLeadStatus(null);
        }
        if (props.LeadDetails.leadSubStatus) {
          setLeadSubStatus(props.LeadDetails.leadSubStatus);
        } else {
          setLeadSubStatus(null);
        }
      }

      if (props.FollowUp) {
        setDisableTask(false);
      }
    }
  }, [props]);
  useEffect(() => {
    if (leadStatus) {
      handleGetLeadSubStatusList(leadStatus);
    }
  }, [leadStatus]);

  function handleCreateTaskClose(isSuccess) {
    setLeadSubStatusList([]);
    setIsLeadStatus(null);
    setIsLeadSubStatus(null);
    setIsSelectedDate(null);
    setIsTitle(null);
    setIsAssignTo(null);
    setIsTaskType(null);
    setDate(null);
    setLeadStatus(null);
    setLeadSubStatus(null);
    setAssignTo(null);
    setTitle("");
    setNote(null);
    setTaskType(null);
    setDuration(null);
    setServiceType(null);
    setIsName(null);
    setIsEmailID(null);
    setIsMobileNo(null);
    setIsState(null);
    setIsCity(null);
    setIsLocation(null);
    setISSpeciality(null);
    setIsAppointmentType(null);
    setIsSlot(null);
    setIsAppointmentAmount(null);
    setIsAppointmentDate(null);
    setIsAppointementAssignTo(null);
    setEmailID(null);
    setMobileNo(null);
    setState(null);
    setCity(null);
    setLocation(null);
    setSpeciality(null);
    setAppointmentType(null);
    setAppointmentDate(null);
    setSlots(null);
    setAppointmentAmount(null);
    setType(false);
    setAppointementAssignTo(null);
    setName(null);
    setCityData(null);
    setSpecialityData(null);
    setAppAssignToData(null);
    setAppointmentTypeData(null);
    setSlotsData(null);
    setIsEditTask(false);
    setNotifyProvider(false);
    setDisableTask(false);
    setIsSubmitLoading(false);
    setFolloUp(false);
    props.handleCreateTaskClose(isSuccess);
  }

  function handleCreateLead() {
    // if (props.handleLeadValidation()) {
    if (handleCheckValidation()) {
      var inputData = {};
      inputData.MobileNumber = props.leadInput.mobile;
      inputData.AlternateMobileNumber = props.leadInput.altmobile;
      inputData.FirstName = props.leadInput.firstname;
      inputData.MiddleName = props.leadInput.middlename;
      inputData.LastName = props.leadInput.lastname;
      inputData.Email = props.leadInput.email;
      inputData.AlternateEmail = props.leadInput.altemail;
      inputData.Gender = props.leadInput.gender;
      inputData.DateofBirth = props.leadInput.dob
        ? moment(props.leadInput.dob).format("DD-MM-YYYY")
        : null;
      inputData.AnniversaryDate = props.leadInput.anniversarydate
        ? moment(props.leadInput.anniversarydate).format("YYYY-MM-DD")
        : null;
      inputData.AgeYear = props.leadInput.ageyear
        ? props.leadInput.ageyear.toString()
        : null;
      inputData.AgeMonth = props.leadInput.agemonth
        ? props.leadInput.agemonth.toString()
        : null;
      inputData.AgeDays = props.leadInput.agedays
        ? props.leadInput.agedays.toString()
        : null;
      inputData.Address = props.leadInput.address;
      inputData.State = props.leadInput.state;
      inputData.City = props.leadInput.city;
      setState({ isSubmitLoading: true });
      try {
        leadsService
          .createLead("InsertCustomerInfo", inputData)
          .then((response) => {
            var message = response.message;
            //this.setState({ isSubmitLoading: false });
            if (message === "Success") {
              setState({
                leadId: response.responseData,
              });
              //if (this.state.isCreateTaskandSave) {
              //    this.setState({ isSubmitLoading: false });
              //    this.handleCreateTaskandLeads(response.responseData);
              //} else {
              handleCreateLeadDetails(response.responseData);
              //}
            } else {
              setState({ isSubmitLoading: false });
              NotificationManager.error("Record Not Created.");
            }
          })
          .catch((error) => {
            setState({ isSubmitLoading: false });
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
      // }
    }
  }

  function handleCreateLeadDetails(leadId) {
    //if (this.handleLeadDetailsValidation()) {
    var inputData = {};
    inputData.LeadID = leadId;
    inputData.source = props.leadDetailsInput.source;
    inputData.type = props.leadDetailsInput.type;
    inputData.leadCategory = props.leadDetailsInput.category;
    inputData.subCategory = props.leadDetailsInput.subcategory;
    inputData.lmd = props.leadDetailsInput.lmd
      ? moment(props.leadDetailsInput.lmd).format("YYYY-MM-DD")
      : null;
    inputData.edd = props.leadDetailsInput.edd
      ? moment(props.leadDetailsInput.edd).format("YYYY-MM-DD")
      : null;
    inputData.unit = props.leadDetailsInput.unit;
    inputData.doctor = props.leadDetailsInput.doctor;
    inputData.status = props.leadDetailsInput.status;
    inputData.subStatus = props.leadDetailsInput.substatus;
    inputData.assignTo = parseInt(
      props.leadDetailsInput.assignto.split("-")[0]
    );
    inputData.editor = props.leadDetailsInput.editor
      ? props.leadDetailsInput.editor.replace(new RegExp("<[^>]*>", "g"), "")
      : null;
    inputData.isTeamUser = props.leadDetailsInput.isTeamUser;
    inputData.priorityID = props.leadDetailsInput.priority;
    setState({ isSubmitLoading: true });
    try {
      leadsService
        .createLead("CreateLeadDetail", inputData)
        .then((response) => {
          var message = response.message;
          if (message === "Success") {
            setState({
              leadId: response.responseData,
            });
            handleCreateLeadAddDetails(response.responseData);
          } else {
            NotificationManager.error("Record Not Created.");
            setState({ isSubmitLoading: false });
          }
        })
        .catch((error) => {
          setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      setState({ isSubmitLoading: false });
      console.log(error);
    }
    //}
  }

  function handleCreateLeadAddDetails(leadId) {
    var inputData = {};
    inputData.LeadID = leadId;
    inputData.gestationalAge =
      props.leadAddDetailInput.gestationalage !== "" &&
      props.leadAddDetailInput.gestationalage !== undefined
        ? parseFloat(props.leadAddDetailInput.gestationalage)
        : 0;
    inputData.InsuranceProvider1 = props.leadAddDetailInput.insuranceprovider
      ? props.leadAddDetailInput.insuranceprovider + ""
      : null;
    inputData.InsurancePolicyNo = props.leadAddDetailInput.insurancepolicyno;
    inputData.preauthorizationCode =
      props.leadAddDetailInput.preauthorizationCode;
    inputData.customerIncome = props.leadAddDetailInput.customerincome;
    inputData.occupation = props.leadAddDetailInput.occupation;
    inputData.additionalIncome = props.leadAddDetailInput.additionalincome;
    inputData.hni = props.leadAddDetailInput.hni;
    inputData.CareTakerName = props.leadAddDetailInput.enquiryby;
    inputData.tpa = props.leadAddDetailInput.tpa;
    inputData.pageFormName = props.leadAddDetailInput.pageformname;
    inputData.InsuranceDate = props.leadAddDetailInput.InsuranceDate || null;
    inputData.CashOrInsurance =
      props.leadAddDetailInput.CashOrInsurance || null;
    inputData.MRNumber = props.leadAddDetailInput.MRNumber || null;
    inputData.BillNo = props.leadAddDetailInput.BillNo || null;
    inputData.EmploymentType = props.leadAddDetailInput.employeetype || null;
    inputData.CompanyName = props.leadAddDetailInput.companyname || null;
    inputData.CareTakerRelationship =
      props.leadAddDetailInput.caretakerrelationship || null;
    inputData.CareTakerAge = Number(props.leadAddDetailInput.caretakerage) || 0;
    inputData.CareTakerEmail =
      props.leadAddDetailInput.caretakeremailiD || null;
    inputData.CaseCategory = props.leadAddDetailInput.casecategory || null;
    inputData.AgrDiscount = props.leadAddDetailInput.agrdiscount || null;
    inputData.DiscountedAmount =
      props.leadAddDetailInput.discountedamount || null;
    inputData.EnquiryKeywords =
      props.leadAddDetailInput.enquirykeywords || null;
    inputData.ExpectedRevenue =
      props.leadAddDetailInput.expectedrevenue || null;
    //inputData.PageFormName = this.state.leadAddDetailInput.pageformname || null;
    inputData.TotalRevenue = props.leadAddDetailInput.totalrevenue || null;
    inputData.SourceURL = props.leadAddDetailInput.SourceURL || null;
    inputData.SpecialityID = props.leadAddDetailInput.speciality || 0;
    inputData.RequestedServiceID =
      props.leadAddDetailInput.requestedservice !== undefined
        ? props.leadAddDetailInput.requestedservice + ""
        : null;

    inputData.AppointmentDateTime = props.leadAddDetailInput.AppointmentDateTime
      ? moment(props.leadAddDetailInput.AppointmentDateTime)
          .format("YYYY-MM-DD HH:mm A")
          .toString()
      : null;
    inputData.PreferredDateTimeToContact = props.leadAddDetailInput
      .PreferredDateTimeToContact
      ? moment(props.leadAddDetailInput.PreferredDateTimeToContact)
          .format("YYYY-MM-DD hh:mm A")
          .toString()
      : null;
    setState({ isSubmitLoading: true });
    try {
      leadsService
        .createLead("CreateAdditionalLeadDetail", inputData)
        .then((response) => {
          var message = response.message;

          if (message === "Success") {
            handleCreateTaksAndCreateLead(leadId);

            setState({
              createLeadModal: false,
              isSubmitLoading: false,
              isCreateTaskandSave: false,
            });
            props.handleGetLeadList();
          } else {
            setState({ isSubmitLoading: false });
            NotificationManager.error("Record Not Created.");
          }
        })
        .catch((error) => {
          setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      setState({ isSubmitLoading: false });
      console.log(error);
    }
  }

  function handleChangeInput(name, e) {
    if (name === "LeadStatus") {
      setLeadStatus(e);
      setLeadSubStatus(null);
      setLeadSubStatusList([]);
      var disableTask = leadStatusList.filter((x) => x.leadStatusID === e)[0]
        .disableTask;
      setDisableTask(disableTask);
      setTimeout(() => {
        handleGetLeadSubStatusList(e);
      }, 200);
    } else if (name === "LeadSubStatus") {
      setLeadSubStatus(e);
    } else if (name === "AssignTo") {
      setAssignTo(e);
    }
  }
  function handleGetLeadSubStatusList(id) {
    var inputData = {};
    inputData.LeadStatusID = id;
    try {
      leadsService
        .getLeadDropdownData("GetLeadSubStatus", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadSubStatusData = response.responseData;
          if (statusCode === 200) {
            setLeadSubStatusList(leadSubStatusData);
          } else {
            setLeadSubStatusList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function handleCheckValidation() {
    var isValid = true;

    if (!leadStatus) {
      isValid = false;
      setIsLeadStatus("Please select lead status.");
    }
    if (!leadSubStatus) {
      isValid = false;
      setIsLeadSubStatus("Please select lead sub status.");
    }
    if (!disableTask) {
      if (!selectedDate) {
        isValid = false;
        setIsSelectedDate("Please select date.");
      }
      if (!title) {
        isValid = false;
        setIsTitle("Please enter title.");
      }
      if (!taskType) {
        isValid = false;
        setIsTaskType("Please select task type.");
      }
      if (!assignTo) {
        isValid = false;
        setIsAssignTo("Please select lead status.");
      }
      if (props.FollowUp) {
        if (!Reason) {
          isValid = false;
          setIsAssignTo("Please enter reason.");
        }
      }
    }
    return isValid;
  }

  function handleCreateTaksAndCreateLead(leadId) {
    if (handleCheckValidation()) {
      try {
        var inputData = {};
        inputData.TaskTitle = title;
        inputData.LeadStatusID = leadStatus;
        inputData.LeadSubStatusID = leadSubStatus;
        if (selectedDate) {
          inputData.TaskScheduleDateTime = moment(selectedDate)
            .format("YYYY-MM-DD HH:mm A")
            .toString();
        } else {
          inputData.TaskScheduleDateTime = null;
        }

        inputData.TaskAssignedID = assignTo || 0;
        inputData.TaskDetails = note
          ? note.toString().replace(new RegExp("<[^>]*>", "g"), "")
          : "";
        if (duration) {
          inputData.TaskDurationValue = Number(duration);
          inputData.TaskDuration = durationType;
        } else {
          inputData.TaskDurationValue = 0;
          inputData.TaskDuration = "";
        }

        inputData.TaskTypeID = Number(taskType) || 0;
        inputData.ServiceID = serviceType || 0;
        inputData.LeadID = leadId;
        inputData.Reason = Reason || "";
        inputData.NotifyProvider = notifyProvider;
        setIsSubmitLoading(true);
        leadsService
          .getLeadDetailsTab("InsertLeadTask", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
            if (statusCode === 200) {
              if (document.getElementById("taskEditID")) {
                document.getElementById("taskEditID").click();
              }

              //props.handleCreateTaskClose(true);
              NotificationManager.success(
                "Task and Lead created successfully."
              );
              //handleUpdateStatus();
              if (isEditTask) {
                /*if (!disableTask) {*/
                handleTaskUpdate(2);
                //}
              }
            } else {
              setIsSubmitLoading(false);
              NotificationManager.error("Taks Add fail.");
            }
          })
          .catch((error) => {
            setIsSubmitLoading(false);
            console.log(error);
          });
      } catch (error) {
        setIsSubmitLoading(false);
        console.log(error);
      }
    }
  }

  function handleCreateTaksAndUpdateGridData() {
    if (handleCheckValidation()) {
      try {
        var inputData = {};
        inputData.TaskTitle = title;
        inputData.LeadStatusID = leadStatus;
        inputData.LeadSubStatusID = leadSubStatus;
        if (selectedDate) {
          inputData.TaskScheduleDateTime = moment(selectedDate)
            .format("YYYY-MM-DD HH:mm A")
            .toString();
        } else {
          inputData.TaskScheduleDateTime = null;
        }

        inputData.TaskAssignedID = assignTo || 0;
        inputData.TaskDetails = note
          ? note.toString().replace(new RegExp("<[^>]*>", "g"), "")
          : "";
        if (duration) {
          inputData.TaskDurationValue = Number(duration);
          inputData.TaskDuration = durationType;
        } else {
          inputData.TaskDurationValue = 0;
          inputData.TaskDuration = "";
        }

        inputData.TaskTypeID = Number(taskType) || 0;
        inputData.ServiceID = serviceType || 0;
        inputData.LeadID = props.LeadDetails.leadID;
        inputData.Reason = Reason || "";
        inputData.NotifyProvider = notifyProvider;
        setIsSubmitLoading(true);
        leadsService
          .getLeadDetailsTab("InsertLeadTask", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
            if (statusCode === 200) {
              if (document.getElementById("taskEditID")) {
                document.getElementById("taskEditID").click();
              }
              props.handleCreateTaskClose(true);
              NotificationManager.success("Task created successfully.");

              if (isEditTask) {
                /*if (!disableTask) {*/
                handleTaskUpdate(2);
                //}
              }
            } else {
              setIsSubmitLoading(false);
              NotificationManager.error("Taks Add fail.");
            }
          })
          .catch((error) => {
            setIsSubmitLoading(false);
            console.log(error);
          });
      } catch (error) {
        setIsSubmitLoading(false);
        console.log(error);
      }
    }
  }

  function handleCreateTaks() {
    if (handleCheckValidation()) {
      try {
        var inputData = {};
        inputData.TaskTitle = title;
        inputData.LeadStatusID = leadStatus;
        inputData.LeadSubStatusID = leadSubStatus;
        if (selectedDate) {
          inputData.TaskScheduleDateTime = moment(selectedDate)
            .format("YYYY-MM-DD HH:mm A")
            .toString();
        } else {
          inputData.TaskScheduleDateTime = null;
        }

        inputData.TaskAssignedID = assignTo
          ? Number(assignTo.split("-")[0])
          : 0;
        inputData.IsTeamUser = assignTo
          ? assignTo.split("-")[1] === "true"
            ? true
            : false
          : false;
        inputData.TaskDetails = note
          ? note.toString().replace(new RegExp("<[^>]*>", "g"), "")
          : "";
        if (duration) {
          inputData.TaskDurationValue = Number(duration);
          inputData.TaskDuration = durationType;
        } else {
          inputData.TaskDurationValue = 0;
          inputData.TaskDuration = "";
        }
        inputData.TaskTypeID = Number(taskType) || 0;
        inputData.ServiceID = serviceType || 0;
        inputData.LeadID = props.LeadID;
        inputData.Reason = Reason || "";
        inputData.NotifyProvider = notifyProvider;
        setIsSubmitLoading(true);
        leadsService
          .getLeadDetailsTab("InsertLeadTask", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
            if (statusCode === 200) {
              if (document.getElementById("taskEditID")) {
                document.getElementById("taskEditID").click();
              }
              handleCreateTaskClose(true);
              NotificationManager.success("Task created successfully.");

              if (isEditTask) {
                /*if (!disableTask) {*/
                handleTaskUpdate(2);
                //}
              }
            } else {
              setIsSubmitLoading(false);
              NotificationManager.error("Tasks not created successfully.");
            }
          })
          .catch((error) => {
            setIsSubmitLoading(false);
            console.log(error);
          });
      } catch (error) {
        setIsSubmitLoading(false);
        console.log(error);
      }
    }
  }

  function handleTaskTypeChange(e) {
    setTaskType(e.target.value);
  }

  //handle get state list by country id
  function handleGetStateByCountryIDList() {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      commonService
        .getStateList("GetStateByCountryID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var State = response.responseData;
          if (statusCode === 200) {
            setStateData(State);
          } else {
            setStateData(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  //handle get city by state id
  function handleGetCityByStateIDList(id) {
    var inputData = {};
    inputData.IDs = id + "";
    inputData.Value = "";
    try {
      commonService
        .getCityList("GetCityMasterByStateID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var City = response.responseData;
          if (statusCode === 200) {
            setCityData(City);
          } else {
            setCityData(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function handleGetLocationList() {
    try {
      appointmentService
        .getAppointmentLocation("GetLocation")
        .then((response) => {
          var statusCode = response.statusCode;
          var locationData = response.responseData;
          if (statusCode === 200) {
            setLocationData(locationData);
          } else {
            setLocationData(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function handleGetSpecialityList(id) {
    var inputData = {};
    inputData.LocationId = id;
    try {
      appointmentService
        .getAppointmentDropdown("GetSpeciality", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var locationData = response.responseData;
          if (statusCode === 200) {
            setSpecialityData(locationData);
          } else {
            setSpecialityData(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  function handleGetAssignToList(department) {
    var inputData = {};
    inputData.LocationId = location;
    inputData.Department = department;
    try {
      appointmentService
        .getAppointmentDropdown("GetAssignTo", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var appointementAssignTo = response.responseData;
          if (statusCode === 200) {
            setAppAssignToData(appointementAssignTo);
          } else {
            setAppAssignToData(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  function handleGetAppointmentTypeList(AssignTo) {
    var inputData = {};
    inputData.LocationId = location;
    inputData.AssignToId = AssignTo;
    try {
      appointmentService
        .getAppointmentDropdown("GetAppointmentType", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var appointmentTypeData = response.responseData;
          if (statusCode === 200) {
            setAppointmentTypeData(appointmentTypeData);
          } else {
            setAppointmentTypeData(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  function handleGetAppointmentSlotList(date) {
    var inputData = {};
    inputData.LocationId = location;
    inputData.AssignToId = appointementAssignTo;
    inputData.AppointmentType = appointmentType;
    inputData.Date = moment(date).format("YYYY-MM-DD").toString();
    try {
      appointmentService
        .getAppointmentDropdown("GetAppointmentSlot", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var slotsData = response.responseData;
          if (statusCode === 200) {
            setSlotsData(slotsData);
          } else {
            setSlotsData(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  function handleAppointmentValidation() {
    var isValid = true;
    if (!leadStatus) {
      isValid = false;
      setIsLeadStatus("Please select lead status.");
    }
    if (!leadSubStatus) {
      isValid = false;
      setIsLeadSubStatus("Please select lead sub status.");
    }
    if (!disableTask) {
      if (!name) {
        isValid = false;
        setIsName("Please enter name.");
      }
      if (!mobileNo) {
        isValid = false;
        setIsMobileNo("Please enter mobile no.");
      }
      if (emailID) {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(emailID)) {
          isValid = false;
          setIsEmailID("Please enter valid email address.");
        } else {
          isValid = true;
          setIsEmailID(null);
        }
      } else {
        isValid = false;
        setIsEmailID("Please enter email id.");
      }
      if (!state) {
        isValid = false;
        setIsState("Please select state.");
      }
      if (!city) {
        isValid = false;
        setIsCity("Please select city.");
      }
      if (!speciality) {
        isValid = false;
        setISSpeciality("Please select speciality.");
      }
      if (!location) {
        isValid = false;
        setIsLocation("Please select location.");
      }
      if (!appointmentType) {
        isValid = false;
        setIsAppointmentType("Please select appointment type.");
      }
      if (!appointmentDate) {
        isValid = false;
        setIsAppointmentDate("Please select appointment date.");
      }
      if (!appointementAssignTo) {
        isValid = false;
        setIsAppointementAssignTo("Please select assign to.");
      }
      if (!slotes) {
        isValid = false;
        setIsSlot("Please select appointment slot.");
      }
      if (type === true) {
        if (!appointmentAmount) {
          isValid = false;
          setIsAppointmentAmount("Please enter appointment fee.");
        }
      }
    }
    return isValid;
  }
  function handleCreateAppointment() {
    if (handleAppointmentValidation()) {
      var inputData = {};
      inputData.LocationId = location;
      inputData.AssignToId = appointementAssignTo;
      inputData.AppointmentType = appointementAssignTo;
      inputData.Date = slotes;
      inputData.LeadID = props.LeadDetails.leadID;
      inputData.LeadStatus = leadStatus;
      inputData.LeadSubStatus = leadSubStatus;
      inputData.Name = name;
      inputData.EmailID = emailID;
      inputData.MobileNumber = mobileNo;
      inputData.StateID = state;
      inputData.CityID = city;
      if (location) {
        var locationObj = locationData.filter((x) => x.id === location)[0];

        inputData.LocationName = locationObj.name;
        inputData.LocationPin = locationObj.pin;
        inputData.LocationCity = locationObj.city;
        inputData.LocationArea = locationObj.area;
      }
      if (appointementAssignTo) {
        var assignToObj = AppAssignToData.filter(
          (x) => x.id === appointementAssignTo
        )[0];
        inputData.AssignToID = assignToObj.id;
        inputData.AssignToFirstName = assignToObj.firstName;
        inputData.AssignToLastName = assignToObj.lastName;
        inputData.Qualifications = assignToObj.qualifications;
        inputData.Department = assignToObj.department;
      }
      if (appointmentType) {
        inputData.AppointmanrTypeID = 0;
        inputData.AppointmanrTypeName = appointmentType;
      }
      if (speciality) inputData.SpecialityID = 0;
      inputData.Remarks = note.replace(new RegExp("<[^>]*>", "g"), "");
      inputData.IsFree = type;
      if (type) {
        inputData.AppointmanrFee = appointmentAmount;
      }
      try {
        appointmentService
          .createAppointment("CreateAppointment", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
            if (statusCode === 200) {
              if (document.getElementById("taskEditID")) {
                document.getElementById("taskEditID").click();
              }
              handleCreateTaskClose(true);
              NotificationManager.success("Task successfully.");
            } else {
              NotificationManager.error("Task Add fail.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }
  function handleTaskSave() {
    if (isEditTask) {
      setIsCloseTask(true);
      var disableTask = false;
      if (props.LeadDetails.leadStatus) {
        disableTask = props.leadStatusList.filter(
          (x) => x.leadStatusID === props.LeadDetails.leadStatus
        )[0].disableTask;

        setDisableTask(disableTask);
      } else {
        setDisableTask(disableTask);
      }

      if (props.LeadDetails.leadStatus) {
        setLeadStatus(props.LeadDetails.leadStatus);
      } else {
        setLeadStatus(null);
      }
      if (props.LeadDetails.leadSubStatus) {
        setLeadSubStatus(props.LeadDetails.leadSubStatus);
      } else {
        setLeadSubStatus(null);
      }
      if (props.FollowUp) {
        setDisableTask(false);
      }
      setTitle("");
      setNote("");
      setTaskType(null);
      setDurationType("M");
      setDuration(null);
      setAssignTo(null);
      setDate(null);
      setNote("");
      setTempate([]);
    } else {
      if (taskType === 3) {
        handleCreateAppointment();
      } else {
        if (props.leadStatus) {
          setLeadStatus(props.leadStatus);
          if (props.leadSubStatus) {
            handleCreateLead();
          } else {
            handleCreateTaksAndUpdateGridData();
          }
        } else {
          handleCreateTaks();
        }
      }
    }
  }

  function handleCreateNewTask() {
    handleCreateTaks();
  }

  function handleUpdateTask() {
    if (isEditTask) {
      handleTaskUpdate(1);
    }
  }

  function disabledDate(current) {
    // Can not select days before today
    return moment(current).add(1, "d") < moment().endOf("day");
  }
  function handleTaskUpdate(TaskStatusId) {
    var inputData = {};
    inputData.LeadID = props.LeadID;
    inputData.TaskTypeID = props.editTaskDetails.taskTypeID;
    inputData.TaskStatusID = TaskStatusId;
    inputData.LeadTaskID = props.editTaskDetails.leadTaskID;
    inputData.TaskDetails = editNote
      ? editNote.toString().replace(new RegExp("<[^>]*>", "g"), "")
      : "";
    console.log(inputData);
    try {
      leadsService
        .createLead("UpdateLeadTask", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          if (statusCode === 200) {
            if (document.getElementById("taskEditID")) {
              document.getElementById("taskEditID").click();
            }
            props.handleCreateTaskClose(true);
            NotificationManager.success("Task Updated Successfully.");
          } else {
            NotificationManager.error("Task Not Updated Successfully.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  function handleGetServiceMaster() {
    try {
      commonService
        .getAccessTypeList("GetServiceMaster")
        .then((response) => {
          var statusCode = response.statusCode;
          var serviceData = response.responseData;
          if (statusCode === 200) {
            setServiceList(serviceData);
          } else {
            setServiceList(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  function handleGetTemplateMaster() {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      templateService
        .getTemplate("GetTemplateMaster", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var templateData = response.responseData;
          if (statusCode === 200) {
            setTempateList(templateData);
          } else {
            setTempateList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function handleTemplateChange(e) {
    setTempate(e);
    var smsBody = templateList.filter((x) => x.templateID === e)[0]
      .templateBody;
    setNote(smsBody);
  }

  return (
    <Fragment>
      <div className="addtas">
        <div className="customertab">
          <div className="bulk">
            <label>
              {isEditTask
                ? isCloseTask
                  ? "Add Task"
                  : "Edit Task"
                : "Add Task"}
            </label>
          </div>
          <div className="addtask-conent">
            <div className="row mb-4">
              <div className="col-12 col-md-6">
                <label className="mobile">Lead Status</label>
                <Select
                  value={leadStatus}
                  onChange={(e) => {
                    handleChangeInput("LeadStatus", e);
                    setIsLeadStatus(null);
                  }}
                  disabled={isEditTask ? (isCloseTask ? false : true) : false}
                  placeholder="Lead Status"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                >
                  {leadStatusList
                    ? leadStatusList.map((item) => {
                        return (
                          <Option
                            value={item.leadStatusID}
                            key={item.leadStatusID}
                          >
                            {item.statusName}
                          </Option>
                        );
                      })
                    : null}
                </Select>
                {isLeadStatus ? (
                  <div className="text-danger">{isLeadStatus}</div>
                ) : null}
              </div>

              <div className="col-12 col-md-6">
                <label className="mobile">Sub Status</label>
                <Select
                  disabled={isEditTask ? (isCloseTask ? false : true) : false}
                  value={leadSubStatus}
                  onChange={(e) => {
                    handleChangeInput("LeadSubStatus", e);
                    setIsLeadSubStatus(null);
                  }}
                  placeholder="Lead Status"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                >
                  {leadSubStatusList
                    ? leadSubStatusList.map((item) => {
                        return (
                          <Option
                            value={item.leadSubStatusID}
                            key={item.leadSubStatusID}
                          >
                            {item.subStatusName}
                          </Option>
                        );
                      })
                    : null}
                </Select>
                {isLeadSubStatus ? (
                  <div className="text-danger">{isLeadSubStatus}</div>
                ) : null}
              </div>
            </div>
            {!disableTask ? (
              <Fragment>
                {props.FollowUp ? (
                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Reason</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Reason"
                        value={Reason}
                        disabled={
                          isEditTask ? (isCloseTask ? false : true) : false
                        }
                        onChange={(e) => {
                          setReason(e.target.value);
                          setIsReason(null);
                        }}
                      />
                      {IsReason ? (
                        <div className="text-danger">{IsReason}</div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="row mb-4">
                  <div className="col-12 col-md-12">
                    <label className="mobile">Activity</label>
                    <div className="acti">
                      <Radio.Group
                        disabled={
                          isEditTask ? (isCloseTask ? false : true) : false
                        }
                        value={taskType}
                        onChange={(e) => {
                          handleTaskTypeChange(e);
                          setIsTaskType(null);
                        }}
                      >
                        {taskTypeList
                          ? taskTypeList.map((item) => {
                              return (
                                <Radio.Button
                                  value={item.taskTypeID}
                                  key={item.taskTypeID}
                                >
                                  {/* <img src={item.ActiveICon} alt="CallBlueIcon" /> */}
                                  {item.taskName}
                                </Radio.Button>
                              );
                            })
                          : null}
                      </Radio.Group>
                      {isTaskType ? (
                        <div className="text-danger">{isTaskType}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {taskType !== 3 ? (
                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Title</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Title"
                        value={title}
                        disabled={
                          isEditTask ? (isCloseTask ? false : true) : false
                        }
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setIsTitle(null);
                        }}
                      />
                      {isTitle ? (
                        <div className="text-danger">{isTitle}</div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {taskType !== 3 ? (
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Date</label>
                      <DatePicker
                        disabled={
                          isEditTask ? (isCloseTask ? false : true) : false
                        }
                        value={selectedDate}
                        onChange={(e) => {
                          setDate(e);
                          setIsSelectedDate(null);
                        }}
                        disabledDate={disabledDate}
                        className="mobileinput"
                        placeholder="Start Date Time"
                        format="DD-MM-YYYY HH:mm A"
                        showTime={{ use12Hours: true, format: "HH:mm A" }}
                      />
                      {isSelectedDate ? (
                        <div className="text-danger">{isSelectedDate}</div>
                      ) : null}
                    </div>
                    {taskType === 1 ? (
                      <div className="col-12 col-md-6">
                        <label className="mobile">Duration</label>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <input
                            disabled={
                              isEditTask ? (isCloseTask ? false : true) : false
                            }
                            type="text"
                            className="mobileinput"
                            placeholder="Duration"
                            value={duration}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                setDuration(e.target.value);
                              } else {
                                setDuration(null);
                              }
                            }}
                          />
                          <Select
                            disabled={
                              isEditTask ? (isCloseTask ? false : true) : false
                            }
                            value={durationType}
                            onChange={(e) => {
                              setDurationType(e);
                            }}
                          >
                            <Option value={"M"}>Mins</Option>
                            <Option value={"H"}>Hours</Option>
                          </Select>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {taskType === 3 ? (
                  <React.Fragment>
                    <div className="row mb-4">
                      <div className="col-12 col-md-6">
                        <label className="mobile">Name</label>
                        <input
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          type="text"
                          className="mobileinput"
                          placeholder="Name"
                          onChange={(e) => {
                            setName(e.target.value);
                            setIsName(null);
                          }}
                          value={name}
                        />
                        {isName ? (
                          <div className="text-danger">{isName}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="mobile">Email ID</label>
                        <input
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          type="text"
                          className="mobileinput"
                          placeholder="Email ID"
                          onChange={(e) => {
                            setEmailID(e.target.value);
                            setIsEmailID(null);
                          }}
                          value={emailID}
                        />
                        {isEmailID ? (
                          <div className="text-danger">{isEmailID}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-6">
                        <label className="mobile">Mobile No</label>
                        <input
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          type="text"
                          className="mobileinput"
                          placeholder="Mobile No"
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setMobileNo(e.target.value);
                              setIsMobileNo(null);
                            } else {
                              setMobileNo(null);
                            }
                          }}
                          value={mobileNo}
                        />
                        {isMobileNo ? (
                          <div className="text-danger">{isMobileNo}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="mobile">State</label>
                        <Select
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          placeholder="State"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                          onChange={(e) => {
                            setState(e);
                            setIsState(null);
                            setCityData(null);
                            setCity(null);
                            handleGetCityByStateIDList(e);
                          }}
                          value={state}
                        >
                          {stateData
                            ? stateData.map((item) => {
                                return (
                                  <Option
                                    key={item.stateID}
                                    value={item.stateID}
                                  >
                                    {item.stateName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {isState ? (
                          <div className="text-danger">{isState}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-6">
                        <label className="mobile">City</label>
                        <Select
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          placeholder="City"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                          onChange={(e) => {
                            setCity(e);
                            setIsCity(null);
                          }}
                          value={city}
                        >
                          {cityData
                            ? cityData.map((item) => {
                                return (
                                  <Option key={item.cityID} value={item.cityID}>
                                    {item.cityName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {isCity ? (
                          <div className="text-danger">{isCity}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="mobile">
                          Branch/location/Site/Store
                        </label>
                        <Select
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          placeholder="Branch/location/Site/Store"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                          onChange={(e) => {
                            setLocation(e);
                            setIsLocation(null);
                            handleGetSpecialityList(e);
                          }}
                          value={location}
                        >
                          {locationData
                            ? locationData.map((item) => {
                                return (
                                  <Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {isLocation ? (
                          <div className="text-danger">{isLocation}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-6">
                        <label className="mobile">Speciality/service</label>
                        <Select
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          placeholder="Speciality/service"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                          onChange={(e) => {
                            setSpeciality(e);
                            setISSpeciality(null);
                            handleGetAssignToList(e);
                          }}
                          value={speciality}
                        >
                          {specialityData
                            ? specialityData.map((item) => {
                                return (
                                  <Option value={item} key={item}>
                                    {item}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {isSpeciality ? (
                          <div className="text-danger">{isSpeciality}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="mobile">Assign To</label>
                        <Select
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          placeholder="Assign To"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={(e) => {
                            setAppointementAssignTo(e);
                            setIsAppointementAssignTo(null);

                            handleGetAppointmentTypeList(e);
                          }}
                          value={appointementAssignTo}
                        >
                          {AppAssignToData
                            ? AppAssignToData.map((item, i) => {
                                return (
                                  <Option value={item.id} key={i}>
                                    {item.firstName} {item.lastName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {isAppointementAssignTo ? (
                          <div className="text-danger">
                            {isAppointementAssignTo}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-6">
                        <label className="mobile">Appointment Type</label>

                        <Select
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          placeholder="Appointment Type"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                          onChange={(e) => {
                            setAppointmentType(e);
                            setIsAppointmentType(null);
                          }}
                          value={appointmentType}
                        >
                          {appointmentTypeData
                            ? appointmentTypeData.map((item) => {
                                return (
                                  <Option value={item} key={item}>
                                    {item}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {isAppointmentType ? (
                          <div className="text-danger">{isAppointmentType}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="mobile">Date</label>
                        <DatePicker
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          className="mobileinput"
                          placeholder="Date"
                          format="DD-MM-YYYY"
                          onChange={(e) => {
                            setAppointmentDate(e);
                            setIsAppointmentDate(null);
                            handleGetAppointmentSlotList(e);
                          }}
                          value={appointmentDate}
                        />
                        {isAppointmentDate ? (
                          <div className="text-danger">{isAppointmentDate}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-6">
                        <label className="mobile">Slots</label>
                        <Select
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          placeholder="Slots"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                          onChange={(e) => {
                            setSlots(e);
                            setIsSlot(null);
                          }}
                          value={slotes}
                        >
                          {slotsData
                            ? slotsData.map((item) => {
                                return (
                                  <Option
                                    value={item.actualDateTime}
                                    key={item.actualDateTime}
                                  >
                                    {item.actualDateTime}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {isSlots ? (
                          <div className="text-danger">{isSlots}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <Switch
                          disabled={
                            isEditTask ? (isCloseTask ? false : true) : false
                          }
                          onChange={(e) => {
                            setType(e.target.checked);
                          }}
                          checked={type}
                          checkedChildren="Paid"
                          unCheckedChildren="Free"
                        />
                        <input
                          type="text"
                          className="mobileinput"
                          placeholder="Appointment Fee"
                          disabled={!type}
                          onChange={(e) => {
                            setAppointmentAmount(e.target.value);
                            setIsAppointmentAmount(null);
                          }}
                          value={appointmentAmount}
                        />
                        {isAppointmentAmount ? (
                          <div className="text-danger">
                            {isAppointmentAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
                {taskType === 4 ? (
                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <Select
                        disabled={
                          isEditTask ? (isCloseTask ? false : true) : false
                        }
                        placeholder="Select Service Name"
                        value={serviceType}
                        onChange={(e) => {
                          setServiceType(e);
                        }}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                      >
                        {serviceList
                          ? serviceList.map((item) => {
                              return (
                                <Option
                                  value={item.serviceID}
                                  key={item.serviceID}
                                >
                                  {item.serviceName}
                                </Option>
                              );
                            })
                          : null}
                      </Select>
                    </div>
                  </div>
                ) : null}
                {taskType !== 3 ? (
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Assign To</label>

                      <Select
                        disabled={
                          isEditTask ? (isCloseTask ? false : true) : false
                        }
                        value={assignTo}
                        onChange={(e) => {
                          handleChangeInput("AssignTo", e);
                          setIsAssignTo("");
                        }}
                        placeholder="Assign To"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                      >
                        {assignToList
                          ? assignToList.map((item, i) => {
                              return (
                                <Option
                                  value={item.userID + "-" + item.isTeamUser}
                                  key={i}
                                >
                                  {item.fullName}
                                </Option>
                              );
                            })
                          : null}
                      </Select>
                      {isAssignTo ? (
                        <div className="text-danger">{isAssignTo}</div>
                      ) : null}
                    </div>
                    {/*<div className="col-12 col-md-6">*/}
                    {/*  <label className="mobile">Notify</label>*/}

                    {/*  <Button*/}
                    {/*    disabled={isEditTask?(isCloseTask?false:true):false}*/}
                    {/*    type={notifyProvider ? "primary" : ""}*/}
                    {/*    onClick={() => setNotifyProvider(!notifyProvider)}*/}
                    {/*  >*/}
                    {/*    <img src={LeadIcon} alt="LeadIcon" />*/}
                    {/*    Provider*/}
                    {/*  </Button>*/}
                    {/*</div>*/}
                  </div>
                ) : null}
                <div className="row mb-4">
                  <div className="col-12 col-md-12">
                    <label className="mobile">Remarks</label>
                    <CKEditor
                      data={isEditTask ? (isCloseTask ? note : editNote) : note}
                      onChange={
                        isEditTask
                          ? isCloseTask
                            ? (e) => setNote(e.editor.getData())
                            : (e) => setEditNote(e.editor.getData())
                          : (e) => setNote(e.editor.getData())
                      }
                      config={{
                        toolbar: [
                          {
                            name: "basicstyles",
                            items: ["Bold", "Italic", "Strike"],
                          },
                          {
                            name: "styles",
                            items: ["Styles", "Format"],
                          },
                          {
                            name: "paragraph",
                            items: ["NumberedList", "BulletedList"],
                          },
                          {
                            name: "links",
                            items: ["Link", "Unlink"],
                          },
                          {
                            name: "insert",
                            items: ["Image", "Table"],
                          },
                          {
                            name: "tools",
                            items: ["Maximize"],
                          },
                          {
                            name: "editing",
                            items: ["Scayt"],
                          },
                        ],
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-12 col-md-6">
                    <label className="mobile">
                      Search Comment From Template
                    </label>
                    <div className="Sear">
                      <input type="search" className="mobileinput mr-1" />
                      <Select
                        disabled={
                          isEditTask ? (isCloseTask ? false : true) : false
                        }
                        placeholder="Select template"
                        value={template}
                        onChange={(e) => {
                          handleTemplateChange(e);
                        }}
                      >
                        {templateList &&
                          templateList.map((item) => (
                            <Select.Option value={item.templateID}>
                              {item.templateName}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : null}
          </div>

          <div className="row mb-2">
            <div className="col-12 col-md-6"></div>
            <div className="col-12 col-md-7">
              <div className="casane">
                <Button
                  id="taskEditID"
                  onClick={() => {
                    handleCreateTaskClose(false);
                  }}
                >
                  Cancel
                </Button>
                {isEditTask ? (
                  isCloseTask ? null : (
                    <Button
                      type="primary"
                      onClick={() => {
                        handleUpdateTask();
                      }}
                      loading={isSubmitLoading}
                      disabled={isSubmitLoading}
                    >
                      Update Task
                    </Button>
                  )
                ) : null}
                <Button
                  type="primary"
                  onClick={
                    isEditTask
                      ? isCloseTask
                        ? () => {
                            handleCreateNewTask();
                          }
                        : () => {
                            handleTaskSave();
                          }
                      : () => {
                          handleTaskSave();
                        }
                  }
                  loading={isSubmitLoading}
                  disabled={isSubmitLoading}
                >
                  {isEditTask
                    ? isCloseTask
                      ? "Save"
                      : "Close And Create New Task"
                    : "Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
