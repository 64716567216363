import React, { Component, Fragment } from "react";
import { Button, Select } from "antd";
import LeadsService from "../../../services/Leads/LeadsService";
import { NotificationManager } from "react-notifications";
const { Option } = Select;

export default class LeadActionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LeadStatus: "QueryLead",
      LeadSubStatus: "QueryLead",
      LeadStatusList: props.LeadStatusList,
      LeadSubStatusList: [],
      leadID: 0,
      isLeadStatus: "",
      isLeadSubStatus: "",
      isDisabled: true,
    };
    this.leadsService = new LeadsService();
  }
  componentDidMount() {
    if (this.props.isStatusClick) {
      this.setState({
        LeadStatus: this.props.LeadStatus,
        isDisabled: false,
        LeadSubStatus: null,
      });
    } else {
      this.setState({
        isDisabled: true,
      });
    }
    setTimeout(() => {
      this.handleGetLeadSubStatusList();
    }, 1000);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isStatusClick) {
      this.setState({
        LeadStatus: nextProps.LeadStatus,
        isDisabled: false,
        LeadSubStatus: null,
      });
    } else {
      this.setState({
        LeadStatus: "QueryLead",
        LeadSubStatus: "QueryLead",
        isDisabled: true,
      });
    }
    setTimeout(() => {
      this.handleGetLeadSubStatusList();
    }, 500);
  }

  handleQueryLeadChange = async (name, e) => {
    await this.setState({
      [name]: e,
    });
    if (name === "LeadStatus") {
      this.setState({
        isLeadStatus: "",
      });
    } else {
      this.setState({
        isLeadSubStatus: "",
      });
    }

    if (name === "LeadStatus") {
      this.handleGetLeadSubStatusList();
    }
  };
  handleGetLeadSubStatusList = () => {
    var inputData = {};
    var StatusID = 0;
    if (this.props.isStatusClick) {
      StatusID = Number(this.state.LeadStatus);
    } else {
      StatusID = this.state.LeadStatusList.filter(
        (x) =>
          x.statusName.toLowerCase() === this.state.LeadStatus.toLowerCase()
      )[0].leadStatusID;
    }

    inputData.LeadStatusID = StatusID;
    try {
      this.leadsService
        .getLeadDropdownData("GetLeadSubStatus", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var LeadSubStatusList = response.responseData;
          if (statusCode === 200) {
            this.setState({ LeadSubStatusList });
          } else {
            this.setState({ LeadSubStatusList: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleLeadValidation = () => {
    var isValid = true;
    if (!this.state.LeadStatus) {
      isValid = false;
      this.setState({
        isLeadStatus: "Please select lead status.",
      });
    }

    if (!this.state.LeadStatus) {
      isValid = false;
      this.setState({
        isLeadSubStatus: "Please select lead status.",
      });
    }
    return isValid;
  };
  handleUpdateStatus = () => {
    if (this.handleLeadValidation()) {
      var inputData = {};
      inputData.LeadID = this.props.LeadID;
      var StatusID = 0;
      var SubStatusID = 0;
      if (this.props.isStatusClick) {
        StatusID = Number(this.state.LeadStatus);
        SubStatusID = this.state.LeadSubStatusList.filter(
          (x) =>
            x.subStatusName.toLowerCase() ===
            this.state.LeadSubStatus.toLowerCase()
        )[0].leadSubStatusID;
      } else {
        StatusID = this.state.LeadStatusList.filter(
          (x) =>
            x.statusName.toLowerCase() === this.state.LeadStatus.toLowerCase()
        )[0].leadStatusID;
        SubStatusID = this.state.LeadSubStatusList.filter(
          (x) =>
            x.subStatusName.toLowerCase() ===
            this.state.LeadSubStatus.toLowerCase()
        )[0].leadSubStatusID;
      }

      inputData.StatusID = StatusID;
      inputData.SubStatusID = SubStatusID;
      try {
        this.leadsService
          .getLeadDetailsTab("UpdateLeadIDStatus", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
            if (statusCode === 200) {
              this.setState({
                isLeadSubStatus: "",
                isLeadStatus: "",
                LeadStatus: 21,
                LeadSubStatus: 92,
              });
              this.props.handleCallBackQueryModalClose(true);
              NotificationManager.success("Status update successfully.");
            } else {
              NotificationManager.error("Status not update.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  render() {
    return (
      <Fragment>
        <div className="row">
          {this.props.isStatusClick ? null : (
            <div className="col-md-6">
              <label>Lead Status:</label>
              <Select
                value={this.state.LeadStatus}
                style={{ width: "100%" }}
                placeholder="Select Lead Status"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                optionFilterProp="children"
                onChange={this.handleQueryLeadChange.bind(this, "LeadStatus")}
                disabled={this.state.isDisabled}
              >
                {this.state.LeadStatusList
                  ? this.state.LeadStatusList.map((item) => {
                      return (
                        <Option
                          value={item.statusName}
                          // value={item.leadStatusID}
                          key={item.leadStatusID}
                        >
                          {item.statusName}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </div>
          )}
          <div className="col-md-6">
            <label>Lead Sub Status:</label>
            <Select
              disabled={this.state.isDisabled}
              value={this.state.LeadSubStatus}
              style={{ width: "100%" }}
              placeholder="Select Lead Sub Status"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              optionFilterProp="children"
              onChange={this.handleQueryLeadChange.bind(this, "LeadSubStatus")}
            >
              {this.state.LeadSubStatusList
                ? this.state.LeadSubStatusList.map((item) => {
                    return (
                      <Option
                        // value={item.leadSubStatusID}
                        value={item.subStatusName}
                        key={item.leadSubStatusID}
                      >
                        {item.subStatusName}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </div>
        </div>

        <div className="row qurylead-div">
          <div className="col-md">
            <Button
              onClick={() => this.props.handleCallBackQueryModalClose(false)}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={this.handleUpdateStatus.bind(this)}>
              Update
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}
