import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Table, Switch, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import { NotificationManager } from "react-notifications";
import LeadsService from "../../../services/Leads/LeadsService";

export default class LeadDuplicacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAccess: true,
            viewAccess: true,
            searchBy: "",
            leadStatusData: [],
            LeadDuplicacygridData: [
                {
                    Fields: "CustomerName",
                    Check:true
                },
                {
                    Fields: "MobileNo",
                    Check: false
                },
                {
                    Fields: "EmailId",
                    Check: true
                },
                {
                    Fields: "Status",
                    Check: false
                },
                {
                    Fields: "CreatedDate",
                    Check: true
                },
            ]
        };

        this.LeadsService = new LeadsService();

    }
    componentDidMount() {
        this.handleGetLeadStatusData();
    }

    handleGetLeadStatusData = () => {

        var inputData = {};
        //inputData.BrandID = "0";
        //inputData.EnquiryTypeID = enquiryTypeId.toString()
        try {
            this.LeadsService.getLeadDropdownData("GetLeadStatus")
                .then((response) => {

                    var statusCode = response.statusCode;
                    var leadStatusData = response.responseData;
                    if (statusCode === 200) {
                        this.setState({ leadStatusData });
                    } else {
                        this.setState({ leadStatusData: [] });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };


    render() {
        const { Option } = Select;
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                Lead Duplicacy
                                {/*{this.state.createAccess &&*/}
                                {/*    <img*/}
                                {/*        src={AddIcon}*/}
                                {/*        alt="add"*/}
                                {/*    //onClick={this.handleTeamModalOpen.bind(this)}*/}
                                {/*    />}*/}
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                name="searchByTeam"
                                value={this.state.searchByTeam}
                                //onChange={this.handleSerchInputChange.bind(this)}
                                placeholder="Search by..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    <div className="RoleAccessTable">
                        <Table
                            columns={[
                                {
                                    title: "Field Name",
                                    dataIndex: "Fields",
                                    key: "Fields",
                                    render: (row, item) => {
                                        return (
                                            <div className="report">
                                                <label>{item.Fields}</label>
                                            </div>
                                        );
                                    },
                                },
                                
                                {
                                    title: "Check",
                                    key: "Check",
                                    dataIndex: "Check",
                                    render: (row, item) => {
                                        return (
                                            <div className="report">
                                                <Switch
                                                    checkedChildren="On"
                                                    unCheckedChildren="Off"
                                                    checked={item.Check}

                                                />
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: "",
                                    key: "Check",
                                    dataIndex: "Check",
                                    render: (row, item) => {
                                        return (
                                            item.Fields === "Status" ? (
                                                <div className="col-12 col-md-6" >
                                                    <label className="desig">Status</label>


                                                    <Select
                                                        //onChange={this.handleOnChange.bind(this, "unitID")}
                                                        //value={this.state.teamInput.unitID}
                                                        placeholder="Select Status"
                                                        mode="multiple"
                                                        maxTagCount="responsive"
                                                    >
                                                        {this.state.leadStatusData
                                                            ? this.state.leadStatusData.map((item, key) => {
                                                                return (
                                                                    <Option value={item.leadStatusID}>
                                                                        {item.statusName}
                                                                    </Option>
                                                                );
                                                            })
                                                            : null}
                                                    </Select>
                                                </div>
                                            ) :
                                                item.Fields === "CreatedDate" ? (
                                                    <div className="col-6 col-md-2">
                                                        <label className="desig">Hours</label>
                                                        <Input
                                                            placeholder="hours"
                                                            name="hours"
                                                            //value={item.displayName}

                                                        />
                                                    </div>
                                                ) : null
                                        );
                                    },
                                },

                            ]}
                            dataSource={this.state.LeadDuplicacygridData}
                            pagination={false}
                        />
                    </div>
















                </div>
            </div>
        );
    }
}
