import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  NotificationIcon,
  SearchIcon,
  SettingIcon,
  ProfileIcon,
  MenuBarIcon,
  EditIcon,
  LogoutIcon,
  LeadCloudIcon,
  SUserIcon,
  PencilIcon
} from "./../../../helpers/ImagesImport";
import { Dropdown, Empty, Menu, Modal } from "antd";
import authService from "./../../api-authorization/AuthorizeService";
import { ApplicationPaths } from "./../../api-authorization/ApiAuthorizationConstants";
import NotificationService from "./../../../services/Notification/NotificationService";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      hamburgerClick: false,
      isAuthenticated: false,
      userName: null,
      user: {},
      notification: false,
      notificationCount: 0,
      notificationData: [],
    };

    this.NotificationService = new NotificationService();
    }

  componentDidMount() {
    this.handleGetUserLogin();
    this.handleGetNotificationData();
  }

  componentWillUnmount() {}
  handleGetUserLogin = async () => {
      debugger;
    const [user] = await Promise.all([authService.getUser()]);

    this.setState({
      user,
    });
  };
  handleLogoutOpen() {
    this.setState({ logout: true });
  }
  handleLogoutClose() {
    this.setState({ logout: false });
  }
  handleNotificationOpen() {
    this.setState({ notification: true });
  }
  handleNotificationClose() {
    this.setState({ notification: false });
  }

    handleGetNotificationData = () => {
        
    try {
      this.NotificationService.getNotificationsData("GetNotifications")
          .then((response) => {
              
          var message = response.message;
          var notificationCount = response.responseData.notificationcount;
          var notificationData = response.responseData.notifications;
          if (message == "Success") {
            this.setState({ notificationData, notificationCount });
          } else {
            this.setState({ notificationData: [], notificationCount: 0 });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    };

   
  handleReadNotifications = (notificationId) => {
    var inputData = {};
    inputData.NotificationID = notificationId;
    try {
      if (document.getElementById("notificationIDIMG")) {
        document.getElementById("notificationIDIMG").click();
      }
      this.NotificationService.readNotifications("ReadNotifications", inputData)
        .then((response) => {
          var message = response.message;
            if (message == "Success") {
               
            this.handleGetNotificationData();
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const logoutPath = {
      pathname: `${ApplicationPaths.LogOut}`,
      state: { local: true },
    };
    return (
      <div>
        <header>
          <div className="lefticon">
            <img
              src={MenuBarIcon}
              alt="MenuBarIcon"
              onClick={this.props.handleCheckHambergaurClick}
            />
            <img src={LeadCloudIcon} alt="LeadCloudIcon" />
          </div>
          <div className="righticon">
            <ul>
              {/* <li>
                <Link to="#">
                  <img src={SearchIcon} alt="SearchIcon" />
                </Link>
              </li> */}
              <li>
                <Dropdown
                  overlay={
                    <Menu>
                      <div className="notification-menu-div">
                        <div>
                          <div>
                            {this.state.notificationData.length > 0 ? (
                              this.state.notificationData.map((item) => (
                                <div className="row">
                                  <div className="col-12 col-md-12">
                                     <Link
                                      to={{
                                        pathname: "LeadDetails",
                                        state: {
                                            leadID: item.leadID,
                                          isNotifiction: true,
                                        },
                                      }}
                                      onClick={this.handleReadNotifications.bind(
                                        this,
                                        item.notificationID
                                      )}
                                    >
                                      {item.notificationName}
                                    </Link> 
                                    {/*<label*/}
                                    {/*  onClick={this.handleReadNotifications.bind(*/}
                                    {/*    this,*/}
                                    {/*    item.notificationID*/}
                                    {/*  )}*/}
                                    {/*>*/}
                                    {/*  {item.notificationName}*/}
                                    {/*</label>*/}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <div>
                    <img
                      src={NotificationIcon}
                      alt="NotificationIcon"
                      id="notificationIDIMG"
                    />
                    {this.state.notificationCount > 0 && (
                      <span
                        className="notification-count"
                        id="notificationCount"
                      >
                        {/*{this.state.notificationCount}*/}
                        {this.state.notificationCount}
                      </span>
                    )}
                  </div>
                </Dropdown>
              </li>
              {/* <li>
                  <Link to="#">
                      <img src={SettingIcon} alt="SettingIcon" />
                  </Link>
              </li> */}
              <li onClick={this.handleLogoutOpen.bind(this)}>
                <img src={SUserIcon} alt="ProfileIcon" />
              </li>
            </ul>
          </div>
        </header>
        {/* Logout Modal */}
        <Modal
          visible={this.state.logout}
          closable={false}
          className="LogoutModal"
          onCancel={this.handleLogoutClose.bind(this)}
          footer={null}
        >
          <div className="logout">
            <div className="logprofile">
              <img src={SUserIcon} alt="ProfileIcon" />
              <Link
                to="userprofile"
              >
                <img
                 src={PencilIcon}
                 alt="Pencile"
                 className="pencilImg"
                 title="Edit Profile"
                />
              </Link>
              <div className="logtext">
                {/* <label>
                  Tejas Kadakia
                  <img src={EditIcon} alt="EditIcon" />
                </label> */}
                {this.state.user ? (
                  <span>{this.state.user["name"] || ""}</span>
                ) : null}
              </div>
            </div>
            <div className="logicon">
              <Link to={logoutPath}>
                <img src={LogoutIcon} alt="LogoutIcon" />
              </Link>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Header;
