import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Layout } from "./components/Layout";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import LeadList from "./components/private/Lead/LeadList";
import Opportunity from "./components/private/Lead/Opportunity";
import Hierarchy from "./components/private/AdminSetting/Hierarchy";
import Location from "./components/private/AdminSetting/Location";
import TaskType from "./components/private/AdminSetting/TaskType";
import Priority from "./components/private/AdminSetting/Priority";
import Alerts from "./components/private/AdminSetting/Alerts";
import Category from "./components/private/AdminSetting/Category";
import CrmRole from "./components/private/AdminSetting/CrmRole";
import CustomFields from "./components/private/AdminSetting/CustomFields";
import Dnd from "./components/private/AdminSetting/Dnd";
import LeadScoring from "./components/private/AdminSetting/LeadScoring";
import LeadStageManage from "./components/private/AdminSetting/LeadStageManage";
import SlaMaster from "./components/private/AdminSetting/SlaMaster";
import Template from "./components/private/AdminSetting/Template";
import Users from "./components/private/AdminSetting/Users";
import LeadDetails from "./components/private/Lead/LeadDetails";
import SpecialityMaster from "./components/private/AdminSetting/SpecialityMaster";
import "antd/dist/antd.css";
import "./assets/css/HeaderSidemenu/headersidemenu.css";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import LeadTeams from "./components/private/AdminSetting/LeadTeams";
import RoleAccessControl from "./components/private/AdminSetting/RoleAccessControl";
import LeadBulkUpload from "./components/private/Lead/LeadBulkUpload";
import FacebookIntegrator from "./components/private/AdminSetting/FacebookIntegrator";
import StatusMaster from "./components/private/AdminSetting/StatusMaster";
import EnquiryMaster from "./components/private/AdminSetting/EnquiryMaster";
import LeadSourceMaster from "./components/private/AdminSetting/LeadSourceMaster";
import UserProfile from "./components/private/AdminSetting/UserProfile";
import DoctorMaster from "./components/private/AdminSetting/DoctorMaster";
import ServiceMaster from "./components/private/AdminSetting/ServiceMaster";
import LeadFields from "./components/private/AdminSetting/LeadFields";
import LeadDuplicacy from "./components/private/AdminSetting/LeadDuplicacy";
import DNDMaster from "./components/private/AdminSetting/DNDMaster";

import Report from "./components/private/Reports/Report";
export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Fragment>
        <Router>
          <Layout>
            <Switch>
              <AuthorizeRoute exact path={"/LeadList"} component={LeadList} />
              <AuthorizeRoute
                exact
                path={"/LeadDetails"}
                component={LeadDetails}
              />
              <AuthorizeRoute
                exact
                path={"/LeadBulkUpload"}
                component={LeadBulkUpload}
              />
              <AuthorizeRoute
                exact
                path={"/Opportunity"}
                component={Opportunity}
              />
              <AuthorizeRoute exact path={"/hierarchy"} component={Hierarchy} />
              <AuthorizeRoute exact path={"/Location"} component={Location} />
              <AuthorizeRoute exact path={"/TaskType"} component={TaskType} />
              <AuthorizeRoute exact path={"/Priority"} component={Priority} />
              <AuthorizeRoute
                exact
                path={"/SpecialityMaster"}
                component={SpecialityMaster}
              />
              <AuthorizeRoute exact path={"/CrmRole"} component={CrmRole} />
              <AuthorizeRoute exact path={"/Category"} component={Category} />
              <AuthorizeRoute exact path={"/Dnd"} component={Dnd} />
              <AuthorizeRoute
                exact
                path={"/leadStageManage"}
                component={LeadStageManage}
              />
              <AuthorizeRoute exact path={"/SlaMaster"} component={SlaMaster} />
              <AuthorizeRoute exact path={"/Users"} component={Users} />
              <AuthorizeRoute
                exact
                path={"/LeadScoring"}
                component={LeadScoring}
              />
              <AuthorizeRoute
                exact
                path={"/CustomFields"}
                component={CustomFields}
              />
              <AuthorizeRoute exact path={"/Alerts"} component={Alerts} />
              <AuthorizeRoute exact path={"/Template"} component={Template} />
              <AuthorizeRoute exact path={"/LeadTeams"} component={LeadTeams} />
              <AuthorizeRoute
                exact
                path={"/RoleAccessControl"}
                component={RoleAccessControl}
              />
                        <AuthorizeRoute exact path={"/FacebookConnector"} component={FacebookIntegrator} />
                        <AuthorizeRoute exact path={"/StatusMaster"} component={StatusMaster} />
                        <AuthorizeRoute exact path={"/EnquiryMaster"} component={EnquiryMaster} />
                        <AuthorizeRoute exact path={"/LeadSourceMaster"} component={LeadSourceMaster} />
                        <AuthorizeRoute exact path={"/DoctorMaster"} component={DoctorMaster} />
                        <AuthorizeRoute exact path={"/ServiceMaster"} component={ServiceMaster} />
                        <AuthorizeRoute exact path={"/LeadFields"} component={LeadFields} />
                        <AuthorizeRoute exact path={"/LeadDuplicacy"} component={LeadDuplicacy} />
                        <AuthorizeRoute exact path={"/DNDMaster"} component={DNDMaster} />

              <Route
                path={ApplicationPaths.ApiAuthorizationPrefix}
                component={ApiAuthorizationRoutes}
              />
              <AuthorizeRoute exact path={"/Report"} component={Report} />
              <AuthorizeRoute exact path={"/UserProfile"} component={UserProfile} />

            </Switch>
            <NotificationContainer />
          </Layout>
        </Router>
      </Fragment>
    );
  }
}
