import Configuration from "../../helpers/Configuration";
import axios from "axios";
import authService from "../../components/api-authorization/AuthorizeService";

class StatusService {
    constructor() {
        this.config = new Configuration();
    }

    async insertStatus(url, inputData) {
        const response = await axios(this.config.StatusServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getStatus(url, inputData) {
        const response = await axios(this.config.StatusServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async updateStatus(url, inputData) {
        const response = await axios(this.config.StatusServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async deleteStatus(url, inputData) {
        const response = await axios(this.config.StatusServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

}
export default StatusService;