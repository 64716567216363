import React, { Component, Fragment } from "react";
import Header from "./private/HeaderSideMenu/Header";
import SideMenu from "./private/HeaderSideMenu/SideMenu";
import authService from "./api-authorization/AuthorizeService";

export class Layout extends Component {
  static displayName = Layout.name;
  constructor(props) {
    super(props);

    this.state = {
      isHamburger: true,
      isAuthenticated: false,
      userName: null,
    };
  }
  componentDidMount() {}
  componentWillUnmount() {}
  handleCheckHambergaurClick = () => {
    this.setState({
      isHamburger: !this.state.isHamburger,
    });
  };

  render() {
    return (
      <Fragment>
        <div>
          <Header
            handleCheckHambergaurClick={this.handleCheckHambergaurClick}
          />
        </div>
        <div
          className={
            this.state.isHamburger ? "leftside" : "leftside Hideleftside"
          }
        >
          <SideMenu />
        </div>
        <div
          className={
            this.state.isHamburger
              ? "main-content rightside"
              : "main-content rightside Hiderightside"
          }
          style={{ backgroundColor: "#F2F4F7" }}
        >
          {this.props.children}
        </div>
      </Fragment>
    );
  }
}
