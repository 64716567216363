import Configuration from "../../helpers/Configuration";
import axios from "axios";
import authService from "../../components/api-authorization/AuthorizeService";

class SpecialityService {

    constructor() {
        this.config = new Configuration();
    }

    async getSpeciality(url, inputData) {
        const response = await axios(this.config.SpecialityServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async insertSpeciality(url, inputData) {
        const response = await axios(this.config.SpecialityServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async updateSpeciality(url, inputData) {
        const response = await axios(this.config.SpecialityServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async deleteSpeciality(url, inputData) {
        const response = await axios(this.config.SpecialityServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }


}
export default SpecialityService;
