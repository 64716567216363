import Configuration from "../../helpers/Configuration";
import axios from "axios";
class LeadsService {
  constructor() {
    this.config = new Configuration();
  }

  async createLead(url, inputData) {
    const response = await axios(this.config.LeadServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }

  async getLeadDropdownData(url, inputData) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
    }

    async getUserBasedOnUnit(url, inputData) {
        const response = await axios(this.config.CommonServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

  async getInsuranceProviderData(url) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
    });
    return await response.data;
    }

    async getSpecialityMasterData(url, inputData) {
        const response = await axios(this.config.CommonServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getServiceMasterData(url) {
        const response = await axios(this.config.CommonServiceURL + url, {
            method: "POST",
           
        });
        return await response.data;
    }

    async getTaskStatusData(url) {
        const response = await axios(this.config.CommonServiceURL + url, {
            method: "POST",
        });
        return await response.data;
    }

  async getLeadColumnName(url) {
    const response = await axios(this.config.LeadServiceURL + url, {
      method: "POST",
    });
    return await response.data;
  }
  async getLeadList(url, inputData) {
    const response = await axios(this.config.LeadServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }

  async getLeadDetailsTab(url, inputData) {
    const response = await axios(this.config.LeadServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }

  async addLeadNotes(url, inputData) {
    const response = await axios(this.config.LeadServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async sendEmailWithFile(url, inputData) {
    const response = await axios(this.config.LeadServiceURL + url, {
      method: "POST",
      data: inputData,
      headers: {
        "Content-Type": "application/json",
        "charset": "utf-8",
        "Access-Control-Allow-Origin": "*",
        "accept":"*"
      },
    });
    return await response.data;
  }

  async importFile(url, inputData) {
    const response = await axios(this.config.LeadServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }

  async fileUpload(url, inputData) {
    const response = await axios(this.config.LeadServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }

  async exportToExcel(url, inputData) {
    const response = await axios(this.config.LeadServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
    }

    async assignReassignLead(url, inputData) {
        const response = await axios(this.config.LeadServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }
  
}

export default LeadsService;
