import React, { Component } from "react";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { Input, Radio, Table, Select, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone";
import {
  AddIcon,
  AddNoteIcon,
  UploadIcon,
  CloseCircleIcon,
  DownloadIcon,
} from "./../../../helpers/ImagesImport";
import HierarchyService from "./../../../services/AdminSetting/HierarchyService";
import { NotificationManager } from "react-notifications";
import UserService from "./../../../services/AdminSetting/UserService";

class Hierarchy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      HierarchyModal: false,
      Single: true,
      Multiple: false,
      fileName: "",
      fileN: [],
      hierarchyInputs: {},
      hierarchyErrors: {},
      hierarchyGridData: [],
      isEdit: false,
      isSubmitLoading: false,
      selectedRowKeys: [],
      isLoading: false,
      createAccess: true,
      viewAccess: true,
      deleteAccess: true,
      editAccess: true,
      downloadAccess: true,
    };
    this.HierarchyService = new HierarchyService();
    this.UserService = new UserService();
  }
  componentDidMount() {
    this.handleGetHierarchyGridData();
    this.handleGetModuleDetails();
  }
  /// handle get hierarchy grid data
    handleGetHierarchyGridData = (search) => {
        
    var inputData = {};
    inputData.SearchText = search;
    this.setState({ isLoading: true });
    try {
      this.HierarchyService.getHierarchyGridData("GetHierarchy", inputData)
        .then((response) => {
          var message = response.message;
          var hierarchyGridData = response.responseData;
          this.setState({ isLoading: false });
          if (message == "Success") {
            this.setState({ hierarchyGridData });
          } else {
            this.setState({ hierarchyGridData: [] });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };
  //handle create Hierarchy
  handleCreateHierarchy = () => {
    var self = this;
    var inputData = {};
    inputData.DesignationName = this.state.hierarchyInputs.designationName;
    inputData.ReportsTo = this.state.hierarchyInputs.reportTo;
    inputData.Status = this.state.hierarchyInputs.status;

    this.setState({ isSubmitLoading: true });
    try {
      this.HierarchyService.createHierarchy("CreateHierarchy", inputData)
        .then((response) => {
          self.setState({ isSubmitLoading: false });
          if (response) {
            self.handleGetHierarchyGridData();
            self.setState({ HierarchyModal: false });
            NotificationManager.success("Record Created Successfully.");
          } else {
            NotificationManager.error("Hierarchy Not Created.");
          }
        })
        .catch((error) => {
          self.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle update Hierarchy
  handleUpdateHierarchy = () => {
    var self = this;
    var inputData = {};
    inputData.DesignationID = this.state.hierarchyInputs.designationId;
    inputData.DesignationName = this.state.hierarchyInputs.designationName;
    inputData.ReportsTo = this.state.hierarchyInputs.reportTo;
    inputData.Status = this.state.hierarchyInputs.status;

    this.setState({ isSubmitLoading: true });
    try {
      this.HierarchyService.updateHierarchy("UpdateHierarchy", inputData)
        .then((response) => {
          self.setState({ isSubmitLoading: false });
          if (response) {
            self.handleGetHierarchyGridData();
            self.setState({
              HierarchyModal: false,
            });
            NotificationManager.success("Record Updated Successfully.");
          } else {
            NotificationManager.error("Hierarchy Not Updated.");
          }
        })
        .catch((error) => {
          self.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle delete hierarchy by id
  handleDeleteHierarchy = (designationId) => {
    var inputData = {};
    inputData.DesignationIDs = designationId.toString();
    try {
      this.HierarchyService.deleteHierarchy("DeleteHierarchy", inputData)
        .then((response) => {
          if (response) {
            this.handleGetHierarchyGridData();
            NotificationManager.success("Record Deleted Successfully.");
          } else {
            NotificationManager.error("Hierarchy Not Deleted.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /// handle open modal
  handleCreateHierarchyOpen = async (e) => {
    var hierarchyInputs = this.state.hierarchyInputs;
    hierarchyInputs["designationId"] = e.designationID;
    hierarchyInputs["designationName"] = e.designationName;
    hierarchyInputs["reportTo"] = e.reportToDesignation;
    hierarchyInputs["status"] = e.hierarchyStatus == "Active" ? true : false;
    await this.setState({
      HierarchyModal: true,
      hierarchyInputs,
      isEdit: true,
    });
  };
  /// handle close modal
  handleCreateHierarchyClose() {
    this.setState({
      HierarchyModal: false,
      hierarchyInputs: {},
      hierarchyErrors: {},
      isEdit: false,
    });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  /// handle redio button change
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  /// handle file upload
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };
  /// handle input change
  handleInputOnchange = async (name, event) => {
    var hierarchyInputs = this.state.hierarchyInputs;
    if (name === "designationName") {
      hierarchyInputs[name] = event.target.value;
    } else {
      hierarchyInputs[name] = event;
    }

    await this.setState({
      hierarchyInputs,
    });
  };

    handleSearchOnChange = async (event) => {
        
    this.handleGetHierarchyGridData(event.target.value);
  };

  /// check validation
  handleCheckValidations = () => {
    var inputs = this.state.hierarchyInputs;
    var hierarchyErrors = {};
    var isValid = true;
    if (!inputs["designationName"]) {
      hierarchyErrors["designationName"] = "Please Enter Designation Name";
      isValid = false;
    }
    if (!inputs["reportTo"]) {
      hierarchyErrors["reportTo"] = "Please Select Report To";
      isValid = false;
    }
    if (inputs["status"] === "" || inputs["status"] === null) {
      hierarchyErrors["status"] = "Please Select Status";
      isValid = false;
    }
    this.setState({
      hierarchyErrors,
    });
    return isValid;
  };
  /// handle create hierarchy
  handleCheckHierarchy() {
    if (this.handleCheckValidations()) {
      if (!this.state.isEdit) {
        this.handleCreateHierarchy();
      } else {
        this.handleUpdateHierarchy();
      }
    }
  }

  handleGetModuleDetails = () => {
    try {
        this.UserService.getUserModuleDetails("GetUserModuleDetails")
        .then((response) => {
            
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            //this.setState({ cityData });
              this.setAccessUser(data);
          } else {
            //this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  setAccessUser(data) {
      
      if (data !== null) {
          for (var i = 0; i < data.length; i++) {
              if (data[i].moduleName === "Settings") {
                   this.setState({
                      createAccess: data[i].isCreate,
                      viewAccess: data[i].isView,
                      deleteAccess: data[i].isDelete,
                      editAccess: data[i].isEdit,
                      downloadAccess: data[i].isDownload
                   });
              }
          }
      }
  }
  //handle create Hierarchy
  //handleCreateHierarchy = () => {
  //  alert("Create");
  //};
  //handle update Hierarchy
  //handleUpdateHierarchy = () => {
  //  alert("Update");
  //};

  render() {
    const { Option } = Select;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div className="HierarchyMain">
        <div className="Hier">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Hierarchy
                {this.state.createAccess &&
                    <img
                      src={AddIcon}
                      alt="add"
                      onClick={() =>
                          this.setState({
                              HierarchyModal: true, hierarchyInputs: {},
                              hierarchyErrors: {},
                              isEdit: false, })
                      }
                    />
                }
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by designation, reports to…"
                prefix={<SearchOutlined />}
                onChange={this.handleSearchOnChange.bind(this)}
              />
            </div>
          </div>
          {/*{this.state.deleteAccess && (this.state.selectedRowKeys.length > 0 ? (*/}
          {/*  <div className="hierdown">*/}
          {/*    <label*/}
          {/*      onClick={this.handleDeleteHierarchy.bind(*/}
          {/*        this,*/}
          {/*        this.state.selectedRowKeys.join(",")*/}
          {/*      )}*/}
          {/*    >*/}
          {/*      <img src={CloseCircleIcon} alt="Delete" />*/}
          {/*      Delete*/}
          {/*    </label>*/}
          {/*    */}{/* <label>*/}{/*
          */}{/*    <img src={DownloadIcon} alt="Delete" />*/}{/*
          */}{/*    Excel*/}{/*
          */}{/*  </label> */}
          {/*  </div>*/}
          {/*) : null)}*/}
          {this.state.viewAccess &&
          <div className="HierarchyTable">
            <Table
              rowSelection={rowSelection}
              rowKey={(record) => record.designationID}
              columns={[
                {
                  title: "Designation",
                  dataIndex: "designationName",
                  key: "designationName",
                },
                {
                  title: "Reports To",
                  dataIndex: "reportToDesignationName",
                  key: "reportToDesignationName",
                },
                {
                  title: "Created by",
                  key: "createdByName",
                  dataIndex: "createdByName",
                },
                {
                  title: "Updated by",
                  key: "modifiedByName",
                  dataIndex: "modifiedByName",
                },
                {
                  title: "Status",
                  key: "hierarchyStatus",
                  dataIndex: "hierarchyStatus",
                  render: (row, item) => {
                    return (
                      <div className="Status">
                        <Select showArrow={false} value={row} disabled={true}>
                          <Option value={true}>Active</Option>
                          <Option value={false}>Inactive</Option>
                        </Select>
                      </div>
                    );
                  },
                },
                {
                  title: "Actions",
                  key: "actions",
                  dataIndex: "actions",
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        {this.state.editAccess &&
                        <img
                          src={AddNoteIcon}
                          alt="Edit"
                          onClick={this.handleCreateHierarchyOpen.bind(
                            this,
                            item
                          )}
                        />}
                        {this.state.deleteAccess &&
                        <img
                          src={CloseCircleIcon}
                          alt="delete"
                          onClick={this.handleDeleteHierarchy.bind(
                            this,
                            item.designationID
                          )}
                        />
                        }
                      </div>
                    );
                  },
                },
              ]}
              dataSource={this.state.hierarchyGridData}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true
              }}
              loading={this.state.isLoading}
            />
          </div>
          }
          {/* Create Hierarchy */}

          <Modal
            className="CreateHierarchyModal"
            closable={false}
            title={this.state.isEdit ? "Edit Hierarchy" : "Create Hierarchy"}
            visible={this.state.HierarchyModal}
            footer={[
              <Button
                key="back"
                onClick={this.handleCreateHierarchyClose.bind(this)}
              >
                CANCEL
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.handleCheckHierarchy.bind(this)}
              >
                {this.state.isEdit ? "UPDATE" : "ADD"}
              </Button>,
            ]}
          >
            <div className="createHier">
              {/* <div className="mb-2">
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Single}
                  onChange={this.handleRedioButtonChange.bind(this, "Single")}
                >
                  Single
                </Radio>
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Multiple}
                  onChange={this.handleRedioButtonChange.bind(this, "Multiple")}
                >
                  Multiple
                </Radio>
              </div> */}
              {this.state.Single && (
                <>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Designation Name</label>
                      <Input
                        placeholder="Enter Designation Name"
                        name="designationName"
                        value={this.state.hierarchyInputs.designationName}
                        autoComplete="off"
                        onChange={this.handleInputOnchange.bind(
                          this,
                          "designationName"
                        )}
                      />
                      {this.state.hierarchyErrors && (
                        <div className="validationsMsg">
                          {this.state.hierarchyErrors["designationName"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Report To</label>
                      <Select
                        value={this.state.hierarchyInputs.reportTo}
                        onChange={this.handleInputOnchange.bind(
                          this,
                          "reportTo"
                        )}
                        placeholder={"Select Report To"}
                      >
                        {this.state.hierarchyGridData &&
                          this.state.hierarchyGridData.map((item) => (
                            <Option value={item.designationID}>
                              {item.designationName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.hierarchyErrors && (
                        <div className="validationsMsg">
                          {this.state.hierarchyErrors["reportTo"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-md-12">
                      <label className="desig">Status</label>
                      <Select
                        value={this.state.hierarchyInputs.status}
                        onChange={this.handleInputOnchange.bind(this, "status")}
                        placeholder={"Select Status"}
                      >
                        <Option value={true}>Active</Option>
                        <Option value={false}>Inactive</Option>
                      </Select>
                      {this.state.hierarchyErrors && (
                        <div className="validationsMsg">
                          {this.state.hierarchyErrors["status"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {this.state.Multiple && (
                <>
                  <div className="editor">
                    <div className="mainfileUpload">
                      <Dropzone onDrop={this.fileUpload.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              className="file-upload d-none"
                            />
                            <div className="file-icon">
                              <img src={UploadIcon} alt="file-upload" />
                            </div>
                            <span className={"drop"}>
                              Drop your file to upload
                            </span>
                            <button className="fileupload">Choose File</button>
                            <span className="down">Download Sample File</span>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Hierarchy;
