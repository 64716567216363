class Master {
  Zone = [
    {
      zoneName: "East",
      zoneID: 260,
    },
    {
      zoneName: "West",
      zoneID: 261,
    },
    {
      zoneName: "North",
      zoneID: 262,
    },
    {
      zoneName: "South",
      zoneID: 263,
    },
  ];
  LeadAction = [
    {
      Name: "Only Query Lead",
      ID: 101,
      isActive:false
    },
    {
      Name: "Follow Up",
      ID: 102,
      isActive:false
    },
    {
      Name: "Disqualify",
      ID: 103,
      isActive:false
    },
    {
      Name: "View Timelines",
      ID: 104,
      isActive:true
    },
    {
      Name: "Add Notes",
      ID: 105,
      isActive:true
    },
    {
      Name: "Add Task",
      ID: 106,
      isActive:true
    },
    {
      Name: "Email",
      ID: 107,
      isActive:true
    },
    {
      Name: "SMS",
      ID: 108,
      isActive:true
    },
  ];
  LeadImportSample = [
    "LeadID",
    "FirstName",
    "MiddleName",
    "LastName",
    "PhoneNo",
    "Email",
    "Gender",
    "DOB",
    "Age",
    "AlternatePhoneNo",
    "AlternateEmail",
    "MaritalStatus",
    "AnniversaryDate",
    "PinCode",
    "Country",
    "State",
    "City",
    "Address",
    "Occupation",
    "EnquiryType",
    "EnquirySubType",
    "LeadSource",
    "LeadStatus",
    "LeadSubStatus",
    "Unit",
    "LeadType",
    "ConsultingHospital",
    "CosultingDoctor",
    "LeadPriority",
    "LPM",
    "EDD",
    "Title",
    "RequestedService",
    "CaseCategory",
    "EnquiryKeywords",
    "Remarks",
    "PreCondition",
    "LeadLocality",
    "campaignName",
    "PageFormName",
  ];
  UserImportSample = [
    [
      "Email",
      "Full Name",
      "Mobile Number",
      "Primary Center",
      "Date Of Birth",
      "Languages Known",
      "Gender",
      "Specialty",
      "Registration Number",
      "Registration Country",
      "Registration State",
      "Work Experience",
      "Lead Access Types",
      "Designation",
      "Reporting Manager",
      "User Level",
      "User Zone",
      "User Region",
      "Degree Name",
      "Institute Name",
      "Clinical Skills",
      "Certifications",
      "Membership",
      "Publications",
      "Awards & Recognitions",
      "Associates Centers",
      "Enquiry Type",
      "Enquiry Sub Type",
    ],
    [
      [
        "user1@gmail.com",
        "user 1",
        "9876543210",
        "location1",
        "06-11-2020",
        "English|Punjabi|Marathi",
        "M",
        "Paediatrics",
        "Reg0001",
        "India",
        "Bihar",
        "IT",
        "Access All Leads",
        "Asst Manager",
        "Root",
        "Center",
        "West",
        "North",
        "Bscit",
        "ST.jeorge",
        "asda",
        "qeqw",
        "234",
        "tyry",
        "nnn",
        "location1|Location 2",
        "Enquiry1|Enquiry2",
        "SubEnquiry1|SubEnquiry2",
      ],
    ],
  ];
  LocationImportSample = [
    [
      "Brand",
      "LocationName",
      "LocationCode",
      "State",
      "City",
      "Address",
      "PinCode",
      "Region",
      "Zone",
      "EmailID",
      "Phone",
      "Status",
    ],
    [
      [
        "Motherhood",
        "Andheri",
        "And001",
        "Maharashtra",
        "Mumbai",
        "S.N. Road",
        "4302291",
        "East",
        "West",
        "sample@gmail.com",
        "4738473847",
        "Active",
      ],
    ],
  ];
  MaritalStatus = [
    {
      id: 1,
      Name: "Married",
    },
    {
      id: 2,
      Name: "Unmarried",
    },
    {
      id: 3,
      Name: "Divorced",
    },
    {
      id: 4,
      Name: "Widowed",
    },
  ];
}
export default Master;
