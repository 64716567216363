import Configuration from "../../helpers/Configuration";
import axios from "axios";
import authService from "../../components/api-authorization/AuthorizeService";

class TeamService {
    constructor() {
        this.config = new Configuration();
    }

    async getTeamGirdData(url, inputData) {
        const response = await axios(this.config.TeamServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async updateData(url, inputData) {
        const response = await axios(this.config.TeamServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async deleteData(url, inputData) {
        const response = await axios(this.config.TeamServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async createTeam(url, inputData) {
        const response = await axios(this.config.TeamServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

   

    async getLocationData(url, inputData) {
        const response = await axios(this.config.TeamServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getUserData(url, inputData) {
        const response = await axios(this.config.TeamServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

}

export default TeamService;
