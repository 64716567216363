import React, { Fragment, Component } from "react";
import { PetrolIcon, SUserIcon } from "./../../../helpers/ImagesImport";
import LeadsService from "../../../services/Leads/LeadsService";
import { Button, Empty, Pagination, Spin } from "antd";
import parse from "html-react-parser";
import Modal from "antd/lib/modal/Modal";
import AddNoteComponent from "./AddNoteComponent";
export default class NoteTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      Note: [],
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      taskModal: false,
      leadID: props.LeadID,
      notesCreateAccess: props.CreateNotes,
      notesViewAccess: props.ViewNotes
    };
    this.leadsService = new LeadsService();
    this.handleNoteModalClose = this.handleNoteModalClose.bind(this);
  }
  componentDidMount() {
    this.handleGetNote();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.SelectedTab == "3") {
      this.handleGetNote();
    }
  }
  handleGetNote = () => {
    try {
      var inputData = {};
      inputData.IDs = this.state.leadID + "";
      inputData.PageNo = this.state.pageNo;
      inputData.PageSize = this.state.pageSize;
      this.setState({
        isLoading: true,
      });
      this.leadsService
        .getLeadDetailsTab("GetLeadNotesByLeadID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var Note = response.responseData.notesDetails;
          var totalCount = response.responseData.totalCount;
          this.setState({
            isLoading: false,
          });
          if (statusCode === 200) {
            this.setState({
              Note: Note,
              totalCount: totalCount,
            });
          } else {
            this.setState({
              Note: [],
              totalCount: 0,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log(error);
        });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      console.log(error);
    }
  };
  handlePageNoChange = async (e) => {
    await this.setState({
      pageNo: e,
    });
    this.handleGetNote();
  };
  handlePageSizeChange = async (e, pageSize) => {
    await this.setState({
      pageSize: pageSize,
      pageNo: e,
    });
    this.handleGetNote();
  };
  handleNoteModalClose = (isSuccess) => {
    if (isSuccess) {
      this.handleGetNote();
    }
    this.setState({
      noteModal: false,
    });
  };
  handleNoteModalOpen = () => {
    this.setState({
      noteModal: true,
    });
  };
  render() {
    return (
      <Fragment>
        <div className="NotesMain">
        {this.state.notesViewAccess && (
          <>
          <ul>
            <Spin spinning={this.state.isLoading}>
              {this.state.Note ? (
                this.state.Note.map((item, key) => {
                  return (
                    <li>
                      <div className="Notes">
                        <div className="lefSide">
                          <div className="rightUs">
                            <div className="DateTime">
                              <h4 className="abou">{item.createdDate}</h4>
                              {/* <h4 className="dayago">{item.notetime}</h4> */}
                            </div>
                            <div className="QuContent">
                              <span>
                                <img src={PetrolIcon} alt="User" />
                              </span>
                              <div className="Quote">
                                <h4 className="dayago">
                                  {parse(item.notesDetail)}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                            {item.userProfile ? (
                              <img src={item.userProfile} alt="User" />
                            ) : (
                              <img src={SUserIcon} alt="User" />
                            )}
                            <div>
                              <h4 className="abou">{item.createdByName}</h4>
                              {/* <h4 className="dayago">{item.designtion}</h4> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Spin>
          </ul>
          {this.state.Note.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : null}
          {this.state.Note.length > 0 ? (
            <Pagination
              defaultCurrent={1}
              total={this.state.totalCount}
              onChange={this.handlePageNoChange.bind(this)}
              pageSizeOptions={[10, 20, 30]}
              onShowSizeChange={this.handlePageSizeChange.bind(this)}
              responsive={true}
              showSizeChanger={true}
              current={this.state.pageNo}
              pageSize={this.state.pageSize}
            />
          ) : null}
          </>
          )}
          {this.state.notesCreateAccess &&
          <div className="row">
            <div className="col-md-12" style={{ textAlign: "right" }}>
              <Button
                type="primary"
                onClick={this.handleNoteModalOpen.bind(this)}
              >
                Add Note
              </Button>
            </div>
          </div>}
          {/* Add Note Modal */}
          <Modal
            title={"Add Note"}
            closable={false}
            visible={this.state.noteModal}
            footer={null}
            onCancel={this.handleNoteModalClose.bind(this, false)}
          >
            <AddNoteComponent
              handleAddNotesClose={this.handleNoteModalClose}
              LeadID={this.state.leadID}
            />
          </Modal>
        </div>
      </Fragment>
    );
  }
}
