
import config from "./../../helpers/Configuration";
import { AuthHeader as authHeader } from "./../../helpers/AuthHeader";
import axios from "axios";

class TaskTypeService {
  constructor() {
    // this.config = new Configuration();
  }
  /// get grid data
  async getTaskTypeGridData(url) {
    const res = await axios({
      method: "post",
      url: this.config.APIURL + url,
      headers: authHeader(),
    });
    return await res.json();
  }
  /// get Report To data 
  async getReportToData(url) {
    const res = await axios({
      method: "post",
      url: this.config.APIURL + url,
      headers: authHeader(),
    });
    return await res.json();
  }
  /// Create task type
  async CreateTaskType(inputData) {
    const res = await axios({
      method: "post",
      url: this.config.APIURL,
      headers: authHeader(),
      data: inputData,
    });
    return await res.json();
  }
  /// update task type
  async updateTaskType(inputData) {
    const res = await axios({
      method: "post",
      url: this.config.APIURL,
      headers: authHeader(),
      data: inputData,
    });
    return await res.json();
  }
    /// delete task type
    async deleteTaskType(taskTypeID) {
      const res = await axios({
        method: "post",
        url: this.config.APIURL,
        headers: authHeader(),
        params: {
          TaskTypeId: taskTypeID,
        },
      });
      return await res.json();
    }
}

export default TaskTypeService;
