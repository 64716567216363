import React, { Component } from "react";
import {
  Input,
  Radio,
  Select,
  TimePicker,
  Table,
  DatePicker,
  Checkbox,
  Modal,
  Button,
  Dropdown,
  Menu,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Dropzone from "react-dropzone";
import {
  AddIcon,
  AddNoteIcon,
  CloseCircleIcon,
  UploadIcon,
} from "./../../../helpers/ImagesImport";
import TaskTypeService from "../../../services/AdminSetting/TaskTypeService";
const { TextArea } = Input;

class TaskType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createTask: false,
      Single: true,
      Multiple: false,
      fileName: "",
      fileN: [],
      taskTypeInputs: {},
      taskTypeErrors: {},
      isEdit: false,
      taskTypeGridData: [
        {
          key: "1",
          taskType: "Test@123.com",
          createdby: "Vikash Sharma",
          updatedby: "Vikash Sharma",
        },
        {
          key: "2",
          taskType: "Test@123.com",
          createdby: "Vikash Sharma",
          updatedby: "Vikash Sharma",
        },
        {
          key: "3",
          taskType: "Test@123.com",
          createdby: "Vikash Sharma",
          updatedby: "Vikash Sharma",
        },
      ],
      reportToData: [],
      isSubmitLoading: false,
    };
    this.TaskTypeService = new TaskTypeService();
  }
  componentDidMount() {
    /// this.handleGetTaskTypeGridData()
    /// this.handleGetReportToData()
  }
  /// handle get task type grid data
  handleGetTaskTypeGridData() {
    try {
      this.TaskTypeService.getTaskTypeGridData("")
        .then((response) => {
          var taskTypeGridData = response;
          if (taskTypeGridData) {
            this.setState({ taskTypeGridData });
          } else {
            this.setState({ taskTypeGridData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  /// handle Get report to data
  handleGetReportToData = () => {
    try {
      this.TaskTypeService.getReportToData()
        .then((response) => {
          var reportToData = response;
          if (reportToData) {
            this.setState({ reportToData });
          } else {
            this.setState({ reportToData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle create Task Type
  handleCreateTaskType = () => {
    var self = this;
    var inputData = {};
    inputData.TaskType = this.state.taskTypeInputs.taskType;
    inputData.Description = this.state.taskTypeInputs.description;
    inputData.DueDate = this.state.taskTypeInputs.dueDate;
    inputData.DueTime = this.state.taskTypeInputs.dueTime;
    inputData.OutCome = this.state.taskTypeInputs.outCome;
    inputData.RelatedTo = this.state.taskTypeInputs.relatedTo;
    inputData.Collaborators = this.state.taskTypeInputs.collaborators;

    this.setState({ isSubmitLoading: true });
    try {
      this.TaskTypeService.CreateTaskType(inputData)
        .then((response) => {
          self.setState({ isSubmitLoading: false });
          if (response) {
            self.handleGetTaskTypeGridData();
            self.setState({ createTask: false });
          } else {
          }
        })
        .catch((error) => {
          self.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle update Task Type
  handleUpdateTaskType = () => {
    var self = this;
    var inputData = {};
    inputData.TaskType = this.state.taskTypeInputs.taskType;
    inputData.Description = this.state.taskTypeInputs.description;
    inputData.DueDate = this.state.taskTypeInputs.dueDate;
    inputData.DueTime = this.state.taskTypeInputs.dueTime;
    inputData.OutCome = this.state.taskTypeInputs.outCome;
    inputData.RelatedTo = this.state.taskTypeInputs.relatedTo;
    inputData.Collaborators = this.state.taskTypeInputs.collaborators;

    this.setState({ isSubmitLoading: true });
    try {
      this.TaskTypeService.updateTaskType(inputData)
        .then((response) => {
          self.setState({ isSubmitLoading: false });
          if (response) {
            self.handleGetTaskTypeGridData();
          } else {
          }
        })
        .catch((error) => {
          self.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle delete Task Type by id
  handleDeleteTaskType = () => {
    var taskTypeID = "";
    try {
      this.TaskTypeService.deleteTaskType(taskTypeID)
        .then((response) => {
          if (response) {
            this.handleGetTaskTypeGridData();
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /// handle modal open
  handleCreateTaskOpen() {
    this.setState({ createTask: true, isEdit: true });
  }
  /// handle modal close
  handleCreateTaskClose() {
    this.setState({
      createTask: false,
      taskTypeInputs: {},
      taskTypeErrors: {},
      isEdit: false,
    });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  /// handle radio button change
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  /// file upload
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };

  /// handle input change
  handleInputOnchange = async (name, event) => {
    var taskTypeInputs = this.state.taskTypeInputs;
    if (name === "description") {
      taskTypeInputs[name] = event.target.value;
    } else {
      taskTypeInputs[name] = event;
    }
    await this.setState({
      taskTypeInputs,
    });
  };

  /// check validation
  handleCheckValidations = () => {
    var inputs = this.state.taskTypeInputs;
    var taskTypeErrors = {};
    var isValid = true;
    if (!inputs["taskType"]) {
      taskTypeErrors["taskType"] = "Please Select Task Type";
      isValid = false;
    }
    if (!inputs["description"]) {
      taskTypeErrors["description"] = "Please Enter Description";
      isValid = false;
    }
    if (!inputs["dueDate"]) {
      taskTypeErrors["dueDate"] = "Select DueDate";
      isValid = false;
    }
    if (!inputs["dueTime"]) {
      taskTypeErrors["dueTime"] = "Select DueTime";
      isValid = false;
    }
    if (!inputs["outCome"]) {
      taskTypeErrors["outCome"] = "Please Select Out Come";
      isValid = false;
    }
    if (!inputs["relatedTo"]) {
      taskTypeErrors["relatedTo"] = "Please Select Related To";
      isValid = false;
    }
    if (!inputs["collaborators"]) {
      taskTypeErrors["collaborators"] = "Please Select Collaborators";
      isValid = false;
    }
    this.setState({
      taskTypeErrors,
    });
    return isValid;
  };

  /// handle create task type
  handleCheckTaskType() {
    if (this.handleCheckValidations()) {
      if (!this.state.isEdit) {
        this.handleCreateTaskType();
      } else {
        this.handleUpdateHierarchy();
      }
    }
  }
  ///handle create task type
  handleCreateTaskType = () => {
    alert("Create");
  };
  ///handle update task type
  handleUpdateHierarchy = () => {
    alert("Update");
  };
  render() {
    const { Option } = Select;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
    return (
      <div className="TaskTypeMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Task Types
                <img
                  src={AddIcon}
                  alt="add"
                  onClick={() => this.setState({ createTask: true })}
                />
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by title, type..."
                prefix={<SearchOutlined />}
              />
            </div>
          </div>

          <div className="TaskTypeTable">
            <Table
              rowSelection={rowSelection}
              columns={[
                {
                  title: "Task Type",
                  dataIndex: "taskType",
                  key: "taskType",
                  // render: (row, item) => {
                  //   return (
                  //     <div className="report">
                  //       <label>Test@123.com</label>
                  //     </div>
                  //   );
                  // },
                },
                {
                  title: "Created by",
                  key: "createdby",
                  dataIndex: "createdby",
                },
                {
                  title: "Updated by",
                  key: "updatedby",
                  dataIndex: "updatedby",
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status",
                  render: (row, item) => {
                    return (
                      <div className="Status">
                        <Select defaultValue="Active">
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                        </Select>
                      </div>
                    );
                  },
                },
                {
                  title: "Actions",
                  key: "actions",
                  dataIndex: "actions",
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        <img
                          src={AddNoteIcon}
                          alt="Edit"
                          onClick={this.handleCreateTaskOpen.bind(this)}
                        />
                        <img src={CloseCircleIcon} alt="delete" />
                      </div>
                    );
                  },
                },
              ]}
              dataSource={this.state.taskTypeGridData}
              pagination={false}
            />
          </div>
          {/* Create Task */}

          <Modal
            className="CreateTaskTyModal"
            closable={false}
            title={this.state.isEdit ? "Edit Task Type" : "Create Task Type"}
            visible={this.state.createTask}
            footer={[
              <Button
                key="back"
                onClick={this.handleCreateTaskClose.bind(this)}
              >
                CANCEL
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.handleCheckTaskType.bind(this)}
              >
                {this.state.isEdit ? "UPDATE" : "CREATE"}
              </Button>,
            ]}
          >
            <div className="createTas">
              <div className="mb-2">
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Single}
                  onChange={this.handleRedioButtonChange.bind(this, "Single")}
                >
                  Single
                </Radio>
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Multiple}
                  onChange={this.handleRedioButtonChange.bind(this, "Multiple")}
                >
                  Multiple
                </Radio>
              </div>
              {this.state.Single && (
                <>
                  <div className="row mb-2">
                    <div className="col-12 col-md-12">
                      <label className="desig">
                        Task Type<span style={{ color: "red" }}>*</span>
                      </label>

                      <Select
                        defaultValue="Click to Select"
                        value={this.state.taskTypeInputs.taskType || ""}
                        onChange={this.handleInputOnchange.bind(
                          this,
                          "taskType"
                        )}
                      >
                        <Option value="hod">HOD</Option>
                        <Option value="asst">Asst Manager</Option>
                      </Select>
                      {this.state.taskTypeErrors && (
                        <div className="validationsMsg">
                          {this.state.taskTypeErrors["taskType"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-12">
                      <label className="desig">Description</label>
                      <TextArea
                        rows={3}
                        placeholder="Enter Designation Name"
                        name="description"
                        value={this.state.taskTypeInputs.description || ""}
                        autoComplete="off"
                        onChange={this.handleInputOnchange.bind(
                          this,
                          "description"
                        )}
                      />
                      {this.state.taskTypeErrors && (
                        <div className="validationsMsg">
                          {this.state.taskTypeErrors["description"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-3 pr-0">
                      <label className="desig">
                        Due Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        showToday={false}
                        value={this.state.taskTypeInputs.dueDate || ""}
                        format={dateFormatList}
                        onChange={this.handleInputOnchange.bind(
                          this,
                          "dueDate"
                        )}
                      />
                      {this.state.taskTypeErrors && (
                        <div className="validationsMsg">
                          {this.state.taskTypeErrors["dueDate"] || ""}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-3">
                      <label className="desig" style={{ visibility: "hidden" }}>
                        Due Time
                      </label>
                      <TimePicker
                        value={this.state.taskTypeInputs.dueTime || ""}
                        format="HH:mm"
                        onChange={this.handleInputOnchange.bind(
                          this,
                          "dueTime"
                        )}
                      />
                      {this.state.taskTypeErrors && (
                        <div className="validationsMsg">
                          {this.state.taskTypeErrors["dueTime"] || ""}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="desig">Outcome</label>
                      <Select
                        defaultValue="Click to Select"
                        value={this.state.taskTypeInputs.outCome || ""}
                        onChange={this.handleInputOnchange.bind(
                          this,
                          "outCome"
                        )}
                      >
                        <Option value="hod">HOD</Option>
                        <Option value="asst">Asst Manager</Option>
                      </Select>
                      {this.state.taskTypeErrors && (
                        <div className="validationsMsg">
                          {this.state.taskTypeErrors["outCome"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-6">
                      <label className="desig">Related To</label>
                      <Select
                        defaultValue="Click to Select"
                        value={this.state.taskTypeInputs.relatedTo || ""}
                        onChange={this.handleInputOnchange.bind(
                          this,
                          "relatedTo"
                        )}
                      >
                        <Option value="hod">HOD</Option>
                        <Option value="asst">Asst Manager</Option>
                      </Select>
                      {this.state.taskTypeErrors && (
                        <div className="validationsMsg">
                          {this.state.taskTypeErrors["relatedTo"] || ""}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="desig">Collaborators</label>
                      <Select
                        defaultValue="Click to Select"
                        value={this.state.taskTypeInputs.collaborators || ""}
                        onChange={this.handleInputOnchange.bind(
                          this,
                          "collaborators"
                        )}
                      >
                        <Option value="hod">HOD</Option>
                        <Option value="asst">Asst Manager</Option>
                      </Select>
                      {this.state.taskTypeErrors && (
                        <div className="validationsMsg">
                          {this.state.taskTypeErrors["collaborators"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="desig">
                        <Checkbox>Mark as Completed</Checkbox>
                      </label>
                    </div>
                  </div>
                </>
              )}
              {this.state.Multiple && (
                <div className="editor">
                  <div className="mainfileUpload">
                    <Dropzone onDrop={this.fileUpload.bind(this)}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            className="file-upload d-none"
                          />
                          <div className="file-icon">
                            <img src={UploadIcon} alt="file-upload" />
                          </div>
                          <span className={"drop"}>
                            Drop your file to upload
                          </span>
                          <button className="fileupload">Choose File</button>
                          <span className="down">Download Sample File</span>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default TaskType;
