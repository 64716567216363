import React, { Component } from "react";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { Input, Select, Table, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  AddIcon,
  AddNoteIcon,
  CloseCircleIcon,
} from "./../../../helpers/ImagesImport";
import PriorityService from "../../../services/AdminSetting/PriorityService";
import { NotificationManager } from "react-notifications";
import UserService from "./../../../services/AdminSetting/UserService";

class Priority extends Component {
  constructor(props) {
    super(props);

    this.state = {
      priorityModal: false,
      priorityInput: {},
      priorityError: {},
      isEdit: false,
      isSubmitLoading: false,
      priorityGirdData: [],
      loading: false,
      createAccess: true,
      viewAccess: true,
      deleteAccess: true,
      editAccess: true,
      downloadAccess: true,
    };
    this.priorityService = new PriorityService();
    this.UserService = new UserService();
  }
  componentDidMount() {
      this.handleGetPriorityGridData();
      this.handleGetModuleDetails();
    }
    handleSearchOnChange = async (event) => {
        
        this.handleGetPriorityGridData(event.target.value);
    };
  //handle priority model open
  handlePriorityModalOpen = () => {
    this.setState({ priorityModal: true, priorityInput: {},
      priorityError: {} });
  };
  //handle priority model close
  handlePriorityModalClose = () => {
    this.setState({
      priorityModal: false,
      priorityInput: {},
      priorityError: {},
      isEdit: false,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  //handle check priority input validation for create and edit
  handlePriorityValidation = () => {
    let priorityInput = this.state.priorityInput;
    let priorityError = {};
    let isValid = true;

    if (!priorityInput["PriorityName"]) {
      isValid = false;
      priorityError["PriorityName"] = "Please enter priority name.";
    }
    if (priorityInput["Status"] === "" || priorityInput["Status"] === null) {
      isValid = false;
      priorityError["Status"] = "Please select status.";
    }
    this.setState({
      priorityError,
    });
    return isValid;
  };
  //handle priority input change
  handlePriorityInputChange = async (name, e) => {
    var priorityInput = this.state.priorityInput;
    var priorityError = this.state.priorityError;
    if (name === "PriorityName") {
      priorityInput[name] = e.target.value;
      priorityError[name] = "";
    } else {
      priorityInput[name] = e;
      priorityError[name] = "";
    }

    await this.setState({
      priorityInput,
      priorityError,
    });
  };
  //handle priority submit
  handlePrioritySubmit = () => {
    if (this.handlePriorityValidation()) {
      if (!this.state.isEdit) {
        this.handleCreatePriority();
      } else {
        this.handleUpdatePriority();
      }
    }
  };
  //handle create priority
  handleCreatePriority = () => {
    var inputData = {};
    inputData.PriorityName = this.state.priorityInput.PriorityName;
    inputData.IsActive = this.state.priorityInput.Status;
    this.setState({ isSubmitLoading: true });
    try {
      this.priorityService
        .createPriority("InsertPriority", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          this.setState({ isSubmitLoading: false });
          if (statusCode === 200) {
            NotificationManager.success("Priority Created Successfully.");
            this.handleGetPriorityGridData();
            this.setState({ priorityModal: false });
          } else {
            NotificationManager.error("Priority Not Create.");
          }
        })
        .catch((error) => {
          this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle update priority
  handleUpdatePriority = () => {
    var inputData = {};
    inputData.PriorityName = this.state.priorityInput.PriorityName;
    inputData.IsActive = this.state.priorityInput.Status;
    inputData.PriorityID = this.state.priorityInput.PriorityID;
    this.setState({ isSubmitLoading: true });
    try {
      this.priorityService
        .updatePriority("UpdatePriority", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          this.setState({ isSubmitLoading: false });
          if (statusCode === 200) {
            NotificationManager.success("Priority Updated Successfully.");
            this.handleGetPriorityGridData();
            this.setState({ priorityModal: false });
          } else {
            NotificationManager.error("Priority Not Updated.");
          }
        })
        .catch((error) => {
          this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get edit priority data
  handleGetEditPriorityData = async (e) => {
    var priorityInput = this.state.priorityInput;
    priorityInput["PriorityName"] = e.priorityName;
    priorityInput["Status"] = e.isActive;
    priorityInput["PriorityID"] = e.priorityID;
    await this.setState({
      priorityModal: true,
      priorityInput,
      isEdit: true,
    });
  };
  //handle get priority gird data
  handleGetPriorityGridData = (search) => {
    this.setState({ loading: true });
    var inputData = {};
    inputData.IDs = "";
      inputData.Value = search;
    try {
      this.priorityService
        .getPriorityGirdData("GetPriority", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var priorityGirdData = response.responseData;
          this.setState({ loading: false });
          if (statusCode === 200) {
            this.setState({ priorityGirdData });
          } else {
            this.setState({ priorityGirdData: [] });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get priority gird data
  handleDeletePriority = (id) => {
    var inputData = {};
    inputData.IDs = id.toString();
    inputData.Value = "";
    try {
      this.priorityService
        .deletePriority("DeletePriority", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          this.setState({ isSubmitLoading: false });
          if (statusCode === 200) {
            NotificationManager.success("Priority Deleted Successfully.");
            this.handleGetPriorityGridData();
            this.setState({ priorityModal: false });
          } else {
            NotificationManager.error("Priority Not Deleted.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

    handleGetModuleDetails = () => {
        try {
            this.UserService.getUserModuleDetails("GetUserModuleDetails")
                .then((response) => {
                    
                    var statusCode = response.statusCode;
                    var data = response.responseData;
                    if (statusCode === 200) {
                        //this.setState({ cityData });
                        this.setAccessUser(data);
                    } else {
                        //this.setState({ cityData: [] });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    setAccessUser(data) {
        
        if (data !== null) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].moduleName === "Settings") {
                    this.setState({
                        createAccess: data[i].isCreate,
                        viewAccess: data[i].isView,
                        deleteAccess: data[i].isDelete,
                        editAccess: data[i].isEdit,
                        downloadAccess: data[i].isDownload
                    });
                }
            }
        }
    }

  render() {
    const columns = [
      {
        title: "Priority",
        dataIndex: "priorityName",
      },
      {
        title: "Created by",
        dataIndex: "createdByName",
      },
      {
        title: "Updated by",
        dataIndex: "modifiedByName",
      },
      {
        title: "Created Date",
        dataIndex: "createdDate",
      },
      {
        title: "Status",
        dataIndex: "isActive",
        render: (item, row) => {
          return (
            <div className="Status" >
              <Select value={row.isActive} showArrow={false} disabled={true}>
                <Option value={true}>Active</Option>
                <Option value={false}>Inactive</Option>
              </Select>
            </div>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (item, row) => {
          return (
            <div className="Actionic">
              {this.state.editAccess &&
              <img
                src={AddNoteIcon}
                alt="Edit"
                onClick={this.handleGetEditPriorityData.bind(this, row)}
              />}
              {this.state.deleteAccess &&
              <img
                src={CloseCircleIcon}
                alt="delete"
                onClick={this.handleDeletePriority.bind(this, row.priorityID)}
              />}
            </div>
          );
        },
      },
    ];

    const { Option } = Select;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div className="PriorityMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Priority
                {this.state.createAccess &&
                <img
                  src={AddIcon}
                  alt="add"
                  onClick={this.handlePriorityModalOpen.bind(this)}
                />}
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by Name..."
                            prefix={<SearchOutlined />}
                            onChange={this.handleSearchOnChange.bind(this)}
              />
            </div>
          </div>
          {this.state.viewAccess &&
          <div className="PriorityTable">
            <Table
                        rowSelection={rowSelection}
                        rowKey={(record) => record.priorityID}
              columns={columns}
              dataSource={this.state.priorityGirdData}
              pagination={true}
              loading={this.state.loading}
            />
                    </div>
          }
          {/* Create Priority */}

          <Modal
            closable={false}
            title={!this.state.isEdit ? "Create Priority" : "Edit Priority"}
            visible={this.state.priorityModal}
            footer={[
              <Button
                key="back"
                onClick={this.handlePriorityModalClose.bind(this)}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={this.state.isSubmitLoading}
                onClick={this.handlePrioritySubmit.bind(this)}
              >
                {this.state.isEdit ? "Update" : "Create"}
              </Button>,
            ]}
          >
            <div className="createPrior">
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Priority Name</label>
                  <Input
                    placeholder="Enter Priority Name"
                    value={this.state.priorityInput.PriorityName}
                    onChange={this.handlePriorityInputChange.bind(
                      this,
                      "PriorityName"
                    )}
                  />
                  {this.state.priorityError ? (
                    <div className="text-danger">
                      {this.state.priorityError["PriorityName"] || ""}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Status</label>
                  <Select
                    value={this.state.priorityInput.Status}
                    onChange={this.handlePriorityInputChange.bind(
                      this,
                      "Status"
                    )}
                    placeholder={"Select Status"}
                  >
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                  {this.state.priorityError ? (
                    <div className="text-danger">
                      {this.state.priorityError["Status"] || ""}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Priority;
