import React, { useEffect, useState } from 'react';
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { accountService } from './../../../services/Account/AccountService';
import FBConnectorService from "../../../services/AdminSetting/FBConnectorService";
const fbConn = new FBConnectorService();


function FacbookIntegrator() {
    const [subsriptionDetails, setSubsriptionDetails] = useState([]);
    useEffect(() => {
        // redirect to home if already logged in
        handleGetFBSubscription()
    }, []);
    
    function handleGetFBSubscription() {
        
        try {
            fbConn.getFBSubscription("GetFBSubscription")
                .then((response) => {
                    
                    var statusCode = response.statusCode;
                    var data = response.responseData;
                    if (statusCode === 200) {
                        var subsriptionDetails = [];
                        if (data.length > 0) {
                            console.log('Successfully retrieved pages', response);

                            var fbTab = document.getElementById('fbdatatble');
                            var tblBody = document.createElement("tbody");
                            for (var i = 0, len = data.length; i < len; i++) {
                                var page = data[i];
                                var arrHead = new Array();	// array for header.
                                arrHead = ['Page Id', 'Page Name', 'Action'];
                               
                                var rowCnt = fbTab.rows.length;   // table row count.
                                var tr = fbTab.insertRow(rowCnt); // the table row.
                                //tr = fbTab.insertRow(rowCnt);
                               
                                for (var c = 0; c < arrHead.length; c++) {
                                    var td = document.createElement('td'); // table definition.
                                    td = tr.insertCell(c);

                                    if (c == arrHead.length - 1) {      // the first column.      
                                        var a = document.createElement('button');
                                        a.onclick = handleUpdateFBSubscription.bind(this, page.pageID);
                                        a.innerHTML = "UnSubscribe";
                                        a.className = "btn btn-danger";
                                        a.setAttribute("id", "fb_" + page.pageID)
                                        td.appendChild(a);
                                    }
                                    else {
                                        var newText;
                                        if (c == 0) {
                                            newText = document.createTextNode(page.pageID);
                                        }
                                        else if (c == 1) {
                                            newText = document.createTextNode(page.pageName);
                                        }

                                        td.appendChild(newText);
                                    }
                                }
                                tblBody.appendChild(tr);
                                fbTab.appendChild(tblBody);
                                subsriptionDetails.push(page.pageID);

                            }
                            setSubsriptionDetails(subsriptionDetails);
                        }
                    } else {
                        //alert("Failed");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        } catch (error) {
            console.log(error);
        }
    };

    function handleUpdateFBSubscription(page_id) {
        
        var inputData = {};
        inputData.IDS = page_id;
        try {
            fbConn.updateFBSubscription("UpdateFBSubscription", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    var data = response.responseData;
                    if (statusCode === 200) {
                        alert("Page unsubscribed successfully");
                    } else {
                        alert("Page not unsubscribed successfully");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="LeadBulkUp connFacebook">
        {/*{this.state.leadSuccess ? "onesuccess" : (this.state.leadDetailsSuccess ? "twosuccess" : (this.state.leadAddDetailsSuccess ? "allsuccess" : "addlead"))}*/}
        <div className="row">
          <div className="col-12 col-md-12 p-0">
            <h4 className="hand">Facebook Integrator</h4>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-12 p-0">
            <div className="card">
                <label className="impor"><span>Dear Customer,</span>
                        To get the list of pages to link, Please connect to your facebook account and select the pages
                        which has to be configured for Lead Push. After you select the pages it will populate in the
                        'Facebook Link Pages' section then need to click on Subscribe to activate.
                </label>
                <button type="button" onClick={(event) => accountService.login(subsriptionDetails)}>
                     <img src="https://static.docengage.in/assets/images/facebook/continueWithFb.png" style={{ width: "100%", height: "100%" }}></img>
                </button>            
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 p-0">
            <h4 className="hand mb-3">Facebook Link Pages</h4>
            <table id="fbdatatble" className="table table-bordered">
             <thead>
                 <tr>
                    <th>Page Id</th>
                    <th>Page Name</th>
                    <th>Action</th>
                 </tr>
             </thead>
            
            </table>
          </div>
        </div>
            
      </div>
    );
}

export default FacbookIntegrator;
