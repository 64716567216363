import Configuration from "../../helpers/Configuration";
import axios from "axios";
import authService from "../../components/api-authorization/AuthorizeService";

class DNDService {

    constructor() {
        this.config = new Configuration();
    }

    async getDNDFeatMaster(url, inputData) {
        const response = await axios(this.config.DndServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async insertDNDFeatMaster(url, inputData) {
        const response = await axios(this.config.DndServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

}
export default DNDService;