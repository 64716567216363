import Configuration from "./../../helpers/Configuration";
import { AuthHeader as authHeader } from "./../../helpers/AuthHeader";
import authService from "../../components/api-authorization/AuthorizeService";
import axios from "axios";


class RoleAccessControlService {
  constructor() {
    this.config = new Configuration();
  }

    async getRoleMappingData(url, inputData) {
        const response = await axios(this.config.RoleAccessServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async insertUpdateRoleMapping(url, inputData) {
        const response = await axios(this.config.RoleAccessServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

  
}

export default RoleAccessControlService;
