import React, { Component } from "react";
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import {
  Input,
  Radio,
  Select,
  Table,
  Modal,
  Button,
  Checkbox,
  Tabs,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import {
  AddIcon,
  AddNoteIcon,
  UploadIcon,
  CloseCircleIcon,
} from "./../../../helpers/ImagesImport";
import FileUpload from "./../../../assets/images/upload.png";
import AlertService from "./../../../services/AdminSetting/AlertService";
import { NotificationManager } from "react-notifications";
import UserService from "./../../../services/AdminSetting/UserService";

class Alerts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createAlerts: false,
      Single: true,
      Multiple: false,
      fileName: "",
      fileN: [],
      alertTypeNameData: [],
      unitData: [],
      emailCust: false,
      emailInternal: false,
      smsCust: false,
      smsInternal: false,
      notifCust: false,
      notifInternal: false,
      createAlertsTemp: false,
      alertId: 0,
      alertInputs: {},
      alertGridData: [],
      alertErrors: {},
        isLoading: false,
      isEdit:false,
      placeholderData: [],
      emailPlaceholder: "",
      smsPlaceholder: "",
      notifPlaceholder: "",
      emailContent: "",
      smsContent: "",
      notifContent: "",
      createAccess: true,
      viewAccess: true,
      deleteAccess: true,
      editAccess: true,
      downloadAccess: true,
    };

    this.AlertService = new AlertService();
    this.UserService = new UserService();
  }
  componentDidMount() {
    this.handleGetAlertTypeData();
    this.handleGetAlertGridData();
    this.handleGetPlaceholderData();
    this.handleGetModuleDetails();
  }

    handleSearchOnChange = async (event) => {
        
        this.handleGetAlertGridData(event.target.value);
    };
  handleCreateAlertsOpen() {
    this.setState({
      alertId: 0,
      alertInputs: {},
      emailCust: false,
      emailInternal: false,
      smsCust: false,
      smsInternal: false,
      notifCust: false,
      notifInternal: false,
      createAlerts: true,
      emailPlaceholder: "",
      smsPlaceholder: "",
      notifPlaceholder: "",
      emailContent: "",
      smsContent: "",
        notifContent: "",
        isEdit:false
    });
  }
  handleCreateAlertsClose() {
    this.setState({ createAlerts: false });
  }
  handleCreateAlertsTempOpen() {
    if (this.handleValidation()) {
      this.setState({ createAlertsTemp: true, createAlerts: false });
    }
  }
  handleCreateAlertsTempClose() {
    this.setState({ createAlertsTemp: false });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
    onSelectChange = (selectedRowKeys) => {
        debugger;
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };

  handleGetAlertTypeData = () => {
    try {
      this.AlertService.getAlertTypeName("GetAlertTypeName")
        .then((response) => {
          var statusCode = response.statusCode;
          var unitData = response.responseData;
          if (statusCode === 200) {
            this.setState({ unitData });
          } else {
            this.setState({ unitData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleOnChange = (name, e) => {
    var alertInputs = this.state.alertInputs;
    alertInputs[name] = e;
    this.setState({
      alertInputs,
    });
  };

  handleCreateModifyAlert = () => {
      if (this.handleContentValidation()) {
          
      var alert = this.state;

      var email, sms, noification;
      var jsondata = [];
      var emailCommunicationMode = [],
        smsCommunicationMode = [],
        notifCommunicationMode = [];
      if (alert.emailCust == true) {
        emailCommunicationMode.push(250);
      }
      if (alert.emailInternal == true) {
        emailCommunicationMode.push(251);
      }
      if (alert.smsCust == true) {
        smsCommunicationMode.push(250);
      }
      if (alert.smsInternal == true) {
        smsCommunicationMode.push(251);
      }
      if (alert.notifCust == true) {
        notifCommunicationMode.push(250);
      }
      if (alert.notifInternal == true) {
        notifCommunicationMode.push(251);
      }

      if (alert.emailCust == true || alert.emailInternal == true) {
        email = {
          AlertTypeID: 0,
          Communication_Mode: 240,
          CommunicationFor: emailCommunicationMode,
          Content:
            this.state.emailContent !== undefined
              ? this.state.emailContent
              : "",
          ToEmailID: "",
          CCEmailID: "",
          BCCEmailID: "",
          Subject: "",
        };
        jsondata.push(email);
      }
      if (alert.smsCust == true || alert.smsInternal == true) {
        sms = {
          AlertTypeID: 0,
          Communication_Mode: 241,
          CommunicationFor: smsCommunicationMode,
          Content:
            this.state.smsContent !== undefined ? this.state.smsContent : 0,
          ToEmailID: "",
          CCEmailID: "",
          BCCEmailID: "",
          Subject: "",
        };
        jsondata.push(sms);
      }
      if (alert.notifCust == true || alert.notifInternal == true) {
        noification = {
          AlertTypeID: 0,
          Communication_Mode: 242,
          CommunicationFor: notifCommunicationMode,
          Content:
            this.state.notifContent !== undefined
              ? this.state.notifContent
              : 0,
          ToEmailID: "",
          CCEmailID: "",
          BCCEmailID: "",
          Subject: "",
        };
        jsondata.push(noification);
      }

      var inputData = {};
      inputData.AlertID = this.state.alertInputs.alertType;
      inputData.IsAlertActive = this.state.alertInputs.status;
      inputData.CommunicationModeDetails = jsondata;

      try {
        this.AlertService.createAlert("CreateModifyAlert", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
              if (statusCode === 200) {
                  if (this.state.isEdit === true) {
                      NotificationManager.success("Record Updated Successfully.");
                  }
                  else {
                      NotificationManager.success("Record Created Successfully.");
                  }
              this.handleGetAlertGridData();
              this.setState({
                createAlerts: false,
                createAlertsTemp: false,
              });
            } else {
              NotificationManager.success("Record Not Created.");
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleModeOnChange(e) {
    let check = e.target.checked;
    let name = e.target.name;
    if (check === true) {
      this.setState({
        [name]: check,
      });
    } else {
      this.setState({
        [name]: check,
      });
    }
  }

  handleValidation = () => {
    var alertErrors = {};
    var isValid = true;
    if (
      this.state.alertInputs.alertType == 0 ||
      this.state.alertInputs.alertType == undefined
    ) {
      alertErrors["alertType"] = "Please select alert type name.";
      isValid = false;
    } else if (
      this.state.emailCust === false &&
      this.state.emailInternal === false &&
      this.state.smsCust === false &&
      this.state.smsInternal === false &&
      this.state.notifCust === false &&
      this.state.notifInternal === false
    ) {
      NotificationManager.error("Please select any communication mode");
      isValid = false;
    } else if (this.state.alertInputs.status == undefined) {
      alertErrors["status"] = "Please select status.";
      isValid = false;
    }
    this.setState({
      alertErrors,
    });
    return isValid;
  };

  handleContentValidation = () => {
    var isValid = true;
    if (this.state.emailCust === true || this.state.emailInternal === true) {
      if (!this.state.emailContent || !this.state.emailContent[0]) {
        NotificationManager.error("Please enter email content.");
        isValid = false;
      }
    }
    if (this.state.smsCust === true || this.state.smsInternal === true) {
      if (!this.state.smsContent || !this.state.smsContent[0]) {
        NotificationManager.error("Please enter sms content.");
        isValid = false;
      }
    }
    if (this.state.notifCust === true || this.state.notifInternal === true) {
      if (!this.state.notifContent || !this.state.notifContent[0]) {
        NotificationManager.error("Please enter notification content.");
        isValid = false;
      }
    }
    return isValid;
  };

    handleContentOnChange = (name,e) => {
        

        if (name == "email") {
            this.setState({
                emailContent: e.target.value
            })
        }
        if (name == "sms") {
            this.setState({
                smsContent: e.target.value
            })
        }
        if (name == "notification") {
            this.setState({
                notifContent: e.target.value
            })
        }
    //this.setState({
    //  [name]: [e],
    //});
  };

    handleGetAlertGridData = (search) => {
        debugger;
    var inputData = {};
      inputData.SearchText = search;
    this.setState({
      isLoading: true,
    });
    try {
      this.AlertService.getAlertList("GetAlertList", inputData)
          .then((response) => {
              debugger;
          var message = response.message;
          var alertGridData = response.responseData;
          this.setState({
            isLoading: false,
          });
          if (message == "Success") {
            this.setState({ alertGridData });
          } else {
            this.setState({ alertGridData: [] });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log(error);
        });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      console.log(error);
    }
  };

  handleAlertDetails = (alertID) => {
    var inputData = {};
    inputData.AlertID = alertID;
      this.setState({
          alertId: 0,
          alertInputs: {},
          emailCust: false,
          emailInternal: false,
          smsCust: false,
          smsInternal: false,
          notifCust: false,
          notifInternal: false,
          emailPlaceholder: "",
          smsPlaceholder: "",
          notifPlaceholder: "",
          emailContent: "",
          smsContent: "",
          notifContent: ""
      });

    try {
      this.AlertService.getAlertDetails("GetSelectedAlertDetails", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            var alertInputs = {};
            alertInputs["alertType"] = data.alertID;
            alertInputs["status"] = data.isAlertActive;
            data.communicationModeDetails.map((item) => {
              if (item.communication_Mode == 240) {
                if (item.communicationFor.includes(250)) {
                  this.setState({
                    emailCust: true,
                  });
                }
                if (item.communicationFor.includes(251)) {
                  this.setState({
                    emailInternal: true,
                  });
                }
                this.setState({
                  emailContent: item.content,
                });
              }
              if (item.communication_Mode == 241) {
                if (item.communicationFor.includes(250)) {
                  this.setState({
                    smsCust: true,
                  });
                }
                if (item.communicationFor.includes(251)) {
                  this.setState({
                    smsInternal: true,
                  });
                }
                this.setState({
                  smsContent: item.content,
                });
              }
              if (item.communication_Mode == 242) {
                if (item.communicationFor.includes(250)) {
                  this.setState({
                    notifCust: true,
                  });
                }
                if (item.communicationFor.includes(251)) {
                  this.setState({
                    notifInternal: true,
                  });
                }
                this.setState({
                  notifContent: item.content,
                });
              }
              this.setState({
                alertInputs,
                  createAlerts: true,
                  isEdit: true,
              });
            });
          } else {
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteAlert = (alertID) => {
    var inputData = {};
    inputData.AlertID = alertID;

    try {
      this.AlertService.deleteAlert("DeleteAlertCommunicationMode", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            NotificationManager.success("Record Deleted Successfully.");
            this.handleGetAlertGridData();
          } else {
            NotificationManager.error("Record Not Deleted Successfully.");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetPlaceholderData = () => {
      var inputData = {};
      inputData.AlertID = this.state.alertId;
    try {
        this.AlertService.getPlaceholderData("GetMailParameter", inputData)
        .then((response) => {
            
          var statusCode = response.statusCode;
          var placeholderData = response.responseData;
          if (statusCode === 200) {
            this.setState({ placeholderData });
          } else {
            this.setState({ placeholderData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleSelectOnChange = (name,e) =>{
      
      var { emailContent, smsContent, notifContent } = this.state;

      if (name == "emailPlaceholder") {
          emailContent += e
          this.setState({
              emailContent
          })
      }
      if (name == "smsPlaceholder") {
          smsContent += e
          this.setState({
              smsContent
          })
      }
      if (name == "notifPlaceholder") {
          notifContent += e
          this.setState({
              notifContent
          })
      }
      this.setState({
          [name]: [e],
      });
  }

  handleGetModuleDetails = () => {
    try {
        this.UserService.getUserModuleDetails("GetUserModuleDetails")
        .then((response) => {
            
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            //this.setState({ cityData });
              this.setAccessUser(data);
          } else {
            //this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  setAccessUser(data) {
      if (data !== null) {
          for (var i = 0; i < data.length; i++) {
              if (data[i].moduleName === "Settings") {
                   this.setState({
                      createAccess: data[i].isCreate,
                      viewAccess: data[i].isView,
                      deleteAccess: data[i].isDelete,
                      editAccess: data[i].isEdit,
                      downloadAccess: data[i].isDownload
                   });
              }
          }
      }
  }

  render() {
    const { TabPane } = Tabs;
    const data = [
      {
        key: "1",
        alertName: "Call Back Later",
        createdBy: "Vikash Sharma",
      },
      {
        key: "2",
        alertName: "Claim  Assign",
        createdBy: "Vikash Sharma",
      },
      {
        key: "3",
        alertName: "Campaign Open",
        createdBy: "Vikash Sharma",
      },
    ];
    const { Option } = Select;
    const { loading, selectedRowKeys } = this.state;
      const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className="AlertsMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Alerts
                {this.state.createAccess &&
                <img
                  src={Add}
                  alt="add"
                  onClick={this.handleCreateAlertsOpen.bind(this)}
                />
                }
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by alert name and created by..."
                            prefix={<SearchOutlined />}
                            onChange={this.handleSearchOnChange.bind(this)}
              />
            </div>
          </div>
          {this.state.viewAccess &&
          <div className="AlertTable">
            <Table
                        rowSelection={rowSelection}
                        rowKey={(record) => record.alertID}
              loading={this.state.isLoading}
              columns={[
                {
                  title: "Alert Name",
                  dataIndex: "alertTypeName",
                  key: "alertTypeName",
                },
                {
                  title: "Created By",
                  dataIndex: "createdByName",
                  key: "createdByName",
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status",
                  render: (row, item) => {
                    return (
                      <div className="Status">
                        <Select value={item.isActive} showArrow={false} disabled={true}>
                          <Option value={true}>Active</Option>
                          <Option value={false}>Inactive</Option>
                        </Select>
                      </div>
                    );
                  },
                },
                {
                  title: "Actions",
                  key: "actions",
                  dataIndex: "actions",
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        {this.state.editAccess &&
                        <img
                          src={AddNote}
                          alt="Edit"
                          onClick={this.handleAlertDetails.bind(
                            this,
                            item.alertID
                          )}
                        />}
                        {this.state.deleteAccess &&
                        <img
                          src={CloseCircle}
                          alt="delete"
                          onClick={this.handleDeleteAlert.bind(
                            this,
                            item.alertID
                          )}
                        />}
                      </div>
                    );
                  },
                },
              ]}
              dataSource={this.state.alertGridData}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true
              }}
            />
                    </div>
          }
          {/* Create Alerts */}
                <Modal
                    title={!this.state.isEdit ? "Create Alerts" : "Edit Alerts"}
            visible={this.state.createAlerts}
            closable={false}
            footer={[
              <Button
                key="back"
                onClick={this.handleCreateAlertsClose.bind(this)}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.handleCreateAlertsTempOpen.bind(this)}
              >
                  {!this.state.isEdit ? "Create" : "Update"}
                
              </Button>,
            ]}
          >
            <div className="createAlerts">
                        {/*<div className="hier mb-3">
               <label>Create Alerts</label>
              </div>*/}
              {/* <div className="mb-2">
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Single}
                  onChange={this.handleRedioButtonChange.bind(this, "Single")}
                >
                  Single
                </Radio>
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Multiple}
                  onChange={this.handleRedioButtonChange.bind(this, "Multiple")}
                >
                  Multiple
                </Radio>
              </div> */}
              {this.state.Single && (
                <>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">
                        Alerts Type Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Alert Type Name"
                        name="alertType"
                        value={this.state.alertInputs.alertType}
                        onChange={this.handleOnChange.bind(this, "alertType")}
                      >
                        <Option value={0}>Select Alert Type</Option>
                        {this.state.unitData &&
                          this.state.unitData.map((item) => (
                            <Option value={item.alertID}>
                              {item.alertTypeName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.alertErrors && (
                        <div className="validationsMsg">
                          {this.state.alertErrors["alertType"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="hier">
                    <label>Communication Mode</label>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Email</label>
                      <div className="Em">
                        <Checkbox
                          name="emailCust"
                          checked={this.state.emailCust}
                          onChange={this.handleModeOnChange.bind(this)}
                        >
                          Customer
                        </Checkbox>
                        <Checkbox
                          name="emailInternal"
                          checked={this.state.emailInternal}
                          onChange={this.handleModeOnChange.bind(this)}
                        >
                          Internal
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">SMS</label>
                      <div className="Em">
                        <Checkbox
                          name="smsCust"
                          checked={this.state.smsCust}
                          onChange={this.handleModeOnChange.bind(this)}
                        >
                          Customer
                        </Checkbox>
                        <Checkbox
                          name="smsInternal"
                          checked={this.state.smsInternal}
                          onChange={this.handleModeOnChange.bind(this)}
                        >
                          Internal
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Notification</label>
                      <div className="Em">
                        {/*<Checkbox*/}
                        {/*  name="notifCust"*/}
                        {/*  checked={this.state.notifCust}*/}
                        {/*  onChange={this.handleModeOnChange.bind(this)}*/}
                        {/*>*/}
                        {/*  Customer*/}
                        {/*</Checkbox>*/}
                        <Checkbox
                          name="notifInternal"
                          checked={this.state.notifInternal}
                          onChange={this.handleModeOnChange.bind(this)}
                        >
                          Internal
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Status</label>
                      <Select
                        placeholder="Select Status"
                        name="status"
                        value={this.state.alertInputs.status}
                        onChange={this.handleOnChange.bind(this, "status")}
                      >
                        <Option value={true}>Active</Option>
                        <Option value={false}>Inactive</Option>
                      </Select>
                      {this.state.alertErrors && (
                        <div className="validationsMsg">
                          {this.state.alertErrors["status"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {this.state.Multiple && (
                <>
                  <div className="editor">
                    <div className="mainfileUpload">
                      <Dropzone onDrop={this.fileUpload.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              className="file-upload d-none"
                            />
                            <div className="file-icon">
                              <img src={FileUpload} alt="file-upload" />
                            </div>
                            <span className={"drop"}>
                              Drop your file to upload
                            </span>
                            <button className="fileupload">Choose File</button>
                            {this.state.downloadAccess &&
                            <span className="down">Download Sample File</span>}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Modal>
          {/* Modal For Template */}
          <Modal
            visible={this.state.createAlertsTemp}
            closable={false}
            footer={[
              <Button
                key="back"
                onClick={this.handleCreateAlertsTempClose.bind(this)}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.handleCreateModifyAlert.bind(this)}
              >
                SAVE
              </Button>,
            ]}
          >
            <div className="createAlerts1">
              <div className="hier mb-3">
                <label>
                  {/* Alert Type : <span>Sample123</span> */}
                  Alert Type
                </label>
              </div>
              <Tabs defaultActiveKey="1">
                <TabPane
                  key="1"
                  tab={
                    <div>
                      <label>Email</label>
                    </div>
                  }
                >
                  <div className="Ema">
                    <label className="Compose">Compose Your Email</label>
                    <Select
                     placeholder="Select Placeholder"
                     name="emailPlaceholder"
                     onChange={this.handleSelectOnChange.bind(this,"emailPlaceholder")}
                     value={this.state.emailPlaceholder}
                    >
                        <Option value="">Select Placeholder</Option>
                        {this.state.placeholderData &&
                          this.state.placeholderData.map((item) => (
                              <Option value={item.parameterName}>
                                  {item.description}
                              </Option>
                          ))}
                      </Select>
                    <div className="Card">
                      <textarea
                        type="text"
                        style={{ height: "184px", width: "100%" }}
                        name="emailContent"
                        onChange={this.handleContentOnChange.bind(this,"email")}
                        value={this.state.emailContent}
                        placeholder="Enter Email Content"
                      ></textarea>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  key="2"
                  tab={
                    <div>
                      <label>SMS</label>
                    </div>
                  }
                >
                  <div className="Ema">
                    <label className="Compose">Compose Your SMS</label>
                    <Select defaultValue="Select Placeholder"
                     name="smsPlaceholder"
                     onChange={this.handleSelectOnChange.bind(this,"smsPlaceholder")}
                     value={this.state.smsPlaceholder}
                    >
                        <Option value="">Select Placeholder</Option>
                        {this.state.placeholderData &&
                          this.state.placeholderData.map((item) => (
                              <Option value={item.parameterName}>
                                  {item.description}
                              </Option>
                          ))}
                      </Select>
                    <div className="Card">
                      <textarea
                        type="text"
                        style={{ height: "184px", width: "100%" }}
                        name="smsContent"
                        onChange={this.handleContentOnChange.bind(this,"sms")}
                        value={this.state.smsContent}
                        placeholder="Enter SMS Content"
                      ></textarea>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  key="3"
                  tab={
                    <div>
                      <label>Notification</label>
                    </div>
                  }
                >
                  <div className="Ema">
                    <label className="Compose">Compose Your Notification</label>
                    <Select defaultValue="Select Placeholder"
                     name="notifPlaceholder"
                     onChange={this.handleSelectOnChange.bind(this,"notifPlaceholder")}
                     value={this.state.notifPlaceholder}
                    >
                        <Option value="">Select Placeholder</Option>
                        {this.state.placeholderData &&
                          this.state.placeholderData.map((item) => (
                              <Option value={item.parameterName}>
                                  {item.description}
                              </Option>
                          ))}
                      </Select>
                    <div className="Card">
                      <textarea
                        type="text"
                        style={{ height: "184px", width: "100%" }}
                        name="notifContent"
                        onChange={this.handleContentOnChange.bind(this,"notification")}
                        value={this.state.notifContent}
                        placeholder="Enter Notification Content"
                      ></textarea>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Alerts;
