export default class Configuration {
  APIURL = "https://localhost:44351/";
  PriorityServiceURL = "api/Priority/";
  LocationServiceURL = "api/Location/";
  CommonServiceURL = "api/Common/";
  SLAServiceURL = "api/SLA/";
  EnquiryServiceURL = "api/Enquiry/";
  LeadServiceURL = "api/Lead/";
  HierarchyServiceURL = "api/Hierarchy/";
  AlertServiceURL = "api/Alert/";
  UserServiceURL = "api/User/";
  TeamServiceURL = "api/Team/";
  RoleAccessServiceURL = "api/Role/";
  NotificationServiceURL = "api/Notification/";
  AppointmentServiceURL = "api/Appointment/";
  ReportServiceURL = "api/Report/";
  TemplateServiceURL = "api/Template/";
    FBConnectorServiceURL = "api/FBConnector/";
    StatusServiceURL = "api/Status/";
    SpecialityServiceURL = "api/Speciality/";
    ServiceServiceURL = "api/Service/";
    DndServiceURL = "api/DND/";
}
