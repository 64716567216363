import Configuration from "../../helpers/Configuration";
import { AuthHeader as authHeader } from "../../helpers/AuthHeader";
import authService from "../../components/api-authorization/AuthorizeService";
import axios from "axios";


class UserService {
  constructor() {
    this.config = new Configuration();
    }

    /// get Gird data
    async getUserGridData(url, inputData) {
        const response = await axios(this.config.UserServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getLanguageData(url, inputData) {
        const response = await axios(this.config.CommonServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async createUpdateUser(url, inputData) {
        const response = await axios(this.config.UserServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async deleteUser(url, inputData) {
        const response = await axios(this.config.UserServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getUserModuleDetails(url, inputData) {
        const response = await axios(this.config.UserServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async importFile(url, inputData) {
        const response = await axios(this.config.UserServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getUserProfileData(url, inputData) {
        const response = await axios(this.config.UserServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async updateUserProfileData(url, inputData) {
        const response = await axios(this.config.UserServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }
}

export default UserService;
