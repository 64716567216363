import Configuration from "../../helpers/Configuration";
import axios from "axios";
class CommonService {
  constructor() {
    this.config = new Configuration();
  }
  async getBrandList(url) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
    });
    return await response.data;
  }
  async getCountryList(url) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
    });
    return await response.data;
  }
  async getStateList(url, inputData) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async getCityList(url, inputData) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async getRegionList(url, inputData) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async getPinCodeList(url, inputData) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async getModuleList(url) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
    });
    return await response.data;
  }
  async getLevelList(url) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
    });
    return await response.data;
  }
  async getAccessTypeList(url) {
    const response = await axios(this.config.CommonServiceURL + url, {
      method: "POST",
    });
    return await response.data;
  }
}
export default CommonService;
