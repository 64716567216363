import Configuration from "../../helpers/Configuration";
import axios from "axios";
import authService from "../../components/api-authorization/AuthorizeService";

class PriorityService {
  constructor() {
    this.config = new Configuration();
  }
  async getPriorityGirdData(url,inputData) {
    const response = await axios(this.config.PriorityServiceURL + url, {
      method: "POST",
      data:inputData ,
    });
    return await response.data;
  }
  async deletePriority(url, inputData) {
    const response = await axios(this.config.PriorityServiceURL + url, {
      method: "POST",
      data:inputData
    });
    return await response.data;
  }
  async createPriority(url, inputData) {
    const response = await axios(this.config.PriorityServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async updatePriority(url, inputData) {
    const response = await axios(this.config.PriorityServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
}

export default PriorityService;
