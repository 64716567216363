import { Button } from "antd";
import CKEditor from "ckeditor4-react";
import React, { Fragment, useState } from "react";
import { NotificationManager } from "react-notifications";
import LeadSearch from "../../../services/Leads/LeadsService";
const leadSearch = new LeadSearch();
export default function AddNoteComponent(props) {
  const [leadNote, setLeadNote] = useState("");
  const [isLeadNote, setIsLeadNote] = useState("");
  function handleAddNotesClose(isSuccess) {
    props.handleAddNotesClose(isSuccess);
  }

  function handleOnChange(e) {
    setLeadNote(e.editor.getData());
    setIsLeadNote("");
  }

  function handleAddNote() {
    
    if (leadNote && props.LeadID) {
      setIsLeadNote("");
      var inputData = {};
      inputData.LeadID = props.LeadID;
      inputData.NotesDetail = (leadNote).replace(new RegExp('<[^>]*>', 'g'), '');
      try {
        leadSearch
          .addLeadNotes("InsertLeadNotes", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
            var responseData = response.responseData;

            if (statusCode === 200) {
              NotificationManager.success("Note added successfully.");
              handleAddNotesClose(true);
            } else {
              NotificationManager.error("Note note added. ");
            }
          })
          .catch((error) => {
            NotificationManager.error("Note note added. ");
            console.log(error);
          });
      } catch (error) {
        NotificationManager.error("Note note added. ");
        console.log(error);
      }
    } else {
      setIsLeadNote("Please enter note.");
    }
  }
  return (
    <Fragment>
      <div className="addnote">
        <CKEditor
          data={leadNote}
          onChange={(e) => {
            handleOnChange(e);
          }}
          config={{
            toolbar: [
              {
                name: "basicstyles",
                items: ["Bold", "Italic", "Strike"],
              },
              {
                name: "styles",
                items: ["Styles", "Format"],
              },
              {
                name: "paragraph",
                items: ["NumberedList", "BulletedList"],
              },
              {
                name: "links",
                items: ["Link", "Unlink"],
              },
              {
                name: "insert",
                items: ["Image", "Table"],
              },
              {
                name: "tools",
                items: ["Maximize"],
              },
              {
                name: "editing",
                items: ["Scayt"],
              },
            ],
          }}
        />
        {isLeadNote ? (
          <div className="text-danger">{isLeadNote || ""}</div>
        ) : null}
        <div className="editorbtn">
          <Button
            type="button"
            onClick={() => {
              handleAddNotesClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              handleAddNote(e);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
