import { Button, Select } from "antd";
import React, { Fragment, useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import LeadsService from "../../../services/Leads/LeadsService";
import AlertService from "../../../services/AdminSetting/AlertService";
import TemplateService from "../../../services/AdminSetting/TemplateService";
const leadsService = new LeadsService();
const alertService = new AlertService();
const templateService = new TemplateService();

export default function SMSTab(props) {
  const [smsText, setSMSText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSMSText, setIsSMSText] = useState("");
  const [placeholder, setPlaceholder] = useState(null);
  const [placeholderList, setPlaceholderList] = useState([]);
  const [templateList, setTempateList] = useState([]);
  const [template, setTempate] = useState(null);
  useEffect(() => {
    handleGetPlaceholderData();
    handleGetTemplateMaster();
  }, []);
  function handleSendTextChange(e) {
    setIsSMSText("");
    setSMSText(e.target.value);
  }
  function handleSendSMS() {
    try {
      if (smsText) {
        var inputData = {};
        inputData.MobileNumber = props.MobileNumber;
        inputData.SmsText = smsText;
        inputData.IsSMS = 1;
        inputData.LeadID = props.LeadID;

        let form = new FormData();
        form.append("IsSMS", 1);
        form.append("LeadID", props.LeadID);
        form.append("MobileNumber", props.MobileNumber);
        form.append("SmsText", smsText);

        setIsLoading(true);
        leadsService
          .getLeadDetailsTab("SendLeadEmailSMS", form)
          .then((response) => {
            var statusCode = response.statusCode;
            setIsLoading(false);
            if (statusCode === 200) {
              setSMSText("");
              setPlaceholder(null);
              setTempate(null);
              if (props.isAction) {
                props.handleSMSActionModalClose();
              }
              NotificationManager.success("SMS send successfully.");
            } else {
              NotificationManager.error("SMS send fail.");
            }
          })
          .catch((error) => {
            NotificationManager.error("SMS send fail.");
            setIsLoading(false);
            console.log(error);
          });
      } else {
        setIsSMSText("Please enter SMS text.");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }
  function handleGetPlaceholderData() {
    var inputData = {};
    inputData.AlertID = 0;
    try {
      alertService
        .getPlaceholderData("GetMailParameter", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var placeholderData = response.responseData;
          if (statusCode === 200) {
            setPlaceholderList(placeholderData);
          } else {
            setPlaceholderList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  function handleGetTemplateMaster() {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      templateService
        .getTemplate("GetTemplateMaster", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var templateData = response.responseData;
          if (statusCode === 200) {
            console.log(templateData);
            setTempateList(templateData);
          } else {
            setTempateList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function handleTemplateChange(e) {
    setTempate(e);
    var smsBody = templateList.filter((x) => x.templateID === e)[0]
      .templateBody;
    setSMSText(smsBody);
  }
  return (
    <Fragment>
      <div className="SmsMain">
        <label>Send SMS</label>
        <div className="row">
          <div className="col-6 col-md-6">
            <Select
              placeholder="Select template"
              value={template}
              className="SmsMain-pleacehoder"
              onChange={(e) => {
                handleTemplateChange(e);
              }}
            >
              {templateList &&
                templateList.map((item) => (
                  <Select.Option value={item.templateID}>
                    {item.templateName}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className="col-6 col-md-6">
            <Select
              placeholder="Select Placeholder"
              value={placeholder}
              onChange={(e) => {
                setSMSText(smsText + " " + e);
                setPlaceholder(e);
              }}
              className="SmsMain-pleacehoder"
            >
              {placeholderList &&
                placeholderList.map((item) => (
                  <Select.Option value={item.parameterName}>
                    {item.description}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>
        <textarea
          value={smsText}
          onChange={(e) => {
            handleSendTextChange(e);
          }}
        ></textarea>
        {isSMSText ? <div className="text-danger">{isSMSText}</div> : null}
        <div className="row smstabcom">
          <div className="col-md">
            {props.isAction ? (
              <Button
                className="can"
                onClick={() => props.handleSMSActionModalClose()}
              >
                Cancel
              </Button>
            ) : null}
            <Button
              loading={isLoading}
              type="primary"
              className="sen"
              onClick={() => handleSendSMS()}
              disabled={isLoading}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
