import Configuration from "../../helpers/Configuration";
import axios from "axios";
class EnquiryService {
  constructor() {
    this.config = new Configuration();
  }
  async getEnquirySubType(url, inputData) {
    const response = await axios(this.config.EnquiryServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
    }

    async getEnquiryType(url, inputData) {
        const response = await axios(this.config.EnquiryServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }
}

export default EnquiryService;
