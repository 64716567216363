import { Button, Empty, Pagination, Spin } from "antd";
import React, { Fragment, Component } from "react";
import { SUserIcon, UserIcon } from "../../../helpers/ImagesImport";
import LeadsService from "../../../services/Leads/LeadsService";
import parse from "html-react-parser";
import Modal from "antd/lib/modal/Modal";
import AddTaskComponent from "./AddTaskComponent";

export default class TaskTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      Task: [],
      pageNo: 1,
      pageSize: 10,
      TaskTypeList: props.TaskTypeList,
      leadStatusData: props.leadStatusData,
      leadAssignToData: props.leadAssignToData,
      totalCount: 0,
      taskModal: false,
      leadID: props.LeadID,
      leadDetails: props.LeadDetails,
      isEditTask: false,
      editTaskDetails:null,
      taskCreateAccess: props.CreateTask,
      taskEditAccess: props.EditTask,
      taskViewAccess: props.ViewTask,
    };
    this.leadsService = new LeadsService();
    this.handleTaskModalClose = this.handleTaskModalClose.bind(this);
  }

  componentDidMount() {
    this.handleGetTask();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.SelectedTab == "2") {
      this.handleGetTask();
    }
  }
  handleGetTask = () => {
    try {
      var inputData = {};
      inputData.IDs = this.state.leadID + "";
      inputData.PageNo = this.state.pageNo;
      inputData.PageSize = this.state.pageSize;
      this.setState({
        isLoading: true,
      });
      this.leadsService
        .getLeadDetailsTab("GetLeadTaskByLeadID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var Task = response.responseData.taskDetails;
          var totalCount = response.responseData.totalCount;
          this.setState({
            isLoading: false,
          });
          if (statusCode === 200) {
            this.setState({
              totalCount: totalCount,
              Task: Task,
            });
          } else {
            this.setState({
              totalCount: 0,
              Task: [],
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log(error);
        });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      console.log(error);
    }
  };
  handlePageNoChange = async (e) => {
    await this.setState({
      pageNo: e,
    });
    this.handleGetTask();
  };
  handlePageSizeChange = async (e, pageSize) => {
    await this.setState({
      pageNo: e,
      pageSize,
    });
    this.handleGetTask();
  };
  handleTaskModalOpen = () => {
    this.setState({
      taskModal: true,
    });
  };
  handleTaskModalClose = (isSuccess) => {
    if (isSuccess) {
      this.handleGetTask();
      this.handleGetLeadList();
    }
    this.setState({
      taskModal: false,
      isEditTask: false,
      editTaskDetails: null,
    });
  };
  handleEditTask = (details) => {
    this.setState({
      taskModal: true,
      isEditTask: true,
      editTaskDetails:details
    });
    };

    handleGetLeadList = () => {
        var inputData = {};
        inputData.LeadID = this.state.leadID;
        inputData.Pageno = 1;
        inputData.Pagesize = 10;
        try {
            this.leadsService.getLeadList("GetLeadDetails", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    var leadListGridData = response.responseData.leadDetails;
                    if (statusCode === 200) {
                        this.setState({ leadDetails: leadListGridData[0]})
                        this.props.handleUpdateLeadDetails(this.state.leadID);
                    } else {
                        
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };
  render() {
    return (
      <Fragment>
        <div className="TaskMain">
         {this.state.taskViewAccess && (
          <>
          <ul>
            <Spin spinning={this.state.isLoading}>
              {/* <button className="EditPro">Edit Status</button> */}
              {this.state.Task ? (
                this.state.Task.map((item, key) => {
                  return (
                    <React.Fragment>
                      {this.state.taskEditAccess && item.taskStatus.toLowerCase() ===
                      "Planned".toLowerCase() ? (
                        <button
                          className="EditPro"
                          onClick={this.handleEditTask.bind(this, item)}
                        >
                          Edit Status
                        </button>
                      ) : null}
                      <li key={key}>
                        {item.taskStatus.toLowerCase() ===
                        "Planned".toLowerCase() ? (
                          <button className="planned">Planned</button>
                        ) : item.taskStatus.toLowerCase() ===
                          "Completed".toLowerCase() ? (
                          <button className="Completee">Complete</button>
                        ) : (
                          <button className="Pastt">Past</button>
                        )}

                        <div className="Taskk">
                          <div className="lefSide">
                            <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">
                                  {item.taskScheduleDate}
                                </h4>
                                <h4 className="dayago">
                                  {item.taskScheduleTime}
                                </h4>
                              </div>
                              <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="dayago">
                                    <span className="abou">Task: </span>
                                    {item.taskName}
                                    {" - "}
                                    {item.taskTitle}
                                  </h4>
                                  <h4 className="dayago">
                                    <span className="abou">Comment: </span>
                                    {item.taskDetails
                                      ? parse(item.taskDetails)
                                      : null}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="RiSide">
                            <div className="rightUs">
                              {item.userProfile ? (
                                <img src={item.userProfile} alt="User" />
                              ) : (
                                <img src={SUserIcon} alt="User" />
                              )}
                              <div>
                                <h4 className="abou">{item.createdByName}</h4>
                                {/* <h4 className="dayago">{item.designtion}</h4> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </React.Fragment>
                  );
                })
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Spin>
          </ul>
          </>)}
          {this.state.Task.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : null}
          {this.state.taskViewAccess && this.state.Task.length > 0 ? (
            <Pagination
              defaultCurrent={1}
              total={this.state.totalCount}
              onChange={this.handlePageNoChange.bind(this)}
              pageSizeOptions={[10, 20, 30]}
              onShowSizeChange={this.handlePageSizeChange.bind(this)}
              responsive={true}
              showSizeChanger={true}
              current={this.state.pageNo}
              pageSize={this.state.pageSize}
            />
          ) : null}
          {this.state.taskCreateAccess &&
          <div className="row">
            <div className="col-md-12" style={{ textAlign: "right" }}>
              <Button
                type="primary"
                onClick={this.handleTaskModalOpen.bind(this)}
              >
                Add Task
              </Button>
            </div>
          </div>}
          {/* Create Task Modal */}
          <Modal
            closable={false}
            visible={this.state.taskModal}
            className="AddCreateTaskModal"
            footer={null}
            keyboard={true}
            onCancel={this.handleTaskModalClose.bind(this, false)}
          >
            <AddTaskComponent
              leadStatusList={this.state.leadStatusData}
              AssignToList={this.state.leadAssignToData}
              handleCreateTaskClose={this.handleTaskModalClose}
              TaskTypeList={this.state.TaskTypeList}
              LeadID={this.state.leadID}
              FollowUp={false}
              LeadDetails={this.state.leadDetails}
              isEditTask={this.state.isEditTask}
              editTaskDetails={this.state.editTaskDetails}
            />
          </Modal>
        </div>
      </Fragment>
    );
  }
}
