import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Table, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import { NotificationManager } from "react-notifications";
import SpecialityService from "./../../../services/AdminSetting/SpecialityService";

export default class SpecialityMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAccess: true,
            viewAccess: true,
            deleteAccess: true,
            editAccess: true,
            searchBy: "",
            specialityInput: {},
            specialityError: {},
            isEdit: false,
            isSubmitLoading: false,
            loading: false,
            specialityModel: false,
            SpecialityGridData:[],
        };

        this.SpecialityService = new SpecialityService();
    }
    componentDidMount() {
        this.handleGetSpecialityGridData();

    }

    handleSpecialityModalOpen = () => {
        this.setState({ specialityModel: true, specialityInput: { status: true } });
    };

    handleSpecialityModalClose = () => {
        this.setState({
            specialityModel: false,
            specialityInput: {},
            specialityError: {},
            isEdit: false,
        });
    };
    handleOnChangeInput = async (name, e) => {
        var specialityInput = this.state.specialityInput;
        specialityInput[name] = e.target.value;
        await this.setState({
            specialityInput,
        });
    };

    handleOnChange = (name, e) => {
        var specialityInput = this.state.specialityInput;

        specialityInput[name] = e;
        this.setState({
            specialityInput,
        });
    };

    handleSpecialitySubmit = () => {
        if (this.handleSpecialityValidation()) {
            if (!this.state.isEdit) {
                this.handleCreateSpeciality();
            } else {
                this.handleUpdateSpeciality();
            }
        }
    };


    handleSpecialityValidation = () => {
        let specialityInput = this.state.specialityInput;
        let specialityError = {};
        let isValid = true;

        if (!specialityInput["specialityName"]) {
            isValid = false;
            specialityError["specialityName"] = "Please enter speciality name.";
        }
        if (!specialityInput["specialityCode"]) {
            isValid = false;
            specialityError["specialityCode"] = "Please enter speciality code.";
        }


        this.setState({
            specialityError,
        });
        return isValid;
    };


    handleSerchInputChange = async (e) => {
        await this.setState({
            searchBy: e.target.value,
        });

        this.handleGetSpecialityGridData();
    };

    handleGetSpecialityGridData = () => {
        debugger;
        var inputData = {};
        
        inputData.SearchText = this.state.searchBy;
        try {
            this.SpecialityService.getSpeciality("GetSpeciality", inputData)
                .then((response) => {
                    debugger;
                    var message = response.message;
                    var SpecialityGridData = response.responseData;
                    if (message == "Success") {
                        this.setState({ SpecialityGridData });
                    } else {
                        this.setState({ SpecialityGridData: [] });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };


    handleCreateSpeciality = () => {
        debugger;
        var inputData = {};
        inputData.SpecialityName = this.state.specialityInput.specialityName;
        inputData.SpecialityCode = this.state.specialityInput.specialityCode;
        inputData.Status = this.state.specialityInput.status;
        
        this.setState({ isSubmitLoading: true });
        try {
            this.SpecialityService.insertSpeciality("InsertSpeciality", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    this.setState({ isSubmitLoading: false });
                    if (statusCode === 200) {
                        NotificationManager.success("Speciality Created Successfully.");
                        this.handleGetSpecialityGridData();
                        this.setState({
                            specialityModel: false,
                            specialityInput: {},
                        });
                    } else {
                        NotificationManager.error("Speciality Not Create.");
                    }
                })
                .catch((error) => {
                    this.setState({ isSubmitLoading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };


    handleGetEditSpecialityData = async (e) => {
        var specialityInput = this.state.specialityInput;
        specialityInput["specialityID"] = e.specialityID;
        specialityInput["specialityName"] = e.specialityName;

        specialityInput["specialityCode"] = e.specialityCode;
        specialityInput["status"] = e.status;
       
        await this.setState({
            specialityModel: true,
            specialityInput,
            isEdit: true,
        });
    };

    handleUpdateSpeciality = () => {
        var inputData = {};
        inputData.SpecialityID = this.state.specialityInput.specialityID;
        inputData.SpecialityName = this.state.specialityInput.specialityName;
        inputData.SpecialityCode = this.state.specialityInput.specialityCode;
        inputData.Status = this.state.specialityInput.status;
        
        this.setState({ isSubmitLoading: true });
        try {
            this.SpecialityService.updateSpeciality("UpdateSpeciality", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    this.setState({ isSubmitLoading: false });
                    if (statusCode === 200) {
                        NotificationManager.success("Speciality Updated Successfully.");
                        this.handleGetSpecialityGridData();
                        this.setState({ specialityModel: false, isEdit: false,});
                    } else {
                        NotificationManager.error("Speciality Not Updated.");
                    }
                })
                .catch((error) => {
                    this.setState({ isSubmitLoading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    handleDeleteSpeciality = (ID) => {
        debugger;
        var inputData = {};
        inputData.IDs = ID + "";

        try {
            this.SpecialityService.deleteSpeciality("DeleteSpeciality", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    var data = response.responseData;
                    if (statusCode === 200) {
                        NotificationManager.success("Record Deleted Successfully.");
                        this.handleGetSpecialityGridData();
                    } else {
                        NotificationManager.error("Record Not Deleted.");
                    }
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };



    render() {
        const { Option } = Select;
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                Speciality Master
                                {this.state.createAccess &&
                                    <img
                                        src={AddIcon}
                                        alt="add"
                                    onClick={this.handleSpecialityModalOpen.bind(this)}
                                    />}
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                name="searchByTeam"
                                value={this.state.searchByTeam}
                                onChange={this.handleSerchInputChange.bind(this)}
                                placeholder="Search by..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    {this.state.viewAccess &&
                        <div className="AlertTable">
                            <Table
                                columns={[
                                    {
                                        title: "Speciality Name",
                                        dataIndex: "specialityName",
                                        key: "specialityName",
                                    },
                                {
                                    title: "Speciality Code",
                                    dataIndex: "specialityCode",
                                    key: "specialityCode",
                                },
                                    {
                                        title: "Created By",
                                        dataIndex: "createdByName",
                                        key: "createdByName",
                                    },
                                    {
                                        title: "Modified By",
                                        dataIndex: "modifiedByName",
                                        key: "modifiedByName",
                                    },
                                {
                                    title: "Status",
                                    dataIndex: "status",
                                    render: (item, row) => {
                                        return (
                                            <div className="Status" >
                                                <Select value={row.status} showArrow={false} disabled={true}>
                                                    <Option value={true}>Active</Option>
                                                    <Option value={false}>Inactive</Option>
                                                </Select>
                                            </div>
                                        );
                                    },
                                },
                                    {
                                        title: "Actions",
                                        key: "actions",
                                        dataIndex: "actions",
                                        render: (row, item) => {
                                            return (
                                                <div className="Actionic">
                                                    {this.state.editAccess &&
                                                        <img
                                                            src={AddNote}
                                                            alt="Edit"
                                                            onClick={this.handleGetEditSpecialityData.bind(this, item)}
                                                        />}
                                                    {this.state.deleteAccess &&
                                                        <img
                                                            src={CloseCircle}
                                                            alt="delete"
                                                          onClick={this.handleDeleteSpeciality.bind( this,item.specialityID)}
                                                        />}
                                                </div>
                                            );
                                        },
                                    },
                            ]}
                            dataSource={this.state.SpecialityGridData}
                            pagination={{
                                position: ["bottomCenter"],
                                showSizeChanger: true,
                            }}
                            />
                        </div>
                    }


                    <Modal
                        closable={false}
                        title={!this.state.isEdit ? "Create Speciality" : "Edit Speciality"}
                        visible={this.state.specialityModel}
                        footer={[
                            <Button key="back" onClick={this.handleSpecialityModalClose.bind(this)}>
                                Cancel
              </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={this.state.isSubmitLoading}
                                onClick={this.handleSpecialitySubmit.bind(this)}
                            >
                                {this.state.isEdit ? "Update" : "Create"}
                            </Button>,
                        ]}
                    >
                        <div className="createPrior">
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Speciality Name</label>
                                    <Input
                                        placeholder="Enter Speciality Name"
                                        name="specialityName"
                                        value={this.state.specialityInput.specialityName}
                                        onChange={this.handleOnChangeInput.bind(this, "specialityName")}
                                    />
                                    {this.state.specialityError ? (
                                        <div className="text-danger">
                                            {this.state.specialityError["specialityName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Speciality Code</label>
                                    <Input
                                        placeholder="Enter Speciality Code"
                                        name="specialityCode"
                                        value={this.state.specialityInput.specialityCode}
                                        onChange={this.handleOnChangeInput.bind(this, "specialityCode")}
                                    />
                                    {this.state.specialityError ? (
                                        <div className="text-danger">
                                            {this.state.specialityError["specialityCode"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Status</label>
                                    <Select
                                        value={this.state.specialityInput.status}
                                        onChange={this.handleOnChange.bind(this, "status")}
                                        placeholder={"Select Status"}
                                    >
                                        <Option value={true} >Active</Option>
                                        <Option value={false}>Inactive</Option>
                                    </Select>
                                   
                                </div>
                            </div>





                        </div>

                    </Modal>


















                </div>
            </div>
        );
    }
}
