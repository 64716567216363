/*import { BehaviorSubject } from 'rxjs';*/
import axios from 'axios';
import FBConnectorService from "../../services/AdminSetting/FBConnectorService";

const fbConn = new FBConnectorService();
//const accountSubject = new BehaviorSubject(null);
export const accountService = {
    login,
    //apiAuthenticate,
    //logout,
    //getAll,
    //getById,
    //update,
    //delete: _delete,
    //account: accountSubject.asObservable(),
    //get accountValue() { return accountSubject.value; }
};

async function login(props) {
    
    function subscribeApp(page_id, page_name, page_access_token) {
        console.log('Subscribing page to app! ' + page_id);
        window.FB.api(
            '/' + page_id + '/subscribed_apps',
            'post',
            { access_token: page_access_token, subscribed_fields: ['leadgen'] },
            function (response) {
                console.log('Successfully subscribed page', response);
                if (response.success) {
                    handleInsertFBSubscription(page_id, page_name);
                } else {
                    alert("Page not subscribed successfully");
                }
            }
        );
    }

    function handleInsertFBSubscription(page_id, page_name) {
        
        var inputData = {};
        inputData.PageID = page_id;
        inputData.PageName = page_name;
        inputData.IsSubscribed = true;
        try {
            fbConn.insertFBSubscription("InsertFBSubscription", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    var data = response.responseData;
                    if (statusCode === 200) {
                        var fbPageId = document.getElementById("fb_" + page_id);
                        fbPageId.onclick = handleUpdateFBSubscription.bind(this, page_id);
                        fbPageId.innerHTML = "UnSubscribe";
                        fbPageId.className = "btn btn-danger";
                        alert("Page subscribed successfully");
                    } else {
                        alert("Page not subscribed successfully");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            
        } catch (error) {
            console.log(error);
        }
    };

    function handleUpdateFBSubscription(page_id) {
        
        var inputData = {};
        inputData.IDS = page_id;
        try {
            fbConn.updateFBSubscription("UpdateFBSubscription", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    var data = response.responseData;
                    if (statusCode === 200) {
                        alert("Page unsubscribed successfully");
                    } else {
                        alert("Page not unsubscribed successfully");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        } catch (error) {
            console.log(error);
        }
    };

    setTimeout(function () {
            window.FB.login(function (loginresponse) {
                console.log('Successfully logged in', loginresponse);
                
                    window.FB.api('/me/accounts', function (response) {
                        
                        if (loginresponse.authResponse) {
                        //console.log('Successfully retrieved pages', response);
                        var pages = response.data;
                        var totalPages = pages.length;
                        var totalExistPages = 0;
                        if (pages.length > 0) {
                            console.log('Successfully retrieved pages', response);
                            var fbTab = document.getElementById('fbdatatble');
                            var tblBody = document.createElement("tbody");
                            for (var i = 0, len = pages.length; i < len; i++) {
                                var page = pages[i];
                                if (!props.includes(page.id)) {
                                    props.push(page.id)
                                    var arrHead = new Array();	// array for header.
                                    arrHead = ['Page Id', 'Page Name', 'Action'];
                                   
                                    var rowCnt = fbTab.rows.length;   // table row count.
                                    var tr = fbTab.insertRow(rowCnt); // the table row.
                                    
                                    //tr = fbTab.insertRow(rowCnt);

                                    for (var c = 0; c < arrHead.length; c++) {
                                        var td = document.createElement('td'); // table definition.
                                        td = tr.insertCell(c);

                                        if (c == arrHead.length - 1) {      // the first column.      
                                            var a = document.createElement('button');
                                            a.onclick = subscribeApp.bind(this, page.id, page.name, page.access_token);
                                            a.innerHTML = "Subscribe";
                                            a.className = "btn btn-primary";
                                            a.setAttribute("id", "fb_" + page.id)
                                            td.appendChild(a);
                                        }
                                        else {
                                            var newText;
                                            if (c == 0) {
                                                newText = document.createTextNode(page.id);
                                            }
                                            else if (c == 1) {
                                                newText = document.createTextNode(page.name);
                                            }

                                            td.appendChild(newText);
                                        }
                                    }
                                    tblBody.appendChild(tr);
                                    fbTab.appendChild(tblBody);
                                }
                                else {
                                    totalExistPages++;
                                }
                            }
                        }
                        if (totalPages == totalExistPages) {
                            alert("Already Exists");
                        }
                        } else {
                            return;
                        }
                    });
                
            }, { scope: ['pages_show_list', 'leads_retrieval', 'pages_manage_ads', 'pages_manage_metadata', 'pages_read_engagement'] });
            
    }, 1500);

    
}


