import React, { Component, Fragment } from "react";
import {
  Table,
  Menu,
  Dropdown,
  Select,
  Popover,
  Radio,
  Tabs,
  DatePicker,
  Modal,
  Button,
  Pagination,
  Spin,
  Empty,
} from "antd";
import "./../../../assets/css/Leads/leadlist.css";
import moment from "moment";
import CKEditor from "ckeditor4-react";
import {
  AddIcon,
  SearchIcon,
  FilterIcon,
  CircleBackIcon,
  DropdownIcon,
  OptionIcon,
  AddNoteIcon,
  SUserIcon,
  DownloadIcon,
  LeadIcon,
} from "./../../../helpers/ImagesImport";
import LeadsService from "../../../services/Leads/LeadsService";
import EnquiryService from "../../../services/AdminSetting/EnquiryService";
import CommonService from "../../../services/Common/CommonService";
import UserService from "../../../services/AdminSetting/UserService";
import { NotificationManager } from "react-notifications";
import LeadFilterColumnList from "./LeadFilterColumnList";
import AddTaskComponent from "./AddTaskComponent";
import AddNoteComponent from "./AddNoteComponent";
import Master from "../../../helpers/Master";
import QueryLead from "./LeadActionModal";
import DisqualifyAction from "./DisqualifyAction";
import EmailTab from "./EmailTab";
import SMSTab from "./SMSTab";
import PriorityService from "../../../services/AdminSetting/PriorityService";
import ImportExport from "../../../helpers/ImportExport";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import ReportService from "../../../services/Report/ReportService";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

function disabledDate(current) {
  return current && current > moment().endOf("day");
}

function disabledDatef(current) {
  return moment(current).add(1, "d") < moment().endOf("day");
}

class LeadList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAssignModel: false,
      assignInput: {},
      isCreateTaskandSave: false,
      taskLeadID: 0,
      taskdate: "",
      isFilter: false,
      addNote: false,
      createLeadModal: false,
      BulkUpload: false,
      createTask: false,
      listview: true,
      cardView: false,
      isPophoverVisibale: false,
      fileName: "",
      fileN: [],
      mobileView: false,
      stateData: [],
      leadInput: {},
      cityData: [],
      enquiryTypeData: [],
      enquirySubTypeData: [],
      taskStatusData: [],
      leadDetailsInput: {},
      leadStatusData: [],
      leadSourceData: [],
      leadLocationData: [],
      leadDoctorsData: [],
      leadAssignToData: [],
      InsuranceProviderData: [],
      SpecialityData: [],
      RequestedServiceData: [],
      UserBasedOnUnitData: [],
      leadId: 0,
      lmd: "",
      edd: "",
      editor: "",
      leadAddDetailInput: {},
      errors: {},
      male: true,
      female: false,
      other: false,
      leadColumnData: [],
      leadColumns: [
        {
          key: "FirstCol",
          render: (row, item) => {
            return (
              <div>
                <Dropdown
                  overlay={
                    <Menu>
                      <div className="Opdropdown">
                        <div className="dropdownscroll">
                          <Radio.Group
                            onChange={this.handleActionChange.bind(this, item)}
                            value={this.state.selectedAction}
                          >
                            {this.MasterData.LeadAction.map((item) => {
                              return item.isActive ? (
                                <div className="rad1">
                                  <Radio value={item.ID}>{item.Name}</Radio>
                                </div>
                              ) : null;
                            })}
                          </Radio.Group>
                        </div>
                      </div>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <img src={OptionIcon} alt="Option" className="Option" />
                </Dropdown>
              </div>
            );
          },
        },
        {
          title: "Lead Code",
          dataIndex: "leadUID",
          key: "LeadUID",
          visible: true,
          render: (row, item) => {
            return (
              <div className="Cate2">
                <Popover
                  content={
                    <div className="UserInfo">
                      <div className="usname">
                        <div className="usname1">
                          <label>{item.customerName}</label>
                          <label>{item.phoneNo}</label>
                        </div>
                        <div className="usname1">
                          <label>Last update</label>
                          <label style={{ fontStyle: "italic" }}>
                            {item.modifiedDate}
                          </label>
                        </div>
                      </div>
                      <div className="usnam">
                        <div className="usnam1">
                          <label>Mobile</label>:<label>{item.phoneNo}</label>
                        </div>
                        <div className="usnam1">
                          <label>Email</label>:<label>{item.email}</label>
                        </div>
                        <div className="usnam1">
                          <label>Status</label>:
                          <label>{item.isActive ? "Active" : "Inactive"}</label>
                        </div>
                        <div className="usnam1">
                          <label>Assign To</label>:
                          <label>{item.assignToName}</label>
                        </div>
                        <div className="usnam1">
                          <label>Unit</label>:<label>{item.locationName}</label>
                        </div>
                      </div>
                      <div className="usnamebtn">
                        <label
                          onClick={this.handleCreateTaskOpen.bind(this, item)}
                        >
                          <img src={AddNoteIcon} alt="CreateTask" />
                          Create Task
                        </label>
                        <label
                          onClick={this.handleAddNotesOpen.bind(this, item)}
                        >
                          <img src={AddNoteIcon} alt="AddNote" />
                          Write Note
                        </label>
                      </div>
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="hover"
                  overlayClassName={
                    this.state.isPophoverVisibale ? "isvisibalePophover" : ""
                  }
                  // overlayClassName="isvisibalePophover"
                >
                  {/*{item.userProfile ? (*/}
                  {/*    <img src={item.userProfile} alt="NameIcon" />*/}
                  {/*) : (*/}
                  {/*        <img src={SUserIcon} alt="NameIcon" />*/}
                  {/*    )}*/}
                  <label
                    onClick={this.handleRedirecttoDetails.bind(this, item)}
                  >
                    {item.leadUID}
                  </label>
                </Popover>
                {/* <Link
                  to={{
                    pathname: "LeadDetails",
                    state: {
                      leadDeatils: item,
                      leadStatusData: this.state.leadStatusData,
                      leadTypeData: this.state.leadTypeData,
                      leadAssignToData: this.state.leadAssignToData,
                      enquiryTypeData: this.state.enquiryTypeData,
                      leadSourceData: this.state.leadSourceData,
                      TaskTypeList: this.state.taskTypeList,
                      InsuranceProviderData: this.state.InsuranceProviderData,
                      isFilterSearch: this.state.isFilterSearch,
                      isFilter: this.state.isFilter,
                      leadFilterInput: this.state.leadFilterInput,
                      RequestedServiceData: this.state.RequestedServiceData,
                    },
                  }}
                > */}

                {/* </Link> */}
              </div>
            );
          },
        },
        {
          title: "Name",
          dataIndex: "customerName",
          key: "CustomerName",
          visible: true,
          //    render: (row, item) => {
          //        return (
          //            <div className="Cate2">
          //                <Popover
          //                    content={
          //                        <div className="UserInfo">
          //                            <div className="usname">
          //                                <div className="usname1">
          //                                    <label>{item.customerName}</label>
          //                                    <label>{item.phoneNo}</label>
          //                                </div>
          //                                <div className="usname1">
          //                                    <label>Last update</label>
          //                                    <label style={{ fontStyle: "italic" }}>
          //                                        {item.modifiedDate}
          //                                    </label>
          //                                </div>
          //                            </div>
          //                            <div className="usnam">
          //                                <div className="usnam1">
          //                                    <label>Mobile</label>:<label>{item.phoneNo}</label>
          //                                </div>
          //                                <div className="usnam1">
          //                                    <label>Email</label>:<label>{item.email}</label>
          //                                </div>
          //                                <div className="usnam1">
          //                                    <label>Status</label>:
          //          <label>{item.isActive ? "Active" : "Inactive"}</label>
          //                                </div>
          //                                <div className="usnam1">
          //                                    <label>Assign To</label>:
          //          <label>{item.assignToName}</label>
          //                                </div>
          //                                <div className="usnam1">
          //                                    <label>Unit</label>:<label>{item.locationName}</label>
          //                                </div>
          //                            </div>
          //                            <div className="usnamebtn">
          //                                <label
          //                                    onClick={this.handleCreateTaskOpen.bind(this, item)}
          //                                >
          //                                    <img src={AddNoteIcon} alt="CreateTask" />
          //          Create Task
          //        </label>
          //                                <label
          //                                    onClick={this.handleAddNotesOpen.bind(this, item)}
          //                                >
          //                                    <img src={AddNoteIcon} alt="AddNote" />
          //          Write Note
          //        </label>
          //                            </div>
          //                        </div>
          //                    }
          //                    placement="bottomLeft"
          //                    trigger="hover"
          //                    overlayClassName={
          //                        this.state.isPophoverVisibale ? "isvisibalePophover" : ""
          //                    }
          //                // overlayClassName="isvisibalePophover"
          //                >
          //                    {item.userProfile ? (
          //                        <img src={item.userProfile} alt="NameIcon" />
          //                    ) : (
          //                            <img src={SUserIcon} alt="NameIcon" />
          //                        )}
          //                </Popover>
          //                {/* <Link
          //  to={{
          //    pathname: "LeadDetails",
          //    state: {
          //      leadDeatils: item,
          //      leadStatusData: this.state.leadStatusData,
          //      leadTypeData: this.state.leadTypeData,
          //      leadAssignToData: this.state.leadAssignToData,
          //      enquiryTypeData: this.state.enquiryTypeData,
          //      leadSourceData: this.state.leadSourceData,
          //      TaskTypeList: this.state.taskTypeList,
          //      InsuranceProviderData: this.state.InsuranceProviderData,
          //      isFilterSearch: this.state.isFilterSearch,
          //      isFilter: this.state.isFilter,
          //      leadFilterInput: this.state.leadFilterInput,
          //      RequestedServiceData: this.state.RequestedServiceData,
          //    },
          //  }}
          //> */}
          //                <label onClick={this.handleRedirecttoDetails.bind(this, item)}>
          //                    {item.customerName}
          //                </label>
          //                {/* </Link> */}
          //            </div>
          //        );
          //    },
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "Email",
          className: "MobViewNone hide",
        },
        {
          title: "Contact",
          dataIndex: "phoneNo",
          key: "PhoneNo",
          className: "MobViewNone hide",
        },
        {
          title: "Enquiry Type",
          key: "EnquiryType",
          dataIndex: "enquiryTypeName",
          className: "MobViewNone",

          render: (row, item) => {
            return (
              <div className="Cate">
                <label>{item.enquiryTypeName}</label>
              </div>
            );
          },
        },
        {
          title: "Sub Category",
          key: "enquirySubTypeName",
          dataIndex: "enquirySubTypeName",
          className: "MobViewNone",
          visible: true,
          render: (row, item) => {
            return (
              <div className="Cate">
                <label>{item.enquirySubTypeName}</label>
              </div>
            );
          },
        },
        {
          title: "Source",
          key: "LeadSource",
          dataIndex: "sourceName",
          className: "MobViewNone",
          visible: true,
          render: (row, item) => {
            return (
              <div className="Cate">
                <label>{item.sourceName}</label>
              </div>
            );
          },
        },
        {
          title: "Status",
          key: "LeadStatus",
          dataIndex: "statusName",
          className: "MobViewNone",
          visible: true,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.statusName}</label>
                <Dropdown
                  overlay={
                    <Menu>
                      <div className="dropdown">
                        <input
                          type="search"
                          className="sear"
                          placeholder="Search Comment"
                        />
                        <img src={SearchIcon} alt="Search" />
                        <div className="dropdownscroll">
                          <Radio.Group
                            value={this.state.selectedLeadStatus}
                            onChange={this.handleTableStatusChange.bind(
                              this,
                              item
                            )}
                          >
                            {this.state.leadStatusData
                              ? this.state.leadStatusData.map((item) => {
                                  return (
                                    <div
                                      className="rad"
                                      key={item.leadStatusID}
                                    >
                                      <Radio value={item.leadStatusID}>
                                        {item.statusName}
                                      </Radio>
                                    </div>
                                  );
                                })
                              : null}
                          </Radio.Group>
                        </div>
                      </div>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <img
                    src={DropdownIcon}
                    alt="Dropdown"
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    // }}
                  />
                </Dropdown>
              </div>
            );
          },
        },
        {
          title: "First Name",
          key: "FirstName",
          dataIndex: "firstName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.firstName}</label>
              </div>
            );
          },
        },
        {
          title: "Middle Name",
          key: "MiddleName",
          dataIndex: "middleName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.middleName}</label>
              </div>
            );
          },
        },
        {
          title: "Last Name",
          key: "LastName",
          dataIndex: "lastName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.lastName}</label>
              </div>
            );
          },
        },
        {
          title: "Gender",
          key: "Gender",
          dataIndex: "gender",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.gender}</label>
              </div>
            );
          },
        },
        {
          title: "Date of Birth",
          key: "DOB",
          dataIndex: "dob",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.dob}</label>
              </div>
            );
          },
        },
        {
          title: "Age",
          key: "Age",
          dataIndex: "age",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.age > 0 ? item.age : null}</label>
              </div>
            );
          },
        },
        {
          title: "Alternate Phone No",
          key: "AlternatePhoneNo",
          dataIndex: "alternatePhoneNo",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.alternatePhoneNo}</label>
              </div>
            );
          },
        },
        {
          title: "Alternate Email",
          key: "AlternateEmail",
          dataIndex: "alternateEmail",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.alternateEmail}</label>
              </div>
            );
          },
        },
        {
          title: "Marital Status",
          key: "MaritalStatus",
          dataIndex: "maritalStatus",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.maritalStatus}</label>
              </div>
            );
          },
        },
        {
          title: "Anniversary Date",
          key: "AnniversaryDate",
          dataIndex: "anniversaryDate",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.anniversaryDate}</label>
              </div>
            );
          },
        },
        {
          title: "Pin Code",
          key: "PinCode",
          dataIndex: "pinCode",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.pinCode}</label>
              </div>
            );
          },
        },
        {
          title: "Country",
          key: "Country",
          dataIndex: "countryName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.countryName}</label>
              </div>
            );
          },
        },
        {
          title: "State",
          key: "State",
          dataIndex: "stateName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.stateName}</label>
              </div>
            );
          },
        },
        {
          title: "City",
          key: "City",
          dataIndex: "cityName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.cityName}</label>
              </div>
            );
          },
        },
        {
          title: "City",
          key: "City",
          dataIndex: "cityName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.cityName}</label>
              </div>
            );
          },
        },
        {
          title: "Address",
          key: "Address",
          dataIndex: "address",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.address}</label>
              </div>
            );
          },
        },
        {
          title: "Occupation",
          key: "Occupation",
          dataIndex: "occupation",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.occupation}</label>
              </div>
            );
          },
        },
        {
          title: "CompanyName",
          key: "CompanyName",
          dataIndex: "companyName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.companyName}</label>
              </div>
            );
          },
        },
        {
          title: "Employment Type",
          key: "EmploymentType",
          dataIndex: "employmentType",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.employmentType}</label>
              </div>
            );
          },
        },
        {
          title: "Income",
          key: "Income",
          dataIndex: "income",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.income}</label>
              </div>
            );
          },
        },
        {
          title: "Additional Income",
          key: "AdditionalIncome",
          dataIndex: "additionalIncome",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.additionalIncome}</label>
              </div>
            );
          },
        },
        {
          title: "MHID",
          key: "MHID",
          dataIndex: "mhid",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.mhid}</label>
              </div>
            );
          },
        },
        {
          title: "MR Number",
          key: "MRNumber",
          dataIndex: "mrNumber",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.mrNumber}</label>
              </div>
            );
          },
        },
        {
          title: "Patient ID",
          key: "PatientID",
          dataIndex: "patientID",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.patientID > 0 ? item.patientID : null}</label>
              </div>
            );
          },
        },
        {
          title: "EnquiryType",
          key: "EnquiryType",
          dataIndex: "enquiryTypeName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.enquiryTypeName}</label>
              </div>
            );
          },
        },
        {
          title: "Enquiry Sub Type",
          key: "EnquirySubType",
          dataIndex: "enquirySubTypeName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.enquirySubTypeName}</label>
              </div>
            );
          },
        },
        {
          title: "Lead Source",
          key: "LeadSource",
          dataIndex: "sourceName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.sourceName}</label>
              </div>
            );
          },
        },
        {
          title: "Lead Status",
          key: "LeadStatus",
          dataIndex: "statusName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.statusName}</label>
              </div>
            );
          },
        },
        {
          title: "Lead Sub Status",
          key: "LeadSubStatus",
          dataIndex: "subStatusName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.subStatusName}</label>
              </div>
            );
          },
        },
        {
          title: "Lead Sub Status",
          key: "LeadSubStatus",
          dataIndex: "subStatusName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.subStatusName}</label>
              </div>
            );
          },
        },
        {
          title: "Unit",
          key: "Unit",
          dataIndex: "unit",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.locationName}</label>
              </div>
            );
          },
        },
        {
          title: "Lead Locality",
          key: "LeadLocality",
          dataIndex: "unit",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.leadLocality}</label>
              </div>
            );
          },
        },
        {
          title: "Assign To",
          key: "AssignTo",
          dataIndex: "assignToName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.assignToName}</label>
              </div>
            );
          },
        },
        {
          title: "Lead Type",
          key: "LeadType",
          dataIndex: "leadTypeName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.leadTypeName}</label>
              </div>
            );
          },
        },
        {
          title: "Consulting Hospital",
          key: "ConsultingHospital",
          dataIndex: "consultingHospital",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.consultingHospital}</label>
              </div>
            );
          },
        },
        {
          title: "Consulting Doctor ",
          key: "CosultingDoctor",
          dataIndex: "cosultingDoctor",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.cosultingDoctorName}</label>
              </div>
            );
          },
        },
        {
          title: "Lead Priority",
          key: "LeadPriority",
          dataIndex: "leadPriority",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.priorityName}</label>
              </div>
            );
          },
        },
        {
          title: "PreferredDateTimeForContact",
          key: "PreferredDateTimeForContact",
          dataIndex: "preferredDateTimeForContact",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.preferredDateTimeForContact}</label>
              </div>
            );
          },
        },
        {
          title: "LMP",
          key: "LPM",
          dataIndex: "lpm",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.lpm}</label>
              </div>
            );
          },
        },
        {
          title: "EDD",
          key: "EDD",
          dataIndex: "edd",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.edd}</label>
              </div>
            );
          },
        },
        {
          title: "Title",
          key: "Title",
          dataIndex: "title",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.title}</label>
              </div>
            );
          },
        },
        {
          title: "RequestedService",
          key: "RequestedService",
          dataIndex: "requestedService",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.requestedService}</label>
              </div>
            );
          },
        },
        {
          title: "RefID",
          key: "RefID",
          dataIndex: "refID",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.refID}</label>
              </div>
            );
          },
        },
        {
          title: "Speciality",
          key: "SpecialityID",
          dataIndex: "specialityID",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.specialityName}</label>
              </div>
            );
          },
        },
        {
          title: "CaseCategory",
          key: "CaseCategory",
          dataIndex: "caseCategory",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.caseCategory}</label>
              </div>
            );
          },
        },
        {
          title: "DiscountedAmount",
          key: "DiscountedAmount",
          dataIndex: "discountedAmount",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>
                  {Number(item.discountedAmount) > 0
                    ? item.discountedAmount
                    : null}
                </label>
              </div>
            );
          },
        },
        {
          title: "DisqualifyReason",
          key: "DisqualifyReason",
          dataIndex: "disqualifyReason",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.disqualifyReason}</label>
              </div>
            );
          },
        },
        {
          title: "EnquiryKeywords",
          key: "EnquiryKeywords",
          dataIndex: "enquiryKeywords",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.enquiryKeywords}</label>
              </div>
            );
          },
        },
        {
          title: "Remarks",
          key: "Remarks",
          dataIndex: "remarks",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.remarks ? parse(item.remarks) : null}</label>
              </div>
            );
          },
        },
        {
          title: "CareTakerName",
          key: "CareTakerName",
          dataIndex: "careTakerName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.careTakerName}</label>
              </div>
            );
          },
        },
        {
          title: "CareTakerMobileNo",
          key: "CareTakerMobileNo",
          dataIndex: "careTakerMobileNo",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.careTakerMobileNo}</label>
              </div>
            );
          },
        },
        {
          title: "CareTakerAge",
          key: "CareTakerAge",
          dataIndex: "careTakerAge",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>
                  {item.careTakerAge > 0 ? item.careTakerAge : null}
                </label>
              </div>
            );
          },
        },
        {
          title: "CareTakerEmail",
          key: "CareTakerEmail",
          dataIndex: "careTakerEmail",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.careTakerEmail}</label>
              </div>
            );
          },
        },
        {
          title: "CareTakerRelationship",
          key: "CareTakerRelationship",
          dataIndex: "careTakerRelationship",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.careTakerRelationship}</label>
              </div>
            );
          },
        },
        {
          title: "ExpectedRevenue",
          key: "ExpectedRevenue",
          dataIndex: "expectedRevenue",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.expectedRevenue}</label>
              </div>
            );
          },
        },
        {
          title: "HNI",
          key: "HNI",
          dataIndex: "hni",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.hni}</label>
              </div>
            );
          },
        },
        {
          title: "LastMenstrualPeriod",
          key: "LastMenstrualPeriod",
          dataIndex: "lastMenstrualPeriod",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.lastMenstrualPeriod}</label>
              </div>
            );
          },
        },
        {
          title: "TotalRevenue",
          key: "TotalRevenue",
          dataIndex: "totalRevenue",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>
                  {Number(item.totalRevenue) > 0 ? item.totalRevenue : null}
                </label>
              </div>
            );
          },
        },
        {
          title: "GestationalAge",
          key: "GestationalAge",
          dataIndex: "gestationalAge",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>
                  {Number(item.gestationalAge) > 0 ? item.gestationalAge : null}
                </label>
              </div>
            );
          },
        },
        {
          title: "Insurance Provider",
          key: "InsuranceProvider1",
          dataIndex: "insuranceProvider1",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.insuranceProvider1}</label>
              </div>
            );
          },
        },
        {
          title: "Insurance Policy No",
          key: "InsurancePolicyNo",
          dataIndex: "insurancePolicyNo",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.insurancePolicyNo}</label>
              </div>
            );
          },
        },
        {
          title: "PreAuthorizationCode",
          key: "PreAuthorizationCode",
          dataIndex: "preAuthorizationCode",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.preAuthorizationCode}</label>
              </div>
            );
          },
        },
        {
          title: "EnquiryBy",
          key: "EnquiryBy",
          dataIndex: "enquiryBy",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.enquiryBy}</label>
              </div>
            );
          },
        },
        {
          title: "TPA",
          key: "TPA",
          dataIndex: "tpa",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.tpa}</label>
              </div>
            );
          },
        },
        {
          title: "TenantID",
          key: "TenantID",
          dataIndex: "tenantID",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.tenantID}</label>
              </div>
            );
          },
        },
        {
          title: "IsActive",
          key: "IsActive",
          dataIndex: "isActive",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.isActive}</label>
              </div>
            );
          },
        },
        {
          title: "IsDeleted",
          key: "IsDeleted",
          dataIndex: "isDeleted",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.isDeleted}</label>
              </div>
            );
          },
        },
        {
          title: "CreatedBy",
          key: "CreatedBy",
          dataIndex: "createdByName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.createdByName}</label>
              </div>
            );
          },
        },
        {
          title: "CreatedDate",
          key: "CreatedDate",
          dataIndex: "createdDate",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.createdDate}</label>
              </div>
            );
          },
        },
        {
          title: "ModifiedBy",
          key: "ModifiedBy",
          dataIndex: "modifiedBy",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.modifiedByName}</label>
              </div>
            );
          },
        },
        {
          title: "ModifiedDate",
          key: "ModifiedDate",
          dataIndex: "modifiedDate",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.modifiedDate}</label>
              </div>
            );
          },
        },
        {
          title: "PreCondition",
          key: "PreCondition",
          dataIndex: "preCondition",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.PreCondition || ""}</label>
              </div>
            );
          },
        },
        {
          title: "Campaign Name",
          key: "CampaignName",
          dataIndex: "campaignName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.campaignName || ""}</label>
              </div>
            );
          },
        },
        {
          title: "Page Form Name",
          key: "PageFormName",
          dataIndex: "pageFormName",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.pageFormName || ""}</label>
              </div>
            );
          },
        },
        {
          title: "Task Title",
          key: "TaskTitle",
          dataIndex: "taskTitle",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.taskTitle || ""}</label>
              </div>
            );
          },
        },
        {
          title: "Task Status",
          key: "TaskStatus",
          dataIndex: "taskStatus",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.taskStatus || ""}</label>
              </div>
            );
          },
        },
        {
          title: "Task Created Date",
          key: "TaskCreatedDate",
          dataIndex: "taskCreatedDate",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.taskCreatedDate || ""}</label>
              </div>
            );
          },
        },
        {
          title: "Source URL",
          key: "SourceURL",
          dataIndex: "SourceURL",
          className: "MobViewNone",
          //visible: false,
          render: (row, item) => {
            return (
              <div className="Cate3">
                <label>{item.sourceURL || ""}</label>
              </div>
            );
          },
        },
      ],
      Columns: ["FirstCol", "LeadUID", "CustomerName", "Email", "PhoneNo"],
      isLoading: false,
      tab: "1",
      selectedAction: 0,
      QueryLead: false,
      FollowUp: false,
      ViewTimelines: false,
      emailModal: false,
      smsModal: false,
      selectedLeadDetails: {},
      isStatusClick: false,
      selectedRowKeys: [],
      pageNo: 1,
      pageSize: 10,
      totalcount: 0,
      isColumnUpdate: true,
      eddDate: null,
      Disqualify: false,
      priorityData: [],
      leadFilterInput: {},
      isFilterSearch: false,
      userData: [],
      createAccess: true,
      viewAccess: true,
      deleteAccess: true,
      editAccess: true,
      downloadAccess: true,
      isSelectAll: false,
      fileCreateAccess: true,
      isSubmitLoading: false,
      filterCount: 0,
      selectedLeadId: "",
      Task: [],
      OpenTask: false,
      isTaskOpen: false,
      isEditTask: false,
      editTaskDetails: null,
      pageNo: 1,
      pageSize: 10,
      isTaskLoading: false,
    };
    this.LeadsService = new LeadsService();
    this.EnquiryService = new EnquiryService();
    this.CommonService = new CommonService();
    this.MasterData = new Master();
    this.priorityService = new PriorityService();
    this.UserService = new UserService();
    this.importExport = new ImportExport();

    this.handleCallBackToList = this.handleCallBackToList.bind(this);
    this.handleCallBackQueryModalClose = this.handleCallBackQueryModalClose.bind(
      this
    );
    this.handleCreateTaskClose = this.handleCreateTaskClose.bind(this);
    this.handleAddNotesClose = this.handleAddNotesClose.bind(this);
    this.handleSMSActionModalClose = this.handleSMSActionModalClose.bind(this);
    this.handleEmailActionModalClose = this.handleEmailActionModalClose.bind(
      this
    );
    this.handleCloseDisqualifyModalClose = this.handleCloseDisqualifyModalClose.bind(
      this
    );
    this.ReportService = new ReportService();
  }
  handleAddNotesOpen(item) {
    this.setState({
      addNote: true,
      isPophoverVisibale: true,
      selectedLeadDetails: item,
    });
  }
  handleAddNotesClose() {
    this.setState({
      addNote: false,
      isPophoverVisibale: false,
      selectedLeadDetails: {},
    });
  }
  handleCreateLeadModalOpen() {
    this.setState({
      createLeadModal: true,
      leadInput: {},
      leadDetailsInput: {},
      leadAddDetailInput: {},
      tab: "1",
    });
  }
  handleCreateLeadModalClose = () => {
    this.setState({ createLeadModal: false });
  };
  handleBulkUploadOpen() {
    this.props.history.push({
      pathname: "LeadBulkUpload",
    });
  }
  handleBulkUploadClose() {
    this.setState({ BulkUpload: false });
  }
  handleCreateTaskOpen(item) {
    this.setState({
      createTask: true,
      isPophoverVisibale: true,
      selectedLeadDetails: item,
    });
  }
  handleCreateTaskClose = (isSuccess) => {
    this.setState({
      createTask: false,
      isPophoverVisibale: false,
      selectedAction: 0,
      selectedLeadDetails: {},
      FollowUp: false,
    });

    if (isSuccess) {
      this.handleGetLeadList();
    }
  };
  handleFilterClick = () => {
    this.setState({ isFilter: !this.state.isFilter });
  };

  componentDidMount() {
    if (this.props.location.state) {
      if (this.props.location.state.isCardView) {
        this.setState({
          listview: false,
          cardView: true,
        });
      } else {
        this.setState({
          listview: true,
          cardView: false,
        });
      }
      var leadFilterInputtemp = localStorage.getItem("leadFilterInput");
      if (
        this.props.location.state.isFilterSearch &&
        this.props.location.state.isFilter &&
        leadFilterInputtemp
      ) {
        var leadFilterInput = null;
        if (leadFilterInputtemp) {
          leadFilterInput = JSON.parse(leadFilterInputtemp);
        }
        if (leadFilterInput.LeadCreateDate) {
          leadFilterInput.LeadCreateDate[0] = moment(
            leadFilterInput.LeadCreateDate[0]
          );
          leadFilterInput.LeadCreateDate[1] = moment(
            leadFilterInput.LeadCreateDate[2]
          );
        }
        if (leadFilterInput.LPM) {
          leadFilterInput.LPM[0] = moment(leadFilterInput.LPM[0]);
          leadFilterInput.LPM[1] = moment(leadFilterInput.LPM[2]);
        }
        if (leadFilterInput.EDD) {
          leadFilterInput.EDD[0] = moment(leadFilterInput.EDD[0]);
          leadFilterInput.EDD[1] = moment(leadFilterInput.EDD[2]);
        }
        if (leadFilterInput.AppointmentDate) {
          leadFilterInput.AppointmentDate[0] = moment(
            leadFilterInput.AppointmentDate[0]
          );
          leadFilterInput.AppointmentDate[1] = moment(
            leadFilterInput.AppointmentDate[2]
          );
        }
        if (leadFilterInput.LeadResolvedDate) {
          leadFilterInput.LeadResolvedDate[0] = moment(
            leadFilterInput.LeadResolvedDate[0]
          );
          leadFilterInput.LeadResolvedDate[1] = moment(
            leadFilterInput.LeadResolvedDate[2]
          );
        }
        if (leadFilterInput.TaskDate) {
          leadFilterInput.TaskDate[0] = moment(leadFilterInput.TaskDate[0]);
          leadFilterInput.TaskDate[1] = moment(leadFilterInput.TaskDate[2]);
        }
        var pageNo = leadFilterInput.pageNo;
        var pageSize = leadFilterInput.pageSize;
        this.setState({
          pageNo,
          pageSize,
          isFilter: this.props.location.state.isFilter,
          leadFilterInput: leadFilterInput,
        });
        setTimeout(() => {
          this.handleFilterSearchList(false);
        }, 100);
      } else {
        this.handleGetLeadList();
      }
    } else {
      this.handleGetLeadList();
    }
    var leadColumnsData = localStorage.getItem("leadColumnsData");
    if (leadColumnsData) {
      leadColumnsData = JSON.parse(leadColumnsData);
      var columnList = [];
      columnList = leadColumnsData
        .filter((x) => x.addedColumn === true)
        .map((x) => x.columnName);
      columnList.forEach((element) => {
        this.state.Columns.push(element);
      });
      let setColumns = new Set(this.state.Columns);
      this.setState({
        Columns: setColumns,
      });
    }
    if (window.screen.width > 768) {
      this.setState({
        mobileView: false,
      });
    } else {
      this.setState({
        mobileView: true,
      });
    }
    // this.handleGetLeadColumnName();

    this.handleGetStateData();
    this.handleGetEnquiryTypeData();
    this.handleGetLeadStatusData();
    this.handleGetSourceData();
    this.handleGetTypeData();
    this.handleGetLeadLocationData();
    // this.handleGetDoctorsData();
    this.handleGetAssignToData();
    this.handleGetTaskTypeList();
    this.handleGetSpecialityMasterData();
    this.handleGetServiceMasterData();
    this.handleTaskStatusData();
    this.handleInsuranceProviderData();

    this.handleGetPriorityList();
    this.handleGetUserList();
    this.handleGetModuleDetails();
  }
  onSelectChange = (selectedRowKeys) => {
    
    this.setState({
      selectedRowKeys,
      selectedLeadId: selectedRowKeys.toString(),
    });
  };
  handleRedioButtonChange(check) {
    if (check === "listview") {
      this.setState({
        listview: true,
        cardView: false,
      });
    } else {
      this.setState({
        listview: false,
        cardView: true,
      });
    }
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };

  handleGetStateData = () => {
    var inputData = {};
    inputData.IDs = "1";
    inputData.Value = "";
    try {
      this.CommonService.getStateList("GetStateByCountryID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var stateData = response.responseData;
          if (statusCode === 200) {
            this.setState({ stateData });
          } else {
            this.setState({ stateData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetCityData = (stateId) => {
    var inputData = {};
    inputData.IDs = stateId.toString();
    inputData.Value = "";
    try {
      this.CommonService.getCityList("GetCityMasterByStateID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var cityData = response.responseData;
          if (statusCode === 200) {
            this.setState({ cityData });
          } else {
            this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleInputChange = async (name, e) => {
    var leadInput = this.state.leadInput;
    if (name === "mobile" || name === "altmobile") {
      if (!isNaN(e.target.value)) {
        leadInput[name] = e.target.value;
      } else {
        if (!leadInput[name]) {
          leadInput[name] = "";
        } else {
          leadInput[name] = leadInput[name];
        }
      }
    } else {
      leadInput[name] = e.target.value;
    }
    await this.setState({
      leadInput,
    });
  };

  handleGenderInputChange = async (name, e) => {
    var leadInput = this.state.leadInput;

    if (name === "male") {
      leadInput["gender"] = 1;
      await this.setState({
        male: true,
        female: false,
        other: false,
      });
    } else if (name === "female") {
      leadInput["gender"] = 2;
      await this.setState({
        female: true,
        male: false,
        other: false,
      });
    } else {
      leadInput["gender"] = 3;
      await this.setState({
        other: true,
        female: false,
        male: false,
      });
    }
    await this.setState({
      leadInput,
    });
  };

  handleSelectInputChange = async (name, e) => {
    var leadInput = this.state.leadInput;
    if (name == "state") {
      leadInput[name] = e;
      this.handleGetCityData(e);
    } else {
      leadInput[name] = e;
    }
    await this.setState({
      leadInput,
    });
  };

  handleDateOnChange = async (name, dates, dateStrings) => {
    
    var leadInput = this.state.leadInput;
    var leadDetailsInput = this.state.leadDetailsInput;
    var leadAddDetailInput = this.state.leadAddDetailInput;

    if (name == "anniversarydate") {
      if (dates !== null) {
        leadInput["anniversarydate"] = dates._d;
      } else {
        leadInput["anniversarydate"] = "";
      }
    }
    if (name == "dob") {
      if (dates !== null) {
        leadInput["dob"] = dates._d;
      } else {
        leadInput["dob"] = "";
      }

      if (dateStrings) {
        var dob = new Date(dateStrings);
        var dobYear = dob.getYear();
        var dobMonth = dob.getMonth();
        var dobDate = dob.getDate();

        var now = new Date();
        var currentYear = now.getYear();
        var currentMonth = now.getMonth();
        var currentDate = now.getDate();

        var yearAge = currentYear - dobYear;

        if (currentMonth >= dobMonth) {
          var monthAge = currentMonth - dobMonth;
        } else {
          yearAge--;
          var monthAge = 12 + currentMonth - dobMonth;
        }

        if (currentDate >= dobDate) {
          var dateAge = currentDate - dobDate;
        } else {
          monthAge--;
          var dateAge = 31 + currentDate - dobDate;

          if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
          }
        }
        this.state.leadInput.ageyear = yearAge;
        this.state.leadInput.agemonth = monthAge;
        this.state.leadInput.agedays = dateAge;

        await this.setState({ leadInput });
      }
    }
    if (name == "lmp") {
      if (dates !== null) {
        leadDetailsInput["lmd"] = moment(dates._d).format("YYYY-MM-DD");
        var eddDate = new Date(dates._d.setDate(dates._d.getDate() + 280));
        leadDetailsInput["edd"] = moment(eddDate).format("YYYY-MM-DD");
        var diff_time =
          new Date(moment(new Date()).format("MM-DD-YYYY")).getTime() -
          new Date(
            moment(leadDetailsInput["lmd"]).format("MM-DD-YYYY")
          ).getTime();

        var diff_days = diff_time / (1000 * 3600 * 24);
        leadAddDetailInput["gestationalage"] = (diff_days / 7)
          .toFixed(3)
          .slice(0, -2);
        //this.setState({
        //    eddDate
        //})
      } else {
        leadDetailsInput["lmd"] = "";
      }
    }
    if (name == "edd") {
      if (dates !== null) {
        leadDetailsInput["edd"] = moment(dates._d).format("YYYY-MM-DD");
        var lmpDate = new Date(dates._d.setDate(dates._d.getDate() - 280));
        leadDetailsInput["lmd"] = moment(lmpDate).format("YYYY-MM-DD");
        var diff_time =
          new Date(moment(new Date()).format("MM-DD-YYYY")).getTime() -
          new Date(
            moment(leadDetailsInput["lmd"]).format("MM-DD-YYYY")
          ).getTime();

        var diff_days = diff_time / (1000 * 3600 * 24);
        leadAddDetailInput["gestationalage"] = (diff_days / 7)
          .toFixed(3)
          .slice(0, -2);
      } else {
        leadDetailsInput["edd"] = "";
      }
    }
    if (name === "AppointmentDateTime") {
      leadDetailsInput["AppointmentDateTime"] = moment(dates).toString();
    }
    await this.setState({ leadInput, leadDetailsInput, leadAddDetailInput });
  };

  handleLeadValidation() {
    let leadInput = this.state.leadInput;
    let errors = {};
    let formIsValid = true;

    if (!this.state.leadInput.firstname.trim()) {
      formIsValid = false;
      errors["firstname"] = "Please enter first name.";
    }

    // if (!this.state.leadInput.lastname) {
    //   formIsValid = false;
    //  errors["lastname"] = "Please enter last name.";
    //}

    if (!this.state.leadInput.email && !this.state.leadInput.mobile) {
      formIsValid = false;
      NotificationManager.error("Please enter mobile number or email id.");
    }

    if (this.state.leadInput.email) {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (reg.test(this.state.leadInput.email.trim()) === false) {
        formIsValid = false;
        errors["email"] = "Invalid email id.";
      }
    }

    if (this.state.leadInput.altemail) {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (reg.test(this.state.leadInput.altemail.trim()) === false) {
        formIsValid = false;
        errors["altemail"] = "Invalid email id.";
      }
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleCreateLead = () => {
    
    if (this.handleLeadValidation()) {
      var inputData = {};
      inputData.MobileNumber = this.state.leadInput.mobile;
      inputData.AlternateMobileNumber = this.state.leadInput.altmobile;
      inputData.FirstName = this.state.leadInput.firstname;
      inputData.MiddleName = this.state.leadInput.middlename;
      inputData.LastName = this.state.leadInput.lastname;
      inputData.Email = this.state.leadInput.email;
      inputData.AlternateEmail = this.state.leadInput.altemail;
      inputData.Gender = this.state.leadInput.gender;
      inputData.DateofBirth = this.state.leadInput.dob
        ? moment(this.state.leadInput.dob).format("DD-MM-YYYY")
        : null;
      inputData.AnniversaryDate = this.state.leadInput.anniversarydate
        ? moment(this.state.leadInput.anniversarydate).format("YYYY-MM-DD")
        : null;
      inputData.AgeYear = this.state.leadInput.ageyear
        ? this.state.leadInput.ageyear.toString()
        : null;
      inputData.AgeMonth = this.state.leadInput.agemonth
        ? this.state.leadInput.agemonth.toString()
        : null;
      inputData.AgeDays = this.state.leadInput.agedays
        ? this.state.leadInput.agedays.toString()
        : null;
      inputData.Address = this.state.leadInput.address;
      inputData.State = this.state.leadInput.state;
      inputData.City = this.state.leadInput.city;
      this.setState({ isSubmitLoading: true });
      try {
        this.LeadsService.createLead("InsertCustomerInfo", inputData)
          .then((response) => {
            var message = response.message;
            //this.setState({ isSubmitLoading: false });
            if (message === "Success") {
              this.setState({
                leadId: response.responseData,
              });
              //if (this.state.isCreateTaskandSave) {
              //    this.setState({ isSubmitLoading: false });
              //    this.handleCreateTaskandLeads(response.responseData);
              //} else {
              this.handleCreateLeadDetails(response.responseData);
              //}
            } else {
              this.setState({ isSubmitLoading: false });
              NotificationManager.error("Record Not Created.");
            }
          })
          .catch((error) => {
            this.setState({ isSubmitLoading: false });
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleGetEnquiryTypeData = () => {
    var inputData = {};
    inputData.BrandID = "0";
    try {
      this.EnquiryService.getEnquiryType("GetEnquiryType", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var enquiryTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ enquiryTypeData });
          } else {
            this.setState({ enquiryTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetSubEnquiryTypeData = (enquiryTypeId) => {
    
    var inputData = {};
    inputData.BrandID = "0";
    inputData.EnquiryTypeID = enquiryTypeId.toString();
    try {
      this.EnquiryService.getEnquirySubType("GetEnquirySubType", inputData)
        .then((response) => {
          
          var statusCode = response.statusCode;
          var enquirySubTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ enquirySubTypeData });
          } else {
            this.setState({ enquirySubTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleLeadDetailsInputChange = async (name, e) => {
    var leadDetailsInput = this.state.leadDetailsInput;
    //if (name == "state" || name == "city") {
    //    leadInput[name] = e;
    //    this.handleGetCityData(e);
    //} else {
    if (name === "editor") {
      leadDetailsInput[name] = e.editor.getData();
    } else {
      leadDetailsInput[name] = e.target.value;
    }
    //}
    await this.setState({
      leadDetailsInput,
    });
  };

  handleLeadDetailsSelectInputChange = async (name, e, event) => {
    
    var leadDetailsInput = this.state.leadDetailsInput;
    var leadAddDetailInput = this.state.leadAddDetailInput;
    leadAddDetailInput[name] = e;
    leadDetailsInput[name] = e;
    if (name == "category") {
      this.handleGetSubEnquiryTypeData(e);
    } else if (name == "status") {
      this.handleGetLeadSubStatusData(e);
    } else if (name == "assignto") {
      leadDetailsInput["isTeamUser"] = event.isTeamUser;
    }
    if (name == "unit") {
      this.handleGetDoctorsData(e);
    }
    if (name == "status") {
      this.setState({
        isCreateTaskandSave: false,
      });
      leadDetailsInput[name] = e;
      var disableTask = this.state.leadStatusData.filter(
        (x) => x.leadStatusID === e
      )[0].disableTask;

      if (!disableTask) {
        this.setState({
          isCreateTaskandSave: true,
        });
      }
    }
    await this.setState({
      leadDetailsInput,
    });
  };

  handleGetLeadStatusData = () => {
    
    var inputData = {};
    //inputData.BrandID = "0";
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GetLeadStatus")
        .then((response) => {
          
          var statusCode = response.statusCode;
          var leadStatusData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadStatusData });
          } else {
            this.setState({ leadStatusData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetLeadSubStatusData = (statusId) => {
    var inputData = {};
    inputData.LeadStatusID = statusId;
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GetLeadSubStatus", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadSubStatusData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadSubStatusData });
          } else {
            this.setState({ leadSubStatusData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetSourceData = () => {
    var inputData = {};
    //inputData.LeadStatusID = statusId;
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GetLeadSource")
        .then((response) => {
          var statusCode = response.statusCode;
          var leadSourceData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadSourceData });
          } else {
            this.setState({ leadSourceData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetTypeData = () => {
    var inputData = {};
    //inputData.LeadStatusID = statusId;
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GetLeadType")
        .then((response) => {
          var statusCode = response.statusCode;
          var leadTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadTypeData });
          } else {
            this.setState({ leadTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetLeadLocationData = () => {
    var inputData = {};
    inputData.SearchText = "";
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GeLeadLocation", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadLocationData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadLocationData });
          } else {
            this.setState({ leadLocationData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetUserBasedOnUnit = (unitid) => {
    
    var inputData = {};
    //inputData.UnitID = this.state.leadDetailsInput.unit;
    inputData.UnitID = unitid;
    try {
      this.LeadsService.getUserBasedOnUnit("GetUserBasedOnUnit", inputData)
        .then((response) => {
          
          var statusCode = response.statusCode;
          var UserBasedOnUnitData = response.responseData;
          if (statusCode === 200) {
            this.setState({ UserBasedOnUnitData });
          } else {
            this.setState({ UserBasedOnUnitData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetDoctorsData = (unitid) => {
    
    var inputData = {};
    //inputData.UnitID = this.state.leadDetailsInput.unit;
    inputData.UnitID = unitid;
    try {
      this.LeadsService.getLeadDropdownData("GeLeadDoctors", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadDoctorsData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadDoctorsData });
          } else {
            this.setState({ leadDoctorsData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleInsuranceProviderData = () => {
    try {
      this.LeadsService.getInsuranceProviderData("GetInsuranceProvider")
        .then((response) => {
          var statusCode = response.statusCode;
          var InsuranceProviderData = response.responseData;
          if (statusCode === 200) {
            this.setState({ InsuranceProviderData });
          } else {
            this.setState({ InsuranceProviderData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetSpecialityMasterData = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";

    try {
      this.LeadsService.getSpecialityMasterData(
        "GetSpecialityMaster",
        inputData
      )
        .then((response) => {
          var statusCode = response.statusCode;
          var SpecialityData = response.responseData;

          if (statusCode === 200) {
            this.setState({ SpecialityData });
          } else {
            this.setState({ SpecialityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetServiceMasterData = () => {
    var inputData = {};

    try {
      this.LeadsService.getServiceMasterData("GetServiceMaster")
        .then((response) => {
          var statusCode = response.statusCode;
          var RequestedServiceData = response.responseData;

          if (statusCode === 200) {
            this.setState({ RequestedServiceData });
          } else {
            this.setState({ RequestedServiceData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleTaskStatusData = () => {
    var inputData = {};

    try {
      this.LeadsService.getTaskStatusData("GetTaskStatusMaster")
        .then((response) => {
          var statusCode = response.statusCode;
          var taskStatusData = response.responseData;
          if (statusCode === 200) {
            this.setState({ taskStatusData });
          } else {
            this.setState({ taskStatusData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetAssignToData = () => {
    var inputData = {};
    inputData.SearchText = "";
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GeLeadUsers", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadAssignToData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadAssignToData });
          } else {
            this.setState({ leadAssignToData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetPriorityList = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      this.priorityService
        .getPriorityGirdData("GetPriority", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var priorityData = response.responseData;
          if (statusCode === 200) {
            this.setState({ priorityData });
          } else {
            this.setState({ priorityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleLeadDetailsValidation() {
    let leadDetailsInput = this.state.leadDetailsInput;
    let errors = {};
    let formIsValid = true;

    if (!this.state.leadDetailsInput.source) {
      formIsValid = false;
      errors["source"] = "Please select source.";
    }

    if (!this.state.leadDetailsInput.type) {
      formIsValid = false;
      errors["type"] = "Please select type.";
    }

    if (!this.state.leadDetailsInput.category) {
      formIsValid = false;
      errors["category"] = "Please select enquiry type.";
    }

    if (!this.state.leadDetailsInput.subcategory) {
      formIsValid = false;
      errors["subcategory"] = "Please select sub enquiry type.";
    }

    if (!this.state.leadDetailsInput.unit) {
      formIsValid = false;
      errors["unit"] = "Please select unit.";
    }

    if (!this.state.leadDetailsInput.status) {
      formIsValid = false;
      errors["status"] = "Please select status.";
    }

    if (!this.state.leadDetailsInput.substatus) {
      formIsValid = false;
      errors["substatus"] = "Please select sub status.";
    }

    if (!this.state.leadDetailsInput.assignto) {
      formIsValid = false;
      errors["assignto"] = "Please select assign to.";
    }

    if (!this.state.leadDetailsInput.priority) {
      formIsValid = false;
      errors["priority"] = "Please select priority.";
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleCreateLeadDetails = (leadId) => {
    //if (this.handleLeadDetailsValidation()) {
    var inputData = {};
    inputData.LeadID = leadId;
    inputData.source = this.state.leadDetailsInput.source;
    inputData.type = this.state.leadDetailsInput.type;
    inputData.leadCategory = this.state.leadDetailsInput.category;
    inputData.subCategory = this.state.leadDetailsInput.subcategory;
    inputData.lmd = this.state.leadDetailsInput.lmd
      ? moment(this.state.leadDetailsInput.lmd).format("YYYY-MM-DD")
      : null;
    inputData.edd = this.state.leadDetailsInput.edd
      ? moment(this.state.leadDetailsInput.edd).format("YYYY-MM-DD")
      : null;
    inputData.unit = this.state.leadDetailsInput.unit;
    inputData.doctor = this.state.leadDetailsInput.doctor;
    inputData.status = this.state.leadDetailsInput.status;
    inputData.subStatus = this.state.leadDetailsInput.substatus;
    inputData.assignTo = parseInt(
      this.state.leadDetailsInput.assignto.split("-")[0]
    );
    inputData.editor = this.state.leadDetailsInput.editor
      ? this.state.leadDetailsInput.editor.replace(
          new RegExp("<[^>]*>", "g"),
          ""
        )
      : null;
    inputData.isTeamUser = this.state.leadDetailsInput.isTeamUser;
    inputData.priorityID = this.state.leadDetailsInput.priority;
    this.setState({ isSubmitLoading: true });
    try {
      this.LeadsService.createLead("CreateLeadDetail", inputData)
        .then((response) => {
          var message = response.message;
          if (message === "Success") {
            this.setState({
              leadId: response.responseData,
            });
            this.handleCreateLeadAddDetails(response.responseData);
          } else {
            NotificationManager.error("Record Not Created.");
            this.setState({ isSubmitLoading: false });
          }
        })
        .catch((error) => {
          this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isSubmitLoading: false });
      console.log(error);
    }
    //}
  };

  handleLeadAddDetailsInputChange = async (name, e) => {
    
    var leadAddDetailInput = this.state.leadAddDetailInput;
    //if (name == "state" || name == "city") {
    //    leadInput[name] = e;
    //    this.handleGetCityData(e);
    //} else {
    leadAddDetailInput[name] = e.target.value;
    //}

    if (
      name === "caretakerage" ||
      name === "customerincome" ||
      name === "additionalincome" ||
      name === "agrdiscount" ||
      name === "discountedamount" ||
      name === "expectedrevenue" ||
      name === "totalrevenue"
    ) {
      if (!isNaN(e.target.value)) {
        leadAddDetailInput[name] = e.target.value;
      } else {
        if (leadAddDetailInput[name]) {
          leadAddDetailInput[name] = "";
        } else {
          leadAddDetailInput[name] = leadAddDetailInput[name];
        }
      }
    } else {
      leadAddDetailInput[name] = e.target.value;
    }

    await this.setState({
      leadAddDetailInput,
    });
  };

  //handleLeadAddDetailsSelectInputChange = async (name, e) => {
  //
  //    var leadAddDetailInput = this.state.leadAddDetailInput;
  //    leadDetailsInput[name] = e;
  //    if (name == "category") {
  //        this.handleGetSubEnquiryTypeData(e);
  //    } else if (name == "status") {
  //        this.handleGetLeadSubStatusData(e);
  //    }
  //    await this.setState({
  //        leadDetailsInput
  //    });

  //};

  handleCreateLeadAddDetails = (leadId) => {
    
    var inputData = {};
    inputData.LeadID = leadId;
    inputData.gestationalAge =
      this.state.leadAddDetailInput.gestationalage !== "" &&
      this.state.leadAddDetailInput.gestationalage !== undefined
        ? parseFloat(this.state.leadAddDetailInput.gestationalage)
        : 0;
    inputData.InsuranceProvider1 = this.state.leadAddDetailInput
      .insuranceprovider
      ? this.state.leadAddDetailInput.insuranceprovider + ""
      : null;
    inputData.InsurancePolicyNo = this.state.leadAddDetailInput.insurancepolicyno;
    inputData.preauthorizationCode = this.state.leadAddDetailInput.preauthorizationCode;
    inputData.customerIncome = this.state.leadAddDetailInput.customerincome;
    inputData.occupation = this.state.leadAddDetailInput.occupation;
    inputData.additionalIncome = this.state.leadAddDetailInput.additionalincome;
    inputData.hni = this.state.leadAddDetailInput.hni;
    inputData.CareTakerName = this.state.leadAddDetailInput.enquiryby;
    inputData.tpa = this.state.leadAddDetailInput.tpa;
    inputData.pageFormName = this.state.leadAddDetailInput.pageformname;
    inputData.InsuranceDate =
      this.state.leadAddDetailInput.InsuranceDate || null;
    inputData.CashOrInsurance =
      this.state.leadAddDetailInput.CashOrInsurance || null;
    inputData.MRNumber = this.state.leadAddDetailInput.MRNumber || null;
    inputData.BillNo = this.state.leadAddDetailInput.BillNo || null;
    inputData.EmploymentType =
      this.state.leadAddDetailInput.employeetype || null;
    inputData.CompanyName = this.state.leadAddDetailInput.companyname || null;
    inputData.CareTakerRelationship =
      this.state.leadAddDetailInput.caretakerrelationship || null;
    inputData.CareTakerAge =
      Number(this.state.leadAddDetailInput.caretakerage) || 0;
    inputData.CareTakerEmail =
      this.state.leadAddDetailInput.caretakeremailiD || null;
    inputData.CaseCategory = this.state.leadAddDetailInput.casecategory || null;
    inputData.AgrDiscount = this.state.leadAddDetailInput.agrdiscount || null;
    inputData.DiscountedAmount =
      this.state.leadAddDetailInput.discountedamount || null;
    inputData.EnquiryKeywords =
      this.state.leadAddDetailInput.enquirykeywords || null;
    inputData.ExpectedRevenue =
      this.state.leadAddDetailInput.expectedrevenue || null;
    //inputData.PageFormName = this.state.leadAddDetailInput.pageformname || null;
    inputData.TotalRevenue = this.state.leadAddDetailInput.totalrevenue || null;
    inputData.SourceURL = this.state.leadAddDetailInput.SourceURL || null;
    inputData.SpecialityID = this.state.leadAddDetailInput.speciality || 0;
    inputData.RequestedServiceID =
      this.state.leadAddDetailInput.requestedservice !== undefined
        ? this.state.leadAddDetailInput.requestedservice + ""
        : null;

    inputData.AppointmentDateTime = this.state.leadAddDetailInput
      .AppointmentDateTime
      ? moment(this.state.leadAddDetailInput.AppointmentDateTime)
          .format("YYYY-MM-DD HH:mm A")
          .toString()
      : null;
    inputData.PreferredDateTimeToContact = this.state.leadAddDetailInput
      .PreferredDateTimeToContact
      ? moment(this.state.leadAddDetailInput.PreferredDateTimeToContact)
          .format("YYYY-MM-DD hh:mm A")
          .toString()
      : null;
    this.setState({ isSubmitLoading: true });
    try {
      this.LeadsService.createLead("CreateAdditionalLeadDetail", inputData)
        .then((response) => {
          var message = response.message;

          if (message === "Success") {
            //if (this.state.isCreateTaskandSave) {
            //    NotificationManager.success("Task and Record Created Successfully.");
            //} else {
            NotificationManager.success("Record Created Successfully.");
            // }

            this.setState({
              createLeadModal: false,
              isSubmitLoading: false,
              isCreateTaskandSave: false,
            });
            this.handleGetLeadList();
          } else {
            this.setState({ isSubmitLoading: false });
            NotificationManager.error("Record Not Created.");
          }
        })
        .catch((error) => {
          this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isSubmitLoading: false });
      console.log(error);
    }
  };
  //handle get lead column name
  handleGetLeadColumnName = () => {
    try {
      this.LeadsService.getLeadColumnName("GetLeadColumnName")
        .then((response) => {
          var statusCode = response.statusCode;
          var leadColumnData = response.responseData;

          leadColumnData.forEach((element) => {
            if (element.columnName === "LeadUID") {
              element["isFixed"] = true;
              element["addedColumn"] = false;
              element["removeColumn"] = false;
            } else if (element.columnName === "CustomerName") {
              element["isFixed"] = true;
              element["addedColumn"] = false;
              element["removeColumn"] = false;
            } else if (element.columnName === "PhoneNo") {
              element["isFixed"] = true;
              element["addedColumn"] = false;
              element["removeColumn"] = false;
            } else {
              element["isFixed"] = false;
              element["addedColumn"] = true;
              element["removeColumn"] = false;
            }
          });
          if (statusCode === 200) {
            this.setState({ leadColumnData });
          } else {
            this.setState({ leadColumnData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get lead list
  handleGetLeadList = () => {
    var inputData = {};
    inputData.LeadID = 0;
    inputData.Pageno = this.state.pageNo;
    inputData.Pagesize = this.state.pageSize;

    try {
      this.setState({ isLoading: true });
      this.LeadsService.getLeadList("GetLeadDetails", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadListGridData = response.responseData.leadDetails;
          var totalcount = response.responseData.totalcount;
          this.setState({ isLoading: false });
          if (statusCode === 200) {
            this.setState({
              createLeadModal: false,
              leadListGridData,
              totalcount,
            });
          } else {
            this.setState({ leadListGridData: [], totalcount: 0 });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleCallBackToList = () => {
    this.state.Columns = [
      "FirstCol",
      "LeadUID",
      "CustomerName",
      "Email",
      "PhoneNo",
    ];
    var leadColumnsData = localStorage.getItem("leadColumnsData");
    if (leadColumnsData) {
      leadColumnsData = JSON.parse(leadColumnsData);
      var columnList = [];
      columnList = leadColumnsData
        .filter((x) => x.addedColumn === true)
        .map((x) => x.columnName);
      columnList.forEach((element) => {
        this.state.Columns.push(element);
      });
      let setColumns = new Set(this.state.Columns);
      this.setState({
        Columns: setColumns,
      });
    }
  };

  handleCreateTaskandLeads = () => {
    var ststus = this.state.leadDetailsInput.status;
    if (this.state.leadAddDetailInput.PreferredDateTimeToContact) {
      var date = this.state.leadAddDetailInput.PreferredDateTimeToContact;
      this.setState({
        taskdate: date,
      });
    }

    this.setState({
      selectedLeadStatus: ststus,
      // taskLeadID: leadID,
      createTask: true,
      taskdate: date,
    });
  };

  handleSaveLeads = () => {
    this.handleCreateLead();
  };

  handleNextLead = (tabno) => {
    if (tabno == "1") {
      this.setState({
        tab: tabno,
      });
    }
    if (tabno == "2") {
      if (this.handleLeadValidation()) {
        this.setState({
          tab: tabno,
        });
      }
    }
    if (tabno == "3") {
      if (this.handleLeadDetailsValidation()) {
        this.setState({
          tab: tabno,
        });
      }
    }
  };

  changeTab = (activeKey) => {
    if (activeKey == "1") {
      this.setState({
        tab: activeKey,
      });
    }
    if (activeKey == "2") {
      if (this.handleLeadValidation()) {
        this.setState({
          tab: activeKey,
        });
      }
    }
    if (activeKey == "3") {
      if (this.handleLeadDetailsValidation()) {
        this.setState({
          tab: activeKey,
        });
      }
    }
  };
  handleActionChange = async (rowItem, e) => {
    
    await this.setState({
      selectedAction: e.target.value,
      selectedLeadDetails: rowItem,
    });

    if (e.target.value === 101) {
      //Only Query Lead
      this.setState({
        QueryLead: true,
      });
    } else if (e.target.value === 102) {
      //Follow Up
      this.setState({
        createTask: true,
        FollowUp: true,
      });
    } else if (e.target.value === 103) {
      //Disqualify
      this.disqualifyAction = React.createRef();
      this.setState({
        Disqualify: true,
      });
      setTimeout(() => {
        this.disqualifyAction.handleDisqualifyModalOpen(
          this.state.leadStatusData,
          this.state.selectedLeadDetails.leadID
        );
      }, 100);
    } else if (e.target.value === 104) {
      //View Timelines
      this.props.history.push({
        pathname: "LeadDetails",
        state: {
          leadDeatils: rowItem,
          leadStatusData: this.state.leadStatusData,
          leadTypeData: this.state.leadTypeData,
          leadAssignToData: this.state.leadAssignToData,
          enquiryTypeData: this.state.enquiryTypeData,
          leadSourceData: this.state.leadSourceData,
          TaskTypeList: this.state.taskTypeList,
          InsuranceProviderData: this.state.InsuranceProviderData,
        },
      });
    } else if (e.target.value === 105) {
      //Add Notes
      this.setState({
        addNote: true,
      });
    } else if (e.target.value === 106) {
      //Add Task
      this.setState({
        createTask: true,
      });
    } else if (e.target.value === 107) {
      //Email
      this.setState({
        emailModal: true,
      });
    } else {
      //SMS
      this.setState({
        smsModal: true,
      });
    }
  };

  handleCallBackQueryModalClose = (isSuccess) => {
    this.setState({
      QueryLead: false,
      selectedAction: 0,
      isStatusClick: false,
      selectedLeadStatus: 0,
      selectedLeadDetails: {},
    });
    if (isSuccess) {
      this.handleGetLeadList();
    }
  };
  handleTableStatusChange = (item, e) => {
    
    var disableTask = this.state.leadStatusData.filter(
      (x) => x.leadStatusID === e.target.value
    )[0].disableTask;
    this.setState({ isTaskOpen: false, isEditTask: false });
    this.handleGetTask(item, disableTask, e);

    //if (disableTask) {
    //    if (this.state.Task.length > 0) {
    //        this.state.Task.map((item) => {
    //            if (item.taskStatus === "Planned") {
    //                NotificationManager.error("Task is already planned.");
    //            }
    //        })
    //    } else {
    //        this.setState({
    //            selectedLeadDetails: item,
    //            selectedLeadStatus: e.target.value,
    //            isStatusClick: true,
    //            QueryLead: true,
    //        });
    //    }

    //}
    //else {
    //    this.setState({
    //        selectedLeadStatus: e.target.value,
    //        selectedLeadDetails: item,
    //        createTask: true,

    //    });

    //}
  };
  handlePageSizeChange = (page, pageSize) => {
    this.setState({
      pageSize: pageSize,
      pageNo: page,
    });
    if (this.state.isFilter && this.state.isFilterSearch) {
      setTimeout(() => {
        this.handleFilterSearchList(false);
      }, 100);
    } else {
      setTimeout(() => {
        this.handleGetLeadList();
      }, 100);
    }
  };
  handlePageNoChange = (e) => {
    this.setState({
      pageNo: e,
    });
    if (this.state.isFilter && this.state.isFilterSearch) {
      setTimeout(() => {
        this.handleFilterSearchList(false);
      }, 100);
    } else {
      setTimeout(() => {
        this.handleGetLeadList();
      }, 100);
    }
  };
  handleSMSActionModalClose = () => {
    this.setState({
      smsModal: false,
      selectedAction: 0,
      selectedLeadDetails: {},
    });
  };
  handleEmailActionModalClose = () => {
    this.setState({
      emailModal: false,
      selectedAction: 0,
      selectedLeadDetails: {},
    });
  };
  handleGetTaskTypeList = () => {
    try {
      this.LeadsService.getLeadDropdownData("GetTaskTypeMaster")
        .then((response) => {
          var statusCode = response.statusCode;
          var taskTypeList = response.responseData;
          if (statusCode === 200) {
            this.setState({ taskTypeList });
          } else {
            this.setState({ taskTypeList: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleCloseDisqualifyModalClose = (isSuccess) => {
    this.setState({
      selectedLeadDetails: {},
      selectedAction: 0,
    });
    if (isSuccess) {
      this.handleGetLeadList();
    }
  };

  handleAssignModalOpen = () => {
    this.setState({
      isAssignModel: true,
    });
  };

  handleAssignModalClose = () => {
    this.setState({
      isAssignModel: false,
      assignInput: {},
      UserBasedOnUnitData: [],
    });
  };

  handleAssignInputChange = async (name, e) => {
    
    var assignInput = this.state.assignInput;
    assignInput[name] = e;
    if (name === "Unit") {
      this.state.assignInput.User = null;
      this.setState({
        UserBasedOnUnitData: [],
        assignInput,
      });

      this.handleGetUserBasedOnUnit(e);
    }

    await this.setState({
      assignInput: assignInput,
    });
  };

  handleAssignUser = () => {
    
    var isTeamUser = false;
    if (this.state.assignInput.User) {
      //isTeamUser = this.state.UserBasedOnUnitData.filter(x => x.userID == this.state.assignInput.User.split("-")[0])[0].isTeamUser;
      isTeamUser = this.state.assignInput.User.split("-")[1];
      if (isTeamUser === "true") {
        isTeamUser = true;
      } else {
        isTeamUser = false;
      }
    }

    var inputData = {};
    var leadFilterData = {};
    inputData.LeadID =
      this.state.isSelectAll !== true ? this.state.selectedLeadId : "all";
    inputData.LeadAssignTo = this.state.assignInput.User
      ? Number(this.state.assignInput.User.split("-")[0])
      : null;
    inputData.IsTeamUser = isTeamUser;

    leadFilterData.LeadID = Number(this.state.leadFilterInput.LeadID || 0);
    leadFilterData.Pageno = 1;
    leadFilterData.Pagesize = 10;
    leadFilterData.PhoneNo = this.state.leadFilterInput.PhoneNo || null;
    leadFilterData.Email = this.state.leadFilterInput.Email || null;
    leadFilterData.AlternatePhoneNo =
      this.state.leadFilterInput.AlternatePhoneNo || null;
    leadFilterData.LeadType = this.state.leadFilterInput.LeadType || 0;
    if (this.state.leadFilterInput.Gender === undefined) {
      leadFilterData.Gender = -1;
    } else {
      leadFilterData.Gender = this.state.leadFilterInput.Gender - 1 || 0;
    }
    //leadFilterData.Gender = this.state.leadFilterInput.Gender - 1 || 0;
    leadFilterData.Age = this.state.leadFilterInput.Age || 0;
    leadFilterData.EmployerName = this.state.leadFilterInput.EmployerName || 0;
    leadFilterData.Occupation = this.state.leadFilterInput.Occupation || null;
    leadFilterData.City = this.state.leadFilterInput.City || 0;
    leadFilterData.LeadLocality =
      this.state.leadFilterInput.LeadLocality || null;
    leadFilterData.Unit = this.state.leadFilterInput.Unit || 0;
    //leadFilterData.LPM = this.state.leadFilterInput.LPM || null;
    //leadFilterData.EDD = this.state.leadFilterInput.EDD || null;
    if (this.state.leadFilterInput.LPM) {
      leadFilterData.LPM =
        moment(this.state.leadFilterInput.LPM[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.LPM[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.LPM = null;
    }
    if (this.state.leadFilterInput.EDD) {
      leadFilterData.EDD =
        moment(this.state.leadFilterInput.EDD[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.EDD[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.EDD = null;
    }
    leadFilterData.CosultingDoctor =
      this.state.leadFilterInput.CosultingDoctor || 0;
    leadFilterData.LeadStatus = this.state.leadFilterInput.LeadStatus || 0;
    leadFilterData.LeadSubStatus =
      this.state.leadFilterInput.LeadSubStatus || 0;
    leadFilterData.AssignTo = this.state.leadFilterInput.AssignTo || null;
    leadFilterData.EnquiryType = this.state.leadFilterInput.EnquiryType || 0;
    leadFilterData.EnquirySubType =
      this.state.leadFilterInput.EnquirySubType || 0;
    leadFilterData.LeadSource = this.state.leadFilterInput.LeadSource || 0;
    leadFilterData.CashInsurance =
      this.state.leadFilterInput.CashInsurance || null;
    leadFilterData.InsuranceProvider1 =
      this.state.leadFilterInput.InsuranceProvider1 || null;
    leadFilterData.TPA = this.state.leadFilterInput.TPA || null;
    leadFilterData.SecondaryInsuranceProvider =
      this.state.leadFilterInput.SecondaryInsuranceProvider || null;
    leadFilterData.Packageoffered =
      this.state.leadFilterInput.Packageoffered || null;
    leadFilterData.PageFormName =
      this.state.leadFilterInput.PageFormName || null;
    leadFilterData.LeadCreatedBy =
      this.state.leadFilterInput.LeadCreatedBy || 0;
    leadFilterData.LeadsResponseFailed =
      this.state.leadFilterInput.LeadsResponseFailed || null;
    leadFilterData.LeadsResolutionFailed =
      this.state.leadFilterInput.LeadsResolutionFailed || null;
    leadFilterData.TaskType = this.state.leadFilterInput.TaskType || 0;
    leadFilterData.CreatedBy = this.state.leadFilterInput.CreatedBy || 0;
    leadFilterData.SearchText = null;
    leadFilterData.LeadModifiedBy =
      this.state.leadFilterInput.LeadModifiedBy || 0;
    if (this.state.leadFilterInput.TaskDate) {
      leadFilterData.TaskDate =
        moment(this.state.leadFilterInput.TaskDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.TaskDate[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.TaskDate = null;
    }

    if (this.state.leadFilterInput.LeadResolvedDate) {
      leadFilterData.LeadResolvedDate =
        moment(this.state.leadFilterInput.LeadResolvedDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.LeadResolvedDate[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.LeadResolvedDate = null;
    }
    if (this.state.leadFilterInput.AppointmentDate) {
      leadFilterData.AppointmentDate =
        moment(this.state.leadFilterInput.AppointmentDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.AppointmentDate[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.AppointmentDate = null;
    }
    if (this.state.leadFilterInput.LeadCreateDate) {
      leadFilterData.LeadCreateDate =
        moment(this.state.leadFilterInput.LeadCreateDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        this.state.leadFilterInput.LeadCreateDate[1]
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.LeadCreateDate = null;
    }

    if (this.state.leadFilterInput.LeadModifiedDate) {
      leadFilterData.LeadModifiedDate =
        moment(this.state.leadFilterInput.LeadModifiedDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        this.state.leadFilterInput.LeadModifiedDate[1]
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.LeadModifiedDate = null;
    }
    //var listIDsList = [];
    //if (!this.state.isSelectAll) {
    //    if (this.state.selectedRowKeys) {
    //        this.state.selectedRowKeys.forEach((element) => {
    //            var obj = {};
    //            obj.ListIDs = element;
    //            listIDsList.push(obj);
    //        });
    //    }
    //}
    //inputData.ListIDsList = listIDsList;
    //var leadColumnNameModels =
    //    JSON.parse(localStorage.getItem("leadColumnsData")) || null;
    //var leadColumnNameModelsTemp = [];
    //if (leadColumnNameModels) {
    //    var tempData = leadColumnNameModels.filter((x) => x.addedColumn === true);
    //    tempData.forEach((element) => {
    //        var obj = {};
    //        obj.ID = element.id;
    //        obj.ColumnName = element.columnName;
    //        leadColumnNameModelsTemp.push(obj);
    //    });
    //}
    //inputData.leadColumnNameModels = leadColumnNameModelsTemp;
    inputData.leadfilter = leadFilterData;

    try {
      this.LeadsService.assignReassignLead("AssignReassignLead", inputData)
        .then((response) => {
          
          var statusCode = response.statusCode;
          var LeadsRawReportData = response.responseData;
          if (statusCode === 200) {
            NotificationManager.success("Lead Assigned successfully.");
            this.setState({
              assignInput: {},
              selectedRowKeys: [],
              isSelectAll: false,
              isAssignModel: false,
            });
          } else {
            NotificationManager.error("Lead not assigned.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleFilterInputChange = async (name, e) => {
    var leadFilterInput = this.state.leadFilterInput;
    if (e.target) {
      leadFilterInput[name] = e.target.value;
    } else {
      leadFilterInput[name] = e;
    }
    if (name === "State") {
      this.handleGetCityData(e);
      this.setState({
        cityData: [],
      });
    }
    if (name === "LeadStatus") {
      this.handleGetLeadSubStatusData(e);
      this.setState({
        leadSubStatusData: [],
      });
    }
    if (name === "EnquiryType") {
      this.setState({
        enquirySubTypeData: [],
      });
      this.handleGetSubEnquiryTypeData(e);
    }
    if (name === "Unit") {
      this.setState({
        leadDoctorsData: [],
      });
      this.handleGetDoctorsData(e);
    }
    await this.setState({
      leadFilterInput: leadFilterInput,
    });
  };
  handleFilterSearchList = (isSearchClick) => {
    var inputData = {};
    var pageNo = 1;
    var pageSize = 10;
    if (!isSearchClick) {
      pageNo = this.state.pageNo;
      pageSize = this.state.pageSize;
    } else {
      pageNo = 1;
      pageSize = 10;
      this.setState({
        pageNo: 1,
        pageSize: 10,
      });
    }

    inputData.LeadID = Number(this.state.leadFilterInput.LeadID || 0);
    inputData.Pageno = pageNo;
    inputData.Pagesize = pageSize;
    inputData.PhoneNo = this.state.leadFilterInput.PhoneNo || null;
    inputData.Email = this.state.leadFilterInput.Email || null;
    inputData.AlternatePhoneNo =
      this.state.leadFilterInput.AlternatePhoneNo || null;
    inputData.LeadType = this.state.leadFilterInput.LeadType || 0;
    if (this.state.leadFilterInput.Gender === undefined) {
      inputData.Gender = -1;
    } else {
      inputData.Gender = this.state.leadFilterInput.Gender - 1 || 0;
    }

    inputData.Age = this.state.leadFilterInput.Age || 0;
    inputData.EmployerName = this.state.leadFilterInput.EmployerName || 0;
    inputData.Occupation = this.state.leadFilterInput.Occupation || null;
    inputData.City = this.state.leadFilterInput.City || 0;
    inputData.LeadLocality = this.state.leadFilterInput.LeadLocality || null;
    inputData.Unit = this.state.leadFilterInput.Unit || 0;

    inputData.CosultingDoctor = this.state.leadFilterInput.CosultingDoctor || 0;
    inputData.LeadStatus = this.state.leadFilterInput.LeadStatus || 0;
    inputData.LeadSubStatus = this.state.leadFilterInput.LeadSubStatus || 0;
    inputData.AssignTo = this.state.leadFilterInput.AssignTo || null;
    inputData.EnquiryType = this.state.leadFilterInput.EnquiryType || 0;
    inputData.EnquirySubType = this.state.leadFilterInput.EnquirySubType || 0;
    inputData.LeadSource = this.state.leadFilterInput.LeadSource || 0;
    inputData.CashInsurance = this.state.leadFilterInput.CashInsurance || null;
    inputData.InsuranceProvider1 =
      this.state.leadFilterInput.InsuranceProvider1 || null;
    inputData.TPA = this.state.leadFilterInput.TPA || null;
    inputData.SecondaryInsuranceProvider =
      this.state.leadFilterInput.SecondaryInsuranceProvider || null;
    inputData.Packageoffered =
      this.state.leadFilterInput.Packageoffered || null;
    inputData.PageFormName = this.state.leadFilterInput.PageFormName || null;
    inputData.LeadCreatedBy = this.state.leadFilterInput.LeadCreatedBy || 0;
    inputData.LeadsResponseFailed =
      this.state.leadFilterInput.LeadsResponseFailed || null;
    inputData.LeadsResolutionFailed =
      this.state.leadFilterInput.LeadsResolutionFailed || null;
    inputData.TaskType = this.state.leadFilterInput.TaskType || 0;
    inputData.TaskstatusID = this.state.leadFilterInput.TaskStatus || 0;
    inputData.CreatedBy = this.state.leadFilterInput.CreatedBy || 0;
    inputData.SearchText = null;
    inputData.LeadModifiedBy = this.state.leadFilterInput.LeadModifiedBy || 0;

    if (this.state.leadFilterInput.LPM) {
      inputData.LPM =
        moment(this.state.leadFilterInput.LPM[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.LPM[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      inputData.LPM = null;
    }
    if (this.state.leadFilterInput.EDD) {
      inputData.EDD =
        moment(this.state.leadFilterInput.EDD[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.EDD[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      inputData.EDD = null;
    }
    if (this.state.leadFilterInput.TaskDate) {
      inputData.TaskDate =
        moment(this.state.leadFilterInput.TaskDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.TaskDate[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      inputData.TaskDate = null;
    }

    if (this.state.leadFilterInput.LeadResolvedDate) {
      inputData.LeadResolvedDate =
        moment(this.state.leadFilterInput.LeadResolvedDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.LeadResolvedDate[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      inputData.LeadResolvedDate = null;
    }
    if (this.state.leadFilterInput.AppointmentDate) {
      inputData.AppointmentDate =
        moment(this.state.leadFilterInput.AppointmentDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.AppointmentDate[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      inputData.AppointmentDate = null;
    }
    if (this.state.leadFilterInput.LeadCreateDate) {
      inputData.LeadCreateDate =
        moment(this.state.leadFilterInput.LeadCreateDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        this.state.leadFilterInput.LeadCreateDate[1]
          .format("YYYY-MM-DD")
          .toString();
    } else {
      inputData.LeadCreateDate = null;
    }

    if (this.state.leadFilterInput.LeadModifiedDate) {
      inputData.LeadModifiedDate =
        moment(this.state.leadFilterInput.LeadModifiedDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        this.state.leadFilterInput.LeadModifiedDate[1]
          .format("YYYY-MM-DD")
          .toString();
    } else {
      inputData.LeadModifiedDate = null;
    }

    try {
      this.state.leadFilterInput["pageNo"] = pageNo;
      this.state.leadFilterInput["pageSize"] = pageSize;
      this.setState({
        isLoading: true,
        isFilterSearch: true,
        leadFilterInput: this.state.leadFilterInput,
      });

      this.LeadsService.importFile("GetLeadDetails", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadListGridData = response.responseData.leadDetails;
          var totalcount = response.responseData.totalcount;
          this.setState({ isLoading: false });
          if (statusCode === 200) {
            this.setState({
              leadListGridData,
              totalcount,
              filterCount: totalcount,
            });
          } else {
            this.setState({
              leadListGridData: [],
              totalcount: 0,
              filterCount: 0,
            });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleClearSearch = async () => {
    await this.setState({
      isFilterSearch: false,
      leadFilterInput: {},
      pageNo: 1,
      pageSize: 10,
      filterCount: 0,
    });
    localStorage.removeItem("leadFilterInput");
    this.handleGetLeadList();
  };
  handleGetUserList = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      this.UserService.getUserGridData("GetUserDetails", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var userData = response.responseData;
          if (statusCode === 200) {
            this.setState({ userData });
          } else {
            this.setState({ userData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetModuleDetails = () => {
    try {
      this.UserService.getUserModuleDetails("GetUserModuleDetails")
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            //this.setState({ cityData });
            this.setAccessUser(data);
          } else {
            //this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  setAccessUser(data) {
    if (data !== null) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].moduleName === "Leads") {
          this.setState({
            createAccess: data[i].isCreate,
            viewAccess: data[i].isView,
            deleteAccess: data[i].isDelete,
            editAccess: data[i].isEdit,
            downloadAccess: data[i].isDownload,
          });
        }
        if (data[i].moduleName === "File Upload") {
          this.setState({
            fileCreateAccess: data[i].isCreate,
          });
        }
      }
    }
  }
  //handleExportLead = () => {
  //    var inputData = {};
  //    inputData.LeadID = Number(this.state.leadFilterInput.LeadID || 0);
  //    inputData.Pageno = 1;
  //    inputData.Pagesize = 10;
  //    inputData.PhoneNo = this.state.leadFilterInput.PhoneNo || null;
  //    inputData.Email = this.state.leadFilterInput.Email || null;
  //    inputData.AlternatePhoneNo =
  //        this.state.leadFilterInput.AlternatePhoneNo || null;
  //    inputData.LeadType = this.state.leadFilterInput.LeadType || 0;
  //    inputData.Gender = this.state.leadFilterInput.Gender - 1 || 0;
  //    inputData.Age = this.state.leadFilterInput.Age || 0;
  //    inputData.EmployerName = this.state.leadFilterInput.EmployerName || 0;
  //    inputData.Occupation = this.state.leadFilterInput.Occupation || null;
  //    inputData.City = this.state.leadFilterInput.City || 0;
  //    inputData.LeadLocality = this.state.leadFilterInput.LeadLocality || null;
  //    inputData.Unit = this.state.leadFilterInput.Unit || 0;
  //    inputData.LPM = this.state.leadFilterInput.LPM || null;
  //    inputData.EDD = this.state.leadFilterInput.EDD || null;
  //    inputData.CosultingDoctor = this.state.leadFilterInput.CosultingDoctor || 0;
  //    inputData.LeadStatus = this.state.leadFilterInput.LeadStatus || 0;
  //    inputData.LeadSubStatus = this.state.leadFilterInput.LeadSubStatus || 0;
  //    inputData.AssignTo = this.state.leadFilterInput.AssignTo || 0;
  //    inputData.EnquiryType = this.state.leadFilterInput.EnquiryType || 0;
  //    inputData.EnquirySubType = this.state.leadFilterInput.EnquirySubType || 0;
  //    inputData.LeadSource = this.state.leadFilterInput.LeadSource || 0;
  //    inputData.CashInsurance = this.state.leadFilterInput.CashInsurance || null;
  //    inputData.InsuranceProvider1 =
  //        this.state.leadFilterInput.InsuranceProvider1 || null;
  //    inputData.TPA = this.state.leadFilterInput.TPA || null;
  //    inputData.SecondaryInsuranceProvider =
  //        this.state.leadFilterInput.SecondaryInsuranceProvider || null;
  //    inputData.Packageoffered =
  //        this.state.leadFilterInput.Packageoffered || null;
  //    inputData.PageFormName = this.state.leadFilterInput.PageFormName || null;
  //    inputData.LeadCreatedBy = this.state.leadFilterInput.LeadCreatedBy || 0;
  //    inputData.LeadsResponseFailed =
  //        this.state.leadFilterInput.LeadsResponseFailed || null;
  //    inputData.LeadsResolutionFailed =
  //        this.state.leadFilterInput.LeadsResolutionFailed || null;
  //    inputData.TaskType = this.state.leadFilterInput.TaskType || 0;
  //    inputData.CreatedBy = this.state.leadFilterInput.CreatedBy || 0;
  //    inputData.SearchText = null;
  //    if (this.state.leadFilterInput.TaskDate) {
  //        inputData.TaskDate =
  //            moment(this.state.leadFilterInput.TaskDate[0])
  //                .format("YYYY-MM-DD")
  //                .toString() +
  //            "to" +
  //            moment(this.state.leadFilterInput.TaskDate[1])
  //                .format("YYYY-MM-DD")
  //                .toString();
  //    } else {
  //        inputData.TaskDate = null;
  //    }

  //    if (this.state.leadFilterInput.LeadResolvedDate) {
  //        inputData.LeadResolvedDate =
  //            moment(this.state.leadFilterInput.LeadResolvedDate[0])
  //                .format("YYYY-MM-DD")
  //                .toString() +
  //            "to" +
  //            moment(this.state.leadFilterInput.LeadResolvedDate[1])
  //                .format("YYYY-MM-DD")
  //                .toString();
  //    } else {
  //        inputData.LeadResolvedDate = null;
  //    }
  //    if (this.state.leadFilterInput.AppointmentDate) {
  //        inputData.AppointmentDate =
  //            moment(this.state.leadFilterInput.AppointmentDate[0])
  //                .format("YYYY-MM-DD")
  //                .toString() +
  //            "to" +
  //            moment(this.state.leadFilterInput.AppointmentDate[1])
  //                .format("YYYY-MM-DD")
  //                .toString();
  //    } else {
  //        inputData.AppointmentDate = null;
  //    }
  //    if (this.state.leadFilterInput.LeadCreateDate) {
  //        inputData.LeadCreateDate =
  //            moment(this.state.leadFilterInput.LeadCreateDate[0])
  //                .format("YYYY-MM-DD")
  //                .toString() +
  //            "to" +
  //            this.state.leadFilterInput.LeadCreateDate[1]
  //                .format("YYYY-MM-DD")
  //                .toString();
  //    } else {
  //        inputData.LeadCreateDate = null;
  //    }
  //    var listIDsList = [];
  //    if (!this.state.isSelectAll) {
  //        if (this.state.selectedRowKeys) {
  //            this.state.selectedRowKeys.forEach((element) => {
  //                var obj = {};
  //                obj.ListIDs = element;
  //                listIDsList.push(obj);
  //            });
  //        }
  //    }
  //    inputData.ListIDsList = listIDsList;
  //    var leadColumnNameModels =
  //        JSON.parse(localStorage.getItem("leadColumnsData")) || null;
  //    var leadColumnNameModelsTemp = [];
  //    if (leadColumnNameModels) {
  //        var tempData = leadColumnNameModels.filter((x) => x.addedColumn === true);
  //        tempData.forEach((element) => {
  //            var obj = {};
  //            obj.ID = element.id;
  //            obj.ColumnName = element.columnName;
  //            leadColumnNameModelsTemp.push(obj);
  //        });
  //    }
  //    inputData.leadColumnNameModels = leadColumnNameModelsTemp;

  //    try {
  //        this.LeadsService.exportToExcel("DownloadLeadDetails", inputData)
  //            .then((response) => {
  //                var statusCode = response.statusCode;
  //                var responseData = response.responseData;
  //                if (statusCode === 200) {
  //                    this.importExport.ExportToExcel(
  //                        responseData,
  //                        responseData.split("/").pop()
  //                    );
  //                    this.setState({
  //                        selectedRowKeys: [],
  //                    });
  //                } else {
  //                    NotificationManager.error("File not download.");
  //                }
  //            })
  //            .catch((error) => {
  //                NotificationManager.error("File not download.");
  //                console.log(error);
  //            });
  //    } catch (error) {
  //        NotificationManager.error("File not download.");
  //        console.log(error);
  //    }
  //};

  handleExportLead = () => {
    
    var inputData = {};
    var leadFilterData = {};
    inputData.LeadID =
      this.state.isSelectAll !== true ? this.state.selectedLeadId : "all";
    leadFilterData.LeadID = Number(this.state.leadFilterInput.LeadID || 0);
    leadFilterData.Pageno = 1;
    leadFilterData.Pagesize = 10;
    leadFilterData.PhoneNo = this.state.leadFilterInput.PhoneNo || null;
    leadFilterData.Email = this.state.leadFilterInput.Email || null;
    leadFilterData.AlternatePhoneNo =
      this.state.leadFilterInput.AlternatePhoneNo || null;
    leadFilterData.LeadType = this.state.leadFilterInput.LeadType || 0;
    if (this.state.leadFilterInput.Gender === undefined) {
      leadFilterData.Gender = -1;
    } else {
      leadFilterData.Gender = this.state.leadFilterInput.Gender - 1 || 0;
    }
    //leadFilterData.Gender = this.state.leadFilterInput.Gender - 1 || 0;
    leadFilterData.Age = this.state.leadFilterInput.Age || 0;
    leadFilterData.EmployerName = this.state.leadFilterInput.EmployerName || 0;
    leadFilterData.Occupation = this.state.leadFilterInput.Occupation || null;
    leadFilterData.City = this.state.leadFilterInput.City || 0;
    leadFilterData.LeadLocality =
      this.state.leadFilterInput.LeadLocality || null;
    leadFilterData.Unit = this.state.leadFilterInput.Unit || 0;
    //leadFilterData.LPM = this.state.leadFilterInput.LPM || null;
    //leadFilterData.EDD = this.state.leadFilterInput.EDD || null;
    if (this.state.leadFilterInput.LPM) {
      leadFilterData.LPM =
        moment(this.state.leadFilterInput.LPM[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.LPM[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.LPM = null;
    }
    if (this.state.leadFilterInput.EDD) {
      leadFilterData.EDD =
        moment(this.state.leadFilterInput.EDD[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.EDD[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.EDD = null;
    }
    leadFilterData.CosultingDoctor =
      this.state.leadFilterInput.CosultingDoctor || 0;
    leadFilterData.LeadStatus = this.state.leadFilterInput.LeadStatus || 0;
    leadFilterData.LeadSubStatus =
      this.state.leadFilterInput.LeadSubStatus || 0;
    leadFilterData.AssignTo = this.state.leadFilterInput.AssignTo || null;
    leadFilterData.EnquiryType = this.state.leadFilterInput.EnquiryType || 0;
    leadFilterData.EnquirySubType =
      this.state.leadFilterInput.EnquirySubType || 0;
    leadFilterData.LeadSource = this.state.leadFilterInput.LeadSource || 0;
    leadFilterData.CashInsurance =
      this.state.leadFilterInput.CashInsurance || null;
    leadFilterData.InsuranceProvider1 =
      this.state.leadFilterInput.InsuranceProvider1 || null;
    leadFilterData.TPA = this.state.leadFilterInput.TPA || null;
    leadFilterData.SecondaryInsuranceProvider =
      this.state.leadFilterInput.SecondaryInsuranceProvider || null;
    leadFilterData.Packageoffered =
      this.state.leadFilterInput.Packageoffered || null;
    leadFilterData.PageFormName =
      this.state.leadFilterInput.PageFormName || null;
    leadFilterData.LeadCreatedBy =
      this.state.leadFilterInput.LeadCreatedBy || 0;
    leadFilterData.LeadsResponseFailed =
      this.state.leadFilterInput.LeadsResponseFailed || null;
    leadFilterData.LeadsResolutionFailed =
      this.state.leadFilterInput.LeadsResolutionFailed || null;
    leadFilterData.TaskType = this.state.leadFilterInput.TaskType || 0;
    leadFilterData.CreatedBy = this.state.leadFilterInput.CreatedBy || 0;
    leadFilterData.SearchText = null;
    leadFilterData.LeadModifiedBy =
      this.state.leadFilterInput.LeadModifiedBy || 0;
    if (this.state.leadFilterInput.TaskDate) {
      leadFilterData.TaskDate =
        moment(this.state.leadFilterInput.TaskDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.TaskDate[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.TaskDate = null;
    }

    if (this.state.leadFilterInput.LeadResolvedDate) {
      leadFilterData.LeadResolvedDate =
        moment(this.state.leadFilterInput.LeadResolvedDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.LeadResolvedDate[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.LeadResolvedDate = null;
    }
    if (this.state.leadFilterInput.AppointmentDate) {
      leadFilterData.AppointmentDate =
        moment(this.state.leadFilterInput.AppointmentDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        moment(this.state.leadFilterInput.AppointmentDate[1])
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.AppointmentDate = null;
    }
    if (this.state.leadFilterInput.LeadCreateDate) {
      leadFilterData.LeadCreateDate =
        moment(this.state.leadFilterInput.LeadCreateDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        this.state.leadFilterInput.LeadCreateDate[1]
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.LeadCreateDate = null;
    }

    if (this.state.leadFilterInput.LeadModifiedDate) {
      leadFilterData.LeadModifiedDate =
        moment(this.state.leadFilterInput.LeadModifiedDate[0])
          .format("YYYY-MM-DD")
          .toString() +
        "to" +
        this.state.leadFilterInput.LeadModifiedDate[1]
          .format("YYYY-MM-DD")
          .toString();
    } else {
      leadFilterData.LeadModifiedDate = null;
    }
    //var listIDsList = [];
    //if (!this.state.isSelectAll) {
    //    if (this.state.selectedRowKeys) {
    //        this.state.selectedRowKeys.forEach((element) => {
    //            var obj = {};
    //            obj.ListIDs = element;
    //            listIDsList.push(obj);
    //        });
    //    }
    //}
    //inputData.ListIDsList = listIDsList;
    //var leadColumnNameModels =
    //    JSON.parse(localStorage.getItem("leadColumnsData")) || null;
    //var leadColumnNameModelsTemp = [];
    //if (leadColumnNameModels) {
    //    var tempData = leadColumnNameModels.filter((x) => x.addedColumn === true);
    //    tempData.forEach((element) => {
    //        var obj = {};
    //        obj.ID = element.id;
    //        obj.ColumnName = element.columnName;
    //        leadColumnNameModelsTemp.push(obj);
    //    });
    //}
    //inputData.leadColumnNameModels = leadColumnNameModelsTemp;
    inputData.leadfilter = leadFilterData;
    try {
      this.LeadsService.exportToExcel("ExportLeadDetails", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var LeadsRawReportData = response.responseData;
          if (statusCode === 200) {
            const link = document.createElement("a");
            link.href = LeadsRawReportData;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
              selectedRowKeys: [],
              isSelectAll: false,
            });
          } else {
            NotificationManager.error("File not download.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleCheckBoxSelectAll = (selected) => {
    
    this.setState({
      isSelectAll: selected,
    });
  };
  handleLeadAdditionalDetailsChange = async (name, e) => {
    var leadAddDetailInput = this.state.leadAddDetailInput;
    if (e.target) {
      leadAddDetailInput[name] = e.target.value;
    } else {
      if (name === "InsuranceDate") {
        leadAddDetailInput[name] = moment(e).format("YYYY-MM-DD").toString();
      } else if (name === "AppointmentDateTime") {
        leadAddDetailInput[name] = e;
      } else if (name === "PreferredDateTimeToContact") {
        leadAddDetailInput[name] = e;
      } else {
        leadAddDetailInput[name] = e;
      }
    }
    await this.setState({});
  };

  disabledDOBDate = (current) => {
    return moment(current) > moment().endOf("day");
  };
  handleRedirecttoDetails = (item) => {
    if (this.state.leadFilterInput) {
      localStorage.setItem(
        "leadFilterInput",
        JSON.stringify(this.state.leadFilterInput)
      );
    }
    this.props.history.push({
      pathname: "LeadDetails",
      state: {
        leadDeatils: item,
        leadStatusData: this.state.leadStatusData,
        leadTypeData: this.state.leadTypeData,
        leadAssignToData: this.state.leadAssignToData,
        enquiryTypeData: this.state.enquiryTypeData,
        leadSourceData: this.state.leadSourceData,
        TaskTypeList: this.state.taskTypeList,
        InsuranceProviderData: this.state.InsuranceProviderData,
        isFilterSearch: this.state.isFilterSearch,
        isFilter: this.state.isFilter,
        RequestedServiceData: this.state.RequestedServiceData,
      },
    });
  };

  handleGetTask = async (leadDetail, disableTask, e) => {
    
    try {
      var inputData = {};
      inputData.IDs = leadDetail.leadID.toString();
      inputData.PageNo = 1;
      inputData.PageSize = 10000000;
      this.setState({
        isTaskLoading: true,
      });
      if (disableTask) {
        this.LeadsService.getLeadDetailsTab("GetLeadTaskByLeadID", inputData)
          .then((response) => {
            
            var statusCode = response.statusCode;
            var Task = response.responseData.taskDetails;
            var taskData = [];
            if (statusCode === 200) {
              if (Task.length > 0) {
                Task.map((item) => {
                  if (item.taskStatus.toLowerCase() === "planned") {
                    taskData.push(item);
                  }
                });
                this.setState({
                  selectedLeadDetails: leadDetail,
                  selectedLeadStatus: e.target.value,
                  isStatusClick: true,
                  QueryLead: false,
                  createTask: false,
                  OpenTask: true,
                  taskLeadID: leadDetail.leadID,
                  Task: taskData,
                  isTaskLoading: false,
                });
              } else {
                this.setState({
                  selectedLeadDetails: leadDetail,
                  selectedLeadStatus: e.target.value,
                  isStatusClick: true,
                  QueryLead: true,
                  OpenTask: false,
                  taskLeadID: leadDetail.leadID,
                  Task: [],
                  isTaskLoading: false,
                });
              }
            } else {
              this.setState({
                Task: [],
                isTaskLoading: false,
                selectedLeadDetails: leadDetail,
                selectedLeadStatus: e.target.value,
                isStatusClick: true,
                QueryLead: true,
                OpenTask: false,
                taskLeadID: leadDetail.leadID,
                createTask: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.setState({
          selectedLeadStatus: e.target.value,
          selectedLeadDetails: leadDetail,
          createTask: true,
          OpenTask: false,
          taskLeadID: leadDetail.leadID,
          Task: [],
          isTaskLoading: false,
          QueryLead: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleOpenTask = () => {
    this.setState({
      isTaskOpen: true,
    });
  };

  handleEditTask = (details) => {
    
    this.setState({
      createTask: true,
      isEditTask: true,
      editTaskDetails: details,
    });
  };

  handleTaskClose = () => {
    this.setState({
      OpenTask: false,
    });
  };

  render() {
    const { selectedRowKeys, tab } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      onSelectAll: this.handleCheckBoxSelectAll,
    };
    const finalCol = [];
    let setColumns = new Set(this.state.Columns);
    setColumns.forEach((element1) => {
      finalCol.push(
        this.state.leadColumns.filter((x) => x.key === element1)[0]
      );
    });

    // const hasSelected = selectedRowKeys.length > 0;
    return (
      <div className="leadsmain">
        <div className="lead">
          <div className="leftleft">
            <h4>
              Leads
              {this.state.createAccess && (
                <img
                  src={AddIcon}
                  alt="add"
                  onClick={this.handleCreateLeadModalOpen.bind(this)}
                />
              )}
              {this.state.downloadAccess &&
              this.state.selectedRowKeys.length > 0 ? (
                <img
                  src={DownloadIcon}
                  alt="download"
                  onClick={this.handleExportLead.bind(this)}
                />
              ) : null}
              {this.state.downloadAccess &&
              this.state.selectedRowKeys.length > 0 ? (
                //<img
                //    src={LeadIcon}
                //    alt="Assign/Reassign"
                //    onClick={this.handleAssignModalOpen.bind(this)}
                ///>
                <label onClick={this.handleAssignModalOpen.bind(this)}>
                  <h6>Assign/Reassign</h6>
                </label>
              ) : null}
            </h4>
          </div>
          <ul className="leadright">
            {this.state.viewAccess && (
              <>
                <li>
                  <div className="rad">
                    <label>
                      <input
                        type="radio"
                        name="light"
                        checked={this.state.listview}
                        onChange={this.handleRedioButtonChange.bind(
                          this,
                          "listview"
                        )}
                      />
                      <span className="design"></span>
                      <span className="text">List View</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div className="rad">
                    <label>
                      <input
                        type="radio"
                        name="light"
                        checked={this.state.cardView}
                        onChange={this.handleRedioButtonChange.bind(
                          this,
                          "cardview"
                        )}
                      />
                      <span className="design"></span>
                      <span className="text">Card View</span>
                    </label>
                  </div>
                </li>
              </>
            )}
            {/* <li>
              <img src={TopDownIcon} alt="TopDown" />
            </li> */}

            <LeadFilterColumnList
              handleCallBackToList={this.handleCallBackToList}
            />

            <li>
              <img
                src={FilterIcon}
                alt="Filter"
                onClick={this.handleFilterClick.bind(this)}
              />
            </li>
            {/* <li>
              <img src={DownloadIcon} alt="Download" />
            </li> */}
          </ul>
        </div>
        {this.state.isFilter ? (
          <div className="row m-0 mt-3 addlead">
            <div className="col-md-12 filter customertab">
              <div className="row">
                <div className="col-6 col-md-6">
                  <h5>Filter</h5>
                </div>
                <div className="col-6 col-md-6" style={{ textAlign: "right" }}>
                  {/*{this.state.isFilterSearch ? (
                   <Button type="link">
                     Results :- {this.state.filterCount}
                   </Button>
                  ) : null}*/}
                  {this.state.isFilterSearch ? (
                    <Button
                      onClick={this.handleClearSearch.bind(this)}
                      style={{ marginRight: "10px" }}
                    >
                      Clear Search
                    </Button>
                  ) : null}
                  <Button
                    type="primary"
                    disabled={this.state.isLoading}
                    loading={this.state.isLoading}
                    onClick={this.handleFilterSearchList.bind(this, true)}
                  >
                    Search
                  </Button>
                </div>
              </div>

              <div className="mainFiter">
                <div className="row ">
                  <div className="col-md-3">
                    <label>Lead ID</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Lead ID"
                      name="LeadID"
                      value={this.state.leadFilterInput.LeadID || ""}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadID"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Email ID</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Email ID"
                      name="Email"
                      value={this.state.leadFilterInput.Email || ""}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "Email"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Mobile Number"
                      name="PhoneNo"
                      value={this.state.leadFilterInput.PhoneNo || ""}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "PhoneNo"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Alternative Mobile Number</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Alternative Mobile Number"
                      name="AlternatePhoneNo"
                      value={this.state.leadFilterInput.AlternatePhoneNo || ""}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "AlternatePhoneNo"
                      )}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <label>Lead Type</label>
                    <Select
                      placeholder="Lead Type"
                      value={this.state.leadFilterInput.LeadType || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadType"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.leadTypeData
                        ? this.state.leadTypeData.map((item) => {
                            return (
                              <Option value={item.leadTypeID}>
                                {item.leadTypeName}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </div>
                  <div className="col-md-3">
                    <label>Gender</label>
                    <Select
                      placeholder="Gender"
                      value={this.state.leadFilterInput.Gender || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "Gender"
                      )}
                    >
                      <Option value={1}>Male</Option>
                      <Option value={2}>Female</Option>
                      <Option value={3}>Other</Option>
                    </Select>
                  </div>
                  <div className="col-md-3">
                    <label>Age in years</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Age in years"
                      name="Age"
                      value={this.state.leadFilterInput.Age || ""}
                      onChange={this.handleFilterInputChange.bind(this, "Age")}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Employer Name</label>
                    <Select
                      placeholder="Employer Name"
                      value={this.state.leadFilterInput.EmployerName || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "EmployerName"
                      )}
                    ></Select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Occupation</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Enter Occupation"
                      value={this.state.leadFilterInput.Occupation || ""}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "Occupation"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>State</label>
                    <Select
                      defaultValue={0}
                      placeholder="State"
                      value={this.state.leadFilterInput.State || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "State"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.stateData &&
                        this.state.stateData.map((item) => (
                          <Option value={item.stateID}>{item.stateName}</Option>
                        ))}
                    </Select>
                  </div>
                  <div className="col-md-3">
                    <label>City</label>
                    <Select
                      defaultValue={0}
                      placeholder="City"
                      value={this.state.leadFilterInput.City || null}
                      onChange={this.handleFilterInputChange.bind(this, "City")}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.cityData &&
                        this.state.cityData.map((item) => (
                          <Option value={item.cityID}>{item.cityName}</Option>
                        ))}
                    </Select>
                  </div>
                  <div className="col-md-3">
                    <label>Locality</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="LeadLocality"
                      value={this.state.leadFilterInput.LeadLocality || ""}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadLocality"
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Unit Name</label>
                    <Select
                      defaultValue={0}
                      placeholder="Unit name"
                      value={this.state.leadFilterInput.Unit || null}
                      onChange={this.handleFilterInputChange.bind(this, "Unit")}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.leadLocationData &&
                        this.state.leadLocationData.map((item) => (
                          <Option value={item.locationID}>
                            {item.locationName}
                          </Option>
                        ))}
                    </Select>
                  </div>

                  <div className="col-md-3">
                    <label>LMP</label>

                    <RangePicker
                      value={this.state.leadFilterInput["LPM"]}
                      onChange={this.handleFilterInputChange.bind(this, "LPM")}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>EDD</label>

                    <RangePicker
                      value={this.state.leadFilterInput["EDD"]}
                      onChange={this.handleFilterInputChange.bind(this, "EDD")}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Consulting Doctor</label>
                    <Select
                      defaultValue={0}
                      placeholder="Consulting Doctor"
                      value={
                        this.state.leadFilterInput["CosultingDoctor"] || null
                      }
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "CosultingDoctor"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.leadDoctorsData &&
                        this.state.leadDoctorsData.map((item) => (
                          <Option value={item.doctorID}>
                            {item.doctorName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Lead Status</label>
                    <Select
                      defaultValue={0}
                      placeholder="Lead Status"
                      value={this.state.leadFilterInput["LeadStatus"] || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadStatus"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.leadStatusData &&
                        this.state.leadStatusData.map((item) => (
                          <Option value={item.leadStatusID}>
                            {item.statusName}
                          </Option>
                        ))}
                    </Select>
                  </div>

                  <div className="col-md-3">
                    <label>Assign To</label>
                    <Select
                      defaultValue={0}
                      placeholder="Assign To"
                      value={this.state.leadFilterInput["AssignTo"] || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "AssignTo"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.leadAssignToData &&
                        this.state.leadAssignToData.map((item) => (
                          <Option
                            value={item.userID + "-" + item.isTeamUser}
                            isTeamUser={item.isTeamUser}
                          >
                            {item.fullName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div className="col-md-3">
                    <label>Enquiry Type</label>
                    <Select
                      defaultValue={0}
                      placeholder="Enquiry Type"
                      value={this.state.leadFilterInput["EnquiryType"] || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "EnquiryType"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.enquiryTypeData &&
                        this.state.enquiryTypeData.map((item) => (
                          <Option value={item.enquiryTypeID}>
                            {item.enquiryTypeName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div className="col-md-3">
                    <label>Enquiry Sub Type</label>
                    <Select
                      defaultValue={0}
                      placeholder="Enquiry Sub Type"
                      value={
                        this.state.leadFilterInput["EnquirySubType"] || null
                      }
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "EnquirySubType"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.enquirySubTypeData &&
                        this.state.enquirySubTypeData.map((item) => (
                          <Option value={item.enquirySubTypeID}>
                            {item.enquirySubTypeName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Lead Source</label>
                    <Select
                      defaultValue={0}
                      placeholder="Lead Source"
                      value={this.state.leadFilterInput["LeadSource"] || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadSource"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.leadSourceData &&
                        this.state.leadSourceData.map((item) => (
                          <Option value={item.sourceID}>
                            {item.sourceName}
                          </Option>
                        ))}
                    </Select>
                  </div>

                  <div className="col-md-3">
                    <label>Cash/Insurance</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Cash/Insurance"
                      value={this.state.leadFilterInput["CashInsurance"] || ""}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "CashInsurance"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Pre Insurance Provider</label>
                    <Select placeholder="Pre Insurance Provider"></Select>
                  </div>
                  <div className="col-md-3">
                    <label>TPA</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Enter TPA"
                      value={this.state.leadFilterInput["TPA"] || ""}
                      onChange={this.handleFilterInputChange.bind(this, "TPA")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Secondary Insurance Provider</label>
                    <Select placeholder="Secondary Insurance Provider"></Select>
                  </div>

                  <div className="col-md-3">
                    <label>Package offered</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Package offered"
                      value={this.state.leadFilterInput["Packageoffered"] || ""}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "Packageoffered"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Page/Form Name</label>
                    <input
                      type="text"
                      className="mobileinput"
                      placeholder="Page/Form Name"
                      value={this.state.leadFilterInput["PageFormName"] || ""}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "PageFormName"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Appointment Date</label>
                    <RangePicker
                      value={this.state.leadFilterInput["AppointmentDate"]}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "AppointmentDate"
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Lead Created By</label>
                    <Select
                      placeholder="Lead Created By"
                      value={
                        this.state.leadFilterInput["LeadCreatedBy"] || null
                      }
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadCreatedBy"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.userData &&
                        this.state.userData.map((item) => (
                          <Option value={item.userID}>{item.userName}</Option>
                        ))}
                    </Select>
                  </div>

                  <div className="col-md-3">
                    <label>Lead Create Date</label>
                    <RangePicker
                      value={this.state.leadFilterInput["LeadCreateDate"]}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadCreateDate"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Lead Resolved Date</label>
                    <RangePicker
                      value={this.state.leadFilterInput["LeadResolvedDate"]}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadResolvedDate"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Leads Response Failed</label>
                    <Select
                      placeholder="Leads Response Failed"
                      value={
                        this.state.leadFilterInput["LeadsResponseFailed"] ||
                        null
                      }
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadsResponseFailed"
                      )}
                    >
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Leads Resolution Failed</label>
                    <Select
                      placeholder="Leads Resolution Failed"
                      value={
                        this.state.leadFilterInput["LeadsResolutionFailed"] ||
                        null
                      }
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadsResolutionFailed"
                      )}
                    >
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </div>

                  <div className="col-md-3">
                    <label>Task Type</label>
                    <Select
                      defaultValue={0}
                      placeholder="Task Type"
                      value={this.state.leadFilterInput["TaskType"] || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "TaskType"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.taskTypeList
                        ? this.state.taskTypeList.map((item) => {
                            return (
                              <Option value={item.taskTypeID}>
                                {item.taskName}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </div>
                  <div className="col-md-3">
                    <label>Task Date</label>
                    <RangePicker
                      value={this.state.leadFilterInput["TaskDate"] || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "TaskDate"
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Task Status</label>
                    <Select
                      defaultValue={0}
                      placeholder="Task Status"
                      value={this.state.leadFilterInput["TaskStatus"] || null}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "TaskStatus"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.taskStatusData
                        ? this.state.taskStatusData.map((item) => {
                            return (
                              <Option value={item.taskStatusID}>
                                {item.taskStatus}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Lead Modified By</label>
                    <Select
                      placeholder="Lead Modified By"
                      value={
                        this.state.leadFilterInput["LeadModifiedBy"] || null
                      }
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadModifiedBy"
                      )}
                    >
                      <Option value={0}>Select</Option>
                      {this.state.userData &&
                        this.state.userData.map((item) => (
                          <Option value={item.userID}>{item.userName}</Option>
                        ))}
                    </Select>
                  </div>

                  <div className="col-md-3">
                    <label>Lead Modified Date</label>
                    <RangePicker
                      value={this.state.leadFilterInput["LeadModifiedDate"]}
                      onChange={this.handleFilterInputChange.bind(
                        this,
                        "LeadModifiedDate"
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.viewAccess && this.state.listview && (
          <div className="LeadTable">
            <div>
              <label style={{ color: "#1890ff" }}>
                Total Record:{this.state.totalcount ? this.state.totalcount : 0}{" "}
              </label>
            </div>
            {this.state.isColumnUpdate ? (
              <Table
                scroll={true}
                rowKey={(record) => record.leadID}
                loading={this.state.isLoading}
                rowSelection={rowSelection}
                columns={finalCol}
                expandedRowRender={(row) => {
                  return <div>Hello</div>;
                }}
                expandIcon={({ expanded, onExpand, record }) =>
                  expanded ? (
                    <div className="expandown1">
                      <img
                        alt="CircleBackIcon"
                        src={CircleBackIcon}
                        onClick={(e) => onExpand(record, e)}
                      />
                    </div>
                  ) : (
                    <div className="expandown">
                      <img
                        alt="CircleBackIcon"
                        src={CircleBackIcon}
                        onClick={(e) => onExpand(record, e)}
                      />
                    </div>
                  )
                }
                expandIconColumnIndex={this.state.mobileView ? 8 : -1}
                expandIconAsCell={false}
                dataSource={this.state.leadListGridData}
                pagination={{
                  responsive: true,
                  current: this.state.pageNo,
                  pageSize: this.state.pageSize,
                  pageSizeOptions: [10, 20, 50, 100],
                  showSizeChanger: true,
                  total: this.state.totalcount,
                  onChange: this.handlePageNoChange.bind(this),
                  onShowSizeChange: this.handlePageSizeChange.bind(this),
                }}
              />
            ) : null}
          </div>
        )}

        {this.state.viewAccess &&
          (this.state.cardView ? (
            <Fragment>
              <div>
                <Spin spinning={this.state.isLoading}>
                  <div className="row m-0 lead-card-view">
                    {this.state.leadListGridData ? (
                      this.state.leadListGridData.map((item) => {
                        return (
                          <div className="cards" key={item.leadID}>
                            <Link
                              to={{
                                pathname: "LeadDetails",
                                state: {
                                  leadDeatils: item,
                                  leadStatusData: this.state.leadStatusData,
                                  leadTypeData: this.state.leadTypeData,
                                  leadAssignToData: this.state.leadAssignToData,
                                  enquiryTypeData: this.state.enquiryTypeData,
                                  leadSourceData: this.state.leadSourceData,
                                  TaskTypeList: this.state.TaskTypeList,
                                  isCardView: true,
                                  InsuranceProviderData: this.state
                                    .InsuranceProviderData,
                                  isFilterSearch: this.state.isFilterSearch,
                                  isFilter: this.state.isFilter,
                                  leadFilterInput: this.state.leadFilterInput,
                                },
                              }}
                              className="leadid"
                            >
                              Lead ID - {item.leadUID}
                            </Link>
                            <div className="leada">
                              <label className="leadate">
                                Lead Created Date - {item.createdDate}
                              </label>
                              <label className="leadate1">
                                Updated By - {item.modifieByName || ""}
                                {" , "}
                                {item.modifiedDate}
                              </label>
                              <label className="leadate2">
                                {item.stageName}
                              </label>
                            </div>
                            <div className="cads">
                              <div className="card">
                                <label className="custinfo">
                                  Customer Info
                                </label>
                                <div className="usnam1">
                                  <label>Name</label>:
                                  <label>{item.customerName}</label>
                                </div>
                                <div className="usnam1">
                                  <label>Mobile</label>:
                                  <label>{item.phoneNo}</label>
                                </div>
                                <div className="usnam1">
                                  <label>Age</label>:<label>{item.age}</label>
                                </div>
                                <div className="usnam1">
                                  <label>Email</label>:
                                  <label>{item.email}</label>
                                </div>
                                <div className="usnam1">
                                  <label>Address</label>:
                                  <label>{item.address}</label>
                                </div>
                              </div>
                              <div className="card">
                                <label className="custinfo">Lead Info</label>
                                <div className="usnam">
                                  <label>Enquiry Type</label>:
                                  <label>{item.enquiryTypeName}</label>
                                </div>
                                <div className="usnam">
                                  <label>Enquiry Sub Type</label>:
                                  <label>{item.enquirySubTypeName}</label>
                                </div>
                                <div className="usnam">
                                  <label>Enquiry Source</label>:
                                  <label>{item.sourceName}</label>
                                </div>
                                <div className="usnam">
                                  <label>Page/Form Name</label>:
                                  <label>{item.pageFormName}</label>
                                </div>
                                <div className="usnam">
                                  <label>Sub Status</label>:
                                  <label>{item.subStatusName}</label>
                                </div>
                                <div className="usnam">
                                  <label>Assign To</label>:
                                  <label>{item.assignToName}</label>
                                </div>
                                <div className="usnam">
                                  <label>Notes</label>:
                                  <label>{item.notes || ""}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </div>
                </Spin>
              </div>

              <div className="lead-card-page">
                {this.state.leadListGridData ? (
                  <Pagination
                    defaultCurrent={1}
                    total={this.state.totalcount}
                    pageSizeOptions={[10, 20, 30]}
                    responsive={true}
                    showSizeChanger={true}
                    current={this.state.pageNo}
                    pageSize={this.state.pageSize}
                    onChange={this.handlePageNoChange.bind(this)}
                    onShowSizeChange={this.handlePageSizeChange.bind(this)}
                  />
                ) : null}
              </div>
            </Fragment>
          ) : null)}

        {/* Create Lead Modal  */}
        <Modal
          closable={false}
          title={"Create New Lead"}
          visible={this.state.createLeadModal}
          className="leadstask"
          footer={null}
        >
          <div className="addlead">
            {/*{this.state.leadSuccess ? "onesuccess" : (this.state.leadDetailsSuccess ? "twosuccess" : (this.state.leadAddDetailsSuccess ? "allsuccess" : "addlead"))}*/}
            <Tabs activeKey={tab} tabPosition="left" onChange={this.changeTab}>
              <TabPane tab={<div className="dsfds">Customer Info</div>} key="1">
                <div className="customertab">
                  {this.state.fileCreateAccess && (
                    <div className="bulk mb-3">
                      <label>Customer Info</label>
                      <label onClick={this.handleBulkUploadOpen.bind(this)}>
                        Bulk Upload
                      </label>
                    </div>
                  )}
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Mobile Number</label>
                      <input
                        className="mobileinput"
                        placeholder="Mobile Number"
                        maxLength="10"
                        value={
                          this.state.leadInput.mobile !== undefined
                            ? this.state.leadInput.mobile
                            : ""
                        }
                        onChange={this.handleInputChange.bind(this, "mobile")}
                      />
                      {/*{this.state.errors ? (*/}
                      {/*    <div className="text-danger">*/}
                      {/*        {this.state.errors["mobile"]}*/}
                      {/*    </div>*/}
                      {/*) : null}*/}
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Alternate Mobile Number</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Alternate Mobile Number"
                        maxLength="10"
                        value={
                          this.state.leadInput.altmobile !== undefined
                            ? this.state.leadInput.altmobile
                            : ""
                        }
                        onChange={this.handleInputChange.bind(
                          this,
                          "altmobile"
                        )}
                      />
                      {/*{this.state.errors ? (*/}
                      {/*    <div className="text-danger">*/}
                      {/*        {this.state.errors["altmobile"]}*/}
                      {/*    </div>*/}
                      {/*) : null}*/}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-4">
                      <label className="mobile">
                        First Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="First Name"
                        value={
                          this.state.leadInput.firstname !== undefined
                            ? this.state.leadInput.firstname
                            : ""
                        }
                        onChange={this.handleInputChange.bind(
                          this,
                          "firstname"
                        )}
                      />
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["firstname"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-4">
                      <label className="mobile">Middle Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Middle Name"
                        value={
                          this.state.leadInput.middlename !== undefined
                            ? this.state.leadInput.middlename
                            : ""
                        }
                        onChange={this.handleInputChange.bind(
                          this,
                          "middlename"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <label className="mobile">
                        Last Name
                        {/*<span style={{ color: "red" }}>*</span>*/}
                      </label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Last Name"
                        value={
                          this.state.leadInput.lastname !== undefined
                            ? this.state.leadInput.lastname
                            : ""
                        }
                        onChange={this.handleInputChange.bind(this, "lastname")}
                      />
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["lastname"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Email</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Email"
                        value={
                          this.state.leadInput.email !== undefined
                            ? this.state.leadInput.email
                            : ""
                        }
                        onChange={this.handleInputChange.bind(this, "email")}
                      />
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["email"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Alternate Email</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Alternate Email"
                        value={
                          this.state.leadInput.altemail !== undefined
                            ? this.state.leadInput.altemail
                            : ""
                        }
                        onChange={this.handleInputChange.bind(this, "altemail")}
                      />
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["altemail"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <label className="mobile mb-1">Gender</label>
                      <Radio
                        checked={this.state.male}
                        onChange={this.handleGenderInputChange.bind(
                          this,
                          "male"
                        )}
                      >
                        Male
                      </Radio>
                      <Radio
                        checked={this.state.female}
                        onChange={this.handleGenderInputChange.bind(
                          this,
                          "female"
                        )}
                      >
                        Female
                      </Radio>
                      <Radio
                        checked={this.state.other}
                        onChange={this.handleGenderInputChange.bind(
                          this,
                          "other"
                        )}
                      >
                        Other
                      </Radio>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Date of Birth</label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Date of Birth"
                        onChange={this.handleDateOnChange.bind(this, "dob")}
                        disabledDate={this.disabledDOBDate.bind(this)}
                      />
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["dob"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-2">
                      <label className="mobile">Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Years"
                        value={
                          this.state.leadInput.ageyear !== undefined
                            ? this.state.leadInput.ageyear
                            : ""
                        }
                        onChange={this.handleInputChange.bind(this, "ageyear")}
                      />
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["ageyear"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-2">
                      <label style={{ visibility: "hidden" }}>Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Months"
                        value={
                          this.state.leadInput.agemonth !== undefined
                            ? this.state.leadInput.agemonth
                            : ""
                        }
                        onChange={this.handleInputChange.bind(this, "agemonth")}
                      />
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["agemonth"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-2">
                      <label style={{ visibility: "hidden" }}>Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Days"
                        value={
                          this.state.leadInput.agedays !== undefined
                            ? this.state.leadInput.agedays
                            : ""
                        }
                        onChange={this.handleInputChange.bind(this, "agedays")}
                      />
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["agedays"]}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Anniversary Date</label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Anniversary Date"
                        onChange={this.handleDateOnChange.bind(
                          this,
                          "anniversarydate"
                        )}
                        disabledDate={this.disabledDOBDate.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Address</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Address"
                        value={
                          this.state.leadInput.address !== undefined
                            ? this.state.leadInput.address
                            : ""
                        }
                        onChange={this.handleInputChange.bind(this, "address")}
                      />
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["address"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">State</label>
                      <Select
                        placeholder="Select State"
                        value={this.state.leadInput.state}
                        name="stateId"
                        onChange={this.handleSelectInputChange.bind(
                          this,
                          "state"
                        )}
                      >
                        {this.state.stateData &&
                          this.state.stateData.map((item) => (
                            <Option value={item.stateID}>
                              {item.stateName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["state"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">City</label>
                      <Select
                        placeholder="Select City"
                        value={this.state.leadInput.city}
                        name="cityId"
                        onChange={this.handleSelectInputChange.bind(
                          this,
                          "city"
                        )}
                      >
                        {this.state.cityData &&
                          this.state.cityData.map((item) => (
                            <Option value={item.cityID}>{item.cityName}</Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["city"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-6"></div>
                    <div className="col-12 col-md-6">
                      <div className="casane">
                        <Button
                          type="button"
                          onClick={this.handleCreateLeadModalClose.bind(this)}
                        >
                          Cancel
                        </Button>
                        {/*<button type="button">Save & New</button>*/}
                        <Button
                          type="primary"
                          onClick={this.handleNextLead.bind(this, "2")}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={<div>Lead Detail</div>} key="2">
                <div className="customertab">
                  {this.state.fileCreateAccess && (
                    <div className="bulk mb-3">
                      <label>Lead Detail</label>
                      <label onClick={this.handleBulkUploadOpen.bind(this)}>
                        Bulk Upload
                      </label>
                    </div>
                  )}
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Source<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Source"
                        value={this.state.leadDetailsInput.source}
                        name="source"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "source"
                        )}
                      >
                        {this.state.leadSourceData &&
                          this.state.leadSourceData.map((item) => (
                            <Option value={item.sourceID}>
                              {item.sourceName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["source"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Type"
                        value={this.state.leadDetailsInput.type}
                        name="type"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "type"
                        )}
                      >
                        {this.state.leadTypeData &&
                          this.state.leadTypeData.map((item) => (
                            <Option value={item.leadTypeID}>
                              {item.leadTypeName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["type"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Enquiry Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Enquiry Type"
                        value={this.state.leadDetailsInput.category}
                        name="category"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "category"
                        )}
                      >
                        {this.state.enquiryTypeData &&
                          this.state.enquiryTypeData.map((item) => (
                            <Option value={item.enquiryTypeID}>
                              {item.enquiryTypeName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["category"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Sub Enquiry Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Sub Enquiry Type"
                        value={this.state.leadDetailsInput.subcategory}
                        name="subcategory"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "subcategory"
                        )}
                      >
                        {this.state.enquirySubTypeData &&
                          this.state.enquirySubTypeData.map((item) => (
                            <Option value={item.enquirySubTypeID}>
                              {item.enquirySubTypeName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["subcategory"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Priority<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Priority"
                        value={this.state.leadDetailsInput.priority}
                        name="priority"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "priority"
                        )}
                      >
                        {this.state.priorityData &&
                          this.state.priorityData.map((item, key) => (
                            <Option value={item.priorityID}>
                              {item.priorityName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["priority"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">LMP</label>
                      {/*<input*/}
                      {/*  type="text"*/}
                      {/*  className="mobileinput"*/}
                      {/*  placeholder="Calculate LMD"*/}
                      {/*  value={this.state.leadDetailsInput.lmd}*/}
                      {/*  onChange={this.handleLeadDetailsInputChange.bind(*/}
                      {/*    this,*/}
                      {/*    "lmd"*/}
                      {/*  )}*/}
                      {/*/>*/}
                      <DatePicker
                        className="mobileinput"
                        placeholder="LMP"
                        value={
                          this.state.leadDetailsInput.lmd !== undefined &&
                          this.state.leadDetailsInput.lmd !== ""
                            ? moment(
                                this.state.leadDetailsInput.lmd,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        onChange={this.handleDateOnChange.bind(this, "lmp")}
                        disabledDate={disabledDate}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">EDD</label>
                      {/*<input*/}
                      {/*  type="text"*/}
                      {/*  className="mobileinput"*/}
                      {/*  placeholder="Calculate EDD"*/}
                      {/*  value={this.state.leadDetailsInput.edd}*/}
                      {/*  onChange={this.handleLeadDetailsInputChange.bind(*/}
                      {/*    this,*/}
                      {/*    "edd"*/}
                      {/*  )}*/}
                      {/*/>*/}
                      <DatePicker
                        className="mobileinput"
                        placeholder="EDD"
                        value={
                          this.state.leadDetailsInput.edd !== undefined &&
                          this.state.leadDetailsInput.edd !== ""
                            ? moment(
                                this.state.leadDetailsInput.edd,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        onChange={this.handleDateOnChange.bind(this, "edd")}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Unit<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Unit"
                        value={this.state.leadDetailsInput.unit}
                        name="unit"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "unit"
                        )}
                      >
                        {this.state.leadLocationData &&
                          this.state.leadLocationData.map((item) => (
                            <Option value={item.locationID}>
                              {item.locationName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["unit"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Doctor</label>
                      <Select
                        placeholder="Select Doctor"
                        value={this.state.leadDetailsInput.doctor}
                        name="doctor"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "doctor"
                        )}
                      >
                        {this.state.leadDoctorsData &&
                          this.state.leadDoctorsData.map((item) => (
                            <Option value={item.doctorID}>
                              {item.doctorName}
                            </Option>
                          ))}
                      </Select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Status<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Status"
                        value={this.state.leadDetailsInput.status}
                        name="status"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "status"
                        )}
                      >
                        {this.state.leadStatusData &&
                          this.state.leadStatusData.map((item) => (
                            <Option value={item.leadStatusID}>
                              {item.statusName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["status"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Sub Status<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Sub Status"
                        value={this.state.leadDetailsInput.substatus}
                        name="substatus"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "substatus"
                        )}
                      >
                        {this.state.leadSubStatusData &&
                          this.state.leadSubStatusData.map((item) => (
                            <Option value={item.leadSubStatusID}>
                              {item.subStatusName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["substatus"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-12">
                      <label className="mobile">
                        Assign To<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select Assign To"
                        value={this.state.leadDetailsInput.assignto}
                        name="assignto"
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "assignto"
                        )}
                      >
                        {this.state.leadAssignToData &&
                          this.state.leadAssignToData.map((item) => (
                            <Option
                              value={item.userID + "-" + item.isTeamUser}
                              isTeamUser={item.isTeamUser}
                            >
                              {item.fullName}
                            </Option>
                          ))}
                      </Select>
                      {this.state.errors ? (
                        <div className="text-danger">
                          {this.state.errors["assignto"]}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Remark</label>
                      <CKEditor
                        config={{
                          toolbar: [
                            {
                              name: "basicstyles",
                              items: ["Bold", "Italic", "Strike"],
                            },
                            {
                              name: "styles",
                              items: ["Styles", "Format"],
                            },
                            {
                              name: "paragraph",
                              items: ["NumberedList", "BulletedList"],
                            },
                            {
                              name: "links",
                              items: ["Link", "Unlink"],
                            },
                            {
                              name: "insert",
                              items: ["Image", "Table"],
                            },
                            {
                              name: "tools",
                              items: ["Maximize"],
                            },
                            {
                              name: "editing",
                              items: ["Scayt"],
                            },
                          ],
                          autoParagraph: false,
                        }}
                        data={
                          this.state.leadDetailsInput.editor !== undefined
                            ? this.state.leadDetailsInput.editor
                            : ""
                        }
                        value={
                          this.state.leadDetailsInput.editor !== undefined
                            ? this.state.leadDetailsInput.editor
                            : ""
                        }
                        onChange={this.handleLeadDetailsInputChange.bind(
                          this,
                          "editor"
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-6"></div>
                    <div className="col-12 col-md-6">
                      <div className="casane">
                        <Button
                          type="button"
                          onClick={this.handleCreateLeadModalClose.bind(this)}
                        >
                          Cancel
                        </Button>
                        {/*<button type="button">Save & New</button>*/}
                        <Button
                          type="primary"
                          onClick={this.handleNextLead.bind(this, "3")}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={<div>Leads Additional Details</div>} key="3">
                <div className="customertab">
                  {this.state.fileCreateAccess && (
                    <div className="bulk mb-3">
                      <label>Leads Additional Details</label>
                      <label onClick={this.handleBulkUploadOpen.bind(this)}>
                        Bulk Upload
                      </label>
                    </div>
                  )}
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Gestational Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Gestational Age"
                        value={
                          this.state.leadAddDetailInput.gestationalage !==
                          undefined
                            ? this.state.leadAddDetailInput.gestationalage
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "gestationalage"
                        )}
                        disabled={true}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Insurance Provider Name</label>
                      <Select
                        defaultValue="Click to Select"
                        name="insuranceprovider"
                        value={this.state.leadAddDetailInput.insuranceprovider}
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "insuranceprovider"
                        )}
                      >
                        <Option value={0}>Select Provider</Option>
                        {this.state.InsuranceProviderData &&
                          this.state.InsuranceProviderData.map((item) => (
                            <Option value={item.providerID}>
                              {item.providerName}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Insurance Policy No.</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Policy No."
                        value={
                          this.state.leadAddDetailInput.insurancepolicyno !==
                          undefined
                            ? this.state.leadAddDetailInput.insurancepolicyno
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "insurancepolicyno"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Preauthorization Code</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Preauthorization Code"
                        value={
                          this.state.leadAddDetailInput.preauthorizationCode !==
                          undefined
                            ? this.state.leadAddDetailInput.preauthorizationCode
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "preauthorizationCode"
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Preferred Date & Time to Contact
                      </label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Select Date"
                        format="DD-MM-YYYY HH:mm A"
                        disabledDate={disabledDatef}
                        value={
                          this.state.leadAddDetailInput
                            .PreferredDateTimeToContact
                            ? moment(
                                this.state.leadAddDetailInput
                                  .PreferredDateTimeToContact
                              )
                            : null
                        }
                        showTime={{ use12Hours: true, format: "HH:mm A" }}
                        onChange={this.handleLeadAdditionalDetailsChange.bind(
                          this,
                          "PreferredDateTimeToContact"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Employment Type</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Employment Type"
                        value={
                          this.state.leadAddDetailInput.employeetype !==
                          undefined
                            ? this.state.leadAddDetailInput.employeetype
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "employeetype"
                        )}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Company Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Company Name"
                        value={
                          this.state.leadAddDetailInput.companyname !==
                          undefined
                            ? this.state.leadAddDetailInput.companyname
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "companyname"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Customer Income</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Customer Income"
                        value={
                          this.state.leadAddDetailInput.customerincome !==
                          undefined
                            ? this.state.leadAddDetailInput.customerincome
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "customerincome"
                        )}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Occupation</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Occupation"
                        value={
                          this.state.leadAddDetailInput.occupation !== undefined
                            ? this.state.leadAddDetailInput.occupation
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "occupation"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Additional Income</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Additional Income"
                        value={
                          this.state.leadAddDetailInput.additionalincome !==
                          undefined
                            ? this.state.leadAddDetailInput.additionalincome
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "additionalincome"
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">HNI</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter HNI"
                        value={
                          this.state.leadAddDetailInput.hni !== undefined
                            ? this.state.leadAddDetailInput.hni
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "hni"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Care Taker Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Care Taker Name"
                        value={
                          this.state.leadAddDetailInput.enquiryby !== undefined
                            ? this.state.leadAddDetailInput.enquiryby
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "enquiryby"
                        )}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Care Taker relationship</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Care Taker relationship"
                        value={
                          this.state.leadAddDetailInput
                            .caretakerrelationship !== undefined
                            ? this.state.leadAddDetailInput
                                .caretakerrelationship
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "caretakerrelationship"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Care Taker Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Care Taker Age"
                        value={
                          this.state.leadAddDetailInput.caretakerage !==
                          undefined
                            ? this.state.leadAddDetailInput.caretakerage
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "caretakerage"
                        )}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Care Taker Email ID</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Care Taker Email ID"
                        value={
                          this.state.leadAddDetailInput.caretakeremailiD !==
                          undefined
                            ? this.state.leadAddDetailInput.caretakeremailiD
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "caretakeremailiD"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">TPA</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter TPA"
                        value={
                          this.state.leadAddDetailInput.tpa !== undefined
                            ? this.state.leadAddDetailInput.tpa
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "tpa"
                        )}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Page/Form Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Page/Form Name"
                        value={
                          this.state.leadAddDetailInput.pageformname !==
                          undefined
                            ? this.state.leadAddDetailInput.pageformname
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "pageformname"
                        )}
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label className="mobile">Insurance Date</label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Insurance Date"
                        value={
                          this.state.leadAddDetailInput.InsuranceDate
                            ? moment(
                                this.state.leadAddDetailInput.InsuranceDate
                              )
                            : null
                        }
                        onChange={this.handleLeadAdditionalDetailsChange.bind(
                          this,
                          "InsuranceDate"
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Cash/Insurance</label>
                      <Select
                        value={
                          this.state.leadAddDetailInput.CashOrInsurance || null
                        }
                        onChange={this.handleLeadAdditionalDetailsChange.bind(
                          this,
                          "CashOrInsurance"
                        )}
                        placeholder="Cash/Insurance"
                      >
                        <Option value="Cash">Cash</Option>
                        <Option value="Insurance">Insurance</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">MR Number</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="MR Number"
                        name="MRNumber"
                        value={this.state.leadAddDetailInput.MRNumber || null}
                        onChange={this.handleLeadAdditionalDetailsChange.bind(
                          this,
                          "MRNumber"
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Bill No</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Bill No"
                        name="BillNo"
                        value={this.state.leadAddDetailInput.BillNo || null}
                        onChange={this.handleLeadAdditionalDetailsChange.bind(
                          this,
                          "BillNo"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Appointment Date Time </label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Appointment Date Time"
                        disabledDate={disabledDatef}
                        value={
                          this.state.leadAddDetailInput.AppointmentDateTime
                            ? moment(
                                this.state.leadAddDetailInput
                                  .AppointmentDateTime
                              )
                            : null
                        }
                        format="DD-MM-YYYY HH:mm A"
                        showTime={{ use12Hours: true, format: "HH:mm A" }}
                        onChange={this.handleLeadAdditionalDetailsChange.bind(
                          this,
                          "AppointmentDateTime"
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Case Category</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Case Category"
                        value={
                          this.state.leadAddDetailInput.casecategory !==
                          undefined
                            ? this.state.leadAddDetailInput.casecategory
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "casecategory"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Speciality</label>
                      <Select
                        placeholder="Speciality"
                        name="speciality"
                        value={this.state.leadAddDetailInput.speciality}
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "speciality"
                        )}
                      >
                        <Option value={0}>Select Speciality</Option>
                        {this.state.SpecialityData &&
                          this.state.SpecialityData.map((item) => (
                            <Option value={item.specialityID}>
                              {item.specialityName}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Agr. Discount</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Agr. Discount"
                        value={
                          this.state.leadAddDetailInput.agrdiscount !==
                          undefined
                            ? this.state.leadAddDetailInput.agrdiscount
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "agrdiscount"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Discounted Amount</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Discounted Amount"
                        value={
                          this.state.leadAddDetailInput.discountedamount !==
                          undefined
                            ? this.state.leadAddDetailInput.discountedamount
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "discountedamount"
                        )}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Enquiry Keywords</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enquiry Keywords"
                        value={
                          this.state.leadAddDetailInput.enquirykeywords !==
                          undefined
                            ? this.state.leadAddDetailInput.enquirykeywords
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "enquirykeywords"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Expected Revenue</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Expected Revenue"
                        value={
                          this.state.leadAddDetailInput.expectedrevenue !==
                          undefined
                            ? this.state.leadAddDetailInput.expectedrevenue
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "expectedrevenue"
                        )}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Requested Service</label>
                      <Select
                        placeholder="Requested Service"
                        name="requestedservice"
                        value={this.state.leadAddDetailInput.requestedservice}
                        onChange={this.handleLeadDetailsSelectInputChange.bind(
                          this,
                          "requestedservice"
                        )}
                      >
                        <Option value={0}>Select Service</Option>
                        {this.state.RequestedServiceData &&
                          this.state.RequestedServiceData.map((item) => (
                            <Option value={item.serviceID}>
                              {item.serviceName}
                            </Option>
                          ))}
                      </Select>
                    </div>

                    <div className="col-12 col-md-6">
                      <label className="mobile">Total Revenue</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="TotalRevenue"
                        value={
                          this.state.leadAddDetailInput.totalrevenue !==
                          undefined
                            ? this.state.leadAddDetailInput.totalrevenue
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "totalrevenue"
                        )}
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Source URL</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Source URL"
                        value={
                          this.state.leadAddDetailInput.SourceURL
                            ? this.state.leadAddDetailInput.SourceURL
                            : ""
                        }
                        onChange={this.handleLeadAddDetailsInputChange.bind(
                          this,
                          "SourceURL"
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="casane">
                        <Button
                          type="button"
                          onClick={this.handleCreateLeadModalClose.bind(this)}
                        >
                          Cancel
                        </Button>
                        {/*<button type="button">Save & New</button>*/}

                        {this.state.isCreateTaskandSave ? (
                          <Button
                            loading={this.state.isSubmitLoading}
                            type="primary"
                            disabled={this.state.isSubmitLoading}
                            onClick={this.handleCreateTaskandLeads.bind(this)}
                          >
                            Create Task and Save
                          </Button>
                        ) : (
                          <Button
                            loading={this.state.isSubmitLoading}
                            type="primary"
                            disabled={this.state.isSubmitLoading}
                            onClick={this.handleSaveLeads.bind(this)}
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Modal>
        {/* Create Task Modal */}
        <Modal
          closable={false}
          visible={this.state.createTask}
          className="AddCreateTaskModal"
          footer={null}
          keyboard={true}
          onCancel={this.handleCreateTaskClose}
        >
          <AddTaskComponent
            leadStatusList={this.state.leadStatusData}
            AssignToList={this.state.leadAssignToData}
            handleCreateTaskClose={this.handleCreateTaskClose}
            TaskTypeList={this.state.taskTypeList}
            LeadID={
              this.state.selectedLeadDetails.leadID || this.state.taskLeadID
            }
            FollowUp={this.state.FollowUp}
            LeadDetails={this.state.selectedLeadDetails}
            leadStatus={this.state.selectedLeadStatus}
            leadSubStatus={this.state.leadDetailsInput.substatus}
            taskType={1}
            selectedDate={this.state.taskdate}
            handleCreateLeadDetails={this.handleCreateLeadDetails}
            isEditTask={this.state.isEditTask}
            editTaskDetails={this.state.editTaskDetails}
            leadInput={this.state.leadInput}
            leadDetailsInput={this.state.leadDetailsInput}
            leadAddDetailInput={this.state.leadAddDetailInput}
            handleLeadValidation={this.handleLeadValidation}
            handleGetLeadList={this.handleGetLeadList}
          />
        </Modal>
        {/* Add Note Modal */}
        <Modal
          title={"Add Note"}
          closable={false}
          visible={this.state.addNote}
          footer={null}
          onCancel={this.handleAddNotesClose}
        >
          <AddNoteComponent
            handleAddNotesClose={this.handleAddNotesClose}
            LeadID={this.state.selectedLeadDetails.leadID}
          />
        </Modal>
        {/* Query Lead Modal */}
        <Modal
          visible={this.state.QueryLead}
          title={"Status Changes"}
          footer={null}
          closable={false}
          onCancel={this.handleCallBackQueryModalClose}
        >
          {this.state.QueryLead ? (
            <QueryLead
              handleCallBackQueryModalClose={this.handleCallBackQueryModalClose}
              LeadStatusList={this.state.leadStatusData}
              isStatusClick={this.state.isStatusClick}
              LeadID={this.state.selectedLeadDetails.leadID}
              LeadStatus={this.state.selectedLeadStatus}
            />
          ) : null}
        </Modal>
        <Modal
          visible={this.state.emailModal}
          title={"Email Send"}
          footer={null}
          closable={false}
          className="email-action"
          onCancel={this.handleEmailActionModalClose}
        >
          {this.state.emailModal ? (
            <EmailTab
              isAction={true}
              leadDetails={this.state.selectedLeadDetails}
              handleEmailActionModalClose={this.handleEmailActionModalClose}
            />
          ) : null}
        </Modal>
        <Modal
          visible={this.state.smsModal}
          title={"SMS"}
          footer={null}
          closable={false}
          onCancel={this.handleSMSActionModalClose}
        >
          {this.state.smsModal ? (
            <SMSTab
              isAction={true}
              handleSMSActionModalClose={this.handleSMSActionModalClose}
              MobileNumber={this.state.selectedLeadDetails.phoneNo}
              LeadID={this.state.selectedLeadDetails.leadID}
            />
          ) : null}
        </Modal>
        <Modal
          visible={this.state.isAssignModel}
          title={"Assign/Reassign"}
          closable={false}
          footer={[
            <Button key="back" onClick={this.handleAssignModalClose.bind(this)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              //loading={this.state.isSubmitLoading}
              onClick={this.handleAssignUser.bind(this)}
            >
              Save
            </Button>,
          ]}
        >
          <div className="createPrior">
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label>Unit Name</label>
                <Select
                  defaultValue={0}
                  placeholder="Unit name"
                  value={this.state.assignInput.Unit || null}
                  onChange={this.handleAssignInputChange.bind(this, "Unit")}
                >
                  <Option>Select</Option>
                  {this.state.leadLocationData &&
                    this.state.leadLocationData.map((item) => (
                      <Option value={item.locationID}>
                        {item.locationName}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label>User Name</label>
                <Select
                  defaultValue={0}
                  placeholder="User Name"
                  value={this.state.assignInput["User"] || null}
                  onChange={this.handleAssignInputChange.bind(this, "User")}
                >
                  <Option value={0}>Select</Option>
                  {this.state.UserBasedOnUnitData &&
                    this.state.UserBasedOnUnitData.map((item) => (
                      <Option
                        value={item.userID + "-" + item.isTeamUser}
                        isTeamUser={item.isTeamUser}
                      >
                        {item.fullName}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
          </div>
        </Modal>

        {this.state.Disqualify ? (
          <DisqualifyAction
            ref={(ref) => (this.disqualifyAction = ref)}
            LeadStatusList={this.state.leadStatusData}
            LeadID={this.state.selectedLeadDetails.leadID}
            handleCloseDisqualifyModalClose={
              this.handleCloseDisqualifyModalClose
            }
          />
        ) : null}

        <Modal
          visible={this.state.OpenTask}
          title={"Status Changes"}
          footer={null}
          closable={false}
          className="OpenCreateTaskModal"
        >
          <Fragment>
            <div className="row">
              {/*{this.props.isStatusClick ? null : (*/}
              <div className="col-md-12">
                <label>
                  Lead is having task/task's planned which is pending to
                  complete. Please complete them first & then try to change the
                  lead status
                </label>
              </div>
              {/*)}*/}
            </div>
            <div className="row">
              {/*{this.props.isStatusClick ? null : (*/}
              <div className="col-md-6">
                <Button
                  type="primary"
                  onClick={this.handleRedirecttoDetails.bind(
                    this,
                    this.state.selectedLeadDetails
                  )}
                >
                  Lead Details Page
                </Button>
              </div>
              <div className="col-md-6">
                <Button type="primary" onClick={this.handleOpenTask.bind(this)}>
                  Show Open Task/Task's
                </Button>
              </div>
              {/*)}*/}
            </div>
            {this.state.isTaskOpen ? (
              <div className="row">
                <Table
                  scroll={true}
                  rowKey={(record) => record.leadID}
                  loading={this.state.isTaskLoading}
                  columns={[
                    {
                      title: "Task Type",
                      dataIndex: "taskName",
                      key: "taskName",
                    },
                    {
                      title: "Task Title",
                      dataIndex: "taskTitle",
                      key: "taskTitle",
                    },
                    {
                      title: "Task Planned Date",
                      key: "taskScheduleDate",
                      dataIndex: "taskScheduleDate",
                    },
                    {
                      title: "Task Status",
                      key: "taskStatus",
                      dataIndex: "taskStatus",
                    },
                    {
                      title: "Actions",
                      key: "actions",
                      dataIndex: "actions",
                      render: (row, item) => {
                        return (
                          <div className="Actionic">
                            {/*<img*/}
                            {/*    src={AddNoteIcon}*/}
                            {/*    alt="Edit"*/}
                            {/*    onClick={this.handleEditTask.bind(this, item)}*/}
                            {/*/>*/}
                            <button
                              className="EditStatus"
                              onClick={this.handleEditTask.bind(this, item)}
                            >
                              Edit Status
                            </button>
                          </div>
                        );
                      },
                    },
                  ]}
                  dataSource={this.state.Task}
                  pagination={{
                    position: ["bottomCenter"],
                    showSizeChanger: true,
                  }}
                />
              </div>
            ) : null}
            <div className="row qurylead-div">
              <div className="col-md">
                <Button onClick={this.handleTaskClose}>Cancel</Button>
              </div>
            </div>
          </Fragment>
        </Modal>
      </div>
    );
  }
}

export default LeadList;
