import { Menu } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  CampaignIcon,
  AssetsIcon,
  CommunicationIcon,
  DashboardIcon,
  LeadIcon,
  OpportunityIcon,
  OrderIcon,
  ReportIcon,
  SettingIcon,
  WorkflowIcon,
  CRMRolesIcon,
  SLAIcon,
  PriorityIcon,
  UsersIcon,
  AlertIcon,
  HierarchyIcon,
  LocationIcon,
} from "./../../../helpers/ImagesImport";
import UserService from "./../../../services/AdminSetting/UserService";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkLink: "LeadList",
      leadAccess: true,
      settingAccess: true,
      reportsAccess: true
    };

    this.UserService = new UserService();
  }

  componentDidMount() {
      this.handleGetUserModuleDetails();
  }

  handleCheckPageDirect(link) {
    this.setState({
      checkLink: link,
    });
  }

  handleGetUserModuleDetails = () => {
    try {
        this.UserService.getUserModuleDetails("GetUserModuleDetails")
        .then((response) => {
            
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            //this.setState({ cityData });
              this.setAccessUser(data);
          } else {
            //this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  setAccessUser(data) {
      if (data !== null) {
          for (var i = 0; i < data.length; i++) {
              if (
                  data[i].moduleName === "Leads" &&
                  (data[i].isCreate !== false || data[i].isDelete !== false ||
                      data[i].isDownload !== false || data[i].isEdit !== false ||
                      data[i].isView !== false)
              ) {
                  this.setState({
                      leadAccess: true,
                  });
              }
              if (
                  data[i].moduleName === "Settings" &&
                  (data[i].isCreate !== false || data[i].isDelete !== false ||
                      data[i].isDownload !== false || data[i].isEdit !== false ||
                      data[i].isView !== false)
              ) {
                  this.setState({
                      settingAccess: true,
                  });
              }
              if (
                  data[i].moduleName === "Reports" &&
                  (data[i].isCreate !== false || data[i].isDelete !== false ||
                      data[i].isDownload !== false || data[i].isEdit !== false ||
                      data[i].isView !== false)
              ) {
                  this.setState({
                      reportsAccess: true,
                  });
              }
          }
      }
  }
  render() {
    const { SubMenu } = Menu;
    return (
      <div>
        <div className="sidemenu">
          <ul>
            {/*<li>*/}
            {/*    <Link to="LeadList">*/}
            {/*        <img src={DashboardIcon} alt="DashboardIcon" />*/}
            {/*        <label>Dashboard</label>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/* <li className="active"> */}
            {this.state.leadAccess &&
                <li
                  className={this.state.checkLink === "LeadList" ? "active" : ""}
                  onClick={this.handleCheckPageDirect.bind(this, "LeadList")}
                >
                  <Link to="LeadList">
                    <img src={LeadIcon} alt="LeadIcon" />
                    <label>Leads</label>
                  </Link>
                </li>
            }
            {/*<li*/}
            {/*    className={this.state.checkLink === "Opportunity" ? "active" : ""}*/}
            {/*    onClick={this.handleCheckPageDirect.bind(this, "Opportunity")}*/}
            {/*>*/}
            {/*    <Link to="Opportunity">*/}
            {/*        <img src={OpportunityIcon} alt="OpportunityIcon" />*/}
            {/*        <label>Opportunity</label>*/}
            {/*    </Link>*/}
                    {/*</li>*/}
            {this.state.settingAccess &&
              <li
              className={
                this.state.checkLink === "adminsetting" ? "p-0 active" : "p-0"
              }
              onClick={this.handleCheckPageDirect.bind(this, "adminsetting")}
             >
              <Menu mode="inline">
                <SubMenu
                  key="sub1"
                  title={
                    <div>
                      <img src={SettingIcon} alt="SettingIcon" />
                      <label>Admin Setting</label>
                    </div>
                  }
                >
                  <Menu.Item key="1">
                    <Link to="hierarchy">
                      <img src={HierarchyIcon} alt="CommunicationIcon" />
                      <label className="HierSubMenu">Hierarchy</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to="Location">
                      <img src={LocationIcon} alt="Setting" />
                      <label className="HierSubMenu">Location</label>
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="3">
                    <Link to="TaskType">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">Task Types</label>
                    </Link>
                  </Menu.Item> */}
                  {/* <Menu.Item key="4">
                    <Link to="Template">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">Templates</label>
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="5">
                    <Link to="Alerts">
                      <img src={AlertIcon} alt="Setting" />
                      <label className="HierSubMenu">Alerts</label>
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="6">
                    <Link to="Category">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">Lead Source</label>
                    </Link>
                  </Menu.Item> */}
                  {/* <Menu.Item key="7">
                    <Link to="Category">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">Category</label>
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="8">
                    <Link to="Users">
                      <img src={UsersIcon} alt="Setting" />
                      <label className="HierSubMenu">Users</label>
                    </Link>
                  </Menu.Item>
                  {/*<Menu.Item key="9">*/}
                  {/*    <Link to="CrmRole">*/}
                  {/*        <img src={CommunicationIcon} alt="Setting" />*/}
                  {/*        <label className="HierSubMenu">CRM Roles</label>*/}
                  {/*    </Link>*/}
                  {/*</Menu.Item>*/}
                  {/* <Menu.Item key="10">
                    <Link to="SpecialityMaster">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">Speciality Master</label>
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="11">
                    <Link to="Priority">
                      <img src={PriorityIcon} alt="Setting" />
                      <label className="HierSubMenu">Priority</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="12">
                    <Link to="SlaMaster">
                      <img src={SLAIcon} alt="Setting" />
                      <label className="HierSubMenu">SLA Master</label>
                    </Link>
                  </Menu.Item>

                  {/* <Menu.Item key="13">
                    <Link to="LeadScoring">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">Lead Scoring</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="14">
                    <Link to="Category">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">Journey Builder</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="15">
                    <Link to="Dnd">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">DND (Calls/Email)</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="16">
                    <Link to="leadStageManage">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">
                        Lead Stage Management
                      </label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="17">
                    <Link to="CustomFields">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">
                        Manage Custom Fields
                      </label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="18">
                    <Link to="Category">
                      <img src={CommunicationIcon} alt="Setting" />
                      <label className="HierSubMenu">
                        Landing Pages Templates
                      </label>
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="19">
                    <Link to="RoleAccessControl">
                      <img src={CRMRolesIcon} alt="Setting" />
                      <label className="HierSubMenu">Role Access Control</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="20">
                    <Link to="LeadTeams">
                      <img src={UsersIcon} alt="Setting" />
                      <label className="HierSubMenu">Lead Teams</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="21">
                    <Link to="FacebookConnector">
                      <img src={UsersIcon} alt="Setting" />
                      <label className="HierSubMenu">Facebook Connector</label>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </li>
            }
            {/*<li>*/}
            {/*    <Link to="Category">*/}
            {/*        <img src={CampaignIcon} alt="CampaignIcon" />*/}
            {/*        <label>Campaigns</label>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*    <Link to="Category">*/}
            {/*        <img src={OrderIcon} alt="OrderIcon" />*/}
            {/*        <label>Orders</label>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*    <Link to="Category">*/}
            {/*        <img src={AssetsIcon} alt="AssetsIcon" />*/}
            {/*        <label>Assets</label>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*    <Link to="Category">*/}
            {/*        <img src={WorkflowIcon} alt="WorkflowIcon" />*/}
            {/*        <label>Workflow</label>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            <li>
              <Link to="Report">
                <img src={ReportIcon} alt="ReportIcon" />
                <label>Reports</label>
              </Link>
            </li>
            {/*<li>*/}
            {/*    <Link to="Category">*/}
            {/*        <img src={CommunicationIcon} alt="CommunicationIcon" />*/}
            {/*        <label>Communication</label>*/}
            {/*    </Link>*/}
            {/*</li>*/}
          </ul>
        </div>
      </div>
    );
  }
}

export default SideMenu;
