import React, { Component } from "react";
import "./../../../assets/css/Leads/leadDetail.css";
import { Tabs, Collapse, Select, Button, DatePicker } from "antd";
import {
  CircleBackIcon,
  MeetIcon,
  CallIcon,
  EmailIcon,
  NotesIcon,
  TaskIcon,
  HistoryBlueIcon,
  EditIcon,
  SMSIcon,
  SUserIcon,
} from "./../../../helpers/ImagesImport";
import HistoryTab from "./HistoryTab";
import TaskTab from "./TaskTab";
import NoteTab from "./NoteTab";
import EmailTab from "./EmailTab";
import CallTab from "./CallTab";
import MeetTab from "./MeetTab";
import SMSTab from "./SMSTab";
import LeadsService from "../../../services/Leads/LeadsService";
import EnquiryService from "../../../services/AdminSetting/EnquiryService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import UserService from "./../../../services/AdminSetting/UserService";
import CommonService from "../../../services/Common/CommonService";
import Master from "./../../../helpers/Master";
import moment from "moment";
import LocationService from "../../../services/AdminSetting/LocationService";
import parse from "html-react-parser";
import Modal from "antd/lib/modal/Modal";
import AddTaskComponent from "./AddTaskComponent";
const { Option } = Select;
function disabledDate(current) {
  return current && current > moment().endOf("day");
}
class LeadDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LeadCode: 0,
      moduleList: {
        isHistoryModule: true,
        isTaskModule: true,
        isNotModule: true,
        isEmailModule: true,
        isCallModule: true,
        isMeetModule: false,
        isSMSModule: true,
      },
      leadDetailsInput: {},
      leadDetailsError: {},
      enquirySubTypeData: [],
      enquiryTypeData: [],
      leadAssignToData: [],
      leadTypeData: [],
      leadSubStatusData: [],
      leadStatusData: [],
      leadSourceData: [],
      leadSpecialityData: [],
      isEdit: false,
      isSubmitLoading: false,
      selectedTab: "1",
      isCardView: false,
      TaskTypeList: [],
      callViewAccess: true,
      callDownloadAccess: true,
      emailCreateAccess: true,
      smsCreateAccess: true,
      taskCreateAccess: true,
      taskViewAccess: true,
      taskEditAccess: true,
      notesCreateAccess: true,
      notesViewAccess: true,
      viewAccess: true,
      editAccess: true,
      stateData: [],
      cityData: [],
      unitCityData: [],
      leadLocationData: [],
      leadDoctorsData: [],
      InsuranceProviderData: [],
      isFilterSearch: false,
      isFilter: false,
      leadFilterInput: null,
      RequestedServiceData: [],
      taskModal: false,
      Task: [],
    };
    this.LeadsService = new LeadsService();
    this.EnquiryService = new EnquiryService();
    this.UserService = new UserService();
    this.CommonService = new CommonService();
    this.master = new Master();
    this.locationService = new LocationService();
  }

  componentWillReceiveProps(props) {
    if (this.props !== props) {
      this.props = props;
      this.setState(props);

      this.handleActivityTabChange("0");
      this.componentDidMount();
    }
  }

  componentDidMount() {
    this.handleGetSpecialityList();
    if (this.props.location.state) {
      if (this.props.location.state.isNotifiction) {
        this.handleGetLeadList(this.props.location.state.leadID);
        this.handleGetTask(this.props.location.state.leadID);
        this.handleGetSourceData();
        this.handleGetTypeData();
        this.handleGetAssignToData();
        this.handleGetEnquiryTypeData();
        this.handleGetLeadStatusData();
        this.handleGetTaskTypeList();
        this.handleInsuranceProviderData();
      } else {
        this.handleGetLeadList(this.props.location.state.leadDeatils.leadID);
        this.handleGetTask(this.props.location.state.leadDeatils.leadID);
        this.setState({
          //leadDetailsInput: this.props.location.state.leadDeatils,
          leadSourceData: this.props.location.state.leadSourceData,
          leadTypeData: this.props.location.state.leadTypeData,
          leadAssignToData: this.props.location.state.leadAssignToData,
          enquiryTypeData: this.props.location.state.enquiryTypeData,
          leadStatusData: this.props.location.state.leadStatusData,
          isCardView: this.props.location.state.isCardView || false,
          TaskTypeList: this.props.location.state.TaskTypeList,
          InsuranceProviderData: this.props.location.state
            .InsuranceProviderData,
          // leadFilterInput: this.props.location.state.leadFilterInput,
          isFilterSearch: this.props.location.state.isFilterSearch,
          isFilter: this.props.location.state.isFilter,
          RequestedServiceData: this.props.location.state.RequestedServiceData,
        });
      }
    } else {
      this.props.history.push("LeadList");
    }
    this.handleGetModuleDetails();
    setTimeout(() => {
      this.setState({
        selectedTab: "1",
      });
    }, 500);
  }
  //handle back lead list page
  handleBackLeadListPage = () => {
    this.props.history.push({
      pathname: "Leadlist",
      state: {
        isCardView: this.state.isCardView,
        isFilterSearch: this.state.isFilterSearch,
        isFilter: this.state.isFilter,
        // leadFilterInput: this.state.leadFilterInput,
      },
    });
  };
  //handle Customer Details Validation
  handleCustomerDetailsValidation = () => {
    var leadDetailsInput = this.state.leadDetailsInput;
    var leadDetailsError = this.state.leadDetailsError;
    var isValid = true;
    if (leadDetailsInput["firstName"] === null) {
      leadDetailsError["firstName"] = "Enter first name.";
      isValid = false;
    } else {
      if (!leadDetailsInput["firstName"].trim()) {
        leadDetailsError["firstName"] = "Enter first name.";
        isValid = false;
      }
    }

    //if (!leadDetailsInput["lastName"]) {
    //  leadDetailsError["lastName"] = "Enter last name.";
    //  isValid = false;
    //}

    if (leadDetailsInput["phoneNo"]) {
      if (!leadDetailsInput["phoneNo"]) {
        leadDetailsError["phoneNo"] = "Enter First Name.";
        isValid = false;
      }
    } else {
      if (leadDetailsInput["email"]) {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(leadDetailsInput["email"])) {
          isValid = false;
          leadDetailsInput["email"] = "Enter valid email address.";
        } else {
          isValid = true;
          leadDetailsInput["email"] = "";
        }
      } else {
        isValid = false;
        leadDetailsInput["email"] = "Enter email address.";
      }
    }
    this.setState({
      leadDetailsError,
    });
    return isValid;
  };
  handleDetailsInputChange = async (name, e, event) => {
    var leadDetailsInput = this.state.leadDetailsInput;
    var leadDetailsError = this.state.leadDetailsError;
    if (e.target) {
      if (name === "enquiryByAge") {
        if (!isNaN(e.target.value)) {
          leadDetailsInput[name] = e.target.value;
        } else {
          leadDetailsInput[name] = 0;
        }
      } else {
        leadDetailsInput[name] = e.target.value;
        leadDetailsError[name] = "";
        if (name === "cosultingDoctor") {
          if (!leadDetailsInput["unit"]) {
            this.setState({
              leadDoctorsData: [],
            });
            NotificationManager.error("Please select unit.");
          }
        }
      }
    } else {
      if (name !== "leadStatus") {
        leadDetailsInput[name] = e;
      } else {
        if (!event.disableTask) {
          leadDetailsInput[name] = e;
        }
      }
      leadDetailsError[name] = "";
      if (name === "leadStatus") {
        var taskModal = true;
        if (event.disableTask) {
          if (this.state.Task.length > 0) {
            this.state.Task.map((item) => {
              if (item.taskStatus === "Planned") {
                taskModal = false;
                NotificationManager.error("Task is already planned.");
              } else {
                leadDetailsInput[name] = e;
                leadDetailsInput["leadSubStatus"] = null;
              }
            });
          } else {
            leadDetailsInput[name] = e;
            leadDetailsInput["leadSubStatus"] = null;
          }
        } else {
          leadDetailsInput[name] = e;
          leadDetailsInput["leadSubStatus"] = null;
        }
        this.setState({ taskModal });
        this.handleGetSubStatusListByStatusId();
        leadDetailsInput["statusName"] = event.children;
      }
      if (name === "assignTo") {
        leadDetailsInput["isTeamUser"] = event.isTeamUser;
        leadDetailsInput["assignToName"] = event.children;
      }
      if (name === "careCenterStateID") {
        leadDetailsInput["careCenterState"] = event.children;
        this.handleGetUnitCityData();
      }
      if (name === "careCenterCityID") {
        leadDetailsInput["careCenterCity"] = event.children;
      }
      if (name === "unit") {
        leadDetailsInput["locationName"] = event.children;
        this.handleGetLocationData();
        this.handleGetDoctorsData();
      }
      if (name === "state") {
        leadDetailsInput["stateName"] = event.children;
        this.handleGetCityData();
      }
      if (name === "city") {
        leadDetailsInput["cityName"] = event.children;
      }
      if (name === "enquiryType") {
        this.handleGetLeadSubEnquiryTypeList();
        leadDetailsInput["enquiryTypeName"] = event.children;
      }
      if (name === "enquirySubType") {
        leadDetailsInput["enquirySubTypeName"] = event.children;
      }
      if (name === "leadSubStatus") {
        leadDetailsInput["subStatusName"] = event.children;
      }
      if (name === "specialityID") {
        leadDetailsInput["specialityName"] = event.children;
      }
      if (name === "leadType") {
        leadDetailsInput["leadTypeName"] = event.children;
      }
      if (name === "leadSource") {
        leadDetailsInput["sourceName"] = event.children;
      }
      if (name === "cosultingDoctor") {
        leadDetailsInput["cosultingDoctorName"] = event.children;
      }
      if (name === "anniversaryDate") {
        leadDetailsInput["anniversaryDate"] = moment(e);
      }
      if (name === "dob") {
        leadDetailsInput["dob"] = moment(e);
      }
      if (name === "genderID") {
        leadDetailsInput["gender"] = event.children;
      }
      if (name === "maritalStatusID") {
        leadDetailsInput["maritalStatus"] = event.children;
      }
      if (name === "insuranceProviderID") {
        leadDetailsInput["insuranceProvider1"] = event.children;
      }
    }
    await this.setState({
      leadDetailsError,
      leadDetailsInput,
    });
  };

  //handle get sub status by status id
  handleGetSubStatusListByStatusId = () => {
    var inputData = {};
    inputData.LeadStatusID = this.state.leadDetailsInput.leadStatus;
    try {
      this.LeadsService.getLeadDropdownData("GetLeadSubStatus", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadSubStatusData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadSubStatusData });
          } else {
            this.setState({ leadSubStatusData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //handle get lead sub enquiry type list
  handleGetLeadSubEnquiryTypeList = () => {
    var inputData = {};
    inputData.BrandID = "0";
    inputData.EnquiryTypeID = this.state.leadDetailsInput.enquiryType + "";
    try {
      this.EnquiryService.getEnquirySubType("GetEnquirySubType", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var enquirySubTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ enquirySubTypeData });
          } else {
            this.setState({ enquirySubTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get lead source list
  handleGetSpecialityList = () => {
    try {
      var inputData = {};
      this.LeadsService.getLeadDropdownData("GetSpecialityMaster", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadSpecialityData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadSpecialityData });
          } else {
            this.setState({ leadSpecialityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleEditLeadDetails = () => {
    this.setState({
      isEdit: true,
    });
    if (!this.state.leadDetailsInput["appointmentDateTime"]) {
      if (
        this.state.leadDetailsInput["appointmentDate"] &&
        this.state.leadDetailsInput["appointmentTime"]
      ) {
        this.state.leadDetailsInput["appointmentDateTime"] = new Date(
          this.state.leadDetailsInput.appointmentDate +
            " " +
            this.state.leadDetailsInput.appointmentTime
        );
      } else {
        this.state.leadDetailsInput["appointmentDateTime"] = null;
      }
    }
    this.state.leadDetailsInput["assignTo"] =
      this.state.leadDetailsInput["assignTo"] +
      "-" +
      this.state.leadDetailsInput["isTeamUser"];
    this.setState({
      leadDetailsInput: this.state.leadDetailsInput,
    });

    this.handleGetSubStatusListByStatusId();
    this.handleGetLeadSubEnquiryTypeList();
    this.handleGetStateData();
    this.handleGetCityData();
    this.handleGetLeadLocationData();
    this.handleGetDoctorsData();
    this.handleGetUnitCityData();
  };

  handleLeadDetailsUpdate = (isForm) => {
    try {
      var inputData = {};
      inputData.EnquiryByMobileNo = this.state.leadDetailsInput.careTakerMobileNo;
      inputData.EnquiryKeywords = this.state.leadDetailsInput.enquiryKeywords;
      inputData.ExpectedRevenue = this.state.leadDetailsInput.expectedRevenue;
      inputData.RequestedServiceID = this.state.leadDetailsInput
        .requestedServiceID
        ? Number(this.state.leadDetailsInput.requestedServiceID)
        : 0;
      inputData.TotalRevenue = this.state.leadDetailsInput.totalRevenue
        ? this.state.leadDetailsInput.totalRevenue + ""
        : null;
      inputData.PreferredDateTimeToContact = this.state.leadDetailsInput
        .preferredDateTimeForContact
        ? moment(this.state.leadDetailsInput.preferredDateTimeForContact)
            .format("YYYY-MM-DD hh:mm A")
            .toString()
        : null;
      inputData.EnquirySubType = this.state.leadDetailsInput.enquirySubType;
      inputData.EnquiryType = this.state.leadDetailsInput.enquiryType;
      inputData.MHID = this.state.leadDetailsInput.mhid;
      inputData.CaseCategory = this.state.leadDetailsInput.caseCategory;
      inputData.AgrDiscount = this.state.leadDetailsInput.agrDiscount;
      inputData.AssignTo = this.state.leadDetailsInput.assignTo
        ? Number(this.state.leadDetailsInput.assignTo.split("-")[0])
        : 0;
      inputData.LeadType = this.state.leadDetailsInput.leadType;
      inputData.SpecialityID = this.state.leadDetailsInput.specialityID;
      inputData.SubStatus = this.state.leadDetailsInput.leadSubStatus;
      inputData.Status = this.state.leadDetailsInput.leadStatus;
      inputData.Email = this.state.leadDetailsInput.email;
      inputData.MiddleName = this.state.leadDetailsInput.middleName;
      inputData.FirstName = this.state.leadDetailsInput.firstName;
      inputData.Contact = this.state.leadDetailsInput.phoneNo;
      inputData.LeadID = this.state.leadDetailsInput.leadID;
      inputData.LeadSource = this.state.leadDetailsInput.leadSource;
      inputData.PageFormName = this.state.leadDetailsInput.pageFormName;
      inputData.LastName = this.state.leadDetailsInput.lastName;
      inputData.isTeamUser = this.state.leadDetailsInput.isTeamUser;
      inputData.LastName = this.state.leadDetailsInput.lastName;
      inputData.Address = this.state.leadDetailsInput.address;
      inputData.State = this.state.leadDetailsInput.state;
      inputData.City = this.state.leadDetailsInput.city;
      inputData.EnquiryByName = this.state.leadDetailsInput.careTakerName;
      inputData.EnquiryByRelationship = this.state.leadDetailsInput.careTakerRelationship;
      inputData.EnquiryByAge = Number(this.state.leadDetailsInput.careTakerAge);
      inputData.EnquiryByEmail = this.state.leadDetailsInput.careTakerEmail;
      inputData.CareCenterStateID = this.state.leadDetailsInput.careCenterStateID;
      inputData.CareCenterCityID = this.state.leadDetailsInput.careCenterCityID;
      inputData.Unit = this.state.leadDetailsInput.unit;
      inputData.InsuranceProvider1 =
        this.state.leadDetailsInput.insuranceProviderID + "";
      inputData.InsurancePolicyNo = this.state.leadDetailsInput.insurancePolicyNo;
      inputData.PreauthorizationCode = this.state.leadDetailsInput.preAuthorizationCode;
      inputData.Doctor = this.state.leadDetailsInput.cosultingDoctor
        ? parseInt(this.state.leadDetailsInput.cosultingDoctor)
        : 0;
      inputData.LMD = this.state.leadDetailsInput.lpm
        ? moment(this.state.leadDetailsInput.lpm).format("YYYY-MM-DD")
        : null;
      inputData.EDD = this.state.leadDetailsInput.edd
        ? moment(this.state.leadDetailsInput.edd).format("YYYY-MM-DD")
        : null;
      inputData.GestationalAge = parseFloat(
        this.state.leadDetailsInput.gestationalAge
      );

      inputData.LeadName = this.state.leadDetailsInput.customerName || null;
      inputData.LeadMobile = this.state.leadDetailsInput.phoneNo || null;
      inputData.AlternateNo =
        this.state.leadDetailsInput.alternatePhoneNo || null;
      inputData.Gender = this.state.leadDetailsInput.genderID;

      inputData.DateOfBirth = this.state.leadDetailsInput.dob
        ? moment(this.state.leadDetailsInput.dob)
            .format("YYYY-MM-DD")
            .toString()
        : null;
      inputData.LeadEmail = this.state.leadDetailsInput.email || null;
      inputData.Occupation = this.state.leadDetailsInput.occupation || null;
      inputData.EmploymentType =
        this.state.leadDetailsInput.employmentType || null;
      inputData.MaritalStatus = this.state.leadDetailsInput.maritalStatusID
        ? Number(this.state.leadDetailsInput.maritalStatusID)
        : 0;
      inputData.CompanyName = this.state.leadDetailsInput.companyName || null;
      inputData.AnniversaryDate = this.state.leadDetailsInput.anniversaryDate
        ? moment(this.state.leadDetailsInput.anniversaryDate)
            .format("YYYY-MM-DD")
            .toString()
        : null;
      inputData.HNI = this.state.leadDetailsInput.hni || null;
      inputData.LeadIncome = this.state.leadDetailsInput.income || null;
      inputData.AdditionalIncome =
        this.state.leadDetailsInput.additionalIncome || null;
      inputData.MRNumber = this.state.leadDetailsInput.mrNumber || null;
      inputData.BillNo = this.state.leadDetailsInput.billNo || null;
      inputData.InsuranceDate =
        this.state.leadDetailsInput.insuranceDate || null;
      inputData.CashOrInsurance =
        this.state.leadDetailsInput.cashOrInsurance || null;
      inputData.AppointmentDateTime = this.state.leadDetailsInput
        .appointmentDateTime
        ? moment(this.state.leadDetailsInput.appointmentDateTime)
            .format("YYYY-MM-DD HH:mm A")
            .toString()
        : null;

      if (isForm === "EnquirySourceDetails") {
        inputData.isEnquirySourceDetails = true;
      } else if (isForm === "EnquiryDetails") {
        inputData.isEnquiryDetails = true;
      } else if (isForm === "ContactInfo") {
        inputData.isContactInfoDetails = true;
      } else if (isForm === "EnquiryBy") {
        inputData.isEnquiryByDetails = true;
      } else if (isForm === "CareCenter") {
        inputData.isCareCenterDetails = true;
      } else if (isForm === "InsuranceDetails") {
        inputData.isInsuranceInfoDetails = true;
      } else if (isForm === "MaternityInfo") {
        inputData.isMaternityInfoDetails = true;
      } else if (isForm === "LeadInfo") {
        inputData.isLeadInfoDetails = true;
      } else if (isForm === "OtherDetails") {
        inputData.isOtherDetails = true;
      } else {
        inputData.isCustomerDetails = true;
      }
      this.setState({ isSubmitLoading: true });
      this.LeadsService.getLeadDetailsTab("UpdateLeadDetails", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          this.setState({ isSubmitLoading: false });
          if (statusCode === 200) {
            this.setState({ isEdit: false });

            NotificationManager.success("Lead update successfully.");
          } else {
            NotificationManager.error("Lead update fail.");
          }
        })
        .catch((error) => {
          this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isSubmitLoading: false });
      console.log(error);
    }
  };

  handleSubmitCustomerDetails = () => {
    if (this.handleCustomerDetailsValidation()) {
      this.handleLeadDetailsUpdate("CustomerDetails");
    }
  };
  handleSubmitEnquirySourceDetails = () => {
    if (this.handleEnquirySourceDetailsValidation()) {
      this.handleLeadDetailsUpdate("EnquirySourceDetails");
    }
  };
  handleSubmitEnquiryDetails = () => {
    if (this.handleEnquiryDetailsValidation()) {
      this.handleLeadDetailsUpdate("EnquiryDetails");
    }
  };
  handleSubmitContactInfo = () => {
    this.handleLeadDetailsUpdate("ContactInfo");
  };
  handleSubmitEnquiryBy = () => {
    this.handleLeadDetailsUpdate("EnquiryBy");
  };
  handleSubmitCareCenter = () => {
    if (this.handleEnquiryCareCenterDetailsValidation()) {
      this.handleLeadDetailsUpdate("CareCenter");
    }
  };
  handleSubmitInsuranceDetails = () => {
    this.handleLeadDetailsUpdate("InsuranceDetails");
  };
  handleSubmitMaternityInfo = () => {
    this.handleLeadDetailsUpdate("MaternityInfo");
  };

  handleSubmitOtherDetails = () => {
    this.handleLeadDetailsUpdate("OtherDetails");
  };

  //handle Enquiry Source Details Validation
  handleEnquirySourceDetailsValidation = () => {
    var leadDetailsInput = this.state.leadDetailsInput;
    var leadDetailsError = this.state.leadDetailsError;
    var isValid = true;
    if (!leadDetailsInput["enquiryType"]) {
      leadDetailsError["enquiryType"] = "Select enquiry Type.";
      isValid = false;
    }
    if (!leadDetailsInput["enquirySubType"]) {
      leadDetailsError["enquirySubType"] = "Select enquiry sub Type.";
      isValid = false;
    }
    if (!leadDetailsInput["leadSource"]) {
      leadDetailsError["leadSource"] = "Select lead Source.";
      isValid = false;
    }
    this.setState({
      leadDetailsError,
    });
    return isValid;
  };
  //handle Enquiry Source Details Validation
  handleEnquiryDetailsValidation = () => {
    var leadDetailsInput = this.state.leadDetailsInput;
    var leadDetailsError = this.state.leadDetailsError;
    var isValid = true;
    if (!leadDetailsInput["leadType"]) {
      leadDetailsError["leadType"] = "Select lead type.";
      isValid = false;
    }
    if (!leadDetailsInput["leadStatus"]) {
      leadDetailsError["leadStatus"] = "Select lead status.";
      isValid = false;
    }
    if (!leadDetailsInput["leadSubStatus"]) {
      leadDetailsError["leadSubStatus"] = "Select lead Sourcesub status.";
      isValid = false;
    }
    if (!leadDetailsInput["assignTo"]) {
      leadDetailsError["assignTo"] = "Select assign to.";
      isValid = false;
    }
    // if (!leadDetailsInput["specialityID"]) {
    //   leadDetailsError["specialityID"] = "Select speciality.";
    //   isValid = false;
    // }
    this.setState({
      leadDetailsError,
    });
    return isValid;
  };

  handleEnquiryCareCenterDetailsValidation = () => {
    var leadDetailsInput = this.state.leadDetailsInput;
    var leadDetailsError = this.state.leadDetailsError;
    var isValid = true;
    if (!leadDetailsInput["unit"]) {
      leadDetailsError["careCenterLocationID"] = "Select unit.";
      isValid = false;
    }
    this.setState({
      leadDetailsError,
    });
    return isValid;
  };
  handleActivityTabChange = (e) => {
    this.setState({
      selectedTab: e,
    });
  };

  handleGetModuleDetails = () => {
    try {
      this.UserService.getUserModuleDetails("GetUserModuleDetails")
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            //this.setState({ cityData });
            this.setAccessUser(data);
          } else {
            //this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  setAccessUser(data) {
    if (data !== null) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].moduleName === "Call") {
          this.setState({
            callViewAccess: data[i].isView,
            callDownloadAccess: data[i].isDownload,
          });
        }
        if (data[i].moduleName === "Email") {
          this.setState({
            emailCreateAccess: data[i].isCreate,
          });
        }
        if (data[i].moduleName === "SMS") {
          this.setState({
            smsCreateAccess: data[i].isCreate,
          });
        }
        if (data[i].moduleName === "Task") {
          this.setState({
            taskCreateAccess: data[i].isCreate,
            taskViewAccess: data[i].isView,
            taskEditAccess: data[i].isEdit,
          });
        }
        if (data[i].moduleName === "Notes") {
          this.setState({
            notesCreateAccess: data[i].isCreate,
            notesViewAccess: data[i].isView,
          });
        }
        if (data[i].moduleName === "Leads") {
          this.setState({
            viewAccess: data[i].isView,
            editAccess: data[i].isEdit,
          });
        }
      }
    }
  }

  handleGetStateData = () => {
    var inputData = {};
    inputData.IDs = "1";
    inputData.Value = "";
    try {
      this.CommonService.getStateList("GetStateByCountryID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var stateData = response.responseData;
          if (statusCode === 200) {
            this.setState({ stateData });
          } else {
            this.setState({ stateData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetUnitCityData = () => {
    var inputData = {};
    inputData.IDs = this.state.leadDetailsInput.careCenterStateID.toString();
    inputData.Value = "";
    try {
      this.CommonService.getCityList("GetCityMasterByStateID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var unitCityData = response.responseData;
          if (statusCode === 200) {
            this.setState({ unitCityData });
          } else {
            this.setState({ unitCityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetCityData = () => {
    var inputData = {};
    inputData.IDs = this.state.leadDetailsInput.state.toString();
    inputData.Value = "";
    try {
      this.CommonService.getCityList("GetCityMasterByStateID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var cityData = response.responseData;
          if (statusCode === 200) {
            this.setState({ cityData });
          } else {
            this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetLeadLocationData = () => {
    var inputData = {};
    inputData.SearchText = "";
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GeLeadLocation", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadLocationData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadLocationData });
          } else {
            this.setState({ leadLocationData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handlePreferredDateTimeOnChange = async (name, e) => {
    var leadDetailsInput = this.state.leadDetailsInput;
    if (name === "preferredDateTimeForContact") {
      leadDetailsInput[name] = e;
    }
    await this.setState({ leadDetailsInput });
  };

  handleDateOnChange = async (name, dates, dateStrings) => {
    var leadDetailsInput = this.state.leadDetailsInput;
    if (name == "lmp") {
      if (dates !== null) {
        leadDetailsInput["lpm"] = moment(dates._d).format("YYYY-MM-DD");
        var eddDate = new Date(dates._d.setDate(dates._d.getDate() + 280));
        leadDetailsInput["edd"] = moment(eddDate).format("YYYY-MM-DD");
        var diff_time =
          new Date(moment(new Date()).format("MM-DD-YYYY")).getTime() -
          new Date(
            moment(leadDetailsInput["lpm"]).format("MM-DD-YYYY")
          ).getTime();

        var diff_days = diff_time / (1000 * 3600 * 24);
        leadDetailsInput["gestationalAge"] = (diff_days / 7)
          .toFixed(3)
          .slice(0, -2);
        //this.setState({
        //    eddDate
        //})
      } else {
        leadDetailsInput["lpm"] = "";
        leadDetailsInput["gestationalAge"] = 0;
      }
    }
    if (name == "edd") {
      if (dates !== null) {
        leadDetailsInput["edd"] = moment(dates._d).format("YYYY-MM-DD");
        var lmpDate = new Date(dates._d.setDate(dates._d.getDate() - 280));
        leadDetailsInput["lpm"] = moment(lmpDate).format("YYYY-MM-DD");
        var diff_time =
          new Date(moment(new Date()).format("MM-DD-YYYY")).getTime() -
          new Date(
            moment(leadDetailsInput["lpm"]).format("MM-DD-YYYY")
          ).getTime();

        var diff_days = diff_time / (1000 * 3600 * 24);
        leadDetailsInput["gestationalAge"] = (diff_days / 7)
          .toFixed(3)
          .slice(0, -2);
      } else {
        leadDetailsInput["edd"] = "";
      }
    }
    if (name === "dob") {
      leadDetailsInput["dob"] = moment(dates).format("YYYY-MM-DD").toString();
    }
    if (name === "anniversaryDate") {
      leadDetailsInput["anniversaryDate"] = moment(dates)
        .format("YYYY-MM-DD")
        .toString();
    }
    if (name === "insuranceDate") {
      leadDetailsInput["insuranceDate"] = moment(dates)
        .format("YYYY-MM-DD")
        .toString();
    }

    await this.setState({ leadDetailsInput });
  };

  handleGetDoctorsData = () => {
    var inputData = {};
    inputData.UnitID = this.state.leadDetailsInput.unit;
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GeLeadDoctors", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadDoctorsData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadDoctorsData });
          } else {
            this.setState({ leadDoctorsData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle Lead Info Validation
  handleLeadInfoValidation = () => {
    var leadDetailsInput = this.state.leadDetailsInput;
    var leadDetailsError = this.state.leadDetailsError;
    var isValid = true;

    //if (!leadDetailsInput["customerName"]) {
    //  leadDetailsError["customerName"] = "Enter lead name.";
    //  isValid = false;
    //}

    if (leadDetailsInput["phoneNo"]) {
      if (!leadDetailsInput["phoneNo"]) {
        leadDetailsError["phoneNo"] = "Enter Phone Number.";
        isValid = false;
      }
    } else {
      if (leadDetailsInput["email"]) {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(leadDetailsInput["email"])) {
          isValid = false;
          leadDetailsInput["email"] = "Enter valid email address.";
        } else {
          isValid = true;
          leadDetailsInput["email"] = "";
        }
      } else {
        isValid = false;
        leadDetailsInput["email"] = "Enter email address.";
      }
    }
    this.setState({
      leadDetailsError,
    });
    return isValid;
  };
  handleSubmitLeadInfo = () => {
    if (this.handleLeadInfoValidation()) {
      this.handleLeadDetailsUpdate("LeadInfo");
    }
  };
  handleGetLocationData = () => {
    try {
      this.setState({ loading: true });
      var inputData = {};
      inputData.IDs = this.state.leadDetailsInput.unit.toString();
      inputData.Value = "";
      this.locationService
        .getLocaionGirdData("GetLocation", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData[0];
          //var key = 0;
          //locationGirdData.forEach((element) => {
          //  key += 1;
          //  element.key = key;
          //});
          //this.setState({ loading: false });
          var leadDetailsInput = this.state.leadDetailsInput;
          if (statusCode === 200) {
            leadDetailsInput["careCenterState"] = data.stateName;
            leadDetailsInput["careCenterCity"] = data.cityName;
            this.setState({ leadDetailsInput });
          } else {
            //this.setState({ locationGirdData: [] });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };
  handleAppointmentDateTimeChange = (e) => {
    this.state.leadDetailsInput["appointmentDateTime"] = moment(e);
    this.setState({
      leadDetailsInput: this.state.leadDetailsInput,
    });
  };
  disabledDOBDate = (current) => {
    return moment(current) > moment().endOf("day");
  };
  //handle get lead list
  handleGetLeadList = (leadID) => {
    var inputData = {};
    inputData.LeadID = leadID;
    inputData.Pageno = 1;
    inputData.Pagesize = 10;
    try {
      this.setState({ isLoading: true });
      this.LeadsService.getLeadList("GetLeadDetails", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadListGridData = response.responseData.leadDetails;
          if (statusCode === 200) {
            this.setState({ leadDetailsInput: leadListGridData[0] });
          } else {
            this.setState({ leadDetailsInput: {} });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleGetSourceData = () => {
    try {
      this.LeadsService.getLeadDropdownData("GetLeadSource")
        .then((response) => {
          var statusCode = response.statusCode;
          var leadSourceData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadSourceData });
          } else {
            this.setState({ leadSourceData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleGetTypeData = () => {
    try {
      this.LeadsService.getLeadDropdownData("GetLeadType")
        .then((response) => {
          var statusCode = response.statusCode;
          var leadTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadTypeData });
          } else {
            this.setState({ leadTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleGetAssignToData = () => {
    var inputData = {};
    inputData.SearchText = "";
    try {
      this.LeadsService.getLeadDropdownData("GeLeadUsers", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadAssignToData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadAssignToData });
          } else {
            this.setState({ leadAssignToData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleGetEnquiryTypeData = () => {
    var inputData = {};
    inputData.BrandID = "0";
    try {
      this.EnquiryService.getEnquiryType("GetEnquiryType", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var enquiryTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ enquiryTypeData });
          } else {
            this.setState({ enquiryTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleGetLeadStatusData = () => {
    try {
      this.LeadsService.getLeadDropdownData("GetLeadStatus")
        .then((response) => {
          var statusCode = response.statusCode;
          var leadStatusData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadStatusData });
          } else {
            this.setState({ leadStatusData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleGetTaskTypeList = () => {
    try {
      this.LeadsService.getLeadDropdownData("GetTaskTypeMaster")
        .then((response) => {
          var statusCode = response.statusCode;
          var TaskTypeList = response.responseData;
          if (statusCode === 200) {
            this.setState({ TaskTypeList });
          } else {
            this.setState({ TaskTypeList: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleInsuranceProviderData = () => {
    try {
      this.LeadsService.getInsuranceProviderData("GetInsuranceProvider")
        .then((response) => {
          var statusCode = response.statusCode;
          var InsuranceProviderData = response.responseData;
          if (statusCode === 200) {
            this.setState({ InsuranceProviderData });
          } else {
            this.setState({ InsuranceProviderData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleUpdateLead = (leadId) => {
    this.handleGetLeadList(leadId);
  };

  handleGetTask = (leadID) => {
    try {
      var inputData = {};
      inputData.IDs = leadID.toString();
      inputData.PageNo = 1;
      inputData.PageSize = 10000;
      this.LeadsService.getLeadDetailsTab("GetLeadTaskByLeadID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var Task = response.responseData.taskDetails;
          if (statusCode === 200) {
            this.setState({
              Task: Task,
            });
          } else {
            this.setState({
              Task: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleTaskModalClose = (isSuccess) => {
    if (isSuccess) {
      this.handleGetTask(this.props.location.state.leadDeatils.leadID);
      this.handleGetLeadList(this.props.location.state.leadDeatils.leadID);
    }
    this.setState({
      taskModal: false,
    });
  };

  render() {
    const { TabPane } = Tabs;
    const { Panel } = Collapse;
    return (
      <div className="LeadDetail">
        <div className="leaDetLeft">
          <div className="LeUpper">
            <div className="leftimg">
              <img
                src={CircleBackIcon}
                alt="Back"
                onClick={this.handleBackLeadListPage.bind(this)}
              />
            </div>
            <div className="userimg">
              {this.state.leadDetailsInput.userProfile ? (
                <img src={this.state.leadDetailsInput.userProfile} alt="User" />
              ) : (
                <img src={SUserIcon} alt="User" />
              )}
              <h4>
                {this.state.leadDetailsInput.customerName}
                {this.state.editAccess && (
                  <img
                    src={EditIcon}
                    alt="Edit"
                    className="leadedit-Icon"
                    onClick={this.handleEditLeadDetails.bind(this)}
                  />
                )}
              </h4>
              <h5>{this.state.leadDetailsInput.occupation}</h5>
            </div>
            <div className="step">
              <ul>
                {this.state.leadDetailsInput.stageName === "New" ? (
                  <>
                    <li className="active">
                      <label style={{ marginTop: "5px" }}>
                        {this.state.leadDetailsInput.stageName}
                      </label>
                    </li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </>
                ) : this.state.leadDetailsInput.stageName === "Working" ? (
                  <>
                    <li className="active"></li>
                    <li className="active">
                      <label style={{ marginTop: "5px" }}>
                        {this.state.leadDetailsInput.stageName}
                      </label>
                    </li>
                    <li></li>
                    <li></li>
                  </>
                ) : this.state.leadDetailsInput.stageName === "Qualified" ? (
                  <>
                    <li className="active"></li>
                    <li className="active"></li>
                    <li className="active">
                      <label style={{ marginTop: "5px" }}>
                        {this.state.leadDetailsInput.stageName}
                      </label>
                    </li>
                    <li></li>
                  </>
                ) : (
                  <>
                    <li className="active"></li>
                    <li className="active"></li>
                    <li className="active"></li>
                    <li className="active">
                      <label style={{ marginTop: "5px" }}>
                        {this.state.leadDetailsInput.stageName}
                      </label>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="LeDown">
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <h4 className="abou">About this Contact</h4>
                <h4 className="dayago">
                  {this.state.leadDetailsInput.modifiedDate}
                </h4>
              </div>
              <div className="col-12 col-md-6">
                <div className="rightUs">
                  {this.state.leadDetailsInput.userProfile ? (
                    <img
                      src={this.state.leadDetailsInput.userProfile}
                      alt="User"
                    />
                  ) : (
                    <img src={SUserIcon} alt="User" />
                  )}
                  <div>
                    <h4 className="abou">
                      {this.state.leadDetailsInput.createdByName}
                    </h4>
                    {/* <h4 className="dayago">Lead Owner</h4> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="abou">First Name</label>
                {this.state.isEdit ? (
                  <React.Fragment>
                    <input
                      type="text"
                      value={this.state.leadDetailsInput.firstName}
                      onChange={this.handleDetailsInputChange.bind(
                        this,
                        "firstName"
                      )}
                    />

                    {this.state.leadDetailsError ? (
                      <div className="text-danger">
                        {this.state.leadDetailsError["firstName"]}
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <label>{this.state.leadDetailsInput.firstName}</label>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="abou">Last Name</label>
                {this.state.isEdit ? (
                  <React.Fragment>
                    <input
                      type="text"
                      value={this.state.leadDetailsInput.lastName}
                      onChange={this.handleDetailsInputChange.bind(
                        this,
                        "lastName"
                      )}
                    />
                    {/*{this.state.leadDetailsError ? (*/}
                    {/*  <div className="text-danger">*/}
                    {/*    {this.state.leadDetailsError["lastName"]}*/}
                    {/*  </div>*/}
                    {/*) : null}*/}
                  </React.Fragment>
                ) : (
                  <label>{this.state.leadDetailsInput.lastName}</label>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="abou">Email</label>
                {this.state.isEdit ? (
                  <React.Fragment>
                    <input
                      type="text"
                      value={this.state.leadDetailsInput.email}
                      onChange={this.handleDetailsInputChange.bind(
                        this,
                        "email"
                      )}
                    />
                    {this.state.leadDetailsError ? (
                      <div className="text-danger">
                        {this.state.leadDetailsError["email"]}
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <label>{this.state.leadDetailsInput.email}</label>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="abou">Contact</label>
                {this.state.isEdit ? (
                  <React.Fragment>
                    <input
                      type="text"
                      value={this.state.leadDetailsInput.phoneNo}
                      onChange={this.handleDetailsInputChange.bind(
                        this,
                        "phoneNo"
                      )}
                    />
                    {this.state.leadDetailsError ? (
                      <div className="text-danger">
                        {this.state.leadDetailsError["phoneNo"]}
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <label>{this.state.leadDetailsInput.phoneNo}</label>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="abou">Date of Birth</label>

                {this.state.isEdit ? (
                  <DatePicker
                    className="mobileinput"
                    placeholder="Lead DOB"
                    style={{ width: "100%" }}
                    value={
                      this.state.leadDetailsInput.dob
                        ? moment(this.state.leadDetailsInput.dob)
                        : null
                    }
                    disabledDate={this.disabledDOBDate.bind(this)}
                    onChange={this.handleDateOnChange.bind(this, "dob")}
                  />
                ) : (
                  <label>
                    {this.state.leadDetailsInput.dob
                      ? moment(this.state.leadDetailsInput.dob)
                          .format("DD-MM-YYYY")
                          .toString()
                      : "-"}
                  </label>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="abou">Age</label>
                <label>
                  {this.state.leadDetailsInput.age
                    ? this.state.leadDetailsInput.age
                    : "-"}
                </label>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="abou">Stage</label>
                <label>{this.state.leadDetailsInput.stageName}</label>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="abou">Source</label>
                <label>{this.state.leadDetailsInput.sourceName}</label>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="abou">Status</label>
                <label>{this.state.leadDetailsInput.statusName}</label>
              </div>
            </div>
            {this.state.isEdit ? (
              <div className="row mb-2 Up">
                <div className="col-12 col-md" style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    loading={this.state.isSubmitLoading}
                    disabled={this.state.isSubmitLoading}
                    onClick={this.handleSubmitCustomerDetails.bind(this)}
                  >
                    Update
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="UserInfoo">
            <Collapse expandIconPosition={["right"]} accordion>
              <Panel key="1" header={<label className="Les">Leads Info</label>}>
                <div className="LeName">
                  <label>Lead Name</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput.customerName}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "customerName"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput.customerName || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Lead Mobile</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput.phoneNo}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "phoneNo"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>{this.state.leadDetailsInput.phoneNo || "-"}</label>
                  )}
                </div>
                <div className="LeName">
                  <label>Alt. No</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput.alternatePhoneNo}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "alternatePhoneNo"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput.alternatePhoneNo || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Gender</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <Select
                        value={this.state.leadDetailsInput.genderID}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "genderID"
                        )}
                        placeholder="genderID"
                        style={{ width: "100%" }}
                      >
                        <Option value={0} key={0}>
                          Male
                        </Option>
                        <Option value={1} key={1}>
                          Female
                        </Option>
                        <Option value={2} key={2}>
                          Other
                        </Option>
                      </Select>
                    </label>
                  ) : (
                    <label>{this.state.leadDetailsInput.gender || "-"}</label>
                  )}
                </div>
                {/* <div className="LeName">
                  <label>Lead DOB</label>:
                  {this.state.isEdit ? (
                    <label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Lead DOB"
                        value={
                          this.state.leadDetailsInput.dob
                            ? moment(this.state.leadDetailsInput.dob)
                            : null
                        }
                        disabled={this.disabledDOBDate.bind(this)}
                        onChange={this.handleDateOnChange.bind(this, "dob")}
                      />
                    </label>
                  ) : (
                    <label>{this.state.leadDetailsInput.dob || "-"}</label>
                  )}
                </div> */}
                <div className="LeName">
                  <label>Lead Email</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput.email}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "email"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>{this.state.leadDetailsInput.email || "-"}</label>
                  )}
                </div>
                <div className="LeName">
                  <label>Empl. Type</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput.employmentType}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "employmentType"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput.employmentType || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Marital Status</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <Select
                        value={
                          this.state.leadDetailsInput.maritalStatusID || null
                        }
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "maritalStatusID"
                        )}
                        placeholder="Marital Status"
                        style={{ width: "100%" }}
                      >
                        {this.master.MaritalStatus
                          ? this.master.MaritalStatus.map((item) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.Name}
                                </Option>
                              );
                            })
                          : null}
                      </Select>
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput.maritalStatus || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Employer Name</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput.companyName}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "companyName"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput.companyName || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Anniv. Date</label>:
                  {this.state.isEdit ? (
                    <label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Anniv. Date"
                        value={
                          this.state.leadDetailsInput.anniversaryDate
                            ? moment(
                                this.state.leadDetailsInput.anniversaryDate
                              )
                            : null
                        }
                        onChange={this.handleDateOnChange.bind(
                          this,
                          "anniversaryDate"
                        )}
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput.anniversaryDate || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>HNI</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput["hni"]}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "hni"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>{this.state.leadDetailsInput["hni"] || "-"}</label>
                  )}
                </div>
                <div className="LeName">
                  <label>Lead Income</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput["income"]}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "income"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput["income"] || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Additional Income</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput["additionalIncome"]}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "additionalIncome"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput["additionalIncome"] || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Occupation</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput["occupation"]}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "occupation"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput["occupation"] || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>MR Number</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput["mrNumber"]}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "mrNumber"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput["mrNumber"] || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Bill No</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput["billNo"]}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "billNo"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput["billNo"] || "-"}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Appointment Date Time</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <DatePicker
                        className="mobileinput"
                        placeholder="Appointment Date Time"
                        format="YYYY-MM-DD HH:mm A"
                        showTime={{ use12Hours: true, format: "HH:mm A" }}
                        value={
                          this.state.leadDetailsInput.appointmentDateTime
                            ? moment(
                                this.state.leadDetailsInput.appointmentDateTime
                              )
                            : null
                        }
                        onChange={this.handleAppointmentDateTimeChange.bind(
                          this
                        )}
                      />
                    </label>
                  ) : (
                    <label>
                      {this.state.leadDetailsInput["appointmentDateTime"]
                        ? moment(
                            this.state.leadDetailsInput["appointmentDateTime"]
                          )
                            .format("YYYY-MM-DD HH:mm A")
                            .toString()
                        : this.state.leadDetailsInput["appointmentDate"] &&
                          this.state.leadDetailsInput["appointmentTime"]
                        ? moment(
                            this.state.leadDetailsInput["appointmentDate"] +
                              " " +
                              this.state.leadDetailsInput["appointmentTime"]
                          )
                            .format("YYYY-MM-DD HH:mm A")
                            .toString()
                        : null}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>Campaign Name</label>:
                  <label>
                    {this.state.leadDetailsInput["campaignName"]
                      ? this.state.leadDetailsInput["campaignName"]
                      : "-"}
                  </label>
                </div>
                <div className="LeName">
                  <label>Created Date</label>:
                  <label>
                    {this.state.leadDetailsInput["createdDate"]
                      ? moment(this.state.leadDetailsInput["createdDate"])
                          .format("YYYY-MM-DD HH:mm A")
                          .toString()
                      : "-"}
                  </label>
                </div>
                <div className="LeName">
                  <label>Modified By</label>:
                  <label>
                    {this.state.leadDetailsInput["modifiedByName"] || "-"}
                  </label>
                </div>
                <div className="LeName">
                  <label>Modified Date</label>:
                  <label>
                    {this.state.leadDetailsInput["modifiedDate"]
                      ? moment(this.state.leadDetailsInput["modifiedDate"])
                          .format("YYYY-MM-DD HH:mm A")
                          .toString()
                      : "-"}
                  </label>
                </div>

                {this.state.isEdit ? (
                  <div className="row mb-2 Up">
                    <div
                      className="col-12 col-md"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="primary"
                        loading={this.state.isSubmitLoading}
                        disabled={this.state.isSubmitLoading}
                        onClick={this.handleSubmitLeadInfo.bind(this)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Panel>
              <Panel
                key="2"
                header={<label className="Les">Contact Info</label>}
              >
                <div className="LeName">
                  <label>Address</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <input
                        type="text"
                        value={this.state.leadDetailsInput.address}
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "address"
                        )}
                        className="lead-details-fromno"
                      />
                    </label>
                  ) : (
                    /*<label>{this.state.leadDetailsInput.address || "-"}</label>*/
                    <label className="tooltips">
                      {this.state.leadDetailsInput.address ? (
                        <>
                          {this.state.leadDetailsInput.address.length > 20
                            ? this.state.leadDetailsInput.address.substring(
                                0,
                                20
                              ) + "...."
                            : this.state.leadDetailsInput.address}
                          <span className="tooltiptext">
                            {this.state.leadDetailsInput.address}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </label>
                  )}
                </div>
                <div className="LeName">
                  <label>State</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <Select
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "state"
                        )}
                        value={this.state.leadDetailsInput.state || null}
                        showArrow={false}
                        showSearch
                        placeholder="State"
                        style={{ width: "100%" }}
                        bordered={false}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                      >
                        {this.state.stateData &&
                          this.state.stateData.map((item) => (
                            <Option value={item.stateID}>
                              {item.stateName}
                            </Option>
                          ))}
                      </Select>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.stateName || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>City</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <Select
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "city"
                        )}
                        value={this.state.leadDetailsInput.city || null}
                        showArrow={false}
                        showSearch
                        placeholder="City"
                        style={{ width: "100%" }}
                        bordered={false}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                      >
                        {this.state.cityData &&
                          this.state.cityData.map((item) => (
                            <Option value={item.cityID}>{item.cityName}</Option>
                          ))}
                      </Select>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.cityName || "-"}
                      </label>
                    )}
                  </label>
                </div>
                {this.state.isEdit ? (
                  <div className="row mb-2 Up">
                    <div
                      className="col-12 col-md"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="primary"
                        loading={this.state.isSubmitLoading}
                        disabled={this.state.isSubmitLoading}
                        onClick={this.handleSubmitContactInfo.bind(this)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Panel>
              <Panel
                key="3"
                header={<label className="Les">Care Taker Details</label>}
              >
                <div className="LeName">
                  <label>Name</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          value={this.state.leadDetailsInput.careTakerName}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "careTakerName"
                          )}
                          className="lead-details-fromno"
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.careTakerName || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Mobile No.</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          value={this.state.leadDetailsInput.careTakerMobileNo}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "careTakerMobileNo"
                          )}
                          className="lead-details-fromno"
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.careTakerMobileNo || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Relationship</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          value={
                            this.state.leadDetailsInput.careTakerRelationship
                          }
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "careTakerRelationship"
                          )}
                          className="lead-details-fromno"
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.careTakerRelationship ||
                          "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Age</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          value={this.state.leadDetailsInput.careTakerAge}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "careTakerAge"
                          )}
                          className="lead-details-fromno"
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {" "}
                        {this.state.leadDetailsInput.careTakerAge || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Email</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          value={this.state.leadDetailsInput.careTakerEmail}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "careTakerEmail"
                          )}
                          className="lead-details-fromno"
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {" "}
                        {this.state.leadDetailsInput.careTakerEmail || "-"}
                      </label>
                    )}
                  </label>
                </div>
                {this.state.isEdit ? (
                  <div className="row mb-2 Up">
                    <div
                      className="col-12 col-md"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="primary"
                        loading={this.state.isSubmitLoading}
                        disabled={this.state.isSubmitLoading}
                        onClick={this.handleSubmitEnquiryBy.bind(this)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Panel>
              <Panel
                key="4"
                header={<label className="Les">Enquiry Details</label>}
              >
                {/* <div className="LeName">
                  <label>MH Id/MR No</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          value={this.state.leadDetailsInput.mhid}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "mhid"
                          )}
                          className="lead-details-fromno"
                        />
                      </React.Fragment>
                    ) : (
                      <label>{this.state.leadDetailsInput.mhid || "-"}</label>
                    )}
                  </label>
                </div> */}
                <div className="LeName">
                  <label>Case Category</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          value={this.state.leadDetailsInput.caseCategory}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "caseCategory"
                          )}
                          className="lead-details-fromno"
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.caseCategory || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Status</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <>
                        <Select
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "leadStatus"
                          )}
                          value={this.state.leadDetailsInput.leadStatus || null}
                          showArrow={false}
                          showSearch
                          placeholder="Status"
                          style={{ width: "100%" }}
                          bordered={false}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                        >
                          {this.state.leadStatusData
                            ? this.state.leadStatusData.map((item, kye) => {
                                return (
                                  <Option
                                    value={item.leadStatusID}
                                    key={item.leadStatusID}
                                    disableTask={item.disableTask}
                                  >
                                    {item.statusName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {this.state.leadDetailsError["leadStatus"]}
                          </div>
                        ) : null}
                      </>
                    ) : (
                      this.state.leadDetailsInput.statusName || "-"
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Sub Status</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <Select
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "leadSubStatus"
                          )}
                          value={
                            this.state.leadDetailsInput.leadSubStatus || null
                          }
                          showArrow={false}
                          showSearch
                          placeholder="Sub Status"
                          style={{ width: "100%" }}
                          bordered={false}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                        >
                          {this.state.leadSubStatusData
                            ? this.state.leadSubStatusData.map((item, kye) => {
                                return (
                                  <Option
                                    value={item.leadSubStatusID}
                                    key={item.leadSubStatusID}
                                  >
                                    {item.subStatusName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {this.state.leadDetailsError["leadSubStatus"]}
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      this.state.leadDetailsInput.subStatusName || "-"
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Speciality</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <Select
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "specialityID"
                          )}
                          value={
                            this.state.leadDetailsInput.specialityID || null
                          }
                          showArrow={false}
                          showSearch
                          placeholder="Speciality"
                          style={{ width: "100%" }}
                          bordered={false}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                        >
                          {this.state.leadSpecialityData
                            ? this.state.leadSpecialityData.map((item) => {
                                return (
                                  <Option
                                    value={item.specialityID}
                                    key={item.specialityID}
                                  >
                                    {item.specialityName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {this.state.leadDetailsError["specialityID"]}
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      this.state.leadDetailsInput.specialityName || "-"
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Lead Type</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <Select
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "leadType"
                          )}
                          value={this.state.leadDetailsInput.leadType}
                          showArrow={false}
                          showSearch
                          placeholder="Lead Type"
                          style={{ width: "100%" }}
                          bordered={false}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                        >
                          {this.state.leadTypeData
                            ? this.state.leadTypeData.map((item) => {
                                return (
                                  <Option
                                    value={item.leadTypeID}
                                    key={item.leadTypeID}
                                  >
                                    {item.leadTypeName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {this.state.leadDetailsError["leadType"]}
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      this.state.leadDetailsInput.leadTypeName || "-"
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Agr. Discount</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          value={this.state.leadDetailsInput.agrDiscount}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "agrDiscount"
                          )}
                          className="lead-details-fromno"
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.agrDiscount || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Creator</label>:
                  <label>
                    {this.state.leadDetailsInput.createdByName || "-"}
                  </label>
                </div>
                <div className="LeName">
                  <label>Assign To</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <Select
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "assignTo"
                          )}
                          value={this.state.leadDetailsInput.assignTo}
                          showArrow={false}
                          showSearch
                          placeholder="Assign To"
                          style={{ width: "100%" }}
                          bordered={false}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                        >
                          {this.state.leadAssignToData
                            ? this.state.leadAssignToData.map((item,i) => {
                                return (
                                  <Option
                                    value={item.userID + "-" + item.isTeamUser}
                                    key={i}
                                    isTeamUser={item.isTeamUser}
                                  >
                                    {item.fullName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {this.state.leadDetailsError["assignTo"]}
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      this.state.leadDetailsInput.assignToName || "-"
                    )}
                  </label>
                </div>
                {this.state.isEdit ? (
                  <div className="row mb-2 Up">
                    <div
                      className="col-12 col-md"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="primary"
                        loading={this.state.isSubmitLoading}
                        disabled={this.state.isSubmitLoading}
                        onClick={this.handleSubmitEnquiryDetails.bind(this)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Panel>
              <Panel
                key="5"
                header={<label className="Les">Enquiry Source</label>}
              >
                <div className="LeName">
                  <label>Enquiry Type</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <Select
                          showArrow={false}
                          showSearch
                          placeholder="Enquiry Type"
                          style={{ width: "100%" }}
                          bordered={false}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "enquiryType"
                          )}
                          value={this.state.leadDetailsInput.enquiryType}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                        >
                          {this.state.enquiryTypeData
                            ? this.state.enquiryTypeData.map((item) => {
                                return (
                                  <Option
                                    value={item.enquiryTypeID}
                                    key={item.enquiryTypeID}
                                  >
                                    {item.enquiryTypeName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {this.state.leadDetailsError["enquiryType"]}
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      this.state.leadDetailsInput.enquiryTypeName
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Enq. Sub. Type</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <Select
                          showArrow={false}
                          showSearch
                          placeholder="Enquiry Sub Type"
                          style={{ width: "100%" }}
                          bordered={false}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "enquirySubType"
                          )}
                          value={this.state.leadDetailsInput.enquirySubType}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                        >
                          {this.state.enquirySubTypeData
                            ? this.state.enquirySubTypeData.map((item) => {
                                return (
                                  <Option
                                    value={item.enquirySubTypeID}
                                    key={item.enquirySubTypeID}
                                  >
                                    {item.enquirySubTypeName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {this.state.leadDetailsError["enquirySubType"]}
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      this.state.leadDetailsInput.enquirySubTypeName
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Lead Source Type</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <Select
                          showArrow={false}
                          showSearch
                          placeholder="Enquiry Type"
                          style={{ width: "100%" }}
                          bordered={false}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "leadSource"
                          )}
                          value={this.state.leadDetailsInput.leadSource}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                        >
                          {this.state.leadSourceData
                            ? this.state.leadSourceData.map((item) => {
                                return (
                                  <Option
                                    value={item.sourceID}
                                    key={item.sourceID}
                                  >
                                    {item.sourceName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {this.state.leadDetailsError["leadSource"]}
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      this.state.leadDetailsInput.sourceName
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Page/Form No.</label>:
                  {this.state.isEdit ? (
                    <label className="seledr">
                      <React.Fragment>
                        <input
                          type="text"
                          className="lead-details-fromno"
                          value={this.state.leadDetailsInput.pageFormName}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "pageFormName"
                          )}
                        />
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {this.state.leadDetailsError["pageFormName"]}
                          </div>
                        ) : null}
                      </React.Fragment>
                    </label>
                  ) : (
                    <label className="tooltips">
                      {this.state.leadDetailsInput["pageFormName"] ? (
                        <>
                          {this.state.leadDetailsInput["pageFormName"].length >
                          30
                            ? this.state.leadDetailsInput[
                                "pageFormName"
                              ].substring(0, 30) + "...."
                            : this.state.leadDetailsInput["pageFormName"]}
                          <span className="tooltiptext">
                            {this.state.leadDetailsInput["pageFormName"]}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </label>
                  )}
                </div>
                {this.state.isEdit ? (
                  <div className="row mb-2 Up">
                    <div
                      className="col-12 col-md"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="primary"
                        loading={this.state.isSubmitLoading}
                        disabled={this.state.isSubmitLoading}
                        onClick={this.handleSubmitEnquirySourceDetails.bind(
                          this
                        )}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Panel>
              <Panel
                key="6"
                header={<label className="Les">Care Center Details</label>}
              >
                <div className="LeName">
                  <label>Unit</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <>
                        <Select
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "unit"
                          )}
                          value={this.state.leadDetailsInput.unit || null}
                          showArrow={false}
                          showSearch
                          placeholder="unit"
                          style={{ width: "100%" }}
                          bordered={false}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                        >
                          {this.state.leadLocationData &&
                            this.state.leadLocationData.map((item) => (
                              <Option value={item.locationID}>
                                {item.locationName}
                              </Option>
                            ))}
                        </Select>
                        {this.state.leadDetailsError ? (
                          <div className="text-danger">
                            {
                              this.state.leadDetailsError[
                                "careCenterLocationID"
                              ]
                            }
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.locationName || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Unit State</label>:
                  <label className="seledr">
                    {/*{this.state.isEdit ? (*/}
                    {/*  <Select*/}
                    {/*    onChange={this.handleDetailsInputChange.bind(*/}
                    {/*      this,*/}
                    {/*      "careCenterStateID"*/}
                    {/*    )}*/}
                    {/*    value={*/}
                    {/*      this.state.leadDetailsInput.careCenterStateID || null*/}
                    {/*    }*/}
                    {/*    showArrow={false}*/}
                    {/*    showSearch*/}
                    {/*    placeholder="State"*/}
                    {/*    style={{ width: "100%" }}*/}
                    {/*    bordered={false}*/}
                    {/*    filterOption={(input, option) =>*/}
                    {/*      option.children*/}
                    {/*        .toLowerCase()*/}
                    {/*        .indexOf(input.toLowerCase()) >= 0*/}
                    {/*    }*/}
                    {/*    optionFilterProp="children"*/}
                    {/*  >*/}
                    {/*    {this.state.stateData &&*/}
                    {/*      this.state.stateData.map((item) => (*/}
                    {/*        <Option value={item.stateID}>*/}
                    {/*          {item.stateName}*/}
                    {/*        </Option>*/}
                    {/*      ))}*/}
                    {/*  </Select>*/}
                    {/*) : (*/}
                    <label>
                      {this.state.leadDetailsInput.careCenterState || "-"}
                    </label>
                    {/*)}*/}
                  </label>
                </div>
                <div className="LeName">
                  <label>Unit City</label>:
                  <label className="seledr">
                    {/*{this.state.isEdit ? (*/}
                    {/*  <Select*/}
                    {/*    onChange={this.handleDetailsInputChange.bind(*/}
                    {/*      this,*/}
                    {/*      "careCenterCityID"*/}
                    {/*    )}*/}
                    {/*    value={*/}
                    {/*      this.state.leadDetailsInput.careCenterCityID || null*/}
                    {/*    }*/}
                    {/*    showArrow={false}*/}
                    {/*    showSearch*/}
                    {/*    placeholder="City"*/}
                    {/*    style={{ width: "100%" }}*/}
                    {/*    bordered={false}*/}
                    {/*    filterOption={(input, option) =>*/}
                    {/*      option.children*/}
                    {/*        .toLowerCase()*/}
                    {/*        .indexOf(input.toLowerCase()) >= 0*/}
                    {/*    }*/}
                    {/*    optionFilterProp="children"*/}
                    {/*  >*/}
                    {/*    {this.state.unitCityData &&*/}
                    {/*      this.state.unitCityData.map((item) => (*/}
                    {/*        <Option value={item.cityID}>{item.cityName}</Option>*/}
                    {/*      ))}*/}
                    {/*  </Select>*/}
                    {/*) : (*/}
                    <label>
                      {this.state.leadDetailsInput.careCenterCity || "-"}
                    </label>
                    {/*)}*/}
                  </label>
                </div>

                {this.state.isEdit ? (
                  <div className="row mb-2 Up">
                    <div
                      className="col-12 col-md"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="primary"
                        loading={this.state.isSubmitLoading}
                        disabled={this.state.isSubmitLoading}
                        onClick={this.handleSubmitCareCenter.bind(this)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Panel>
              <Panel
                key="7"
                header={<label className="Les">Insurance Info</label>}
              >
                <div className="LeName">
                  <label>Insurance Provider Name</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <Select
                          style={{ width: "100%" }}
                          value={
                            this.state.leadDetailsInput.insuranceProviderID ||
                            null
                          }
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "insuranceProviderID"
                          )}
                        >
                          {this.state.InsuranceProviderData
                            ? this.state.InsuranceProviderData.map((item) => {
                                return (
                                  <Option
                                    key={item.providerID}
                                    value={item.providerID + ""}
                                  >
                                    {item.providerName}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.insuranceProvider1 || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Insurance Id</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          className="lead-details-fromno"
                          value={this.state.leadDetailsInput.insurancePolicyNo}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "insurancePolicyNo"
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.insurancePolicyNo || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Cash/Insurance</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <Select
                          style={{ width: "100%" }}
                          value={this.state.leadDetailsInput.cashOrInsurance}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "cashOrInsurance"
                          )}
                        >
                          <Option value="Cash">Cash</Option>
                          <Option value={"Insurance"}>Insurance</Option>
                        </Select>
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.cashOrInsurance || "-"}
                      </label>
                    )}
                  </label>
                </div>

                <div className="LeName">
                  <label>Insurance Date</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <DatePicker
                          className="mobileinput"
                          placeholder="Insurance Date"
                          value={
                            this.state.leadDetailsInput.insuranceDate
                              ? moment(
                                  this.state.leadDetailsInput.insuranceDate
                                )
                              : null
                          }
                          onChange={this.handleDateOnChange.bind(
                            this,
                            "insuranceDate"
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.insuranceDate || "-"}
                      </label>
                    )}
                  </label>
                </div>

                <div className="LeName">
                  <label>Preauthorization Code</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          className="lead-details-fromno"
                          value={
                            this.state.leadDetailsInput.preAuthorizationCode
                          }
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "preAuthorizationCode"
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.preAuthorizationCode ||
                          "-"}
                      </label>
                    )}
                  </label>
                </div>
                {this.state.isEdit ? (
                  <div className="row mb-2 Up">
                    <div
                      className="col-12 col-md"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="primary"
                        loading={this.state.isSubmitLoading}
                        disabled={this.state.isSubmitLoading}
                        onClick={this.handleSubmitInsuranceDetails.bind(this)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Panel>
              <Panel
                key="8"
                header={<label className="Les">Maternity Info</label>}
              >
                <div className="LeName">
                  <label>Consulting Doctor</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <Select
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "cosultingDoctor"
                        )}
                        onFocus={this.handleDetailsInputChange.bind(
                          this,
                          "cosultingDoctor"
                        )}
                        value={
                          parseInt(
                            this.state.leadDetailsInput.cosultingDoctor
                          ) || null
                        }
                        showArrow={false}
                        showSearch
                        placeholder="Consulting Doctor"
                        style={{ width: "100%" }}
                        bordered={false}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                      >
                        {this.state.leadDoctorsData &&
                          this.state.leadDoctorsData.map((item) => (
                            <Option value={item.doctorID}>
                              {item.doctorName}
                            </Option>
                          ))}
                      </Select>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.cosultingDoctorName || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>First Day of Last Period(LMP)</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <DatePicker
                          className="mobileinput"
                          placeholder="LMP"
                          value={
                            this.state.leadDetailsInput.lpm
                              ? moment(this.state.leadDetailsInput.lpm)
                              : null
                          }
                          onChange={this.handleDateOnChange.bind(this, "lmp")}
                          disabledDate={disabledDate}
                        />
                      </React.Fragment>
                    ) : (
                      <label>{this.state.leadDetailsInput["lpm"] || "-"}</label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Estimation Date of delivery(EDD)</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <DatePicker
                          className="mobileinput"
                          placeholder="EDD"
                          value={
                            this.state.leadDetailsInput.edd
                              ? moment(this.state.leadDetailsInput.edd)
                              : null
                          }
                          onChange={this.handleDateOnChange.bind(this, "edd")}
                        />
                      </React.Fragment>
                    ) : (
                      <label>{this.state.leadDetailsInput["edd"] || "-"}</label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Estimated gestational age</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          className="lead-details-fromno"
                          value={this.state.leadDetailsInput.gestationalAge}
                          onChange={this.handleDateOnChange.bind(
                            this,
                            "gestationalage"
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput["gestationalAge"] || "-"}
                      </label>
                    )}
                  </label>
                </div>
                {this.state.isEdit ? (
                  <div className="row mb-2 Up">
                    <div
                      className="col-12 col-md"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="primary"
                        loading={this.state.isSubmitLoading}
                        disabled={this.state.isSubmitLoading}
                        onClick={this.handleSubmitMaternityInfo.bind(this)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Panel>
              <Panel
                key="9"
                header={<label className="Les">Other details</label>}
              >
                <div className="LeName">
                  <label>Enquiry Keywords</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          className="lead-details-fromno"
                          value={this.state.leadDetailsInput.enquiryKeywords}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "enquiryKeywords"
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <label className="tooltips">
                        {this.state.leadDetailsInput["enquiryKeywords"] ? (
                          <>
                            {this.state.leadDetailsInput["enquiryKeywords"]
                              .length > 20
                              ? this.state.leadDetailsInput[
                                  "enquiryKeywords"
                                ].substring(0, 20) + "...."
                              : this.state.leadDetailsInput["enquiryKeywords"]}
                            <span className="tooltiptext">
                              {this.state.leadDetailsInput["enquiryKeywords"]}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Expected Revenue</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          className="lead-details-fromno"
                          value={this.state.leadDetailsInput.expectedRevenue}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "expectedRevenue"
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput["expectedRevenue"] || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Preferred Date & Time to Contact</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <DatePicker
                          className="mobileinput"
                          placeholder="preferredDateTimeForContact"
                          format="DD-MM-YYYY HH:mm A"
                          showTime={{ use12Hours: true, format: "HH:mm A" }}
                          value={
                            this.state.leadDetailsInput
                              .preferredDateTimeForContact
                              ? moment(
                                  this.state.leadDetailsInput
                                    .preferredDateTimeForContact
                                )
                              : null
                          }
                          onChange={this.handlePreferredDateTimeOnChange.bind(
                            this,
                            "preferredDateTimeForContact"
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput[
                          "preferredDateTimeForContact"
                        ]
                          ? moment(
                              this.state.leadDetailsInput
                                .preferredDateTimeForContact
                            )
                              .format("YYYY-MM-DD HH:mm A")
                              .toString()
                          : null}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Requested Service</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <Select
                        onChange={this.handleDetailsInputChange.bind(
                          this,
                          "requestedServiceID"
                        )}
                        onFocus={this.handleDetailsInputChange.bind(
                          this,
                          "requestedServiceID"
                        )}
                        value={
                          parseInt(
                            this.state.leadDetailsInput.requestedServiceID
                          ) || null
                        }
                        showArrow={false}
                        showSearch
                        placeholder="Requested Service"
                        style={{ width: "100%" }}
                        bordered={false}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                      >
                        {this.state.RequestedServiceData &&
                          this.state.RequestedServiceData.map((item) => (
                            <Option value={item.serviceID}>
                              {item.serviceName}
                            </Option>
                          ))}
                      </Select>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput.requestedService || "-"}
                      </label>
                    )}
                  </label>
                </div>

                <div className="LeName">
                  <label>Total Revenue</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          className="lead-details-fromno"
                          value={this.state.leadDetailsInput.totalRevenue}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "totalRevenue"
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <label>
                        {this.state.leadDetailsInput["totalRevenue"] || "-"}
                      </label>
                    )}
                  </label>
                </div>
                <div className="LeName">
                  <label>Remarks</label>:
                  <label className="seledr">
                    <label className="tooltips">
                      {this.state.leadDetailsInput["remarks"] ? (
                        <>
                          {this.state.leadDetailsInput["remarks"].length > 20
                            ? parse(
                                this.state.leadDetailsInput[
                                  "remarks"
                                ].substring(0, 20)
                              ) + "...."
                            : parse(this.state.leadDetailsInput["remarks"])}
                          <span className="tooltiptext">
                            {parse(this.state.leadDetailsInput["remarks"])}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </label>
                  </label>
                </div>
                <div className="LeName">
                  <label>Source URL</label>:
                  <label className="seledr">
                    {this.state.isEdit ? (
                      <React.Fragment>
                        <input
                          type="text"
                          className="lead-details-fromno"
                          value={this.state.leadDetailsInput.sourceURL}
                          onChange={this.handleDetailsInputChange.bind(
                            this,
                            "sourceURL"
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <label className="tooltips">
                        {this.state.leadDetailsInput["sourceURL"] ? (
                          <>
                            {this.state.leadDetailsInput["sourceURL"].length >
                            20
                              ? this.state.leadDetailsInput[
                                  "sourceURL"
                                ].substring(0, 20) + "...."
                              : this.state.leadDetailsInput["sourceURL"]}
                            <span className="tooltiptext">
                              {this.state.leadDetailsInput["sourceURL"]}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </label>
                    )}
                  </label>
                </div>

                {this.state.isEdit ? (
                  <div className="row mb-2 Up">
                    <div
                      className="col-12 col-md"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="primary"
                        loading={this.state.isSubmitLoading}
                        disabled={this.state.isSubmitLoading}
                        onClick={this.handleSubmitOtherDetails.bind(this)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="leaDetRight">
          <Tabs
            activeKey={this.state.selectedTab}
            onChange={this.handleActivityTabChange.bind(this)}
          >
            {this.state.moduleList.isHistoryModule ? (
              <TabPane
                key="1"
                tab={
                  <div>
                    <img src={HistoryBlueIcon} alt="History" />
                    <label className="His">History</label>
                  </div>
                }
              >
                {this.state.selectedTab === "1" &&
                this.state.leadDetailsInput.leadID ? (
                  <HistoryTab
                    LeadID={this.state.leadDetailsInput.leadID}
                    SelectedTab={this.state.selectedTab}
                  />
                ) : null}
              </TabPane>
            ) : null}
            {(this.state.taskCreateAccess ||
              this.state.taskEditAccess ||
              this.state.taskViewAccess) &&
            this.state.moduleList.isTaskModule ? (
              <TabPane
                key="2"
                tab={
                  <div>
                    <img src={TaskIcon} alt="Task" />
                    <label className="His">Task</label>
                  </div>
                }
              >
                {this.state.leadDetailsInput.leadID &&
                this.state.selectedTab === "2" ? (
                  <TaskTab
                    SelectedTab={this.state.selectedTab}
                    LeadID={this.state.leadDetailsInput.leadID}
                    leadStatusData={this.state.leadStatusData}
                    leadAssignToData={this.state.leadAssignToData}
                    TaskTypeList={this.state.TaskTypeList}
                    LeadDetails={this.state.leadDetailsInput}
                    CreateTask={this.state.taskCreateAccess}
                    EditTask={this.state.taskEditAccess}
                    ViewTask={this.state.taskViewAccess}
                    handleUpdateLeadDetails={this.handleUpdateLead}
                  />
                ) : null}
              </TabPane>
            ) : null}
            {(this.state.notesCreateAccess || this.state.notesViewAccess) &&
            this.state.moduleList.isNotModule ? (
              <TabPane
                key="3"
                tab={
                  <div>
                    <img src={NotesIcon} alt="Notes" />
                    <label className="His">Notes</label>
                  </div>
                }
              >
                {this.state.leadDetailsInput.leadID &&
                this.state.selectedTab === "3" ? (
                  <NoteTab
                    SelectedTab={this.state.selectedTab}
                    LeadID={this.state.leadDetailsInput.leadID}
                    CreateNotes={this.state.notesCreateAccess}
                    ViewNotes={this.state.notesViewAccess}
                  />
                ) : null}
              </TabPane>
            ) : null}
            {this.state.emailCreateAccess &&
              (this.state.moduleList.isEmailModule ? (
                <TabPane
                  key="4"
                  tab={
                    <div>
                      <img src={EmailIcon} alt="Email" />
                      <label className="His">Email</label>
                    </div>
                  }
                >
                  {this.state.leadDetailsInput.leadID ? (
                    <EmailTab leadDetails={this.state.leadDetailsInput} />
                  ) : null}
                </TabPane>
              ) : null)}
            {this.state.callViewAccess &&
              (this.state.moduleList.isCallModule ? (
                <TabPane
                  key="5"
                  tab={
                    <div>
                      <img src={CallIcon} alt="Call" />
                      <label className="His">Call</label>
                    </div>
                  }
                >
                  {this.state.leadDetailsInput.leadID ? (
                    <CallTab
                      state={{
                        leadID: this.state.leadDetailsInput.leadID,
                        download: this.state.callDownloadAccess,
                      }}
                    />
                  ) : null}
                </TabPane>
              ) : null)}
            {this.state.moduleList.isMeetModule ? (
              <TabPane
                key="6"
                tab={
                  <div>
                    <img src={MeetIcon} alt="Meet" />
                    <label className="His">Meet</label>
                  </div>
                }
              >
                {this.state.leadDetailsInput.leadID ? (
                  <MeetTab LeadID={this.state.leadDetailsInput.leadID} />
                ) : null}
              </TabPane>
            ) : null}
            {this.state.smsCreateAccess &&
              (this.state.moduleList.isSMSModule ? (
                <TabPane
                  key="7"
                  tab={
                    <div>
                      <img src={SMSIcon} alt="Meet" />
                      <label className="His">SMS</label>
                    </div>
                  }
                >
                  {this.state.leadDetailsInput.leadID ? (
                    <SMSTab
                      LeadID={this.state.leadDetailsInput.leadID}
                      MobileNumber={this.state.leadDetailsInput.phoneNo}
                    />
                  ) : null}
                </TabPane>
              ) : null)}
          </Tabs>
        </div>
        <Modal
          closable={false}
          visible={this.state.taskModal}
          className="AddCreateTaskModal"
          footer={null}
          keyboard={true}
          onCancel={this.handleTaskModalClose.bind(this, false)}
        >
          <AddTaskComponent
            leadStatusList={this.state.leadStatusData}
            AssignToList={this.state.leadAssignToData}
            handleCreateTaskClose={this.handleTaskModalClose}
            TaskTypeList={this.state.TaskTypeList}
            LeadID={this.state.leadID}
            FollowUp={false}
            LeadDetails={this.state.leadDetailsInput}
            isEditTask={false}
            editTaskDetails={null}
          />
        </Modal>
      </div>
    );
  }
}

export default LeadDetails;
