import Configuration from "../../helpers/Configuration";
import axios from "axios";

class ReportService {
    constructor() {
        this.config = new Configuration();
    }

    async getLeadsSLAReport(url, inputData) {
       
        const response = await axios(this.config.ReportServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getLocationWiseReport(url, inputData) {
        const response = await axios(this.config.ReportServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getLeadsRawReport(url, inputData) {
        const response = await axios(this.config.ReportServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

}
export default ReportService;
