import React, { Fragment, Component } from "react";
import CKEditor from "ckeditor4-react";
import { Button, Select } from "antd";
import NotificationManager from "react-notifications/lib/NotificationManager";
import LeadsService from "../../../services/Leads/LeadsService";
import Dropzone from "react-dropzone";
import { CloseCircleIcon } from "../../../helpers/ImagesImport";
import AlertService from "../../../services/AdminSetting/AlertService";
import TemplateService from "../../../services/AdminSetting/TemplateService";
import CommonService from "../../../services/Common/CommonService";

export default class EmailTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      To: props.leadDetails.email,
      From: "",
      Subject: "",
      MailBody: "",
      BCC: "",
      CC: "",
      isLoading: false,
      isTo: "",
      isFrom: "",
      isSubject: "",
      isMailBody: "",
      isCC: false,
      isBCC: false,
      AttachementFiles: [],
      placeholderData: [],
      placeholder: null,
      ckCusrsorPosition: 0,
      ckCusrsorData: "",
      templateList: [],
      emailSmsConfig: {},
    };
    this.leadsService = new LeadsService();
    this.alertService = new AlertService();
    this.templateService = new TemplateService();
    this.commonService = new CommonService();
  }
  componentDidMount() {
    this.handleGetTenantEmailSMSConfig();
    this.handleGetPlaceholderData();
    this.handleGetTemplateMaster();
  }
  handleEmailInputChange = (name, e) => {
    if (name === "To") {
      this.setState({
        isTo: "",
        To: e.target.value,
      });
    }
    if (name === "From") {
      this.setState({
        isForm: "",
        Form: e.target.value,
      });
    }
    if (name === "Subject") {
      this.setState({
        isSubject: "",
        Subject: e.target.value,
      });
    }
    if (name === "MailBody") {
      var value = e.editor.getData();
      this.setState({
        isMailBody: "",
        MailBody: value,
      });
    }
    if (name === "CC") {
      this.setState({
        CC: e.target.value,
      });
    }
    if (name === "BCC") {
      this.setState({
        BCC: e.target.value,
      });
    }
  };
  handleCheckValidation = () => {
    var isValid = true;
    if (!this.state.To) {
      isValid = false;
      this.setState({
        isTo: "Please enter from.",
      });
    }
    if (!this.state.From) {
      isValid = false;
      this.setState({
        isForm: "Please enter from.",
      });
    }
    if (!this.state.Subject) {
      isValid = false;
      this.setState({
        isSubject: "Please enter subject.",
      });
    }
    if (!this.state.MailBody) {
      isValid = false;
      this.setState({
        isMailBody: "Please enter mail content.",
      });
    }

    return isValid;
  };
  handleSendEmail = () => {
    if (this.handleCheckValidation()) {
      try {
        let form = new FormData();
        form.append("isEmail", 1);
        form.append("LeadID", this.props.leadDetails.leadID);
        form.append("to", this.state.To);
        form.append("from", this.state.From);
        form.append("html", this.state.MailBody);
        form.append("bcc", this.state.BCC);
        form.append("cc", this.state.CC);
        form.append("subject", this.state.Subject);
        this.state.AttachementFiles.forEach((element) => {
          form.append("Files", element);
        });

        this.setState({
          isLoading: true,
        });
        this.leadsService
          .sendEmailWithFile("SendLeadEmailSMS", form)
          .then((response) => {
            var statusCode = response.statusCode;
            this.setState({
              isLoading: false,
            });
            if (statusCode === 200) {
              this.setState({
                MailBody: "",
                Subject: "",
                isMailBody: "",
                isSubject: "",
                isForm: "",
                isTo: "",
                placeholder: null,
                template: null,
                ckCusrsorPosition: 0,
                ckCusrsorData: "",
                AttachementFiles: [],
                isCC: false,
                isBCC: false,
                BCC: "",
                CC: "",
              });
              if (this.props.isAction) {
                this.props.handleEmailActionModalClose();
              }
              NotificationManager.success("Email send successfully.");
            } else {
              NotificationManager.error("Mail send fail.");
            }
          })
          .catch((error) => {
            NotificationManager.error("Mail send fail.");
            this.setState({
              isLoading: false,
            });
            console.log(error);
          });
      } catch (error) {
        this.setState({
          isLoading: false,
        });
        console.log(error);
      }
    }
  };
  fileUpload = (e) => {
    e.forEach((element) => {
      this.state.AttachementFiles.push(element);
    });
    this.setState({
      AttachementFiles: this.state.AttachementFiles,
    });
  };
  handleRemoveFile(i) {
    let AttachementFiles = [...this.state.AttachementFiles];
    AttachementFiles.splice(i, 1);
    this.setState({ AttachementFiles });
  }
  handleGetPlaceholderData = () => {
    var inputData = {};
    inputData.AlertID = 0;
    try {
      this.alertService
        .getPlaceholderData("GetMailParameter", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var placeholderData = response.responseData;
          if (statusCode === 200) {
            this.setState({ placeholderData });
          } else {
            this.setState({ placeholderData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handlePleaceholerChnage = (name, e) => {
    this.setState({
      [name]: e,
    });
    if (name === "template") {
      var smsBody = this.state.templateList.filter((x) => x.templateID === e)[0]
        .templateBody;

      this.setState({ MailBody: smsBody });
    } else {
      let ckData = this.state.MailBody;
      let ckDataArr = ckData.split("\n\n");
      let ckDataArrNew = [];
      for (let i = 0; i < ckDataArr.length; i++) {
        const element1 = ckDataArr[i].replace(/<[^>]+>/g, "");
        const element2 = element1.replace(/&nbsp;/g, " ");
        const element = element2.replace(/\n/g, " ");
        ckDataArrNew.push(element);
      }
      let selectedVal = "",
        loopFlag = true,
        ckTags,
        selectedArr;
      for (let i = 0; i < ckDataArrNew.length; i++) {
        if (loopFlag) {
          if (this.state.ckCusrsorData.trim() === ckDataArrNew[i].trim()) {
            selectedVal = ckDataArrNew[i];
            selectedArr = i;
            ckTags = ckDataArr[i].match(/<[^>]+>/g);
            loopFlag = false;
          }
        }
      }
      let ckDataArrLast = selectedVal;
      let textBefore = ckDataArrLast.substring(0, this.state.ckCusrsorPosition);
      let textAfter = ckDataArrLast.substring(
        this.state.ckCusrsorPosition,
        ckDataArrLast.length
      );

      let placeholderName = e;

      // ck += placeholderName;
      ckDataArrLast = textBefore + " " + placeholderName + textAfter;
      let newCkCusrsorPosition =
        this.state.ckCusrsorPosition + placeholderName.length + 1;
      this.setState({
        ckCusrsorPosition: newCkCusrsorPosition,
        ckCusrsorData: ckDataArrLast,
      });
      if (ckTags) {
        // let ckFinal = ckTags[0] + ck + ckTags[1];
        let ckFinal = ckTags[0] + ckDataArrLast + ckTags[1];
        // ckDataArr.push(ckFinal);
        ckDataArr.splice(selectedArr, 1, ckFinal);
        ckData = ckDataArr.join(" ");
      }
      if (ckTags) {
        this.setState({ MailBody: ckData });
      } else {
        this.setState({ MailBody: ckDataArrLast });
      }
    }
  };
  onCkBlur = (evt) => {
    var ckCusrsorPosition = evt.editor.getSelection().getRanges()[0];
    var ckCusrsorData = evt.editor.getSelection().getRanges()[0].endContainer.$
      .wholeText;
    if (!ckCusrsorData) {
      ckCusrsorData = "";
    }
    this.setState({
      ckCusrsorPosition: ckCusrsorPosition.startOffset,
      ckCusrsorData,
    });
  };
  handleGetTemplateMaster = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      this.templateService
        .getTemplate("GetTemplateMaster", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var templateList = response.responseData;
          if (statusCode === 200) {
            this.setState({ templateList: templateList });
          } else {
            this.setState({ templateList: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleGetTenantEmailSMSConfig = () => {
    try {
      this.commonService
        .getAccessTypeList("GetTenantEmailSMSConfig")
        .then((response) => {
          var statusCode = response.statusCode;
          var responseData = response.responseData[0];
          if (statusCode === 200) {
            this.setState({ From: responseData.emailUserID||"" });
          } else {
            this.setState({ From: "" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    return (
      <Fragment>
        <div className="EmailMain">
          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <div className="ToEmai">
                <label>To</label>
                <input
                  type="text"
                  value={this.state.To}
                  onChange={this.handleEmailInputChange.bind(this, "To")}
                />
              </div>
              {this.state.isTo ? (
                <div className="text-danger">{this.state.isTo}</div>
              ) : null}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <div className="ToEmai">
                <label>from</label>
                <input
                  type="text"
                  value={this.state.From}
                  onChange={this.handleEmailInputChange.bind(this, "From")}
                  disabled={true}
                />
                <label className="BCC">
                  {!this.state.isCC ? (
                    <span
                      onClick={() =>
                        this.setState({
                          isCC: true,
                        })
                      }
                    >
                      CC
                    </span>
                  ) : null}
                  {!this.state.isBCC ? (
                    <span
                      onClick={() =>
                        this.setState({
                          isBCC: true,
                        })
                      }
                    >
                      BCC
                    </span>
                  ) : null}
                </label>
              </div>
            </div>
          </div>
          {this.state.isCC ? (
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <div className="ToEmai">
                  <label
                    onClick={() =>
                      this.setState({
                        isCC: !this.state.isCC,
                      })
                    }
                  >
                    CC
                  </label>
                  <input
                    type="text"
                    value={this.state.CC}
                    onChange={this.handleEmailInputChange.bind(this, "CC")}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {this.state.isBCC ? (
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <div className="ToEmai">
                  <label
                    onClick={() =>
                      this.setState({
                        isBCC: !this.state.isBCC,
                      })
                    }
                  >
                    BCC
                  </label>
                  <input
                    type="text"
                    value={this.state.BCC}
                    onChange={this.handleEmailInputChange.bind(this, "BCC")}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <div className="ToEmai">
                <label>Subject</label>
                <input
                  type="text"
                  value={this.state.Subject}
                  onChange={this.handleEmailInputChange.bind(this, "Subject")}
                />
              </div>
              {this.state.isSubject ? (
                <div className="text-danger">{this.state.isSubject}</div>
              ) : null}
            </div>
          </div>
          <div className="row SendBtn">
            <div className="col-3 col-md-3">
              <Dropzone
                onDrop={this.fileUpload.bind(this)}
                multiple={true}
                accept=".jpg, .jpeg, .png, .webp, .pdf, .doc, .docx"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input
                      {...getInputProps()}
                      className="file-upload d-none"
                    />
                    <Button>Choose File</Button>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          <div style={{ display: "inline" }}>
            {this.state.AttachementFiles ? (
              <div className="email-file-div">
                {this.state.AttachementFiles.map((item, i) => {
                  return (
                    <div className="row">
                      <div className="col-md-8">{item.name}</div>
                      <div className="col-md-2">
                        <img
                          src={CloseCircleIcon}
                          alt="close Icon"
                          onClick={this.handleRemoveFile.bind(this, i)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className="row SendBtn">
            <div className="col-6 col-md-6">
              <Select
                placeholder="Select Template"
                value={this.state.template}
                className="SmsMain-pleacehoder"
                onChange={this.handlePleaceholerChnage.bind(this, "template")}
              >
                {this.state.templateList &&
                  this.state.templateList.map((item) => (
                    <Select.Option value={item.templateID}>
                      {item.templateName}
                    </Select.Option>
                  ))}
              </Select>
            </div>
            <div className="col-6 col-md-6">
              <Select
                placeholder="Select Placeholder"
                value={this.state.placeholder}
                className="SmsMain-pleacehoder"
                onChange={this.handlePleaceholerChnage.bind(
                  this,
                  "placeholder"
                )}
              >
                {this.state.placeholderData &&
                  this.state.placeholderData.map((item) => (
                    <Select.Option value={item.parameterName}>
                      {item.description}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="row mb-3 SendBtn">
            <div className="col-12 col-md-12">
              <CKEditor
                key="MailSend"
                data={this.state.MailBody}
                onChange={this.handleEmailInputChange.bind(this, "MailBody")}
                onBlur={this.onCkBlur.bind(this)}
                config={{
                  toolbar: [
                    {
                      name: "basicstyles",
                      items: ["Bold", "Italic", "Strike"],
                    },
                    {
                      name: "styles",
                      items: ["Styles", "Format"],
                    },
                    {
                      name: "paragraph",
                      items: ["NumberedList", "BulletedList"],
                    },
                    {
                      name: "links",
                      items: ["Link", "Unlink"],
                    },
                    {
                      name: "insert",
                      items: ["Image", "Table"],
                    },
                    {
                      name: "tools",
                      items: ["Maximize"],
                    },
                    {
                      name: "editing",
                      items: ["Scayt"],
                    },
                  ],
                }}
              />
              {this.state.isMailBody ? (
                <div className="text-danger">{this.state.isMailBody}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md" style={{ textAlign: "right" }}>
              {this.props.isAction ? (
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    this.setState({
                      AttachementFiles: [],
                      MailBody: "",
                      Subject: "",
                      isMailBody: "",
                      isSubject: "",
                      isTo: "",
                    });
                    this.props.handleEmailActionModalClose();
                  }}
                >
                  Cancel
                </Button>
              ) : null}
              <Button
                type="primary"
                loading={this.state.isLoading}
                disabled={this.state.isLoading}
                onClick={this.handleSendEmail.bind(this)}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
