import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Table, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import { NotificationManager } from "react-notifications";

export default class LeadSourceMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAccess: true,
            viewAccess: true,
            searchBy: "",
            sourceInput: {},
            sourceError: {},
            isEdit: false,
            isSubmitLoading: false,
            loading: false,
            sourceModel: false,
        };
    }
    componentDidMount() {

    }

    handleSourceModalOpen = () => {
        this.setState({ sourceModel: true, sourceInput: { status: true } });
    };

    handleSourceModalClose = () => {
        this.setState({
            sourceModel: false,
            sourceInput: {},
            sourceError: {},
            isEdit: false,
        });
    };
    handleOnChangeInput = async (name, e) => {
        var sourceInput = this.state.sourceInput;
        sourceInput[name] = e.target.value;
        await this.setState({
            sourceInput,
        });
    };

    handleOnChange = (name, e) => {
        var sourceInput = this.state.sourceInput;

        sourceInput[name] = e;
        this.setState({
            sourceInput,
        });
    };



    handleSourceValidation = () => {
        let sourceInput = this.state.sourceInput;
        let sourceError = {};
        let isValid = true;

        if (!sourceInput["sourceName"]) {
            isValid = false;
            sourceError["sourceName"] = "Please enter source name.";
        }
       


        this.setState({
            sourceError,
        });
        return isValid;
    };








    render() {
        const { Option } = Select;
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                Lead Source Master
                                {this.state.createAccess &&
                                    <img
                                        src={AddIcon}
                                        alt="add"
                                    onClick={this.handleSourceModalOpen.bind(this)}
                                    />}
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                name="searchByTeam"
                                value={this.state.searchByTeam}
                                //onChange={this.handleSerchInputChange.bind(this)}
                                placeholder="Search by..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>










                    <Modal
                        closable={false}
                        title={!this.state.isEdit ? "Create Source" : "Edit Source"}
                        visible={this.state.sourceModel}
                        footer={[
                            <Button key="back" onClick={this.handleSourceModalClose.bind(this)}>
                                Cancel
              </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={this.state.isSubmitLoading}
                                onClick={this.handleSourceValidation.bind(this)}
                            >
                                {this.state.isEdit ? "Update" : "Create"}
                            </Button>,
                        ]}
                    >
                        <div className="createPrior">
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Source Name</label>
                                    <Input
                                        placeholder="Enter Source Name"
                                        name="sourceName"
                                        value={this.state.sourceInput.sourceName}
                                        onChange={this.handleOnChangeInput.bind(this, "sourceName")}
                                    />
                                    {this.state.sourceError ? (
                                        <div className="text-danger">
                                            {this.state.sourceError["sourceName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                           
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Status</label>
                                    <Select
                                        value={this.state.sourceInput.status}
                                        onChange={this.handleOnChange.bind(this, "status")}
                                        placeholder={"Select Status"}
                                    >
                                        <Option value={true} >Active</Option>
                                        <Option value={false}>Inactive</Option>
                                    </Select>
                                    {this.state.sourceError ? (
                                        <div className="text-danger">
                                            {this.state.sourceError["status"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>





                        </div>

                    </Modal>













                </div>
            </div>
        );
    }
}
