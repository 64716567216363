import { Button, Dropdown, Menu } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { Fragment, Component } from "react";
import { ColumnIcon } from "../../../helpers/ImagesImport";
import LeadsService from "../../../services/Leads/LeadsService";

export default class LeadFilterColumnList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropDownOpen: false,
      leadColumnData: [],
      fixedColumn: [],
    };
    this.leadsService = new LeadsService();
  }
  componentDidMount() {
    var leadColumnData = localStorage.getItem("leadColumnsData");
    if (leadColumnData) {
      this.setState({
        leadColumnData: JSON.parse(leadColumnData),
      });
    }
    if (!leadColumnData) {
      this.handleGetLeadColumnName();
    }
  }
  //handle get lead column name
  handleGetLeadColumnName = () => {
    try {
      this.leadsService
        .getLeadColumnName("GetLeadColumnName")
        .then((response) => {
          var statusCode = response.statusCode;
          var leadColumnData = response.responseData;          
          if (statusCode === 200) {
            leadColumnData.forEach((element) => {
              if (element.columnName === "CustomerName") {
                element["isFixed"] = true;
                element["addedColumn"] = true;
              } else if (element.columnName === "PhoneNo") {
                element["isFixed"] = true;
                element["addedColumn"] = true;
              } else if (element.columnName === "Email") {
                element["isFixed"] = true;
                element["addedColumn"] = true;
              } else {
                element["isFixed"] = false;
                element["addedColumn"] = false;
              }
            });
            localStorage.setItem("leadColumnsData",JSON.stringify(leadColumnData))
            this.setState({
              leadColumnData: leadColumnData,
              fixedColumn: leadColumnData.filter((x) => x.isFixed),
            });
          } else {
            this.setState({
              leadColumnData: [],
              fixedColumn: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleAddedCheckboxchange = (id, e) => {
    this.state.leadColumnData.filter((x) => x.id == id)[0].addedColumn =
      e.target.checked;
    this.setState({
      leadColumnData: this.state.leadColumnData,
    });
  };
  handleRemoveCheckboxchange = (id, e) => {
    this.state.leadColumnData.filter((x) => x.id === id)[0].addedColumn =
      e.target.checked;
    this.setState({
      leadColumnData: this.state.leadColumnData,
    });
  };
  handleApplyClick = () => {
    localStorage.setItem(
      "leadColumnsData",
      JSON.stringify(this.state.leadColumnData)
    );
    this.setState({
      isDropDownOpen: false,
    });
    this.props.handleCallBackToList();
  };
  handleCancelClick = () => {
    var leadColumnsData = localStorage.getItem("leadColumnsData");
    if (leadColumnsData) {
      this.setState({
        leadColumnData: JSON.parse(leadColumnsData),
        isDropDownOpen: false,
      });
    }
  };
  handleIconClick = () => {
    this.setState({
      isDropDownOpen: !this.state.isDropDownOpen,
    });
  };
  render() {
    const columnlist = (
      <Menu>
        <div className="Cate3 column-list filter-div">
          <div>
            <h6>Fixed columns</h6>
            <ul>
              {this.state.fixedColumn
                ? this.state.fixedColumn.map((item) => {
                    return (
                      <li key={item.id}>
                        <label>{item.columnName}</label>
                      </li>
                    );
                  })
                : null}
            </ul>
            <hr />
            <h6>Columns you've added</h6>
            <ul>
              {this.state.leadColumnData
                ? this.state.leadColumnData
                    .filter((x) => x.addedColumn === true)
                    .map((item) => {
                      return item.id ? (
                        item.columnName !== "LeadID" &&
                        item.columnName !== "CustomerName" &&
                        item.columnName !== "FirstName" &&
                        item.columnName !== "MiddleName" &&
                        item.columnName !== "LastName" &&
                        item.columnName !== "PhoneNo" &&
                        item.columnName !== "Email" &&
                        item.columnName !== "TenantID" ? (
                          <li key={item.id}>
                            <Checkbox
                              checked={item.addedColumn}
                              onChange={this.handleRemoveCheckboxchange.bind(
                                this,
                                item.id
                              )}
                            >
                              {item.columnName}
                            </Checkbox>
                          </li>
                        ) : null
                      ) : null;
                    })
                : null}
            </ul>
            <hr />
            <h6>Columns you can add</h6>

            <ul>
              {this.state.leadColumnData
                ? this.state.leadColumnData
                    .filter((x) => x.addedColumn === false)
                    .map((item) => {
                      return item.id ? (
                        item.columnName !== "LeadID" &&
                        item.columnName !== "CustomerName" &&
                        item.columnName !== "FirstName" &&
                        item.columnName !== "MiddleName" &&
                        item.columnName !== "LastName" &&
                        item.columnName !== "PhoneNo" &&
                        item.columnName !== "Email" &&
                        item.columnName !== "TenantID" ? (
                          <li key={item.id}>
                            <Checkbox
                              onChange={this.handleRemoveCheckboxchange.bind(
                                this,
                                item.id
                              )}
                              checked={item.addedColumn}
                            >
                              {item.columnName}
                            </Checkbox>
                          </li>
                        ) : null
                      ) : null;
                    })
                : null}
            </ul>
          </div>
          <hr />
          <div className="column-ftr-btn-div">
            <Button onClick={this.handleCancelClick.bind(this)}>Cancel</Button>
            <Button onClick={this.handleApplyClick.bind(this)} type="primary">
              Apply
            </Button>
          </div>
        </div>
      </Menu>
    );
    return (
      <Fragment>
        <Dropdown
          visible={this.state.isDropDownOpen}
          overlay={columnlist}
          trigger={["click"]}
          overlayStyle={{ boxShadow: "0px 0px 5px #888888" }}
        >
          <img
            style={{ width: "16px" }}
            src={ColumnIcon}
            alt="Dropdown"
            onClick={this.handleIconClick.bind(this)}
          />
        </Dropdown>
      </Fragment>
    );
  }
}
