import React, { Component, Fragment } from "react";
import { Button, Select } from "antd";
import LeadsService from "../../../services/Leads/LeadsService";
import Modal from "antd/lib/modal/Modal";
import { NotificationManager } from "react-notifications";
const { Option } = Select;

export default class LeadActionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disqualifyModal: false,
      LeadStatusList: props.LeadStatusList,
      LeadSubStatusList: [],
      disqualifyInput: {
        LeadStatus: "Disqualified",
        LeadSubStatus: "Disqualified",
      },
      disqualifyError: {},
      isSubmitLoading: false,
      leadID: 0,
    };
    this.leadsService = new LeadsService();
  }
  componentDidMount() {
    this.handleGetLeadSubStatusList();
  }
  handleDisqualifyModalOpen = (leadStatusList, leadID) => {
    this.setState({
      disqualifyModal: true,
      LeadStatusList: leadStatusList,
      leadID: leadID,
    });
  };
  handleDisqualifyModalClose = (isSuccess) => {
    this.setState({
      disqualifyModal: false,
      disqualifyInput: {},
      disqualifyError: {},
      LeadSubStatusList: [],
    });
    this.props.handleCloseDisqualifyModalClose(isSuccess);
  };
  handleDisqualifyInputChange = async (name, e) => {
    var disqualifyInput = this.state.disqualifyInput;
    var disqualifyError = this.state.disqualifyError;
    if (e.target) {
      disqualifyInput[name] = e.target.value;
      disqualifyError[name] = "";
    } else {
      disqualifyInput[name] = e;
      disqualifyError[name] = "";
      if (name === "LeadStatus") {
        this.handleGetLeadSubStatusList();
      }
    }
    await this.setState({
      disqualifyInput,
      disqualifyError,
    });
  };
  handleGetLeadSubStatusList = () => {
    var inputData = {};
    var StatusID = this.state.LeadStatusList.filter(
      (x) =>
        x.statusName.toLowerCase() ===
        this.state.disqualifyInput.LeadStatus.toLowerCase()
    )[0].leadStatusID;
    inputData.LeadStatusID = StatusID;
    try {
      this.leadsService
        .getLeadDropdownData("GetLeadSubStatus", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var LeadSubStatusList = response.responseData;
          if (statusCode === 200) {
            this.setState({ LeadSubStatusList });
          } else {
            this.setState({ LeadSubStatusList: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDisqualifyInputValidation = () => {
    var disqualifyInput = this.state.disqualifyInput;
    var disqualifyError = this.state.disqualifyError;
    var isValid = true;

    if (!disqualifyInput["LeadStatus"]) {
      isValid = false;
      disqualifyError["LeadStatus"] = "Please select lead status.";
    }
    if (!disqualifyInput["LeadSubStatus"]) {
      isValid = false;
      disqualifyError["LeadSubStatus"] = "Please select lead sub status.";
    }
    if (!disqualifyInput["Reason"]) {
      isValid = false;
      disqualifyError["Reason"] = "Please enter reason.";
    }
    this.setState({
      disqualifyError,
    });
    return isValid;
  };
  handleDisqualifySave = () => {
    if (this.handleDisqualifyInputValidation()) {
      this.setState({
        isSubmitLoading: true,
      });
      var inputData = {};
      inputData.LeadID = this.props.LeadID;

      var StatusID = this.state.LeadStatusList.filter(
        (x) =>
          x.statusName.toLowerCase() ===
          this.state.disqualifyInput.LeadStatus.toLowerCase()
      )[0].leadStatusID;

      var SubStatusID = this.state.LeadSubStatusList.filter(
        (x) =>
          x.subStatusName.toLowerCase() ===
          this.state.disqualifyInput.LeadSubStatus.toLowerCase()
      )[0].leadSubStatusID;

      inputData.StatusID = StatusID;
      inputData.SubStatusID = SubStatusID;
      inputData.Reason = this.state.disqualifyInput.Reason;
      try {
        this.leadsService
          .getLeadDetailsTab("UpdateLeadIDStatus", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
            this.setState({
              isSubmitLoading: false,
            });
            if (statusCode === 200) {
              this.setState({
                disqualifyInput: {},
                disqualifyError: {},
              });
              this.handleDisqualifyModalClose(true);
              NotificationManager.success("Record update successfully.");
            } else {
              NotificationManager.error("Record not update.");
            }
          })
          .catch((error) => {
            NotificationManager.error("Record not update.");
            this.setState({
              isSubmitLoading: false,
            });
            console.log(error);
          });
      } catch (error) {
        NotificationManager.error("Record not update.");
        this.setState({
          isSubmitLoading: false,
        });
        console.log(error);
      }
    }
  };

  render() {
    return (
      <Fragment>
        <Modal
          visible={this.state.disqualifyModal}
          title="Lead Status Change with Action"
          footer={[
            <Button onClick={this.handleDisqualifyModalClose.bind(this, false)}>
              Cancel
            </Button>,
            <Button
              type="primary"
              onClick={this.handleDisqualifySave.bind(this)}
              loading={this.state.isSubmitLoading}
              disabled={this.state.isSubmitLoading}
            >
              Save
            </Button>,
          ]}
          closable={false}
          className="AddCreateTaskModal"
        >
          <div className="addtas">
            <div className="customertab">
              <div className="addtask-conent">
                <div className="row mb-4">
                  <div className="col-12 col-md-6">
                    <label className="mobile">Lead Status</label>
                    <Select
                      value={this.state.disqualifyInput.LeadStatus}
                      onChange={this.handleDisqualifyInputChange.bind(
                        this,
                        "LeadStatus"
                      )}
                      placeholder="Lead Status"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp="children"
                      disabled={true}
                    >
                      {this.state.LeadStatusList
                        ? this.state.LeadStatusList.map((item) => {
                            return (
                              <Option
                                value={item.statusName}
                                key={item.leadStatusID}
                              >
                                {item.statusName}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                    {this.state.disqualifyError ? (
                      <div className="text-danger">
                        {this.state.disqualifyError["LeadStatus"]}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="mobile">Sub Status</label>
                    <Select
                      disabled={true}
                      value={this.state.disqualifyInput.LeadSubStatus}
                      onChange={this.handleDisqualifyInputChange.bind(
                        this,
                        "LeadSubStatus"
                      )}
                      placeholder="Lead Sub Status"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp="children"
                    >
                      {this.state.LeadSubStatusList
                        ? this.state.LeadSubStatusList.map((item) => {
                            return (
                              <Option
                                value={item.subStatusName}
                                key={item.leadSubStatusID}
                              >
                                {item.subStatusName}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                    {this.state.disqualifyError ? (
                      <div className="text-danger">
                        {this.state.disqualifyError["LeadSubStatus"]}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-md-12">
                    <label className="mobile">Reason</label>
                    <input
                      type="text"
                      className="mobileinput"
                      Name="Reason"
                      placeholder="Enter Reason"
                      value={this.state.disqualifyInput.Reason}
                      onChange={this.handleDisqualifyInputChange.bind(
                        this,
                        "Reason"
                      )}
                    />
                    {this.state.disqualifyError ? (
                      <div className="text-danger">
                        {this.state.disqualifyError["Reason"]}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
