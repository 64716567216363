import React, { Component } from "react";
import { Select, Table, DatePicker, Modal, Button } from "antd";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Download from "./../../../assets/images/Download.svg";
import ReportService from "../../../services/Report/ReportService";
import moment from "moment";
import LeadsService from "../../../services/Leads/LeadsService";
import EnquiryService from "../../../services/AdminSetting/EnquiryService";

const { RangePicker } = DatePicker;
const { Option } = Select;
class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ReportGridData: [
        {
          reportId: 1,
          reportName: "SLA report",
        },
        {
          reportId: 2,
          reportName: "Location wise report",
        },
        {
          reportId: 3,
          reportName: "Download Leads Report",
        },
      ],
      reportModal: false,
      isDownloadReport: false,
      reportFilterInput: {},
      reportID: 0,
      slaReportData: "",
      locationReportData: "",
      LeadsRawReportData: "",
      leadStatusData: [],
      leadAssignToData: [],
      leadSubStatusData: [],
      enquiryTypeData: [],
      enquirySubTypeData: [],
      isSubmitLoading: false,
    };

    this.ReportService = new ReportService();
    this.LeadsService = new LeadsService();
    this.EnquiryService = new EnquiryService();
  }

  componentDidMount() {
    this.handleGetLeadStatusData();
    this.handleGetAssignToData();
    this.handleGetEnquiryTypeData();
  }

  handleGetSubEnquiryTypeData = (enquiryTypeId) => {
    var inputData = {};
    inputData.BrandID = "0";
    inputData.EnquiryTypeID = enquiryTypeId.toString();
    try {
      this.EnquiryService.getEnquirySubType("GetEnquirySubType", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var enquirySubTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ enquirySubTypeData });
          } else {
            this.setState({ enquirySubTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetEnquiryTypeData = () => {
    var inputData = {};
    inputData.BrandID = "0";
    try {
      this.EnquiryService.getEnquiryType("GetEnquiryType", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var enquiryTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ enquiryTypeData });
          } else {
            this.setState({ enquiryTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetLeadSubStatusData = (statusId) => {
    var inputData = {};
    inputData.LeadStatusID = statusId;
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GetLeadSubStatus", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadSubStatusData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadSubStatusData });
          } else {
            this.setState({ leadSubStatusData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetAssignToData = () => {
    var inputData = {};
    inputData.SearchText = "";
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GeLeadUsers", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadAssignToData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadAssignToData });
          } else {
            this.setState({ leadAssignToData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleGetLeadStatusData = () => {
    var inputData = {};
    //inputData.BrandID = "0";
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GetLeadStatus")
        .then((response) => {
          var statusCode = response.statusCode;
          var leadStatusData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadStatusData });
          } else {
            this.setState({ leadStatusData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleReport = (id) => {
    if (id === 1) {
      this.handleGetSLAReport();
    }
    if (id === 2) {
      this.handleGetLocationWiseReport();
    }
    if (id === 3) {
      this.handleGetLeadsRawReport();
    }
  };

  handleReportModalOpen = (id) => {
    this.setState({
      reportModal: true,
      reportID: id,
    });

    if (id == 3) {
      this.setState({
        isDownloadReport: true,
      });
    }
  };

  handleReportModalClose = () => {
    this.setState({
      reportModal: false,
      reportID: 0,
      isDownloadReport: false,
      reportFilterInput: {},
    });
  };
  handleFilterInputChange = async (name, e) => {
    var reportFilterInput = this.state.reportFilterInput;
    if (e.target) {
      reportFilterInput[name] = e.target.value;
    } else {
      reportFilterInput[name] = e;
    }

    if (name === "LeadStatus") {
      this.setState({
        leadSubStatusData: [],
      });
      this.handleGetLeadSubStatusData(e);
    }
    if (name === "EnquiryType") {
      this.setState({
        enquirySubTypeData: [],
      });
      this.handleGetSubEnquiryTypeData(e);
    }
    await this.setState({
      reportFilterInput: reportFilterInput,
    });
  };

  handleGetSLAReport = () => {
    var inputData = {};
    if (this.state.reportFilterInput.LeadCreatedDate) {
      inputData.CreatedFromDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[0]
      )
        .format("YYYY-MM-DD")
        .toString();
      inputData.CreatedToDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[1]
      )
        .format("YYYY-MM-DD")
        .toString();
      inputData.ResolvedFromDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[0]
      )
        .format("YYYY-MM-DD")
        .toString();
      inputData.ResolvedToDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[1]
      )
        .format("YYYY-MM-DD")
        .toString();
    } else {
      inputData.CreatedFromDate = null;
      inputData.CreatedToDate = null;
      inputData.ResolvedFromDate = null;
      inputData.ResolvedToDate = null;
    }

    inputData.AssignedToID = null;
    inputData.LeadStatusID = null;
    inputData.LeadSubStatusID = null;
    inputData.LeadEnquiryTypeID = null;
    inputData.LeadEnquirySubTypeID = null;

    try {
      this.setState({
        isSubmitLoading: true,
      });
      this.ReportService.getLeadsSLAReport("GetLeadsSLAReport", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var slaReportData = response.responseData;
          if (statusCode === 200) {
            this.setState({ slaReportData });
            this.setState({
                isSubmitLoading: true,
              });
            const link = document.createElement("a");
            link.href = this.state.slaReportData;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                reportModal: false,
                isSubmitLoading:false
            });
          } else {
            this.setState({ slaReportData: "" });
          }
        })
        .catch((error) => {
            this.setState({
                isSubmitLoading: false,
              });
          console.log(error);
        });
    } catch (error) {
        this.setState({
            isSubmitLoading: false,
          });
      console.log(error);
    }
  };

  handleGetLocationWiseReport = () => {
    var inputData = {};
    if (this.state.reportFilterInput.LeadCreatedDate) {
      inputData.CreatedFromDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[0]
      )
        .format("YYYY-MM-DD")
        .toString();
      inputData.CreatedToDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[1]
      )
        .format("YYYY-MM-DD")
        .toString();
      inputData.ResolvedFromDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[0]
      )
        .format("YYYY-MM-DD")
        .toString();
      inputData.ResolvedToDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[1]
      )
        .format("YYYY-MM-DD")
        .toString();
    } else {
      inputData.CreatedFromDate = null;
      inputData.CreatedToDate = null;
      inputData.ResolvedFromDate = null;
      inputData.ResolvedToDate = null;
    }

    inputData.AssignedToID = null;
    inputData.LeadStatusID = null;
    inputData.LeadSubStatusID = null;
    inputData.LeadEnquiryTypeID = null;
    inputData.LeadEnquirySubTypeID = null;

    try {
      this.ReportService.getLocationWiseReport(
        "GetLocationWiseReport",
        inputData
      )
        .then((response) => {
          var statusCode = response.statusCode;
          var locationReportData = response.responseData;
          if (statusCode === 200) {
            this.setState({ locationReportData });
            const link = document.createElement("a");
            link.href = this.state.locationReportData;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                reportModal: false,
                isSubmitLoading: false
            });
          } else {
            this.setState({ locationReportData: "" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetLeadsRawReport = () => {
    var inputData = {};
    if (this.state.reportFilterInput.LeadCreatedDate) {
      inputData.CreatedFromDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[0]
      )
        .format("YYYY-MM-DD")
        .toString();
      inputData.CreatedToDate = moment(
        this.state.reportFilterInput.LeadCreatedDate[1]
      )
        .format("YYYY-MM-DD")
        .toString();
    } else {
      inputData.CreatedFromDate = null;
      inputData.CreatedToDate = null;
    }

    if (this.state.reportFilterInput.LeadResolvedDate) {
      inputData.ResolvedFromDate = moment(
        this.state.reportFilterInput.LeadResolvedDate[0]
      )
        .format("YYYY-MM-DD")
        .toString();
      inputData.ResolvedToDate = moment(
        this.state.reportFilterInput.LeadResolvedDate[1]
      )
        .format("YYYY-MM-DD")
        .toString();
    } else {
      inputData.ResolvedFromDate = null;
      inputData.ResolvedToDate = null;
    }
    if (this.state.reportFilterInput.AssignTo) {
      inputData.AssignedToID = this.state.reportFilterInput.AssignTo + "";
    } else {
      inputData.AssignedToID = null;
    }
    if (this.state.reportFilterInput.LeadStatus) {
      inputData.LeadStatusID = this.state.reportFilterInput.LeadStatus.toString();
    } else {
      inputData.LeadStatusID = null;
    }
    if (this.state.reportFilterInput.substatus) {
      inputData.LeadSubStatusID = this.state.reportFilterInput.substatus.toString();
    } else {
      inputData.LeadSubStatusID = null;
    }
    if (this.state.reportFilterInput.EnquiryType) {
      inputData.LeadEnquiryTypeID = this.state.reportFilterInput.EnquiryType.toString();
    } else {
      inputData.LeadEnquiryTypeID = null;
    }
    if (this.state.reportFilterInput.subcategory) {
      inputData.LeadEnquirySubTypeID = this.state.reportFilterInput.subcategory.toString();
    } else {
      inputData.LeadEnquirySubTypeID = null;
    }

    try {
      this.ReportService.getLeadsRawReport("GetLeadsRawReport", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var LeadsRawReportData = response.responseData;
          if (statusCode === 200) {
            this.setState({ LeadsRawReportData });
            const link = document.createElement("a");
            link.href = this.state.LeadsRawReportData;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                reportModal: false,
                isSubmitLoading: false
            });
          } else {
            this.setState({ LeadsRawReportData: "" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div className="CategryMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>Report</h4>
            </div>
          </div>
          <div className="AlertTable">
            <Table
              dataSource={this.state.ReportGridData}
              columns={[
                {
                  title: "Report Name",
                  dataIndex: "reportName",
                  key: "reportName",
                },

                {
                  title: "Actions",
                  key: "actions",
                  dataIndex: "actions",
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        <img
                          src={Download}
                          alt="Edit"
                          onClick={this.handleReportModalOpen.bind(
                            this,
                            item.reportId
                          )}
                        />
                      </div>
                    );
                  },
                },
              ]}
            />
          </div>

          <Modal
            closable={false}
            title={"Report"}
            visible={this.state.reportModal}
            footer={[
              <Button
                key="back"
                onClick={this.handleReportModalClose.bind(this)}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                disabled={this.state.isSubmitLoading}
                loading={this.state.isSubmitLoading}
                onClick={this.handleReport.bind(this, this.state.reportID)}
              >
                Next
              </Button>,
            ]}
          >
            <div className="createPrior crep">
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Lead Created Date</label>
                  <RangePicker
                    value={this.state.reportFilterInput["LeadCreatedDate"]}
                    onChange={this.handleFilterInputChange.bind(
                      this,
                      "LeadCreatedDate"
                    )}
                  />
                </div>
              </div>
              {this.state.isDownloadReport ? (
                <div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Lead Resolved Date</label>
                      <RangePicker
                        value={this.state.reportFilterInput["LeadResolvedDate"]}
                        onChange={this.handleFilterInputChange.bind(
                          this,
                          "LeadResolvedDate"
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label>Lead Assign To</label>
                      <Select
                        defaultValue={0}
                        placeholder="Assign To"
                        value={this.state.reportFilterInput["AssignTo"] || null}
                        onChange={this.handleFilterInputChange.bind(
                          this,
                          "AssignTo"
                        )}
                      >
                        <Option value={0}>Select</Option>
                        {this.state.leadAssignToData &&
                          this.state.leadAssignToData.map((item) => (
                            <Option
                              value={item.userID}
                              isTeamUser={item.isTeamUser}
                            >
                              {item.fullName}
                            </Option>
                          ))}
                      </Select>
                    </div>

                    <div className="col-md-6">
                      <label>Leads Status</label>
                      <Select
                        defaultValue={0}
                        placeholder="Lead Status"
                        value={
                          this.state.reportFilterInput["LeadStatus"] || null
                        }
                        onChange={this.handleFilterInputChange.bind(
                          this,
                          "LeadStatus"
                        )}
                      >
                        <Option value={0}>Select</Option>
                        {this.state.leadStatusData &&
                          this.state.leadStatusData.map((item) => (
                            <Option value={item.leadStatusID}>
                              {item.statusName}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Leads Sub Status</label>
                      <Select
                        placeholder="Select Sub Status"
                        value={this.state.reportFilterInput["substatus"]}
                        name="substatus"
                        onChange={this.handleFilterInputChange.bind(
                          this,
                          "substatus"
                        )}
                      >
                        {this.state.leadSubStatusData &&
                          this.state.leadSubStatusData.map((item) => (
                            <Option value={item.leadSubStatusID}>
                              {item.subStatusName}
                            </Option>
                          ))}
                      </Select>
                    </div>

                    <div className="col-12 col-md-6">
                      <label>Lead Enquiry Type</label>
                      <Select
                        defaultValue={0}
                        placeholder="Enquiry Type"
                        value={
                          this.state.reportFilterInput["EnquiryType"] || null
                        }
                        onChange={this.handleFilterInputChange.bind(
                          this,
                          "EnquiryType"
                        )}
                      >
                        <Option value={0}>Select</Option>
                        {this.state.enquiryTypeData &&
                          this.state.enquiryTypeData.map((item) => (
                            <Option value={item.enquiryTypeID}>
                              {item.enquiryTypeName}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Lead Enquiry Sub Type</label>
                      <Select
                        placeholder="Select Sub Enquiry Type"
                        value={this.state.reportFilterInput["subcategory"]}
                        name="subcategory"
                        onChange={this.handleFilterInputChange.bind(
                          this,
                          "subcategory"
                        )}
                      >
                        {this.state.enquirySubTypeData &&
                          this.state.enquirySubTypeData.map((item) => (
                            <Option value={item.enquirySubTypeID}>
                              {item.enquirySubTypeName}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
export default Report;
