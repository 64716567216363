import Configuration from "../../helpers/Configuration";
import axios from "axios";
import authService from "../../components/api-authorization/AuthorizeService";

class ServiceService {
    constructor() {
        this.config = new Configuration();
    }

    async getServiceMaster(url, inputData) {
        const response = await axios(this.config.ServiceServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getDesignationList(url) {
        const response = await axios(this.config.ServiceServiceURL + url, {
            method: "POST",
            
        });
        return await response.data;
    }

    async insertServiceMaster(url, inputData) {
        const response = await axios(this.config.ServiceServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async updateServiceMaster(url, inputData) {
        const response = await axios(this.config.ServiceServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async deleteServiceMaster(url, inputData) {
        const response = await axios(this.config.ServiceServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

}
export default ServiceService;
