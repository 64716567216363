import React, { Component } from "react";
import moment from "moment";
import {
  Input,
  Radio,
  Select,
  Table,
  Steps,
  Popover,
  DatePicker,
  Modal,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Dropzone from "react-dropzone";
import {
  AddIcon,
  AddNoteIcon,
  CloseCircleIcon,
  UploadIcon,
} from "./../../../helpers/ImagesImport";
import UserService from "./../../../services/AdminSetting/UserService";
import LeadsService from "./../../../services/Leads/LeadsService";
import CommonService from "./../../../services/Common/CommonService";
import HierarchyService from "./../../../services/AdminSetting/HierarchyService";
import EnquiryService from "./../../../services/AdminSetting/EnquiryService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Master from "./../../../helpers/Master";
import ImportExport from "../../../helpers/ImportExport";

const { Step } = Steps;
class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createUser: false,
      Single: true,
      Multiple: false,
      CurrentStep: 0,
      fileName: "",
      fileN: [],
      locationData: [],
      languageData: [],
      Country: [],
      stateData: [],
      designationData: [],
      levelData: [],
      userInput: {},
      enquiryTypeData: [],
      enquirySubTypeData: [],
      userId: 0,
      userGridData: [],
      accessTypeData: [],
      createAccess: true,
      viewAccess: true,
      deleteAccess: true,
      editAccess: true,
      downloadAccess: true,
      isLoading: false,
      isEdit:false,
      fileCreateAccess: true,
      fileSize: "",
      file: {},
      fileValidation: ""
    };
    this.UserService = new UserService();
    this.LeadsService = new LeadsService();
    this.CommonService = new CommonService();
    this.HierarchyService = new HierarchyService();
    this.EnquiryService = new EnquiryService();
    this.master = new Master();
    this.importExport = new ImportExport();
  }

  componentDidMount() {
    this.handleGetUserGridData();
    this.handleGetLocationData();
    this.handleGetLanguageData();
    this.handleGetSpecialityList();
    this.handleGetCountryList();
    //this.handleGetStateData();
    this.handleGetHierarchyData();
    this.handleGetLevelData();
    this.handleGetRegionListByPinCodeID();
    this.handleGetEnquiryTypeData();
    this.handleGetAccessTypeData();
      this.handleGetModuleDetails();
     
      
  }

  handleSearchOnChange = async (event) => {
    this.handleGetUserGridData(event.target.value);
  };
    handleCreateUserOpen() {
        this.setState({ createUser: true, userInput: {}, userId: 0, isEdit: false });

        var userInput = this.state.userInput;
        userInput.accesstype = 2;
        this.setState({ userInput });
  }
  handleCreateUserClose() {
    this.setState({ createUser: false, CurrentStep: 0 });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  handleChangeStep = (CurrentStep) => {
    this.setState({
      CurrentStep,
    });
  };
  handleNextStepClick() {
    if (this.state.CurrentStep === 0) {
      if (this.handleUserValidation()) {
        this.setState({
          CurrentStep: 1,
        });
      }
    } else if (this.state.CurrentStep === 1) {
      this.setState({
        CurrentStep: 2,
      });
    } else {
      this.setState({
        CurrentStep: 3,
      });
    }
  }
  fileUpload = (file) => {
      
      if (file.length > 0) {
          var fileName = file[0].name;
          if (fileName.split('.').pop() === "csv") {
              this.setState({
                  fileName,
                  file: file[0],
                  fileValidation: "",
              });
          } else {
              NotificationManager.error("File accept only csv type.");
          }
      }
  };

  handleBulkUpload = () => {
      const formData = new FormData();
      formData.append("file", this.state.file);
      this.UserService.importFile("BulkUploadUser", formData)
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            NotificationManager.success("Bulk Uploaded Successfully");
          } else {
            NotificationManager.error("Bulk Uploaded Failed");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
  }

    handleGetUserGridData = (search) => {
        
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = search;
    try {
      this.setState({
        isLoading: true,
      });
      this.UserService.getUserGridData("GetUserDetails", inputData)
          .then((response) => {
          this.setState({
            isLoading: false,
          });
          var message = response.message;
          var userGridData = response.responseData;
          if (message == "Success") {
            this.setState({ userGridData });
          } else {
            this.setState({ userGridData: [] });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log(error);
        });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      console.log(error);
    }
  };

  handleGetLocationData = () => {
    var inputData = {};
    inputData.SearchText = "";
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GeLeadLocation", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var locationData = response.responseData;
          if (statusCode === 200) {
            this.setState({ locationData });
          } else {
            this.setState({ locationData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetLanguageData = () => {
    var inputData = {};
    inputData.SearchText = "";
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.UserService.getLanguageData("GetLanguageMaster")
        .then((response) => {
          var statusCode = response.statusCode;
          var languageData = response.responseData;
          if (statusCode === 200) {
            this.setState({ languageData });
          } else {
            this.setState({ languageData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetSpecialityList = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      this.LeadsService.getLeadDropdownData("GetSpecialityMaster", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var specialityData = response.responseData;
          if (statusCode === 200) {
            this.setState({ specialityData });
          } else {
            this.setState({ specialityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetCountryList = () => {
    try {
      this.CommonService.getCountryList("GetCountryMaster")
        .then((response) => {
          var statusCode = response.statusCode;
          var Country = response.responseData;
          if (statusCode === 200) {
            this.setState({
              Country,
            });
          } else {
            this.setState({
              Country: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetStateData = (countryId) => {
    var inputData = {};
    inputData.IDs = countryId.toString();
    inputData.Value = "";
    try {
      this.CommonService.getStateList("GetStateByCountryID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var stateData = response.responseData;
          if (statusCode === 200) {
            this.setState({ stateData });
          } else {
            this.setState({ stateData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetHierarchyData = (search) => {
    var inputData = {};
    inputData.SearchText = search;
    try {
      this.HierarchyService.getHierarchyGridData("GetHierarchy", inputData)
        .then((response) => {
          var message = response.message;
          var designationData = response.responseData;
          if (message == "Success") {
            this.setState({ designationData });
          } else {
            this.setState({ designationData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetLevelData = () => {
    try {
      this.CommonService.getLevelList("GetUserLevelMaster")
        .then((response) => {
          var message = response.message;
          var levelData = response.responseData;
          if (message == "Success") {
            this.setState({ levelData });
          } else {
            this.setState({ levelData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetRegionListByPinCodeID = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      this.CommonService.getRegionList("GetRegionMasterByPincodeID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var Region = response.responseData;
          if (statusCode === 200) {
            this.setState({
              Region,
            });
          } else {
            this.setState({
              Region: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetEnquiryTypeData = () => {
    var inputData = {};
    inputData.BrandID = "0";
    try {
      this.EnquiryService.getEnquiryType("GetEnquiryType", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var enquiryTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ enquiryTypeData });
          } else {
            this.setState({ enquiryTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetSubEnquiryTypeData = (enquiryTypeId) => {
    var inputData = {};
    inputData.BrandID = "0";
    inputData.EnquiryTypeID = enquiryTypeId.toString();
    try {
      this.EnquiryService.getEnquirySubType("GetEnquirySubType", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var enquirySubTypeData = response.responseData;
          if (statusCode === 200) {
            this.setState({ enquirySubTypeData });
          } else {
            this.setState({ enquirySubTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleInputChange = async (name, e) => {
    var userInput = this.state.userInput;
    if (name === "mobile") {
      if (!isNaN(e.target.value)) {
        userInput[name] = e.target.value;
      } else {
        if (!userInput[name]) {
          userInput[name] = "";
        } else {
          userInput[name] = userInput[name];
        }
      }
    } else {
      userInput[name] = e.target.value;
    }
    await this.setState({
      userInput,
    });
  };

    handleSelectInputChange = async (name, e) => {
        
    var userInput = this.state.userInput;
    if (name == "country") {
      userInput[name] = e;
      this.handleGetStateData(e);
    } else if (name == "dispositions") {
      userInput[name] = e;
      this.handleGetSubEnquiryTypeData(e);
    } else {
      userInput[name] = e;
    }
    await this.setState({
      userInput,
    });
  };

  handleCreateUser = () => {
    if (this.handleUserValidation()) {
      var inputData = {};
      inputData.UserID = this.state.userId;
      inputData.UserName = this.state.userInput.fullname;
      inputData.FullName = this.state.userInput.fullname;
      inputData.Email = this.state.userInput.email;
      inputData.PhoneNumber = this.state.userInput.mobile;
      inputData.DOB =
        this.state.userInput.dob !== undefined &&
        this.state.userInput.dob !== ""
          ? moment(this.state.userInput.dob).format("DD-MM-YYYY")
          : "";
      inputData.Age =
        this.state.userInput.age !== undefined &&
        this.state.userInput.age !== ""
          ? parseInt(this.state.userInput.age)
          : 0;
      inputData.LocationID =
        this.state.userInput.primarycenter !== undefined
          ? this.state.userInput.primarycenter
          : 0;
      inputData.DesignationID =
        this.state.userInput.designation !== undefined
          ? this.state.userInput.designation
          : 0;
      inputData.GenderID =
        this.state.userInput.gender !== undefined
          ? this.state.userInput.gender
          : 0;
      inputData.ReporteeID =
        this.state.userInput.reportingmanager !== undefined
          ? this.state.userInput.reportingmanager
          : 0;
      inputData.RoleID = 0;
      inputData.DeparmentID = 0;
      inputData.RegistrationNumber =
        this.state.userInput.registrationno !== undefined &&
        this.state.userInput.registrationno !== ""
          ? this.state.userInput.registrationno
          : "";
      inputData.RegistrationCountryID =
        this.state.userInput.country !== undefined
          ? this.state.userInput.country
          : 0;
      inputData.RegistrationStateID =
        this.state.userInput.state !== undefined
          ? this.state.userInput.state
          : 0;
      inputData.YearOfExperience =
        this.state.userInput.workexp !== undefined &&
        this.state.userInput.workexp !== ""
          ? this.state.userInput.workexp
          : "0";
      inputData.KnownLanguages =
        this.state.userInput.language !== undefined
          ? this.state.userInput.language.toString()
          : "";
      inputData.SpecialityID =
        this.state.userInput.speciality !== undefined
          ? this.state.userInput.speciality
          : 0;
      inputData.LeadAccessTypeID =
        this.state.userInput.accesstype !== undefined
          ? this.state.userInput.accesstype
          : 0;
      inputData.UserLevelID =
        this.state.userInput.level !== undefined
          ? this.state.userInput.level
          : 0;
      inputData.Zone =
        this.state.userInput.zone !== undefined
          ? this.state.userInput.zone.toString()
          : "0";
      inputData.RegionID =
        this.state.userInput.region !== undefined
          ? this.state.userInput.region
          : 0;
      inputData.DegreeName =
        this.state.userInput.degree !== undefined &&
        this.state.userInput.degree !== ""
          ? this.state.userInput.degree
          : "";
      inputData.InstituteName =
        this.state.userInput.institution !== undefined &&
        this.state.userInput.institution !== ""
          ? this.state.userInput.institution
          : "";
      inputData.ClinicalSkills =
        this.state.userInput.clinicalskills !== undefined &&
        this.state.userInput.clinicalskills !== ""
          ? this.state.userInput.clinicalskills
          : "";
      inputData.Certifications =
        this.state.userInput.certifications !== undefined &&
        this.state.userInput.certifications !== ""
          ? this.state.userInput.certifications
          : "";
      inputData.Membership =
        this.state.userInput.membership !== undefined &&
        this.state.userInput.membership !== ""
          ? this.state.userInput.membership
          : "";
      inputData.Publications =
        this.state.userInput.publication !== undefined &&
        this.state.userInput.publication !== ""
          ? this.state.userInput.publication
          : "";
      inputData.AwardsAndRecognitions =
        this.state.userInput.awards !== undefined &&
        this.state.userInput.awards !== ""
          ? this.state.userInput.awards
          : "";
      inputData.LocationIDs =
        this.state.userInput.center !== undefined &&
        this.state.userInput.center !== ""
          ? this.state.userInput.center.toString()
          : "";
      inputData.EnquiryTypeIDs =
        this.state.userInput.dispositions !== undefined
          ? this.state.userInput.dispositions.toString()
          : "";
      inputData.EnquirySubTypeIDs =
        this.state.userInput.subdispositions !== undefined
          ? this.state.userInput.subdispositions.toString()
          : "";
      inputData.IsActive = true;
      this.setState({ isSubmitLoading: true });
      try {
        this.UserService.createUpdateUser("InsertUpdateUser", inputData)
          .then((response) => {
            var message = response.message;
            //this.setState({ isSubmitLoading: false });
              if (message === "Success") {
                  if (this.state.isEdit === true) {
                      NotificationManager.success("Record Updated Successfully.");
                  } else {
                      NotificationManager.success("Record Created Successfully.");
                  }
             
              this.setState({
                leadId: response.responseData,
                  createUser: false,
                  userInput: {},
                  CurrentStep:0,
                //leadSuccess: true
              });
              this.handleGetUserGridData();
              // createNotification("success", "Priority Created Successfully.");
              //this.handleGetPriorityGridData();
              //this.setState({ priorityModal: false });
            } else {
              //NotificationManager.error("Record Not Created.");
            }
          })
          .catch((error) => {
            //this.setState({ isSubmitLoading: false });
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleGetUserDetails = (userId) => {
    /*        if (this.handleLeadValidation()) {*/
    var inputData = {};
    inputData.IDs = userId.toString();
    inputData.Value = "";
    try {
      this.UserService.getUserGridData("GetUserDetails", inputData)
        .then((response) => {
          var message = response.message;
          var userData = response.responseData[0];
          if (message == "Success") {
            var userInput = {};
            userInput["email"] = userData.email;
            userInput["fullname"] = userData.fullName;
            userInput["mobile"] = userData.phoneNumber;
            userInput["primarycenter"] =
              userData.locationID !== 0 ? userData.locationID : undefined;
            userInput["age"] =
              userData.age !== "0" && userData.age !== null ? userData.age : "";
            userInput["language"] =
              userData.knownLanguages !== "" && userData.knownLanguages !== null
                ? parseInt(userData.knownLanguages)
                : undefined;
            userInput["gender"] = userData.genderID;
            userInput["speciality"] =
              userData.specialityID !== 0 && userData.specialityID !== null
                ? userData.specialityID
                : undefined;
            userInput["registrationno"] = userData.registrationNumber;
            userInput["country"] =
              userData.registrationCountryID !== 0 &&
              userData.registrationCountryID !== null
                ? userData.registrationCountryID
                : undefined;
            if (userData.registrationCountryID !== 0) {
              userInput["state"] =
                userData.registrationStateID !== 0 &&
                userData.registrationStateID !== null
                  ? userData.registrationStateID
                  : undefined;
              this.handleGetStateData(userData.registrationCountryID);
            }
            userInput["workexp"] =
              userData.yearOfExperience !== "0" &&
              userData.yearOfExperience !== null
                ? userData.yearOfExperience
                : "";
            userInput["accesstype"] =
              userData.leadAccessTypeID !== 0 &&
              userData.leadAccessTypeID !== null
                ? userData.leadAccessTypeID
                : undefined;
            userInput["designation"] =
              userData.designationID !== 0 && userData.designationID !== null
                ? userData.designationID
                : undefined;
            userInput["reportingmanager"] =
              userData.reporteeID !== 0 && userData.reporteeID !== null
                ? userData.reporteeID
                : undefined;
            userInput["level"] =
              userData.userLevelID !== 0 && userData.userLevelID !== null
                ? userData.userLevelID
                : undefined;
            userInput["zone"] =
              userData.zone !== "0" && userData.zone !== null
                ? parseInt(userData.zone)
                : undefined;
            userInput["region"] =
              userData.regionID !== 0 && userData.regionID !== null
                ? userData.regionID
                : undefined;
            userInput["degree"] =
              userData.degreeName !== "" && userData.degreeName !== null
                ? userData.degreeName
                : undefined;
            userInput["institution"] =
              userData.instituteName !== "" && userData.instituteName !== null
                ? userData.instituteName
                : undefined;
            userInput["clinicalskills"] =
              userData.clinicalSkills !== "" && userData.clinicalSkills !== null
                ? userData.clinicalSkills
                : undefined;
            userInput["certifications"] =
              userData.certifications !== "" && userData.certifications !== null
                ? userData.clinicalSkills
                : undefined;
            userInput["membership"] =
              userData.membership !== "" && userData.membership !== null
                ? userData.membership
                : undefined;
            userInput["publication"] =
              userData.publications !== "" && userData.publications !== null
                ? userData.publications
                : undefined;
            userInput["awards"] =
              userData.awardsAndRecognitions !== "" &&
              userData.awardsAndRecognitions !== null
                ? userData.awardsAndRecognitions
                : undefined;
            userInput["center"] =
              userData.associatedCentresDetails !== 0 &&
              userData.associatedCentresDetails !== null
                ? userData.associatedCentresDetails
                : undefined;
            var AssociatedCenters = [];
            if (
              userData.associatedCentresDetails !== null &&
              userData.associatedCentresDetails.length > 0
            ) {
              userData.associatedCentresDetails &&
                userData.associatedCentresDetails.map((item) =>
                  AssociatedCenters.push(item.locationID)
                );
              userInput["center"] = AssociatedCenters;
            }
            if (
              userData.leadDepositionsDetails !== null &&
              userData.leadDepositionsDetails.length > 0
            ) {
              userInput["dispositions"] =
                userData.leadDepositionsDetails[0].enquiryTypeID !== 0 &&
                userData.leadDepositionsDetails[0].enquiryTypeID !== null
                  ? userData.leadDepositionsDetails[0].enquiryTypeID
                  : undefined;
              this.handleGetSubEnquiryTypeData(
                userData.leadDepositionsDetails[0].enquiryTypeID
              );
              userInput["subdispositions"] =
                userData.leadDepositionsDetails[0]
                  .leadDepositionsSubTypeDetails[0].enquirySubTypeID !== 0 &&
                userData.leadDepositionsDetails[0]
                  .leadDepositionsSubTypeDetails[0].enquirySubTypeID !== null
                  ? userData.leadDepositionsDetails[0]
                      .leadDepositionsSubTypeDetails[0].enquirySubTypeID
                  : undefined;
            }
            this.setState({
              createUser: true,
              userInput,
                userId: userData.userID,
                isEdit: true
            });
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteUser = (userId) => {
    var inputData = {};
    inputData.IDs = userId.toString();
    try {
      this.UserService.deleteUser("DeleteUser", inputData)
        .then((response) => {
          if (response) {
            this.handleGetUserGridData();
            NotificationManager.success("Record Deleted Successfully.");
          } else {
            NotificationManager.error("User Not Deleted.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleUserValidation() {
    let userInput = this.state.userInput;
    let errors = {};
    let formIsValid = true;

    if (!this.state.userInput.fullname) {
      formIsValid = false;
      errors["fullname"] = "Please enter full name.";
    }

    if (!this.state.userInput.email) {
      formIsValid = false;
      errors["email"] = "Please enter email id.";
    }

    if (!this.state.userInput.mobile) {
      formIsValid = false;
      errors["mobile"] = "Please enter mobile number.";
    }

    if (this.state.userInput.email) {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (reg.test(this.state.userInput.email.trim()) === false) {
        formIsValid = false;
        errors["email"] = "Invalid email id.";
      }
    }

    if (!this.state.userInput.primarycenter) {
      formIsValid = false;
      errors["primarycenter"] = "Please select primary center.";
    }

    if (!this.state.userInput.designation) {
      formIsValid = false;
      errors["designation"] = "Please select designation.";
    }

    if (!this.state.userInput.level) {
      formIsValid = false;
      errors["level"] = "Please select user level.";
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleGetAccessTypeData = () => {
    try {
      this.CommonService.getAccessTypeList("GetLeadAccessTypeMaster")
        .then((response) => {
          var message = response.message;
          var accessTypeData = response.responseData;
            if (message == "Success") {
               
                this.setState({ accessTypeData});
          } else {
            this.setState({ accessTypeData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDateOnChange = async (dates, dateStrings) => {
    var userInput = this.state.userInput;
    if (dates !== null) {
      userInput["dob"] = dates._d;
    } else {
      userInput["dob"] = "";
    }
    await this.setState({ userInput });
  };

  handleGetModuleDetails = () => {
    try {
      this.UserService.getUserModuleDetails("GetUserModuleDetails")
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            //this.setState({ cityData });
            this.setAccessUser(data);
          } else {
            //this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  setAccessUser(data) {
    if (data !== null) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].moduleName === "Settings") {
          this.setState({
            createAccess: data[i].isCreate,
            viewAccess: data[i].isView,
            deleteAccess: data[i].isDelete,
            editAccess: data[i].isEdit,
            downloadAccess: data[i].isDownload,
          });
        }
        if (data[i].moduleName === "File Upload") {
          this.setState({
            fileCreateAccess: data[i].isCreate
          });
        }
      }
    }
  }

  handleUserBulkUploadSampleDownload = () => {
    this.importExport.SampleCSV(
      this.master.UserImportSample[0],
      this.master.UserImportSample[1],
      "User_Sample"
    );
  };
  render() {
    const { Option } = Select;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
    const customDot = (dot, { status, index }) => (
      <Popover
        content={
          <span>
            step {index} status: {status}
          </span>
        }
      >
        {dot}
      </Popover>
    );
    return (
      <div className="UsersMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Users
                {this.state.createAccess && (
                  <img
                    src={AddIcon}
                    alt="add"
                    onClick={this.handleCreateUserOpen.bind(this)}
                  />
                )}
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by designation, user name..."
                prefix={<SearchOutlined />}
                onChange={this.handleSearchOnChange.bind(this)}
              />
            </div>
          </div>
          {this.state.viewAccess && (
            <div className="UsersTable">
              <Table
                loading={this.state.isLoading}
                            rowSelection={rowSelection}
                            rowKey={(record) => record.userID}
                columns={[
                  {
                    title: "User Name",
                    dataIndex: "userName",
                    key: "userName",
                    width: 150,
                  },
                  {
                    title: "Mobile No.",
                    dataIndex: "phoneNumber",
                    key: "phoneNumber",
                  },
                  {
                    title: "Email Address",
                    key: "email",
                    dataIndex: "email",
                  },
                  {
                    title: "Designation",
                    key: "designationName",
                    dataIndex: "designationName",
                  },
                  {
                    title: "Status",
                    key: "status",
                    dataIndex: "status",
                    render: (row, item) => {
                      return (
                        <div className="Status">
                          <Select
                            defaultValue="Active"
                            showArrow={false}
                            disabled={true}
                          >
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                          </Select>
                        </div>
                      );
                    },
                  },
                  {
                    title: "Actions",
                    key: "actions",
                    dataIndex: "actions",
                    width: 100,
                    render: (row, item) => {
                      return (
                        <div className="Actionic">
                          {this.state.editAccess && (
                            <img
                              src={AddNoteIcon}
                              alt="Edit"
                              onClick={this.handleGetUserDetails.bind(
                                this,
                                item.userID
                              )}
                            />
                          )}
                          {this.state.deleteAccess && (
                            <img
                              src={CloseCircleIcon}
                              alt="delete"
                              onClick={this.handleDeleteUser.bind(
                                this,
                                item.userID
                              )}
                            />
                          )}
                        </div>
                      );
                    },
                  },
                ]}
                dataSource={this.state.userGridData}
                pagination={{
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                }}
              />
            </div>
          )}
          {/* Create Users */}
          <Modal
            visible={this.state.createUser}
                    closable={false}
                    title={!this.state.isEdit ? "Create User" : "Edit User"}
           
            className="CreateUserModal"
            footer={null}
          >
            <div>
              <div className="createUser">
                <div className="Steep">
                  <Steps
                    current={this.state.CurrentStep}
                    progressDot={customDot}
                    onChange={this.handleChangeStep}
                  >
                    <Step title="Basic Info" />
                    <Step title="Educational Info" />
                    <Step title="Linked Centres" />
                    <Step title="Leads Despositions" />
                  </Steps>
                </div>

                <div className="mb-2">
                  <Radio
                    type="radio"
                    name="Single"
                    checked={this.state.Single}
                    onChange={this.handleRedioButtonChange.bind(this, "Single")}
                  >
                    Single
                  </Radio>
                  {this.state.fileCreateAccess &&
                  <Radio
                    type="radio"
                    name="Single"
                    checked={this.state.Multiple}
                    onChange={this.handleRedioButtonChange.bind(
                      this,
                      "Multiple"
                    )}
                  >
                    Multiple
                  </Radio>}
                </div>
                {this.state.Single && (
                  <>
                    {this.state.CurrentStep === 0 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Email<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Email"
                              value={
                                this.state.userInput.email !== undefined
                                  ? this.state.userInput.email
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "email"
                              )}
                            />

                            {this.state.errors ? (
                              <div className="text-danger">
                                {this.state.errors["email"]}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Full Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Full Name"
                              value={
                                this.state.userInput.fullname !== undefined
                                  ? this.state.userInput.fullname
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "fullname"
                              )}
                            />
                            {this.state.errors ? (
                              <div className="text-danger">
                                {this.state.errors["fullname"]}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          {/*<div className="col-12 col-md-6">*/}
                          {/*  <label className="desig">User Name</label>*/}
                          {/*  <input type="text" placeholder="Enter User Name"*/}
                          {/*   value={this.state.username}*/}
                          {/*   onChange={this.handleInputChange.bind(this, "username")}*/}
                          {/*  />*/}
                          {/*</div>*/}
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Mobile Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Mobile Number"
                              maxLength="10"
                              value={
                                this.state.userInput.mobile !== undefined
                                  ? this.state.userInput.mobile
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "mobile"
                              )}
                            />
                            {this.state.errors ? (
                              <div className="text-danger">
                                {this.state.errors["mobile"]}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Primary Centre
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              placeholder="Select Primary Centre"
                              value={this.state.userInput.primarycenter}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "primarycenter"
                              )}
                            >
                              {this.state.locationData &&
                                this.state.locationData.map((item) => (
                                  <Option value={item.locationID}>
                                    {item.locationName}
                                  </Option>
                                ))}
                            </Select>
                            {this.state.errors ? (
                              <div className="text-danger">
                                {this.state.errors["primarycenter"]}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Date of Birth</label>
                            <DatePicker
                              style={{ display: "block" }}
                              placeholder="Date of Birth"
                              onChange={this.handleDateOnChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Age</label>
                            <input
                              type="text"
                              placeholder="Enter Age"
                              value={
                                this.state.userInput.age !== undefined
                                  ? this.state.userInput.age
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "age"
                              )}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Langauges Known</label>
                            <Select
                              placeholder="Select Langauges"
                              value={this.state.userInput.language}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "language"
                              )}
                            >
                              {this.state.languageData &&
                                this.state.languageData.map((item) => (
                                  <Option value={item.languageID}>
                                    {item.languageName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Gender</label>
                            <Select
                              placeholder="Select Gender"
                              value={this.state.userInput.gender}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "gender"
                              )}
                            >
                              <Option value={0}>Male</Option>
                              <Option value={1}>Female</Option>
                              <Option value={2}>Other</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Speciality</label>
                            <Select
                              placeholder="Select Speciality"
                              value={this.state.userInput.speciality}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "speciality"
                              )}
                            >
                              {this.state.specialityData
                                ? this.state.specialityData.map((item) => {
                                    return (
                                      <Option
                                        value={item.specialityID}
                                        key={item.specialityID}
                                      >
                                        {item.specialityName}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                          </div>
                          {/*<div className="col-12 col-md-6">*/}
                          {/*  <label className="desig">Skill Grade</label>*/}
                          {/*  <Select defaultValue="Click to Select">*/}
                          {/*    <Option value="first">First</Option>*/}
                          {/*  </Select>*/}
                          {/*</div>*/}
                          <div className="col-12 col-md-6">
                            <label className="desig">Registration Number</label>
                            <input
                              type="text"
                              placeholder="Enter Registration Number"
                              value={
                                this.state.userInput.registrationno !==
                                undefined
                                  ? this.state.userInput.registrationno
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "registrationno"
                              )}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Registration Country
                            </label>
                            <Select
                              placeholder="Select Country"
                              value={this.state.userInput.country}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "country"
                              )}
                            >
                              {this.state.Country &&
                                this.state.Country.map((item) => (
                                  <Option value={item.countryID}>
                                    {item.countryName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Registration State</label>
                            <Select
                              placeholder="Select Registration State"
                              value={this.state.userInput.state}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "state"
                              )}
                            >
                              {this.state.stateData &&
                                this.state.stateData.map((item) => (
                                  <Option value={item.stateID}>
                                    {item.stateName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Work Experience (years)
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Work Experience"
                              value={
                                this.state.userInput.workexp !== undefined
                                  ? this.state.userInput.workexp
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "workexp"
                              )}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Lead Access Type</label>
                            <Select
                              placeholder="Select Lead Access Type"
                              value={this.state.userInput.accesstype}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "accesstype"
                              )}
                            >
                              {this.state.accessTypeData &&
                                this.state.accessTypeData.map((item) => (
                                  <Option value={item.leadAccessID}>
                                    {item.leadAccessTypeName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Designation<span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              placeholder="Select Designation"
                              value={this.state.userInput.designation}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "designation"
                              )}
                            >
                              {this.state.designationData &&
                                this.state.designationData.map((item) => (
                                  <Option value={item.designationID}>
                                    {item.designationName}
                                  </Option>
                                ))}
                            </Select>
                            {this.state.errors ? (
                              <div className="text-danger">
                                {this.state.errors["designation"]}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Reporting Manager</label>
                            <Select
                              placeholder="Select Reporting Manager"
                              value={this.state.userInput.reportingmanager}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "reportingmanager"
                              )}
                            >
                              {this.state.userGridData &&
                                this.state.userGridData.map((item) => (
                                  <Option value={item.userID}>
                                    {item.fullName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          {/*<div className="col-12 col-md-6">*/}
                          {/*  <label className="desig">CRM Role</label>*/}
                          {/*  <Select defaultValue="Click to Select">*/}
                          {/*    <Option value="manager">Manager</Option>*/}
                          {/*  </Select>*/}
                          {/*</div>*/}
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              User Level<span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              placeholder="Select User Level"
                              value={this.state.userInput.level}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "level"
                              )}
                            >
                              {this.state.levelData &&
                                this.state.levelData.map((item) => (
                                  <Option value={item.userLevelID}>
                                    {item.userLevel}
                                  </Option>
                                ))}
                            </Select>
                            {this.state.errors ? (
                              <div className="text-danger">
                                {this.state.errors["level"]}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">User Zone</label>
                            <Select
                              placeholder="Select User Zone"
                              value={this.state.userInput.zone}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "zone"
                              )}
                            >
                              {this.state.locationData &&
                                this.state.locationData.map((item) => (
                                  <Option value={item.locationID}>
                                    {item.locationName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">User Region</label>
                            <Select
                              placeholder="Select User Region"
                              value={this.state.userInput.region}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "region"
                              )}
                            >
                              {this.state.Region
                                ? this.state.Region.map((item, key) => {
                                    return (
                                      <Option value={item.regionID}>
                                        {item.regionName}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                          </div>
                        </div>
                        {/*<div className="row mb-3">  */}
                        {/*  <div className="col-12 col-md-6">*/}
                        {/*    <label className="desig">User City</label>*/}
                        {/*    <Select defaultValue="Click to Select">*/}
                        {/*      <Option value="ahmedabad">Ahmedabad</Option>*/}
                        {/*    </Select>*/}
                        {/*  </div>*/}
                        {/*  <div className="col-12 col-md-6">*/}
                        {/*    <label className="desig">User Centre</label>*/}
                        {/*    <Select defaultValue="Click to Select">*/}
                        {/*      <Option value="east">East</Option>*/}
                        {/*    </Select>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="row mb-3"></div>
                      </div>
                    )}
                    {this.state.CurrentStep === 1 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Degree Name</label>
                            <input
                              type="text"
                              placeholder="Enter Degree Name"
                              value={
                                this.state.userInput.degree !== undefined
                                  ? this.state.userInput.degree
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "degree"
                              )}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Institute Name</label>
                            <input
                              type="text"
                              placeholder="Enter Institute Name"
                              value={
                                this.state.userInput.institution !== undefined
                                  ? this.state.userInput.institution
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "institution"
                              )}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Clinical Skills</label>
                            <input
                              type="text"
                              placeholder="Enter Clinical Skills"
                              value={
                                this.state.userInput.clinicalskills !==
                                undefined
                                  ? this.state.userInput.clinicalskills
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "clinicalskills"
                              )}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Certifications</label>
                            <input
                              type="text"
                              placeholder="Enter Certifications"
                              value={
                                this.state.userInput.certifications !==
                                undefined
                                  ? this.state.userInput.certifications
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "certifications"
                              )}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Membership</label>
                            <input
                              type="text"
                              placeholder="Enter Membership"
                              value={
                                this.state.userInput.membership !== undefined
                                  ? this.state.userInput.membership
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "membership"
                              )}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Publications</label>
                            <input
                              type="text"
                              placeholder="Enter Publications"
                              value={
                                this.state.userInput.publication !== undefined
                                  ? this.state.userInput.publication
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "publication"
                              )}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Awards &amp; Recognitions
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Awards & Recognitions"
                              value={
                                this.state.userInput.awards !== undefined
                                  ? this.state.userInput.awards
                                  : ""
                              }
                              onChange={this.handleInputChange.bind(
                                this,
                                "awards"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.CurrentStep === 2 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Associated Centre</label>
                            <Select
                              placeholder="Select Associated Centre"
                              mode="multiple"
                              value={this.state.userInput.center}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "center"
                              )}
                            >
                              {this.state.locationData &&
                                this.state.locationData.map((item) => (
                                  <Option value={item.locationID}>
                                    {item.locationName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.CurrentStep === 3 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Leads Dispositions</label>
                            <Select
                              placeholder="Select Leads Dispositions"
                              value={this.state.userInput.dispositions}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "dispositions"
                              )}
                            >
                              {this.state.enquiryTypeData &&
                                this.state.enquiryTypeData.map((item) => (
                                  <Option value={item.enquiryTypeID}>
                                    {item.enquiryTypeName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">
                              Leads Sub Dispositions
                            </label>
                            <Select
                              placeholder="Select Leads Sub Dispositions"
                              value={this.state.userInput.subdispositions}
                              onChange={this.handleSelectInputChange.bind(
                                this,
                                "subdispositions"
                              )}
                            >
                              {this.state.enquirySubTypeData &&
                                this.state.enquirySubTypeData.map((item) => (
                                  <Option value={item.enquirySubTypeID}>
                                    {item.enquirySubTypeName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {this.state.Multiple && (
                  <>
                    <div className="editor">
                      <div className="mainfileUpload">
                        <Dropzone onDrop={this.fileUpload}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                className="file-upload d-none"
                              />
                              <div className="file-icon">
                                <img src={UploadIcon} alt="file-upload" />
                              </div>
                              <span className={"drop"}>
                                Drop your file to upload
                              </span>
                               {this.state.fileName && (
                                <p className="file-name">{this.state.fileName}</p>)}
                              <button className="fileupload"
                               onClick={this.fileUpload}
                              >
                                Choose File
                              </button>
                              
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      <span
                        className="down"
                        onClick={this.handleUserBulkUploadSampleDownload.bind(
                          this
                        )}
                      >
                        Download Sample File
                      </span>
                    </div>
                  </>
                )}
                
                {this.state.Single && (
                <div className="editorbtn">
                  <button
                    type="button"
                    onClick={this.handleCreateUserClose.bind(this)}
                  >
                    Cancel
                  </button>
                  {this.state.CurrentStep === 3 ? (
                    <button
                      type="button"
                      onClick={this.handleCreateUser.bind(this)}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={this.handleNextStepClick.bind(this)}
                    >
                      Next
                    </button>
                  )}
                </div>)}
                {this.state.Multiple && (
                <div className="editorbtn">
                  <button
                    type="button"
                    onClick={this.handleCreateUserClose.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                      type="button"
                      onClick={this.handleBulkUpload}
                  >
                      Upload
                  </button>   
              </div>)}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Users;
