import { User } from "oidc-client";
import React, { Component, Fragment } from "react";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import {
    ProfileImg
} from "./../../../helpers/ImagesImport";
import HierarchyService from "./../../../services/AdminSetting/HierarchyService";
import UserService from "./../../../services/AdminSetting/UserService";
import NotificationManager from "react-notifications/lib/NotificationManager";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
        fileName: [],
        imgFlag: "",
        hierarchyGridData: [],
        userId: 0,
        fullName: "",
        lastName: "",
        mobileNo: "",
        emailId: "",
        designation: "",
        userData: []
    };
      this.HierarchyService = new HierarchyService();
      this.UserService = new UserService();
  }
  componentDidMount() {
      this.handleGetHierarchyGridData();
      this.handleGetProfileData();
  }

  fileUpload(e) {
    var allFiles = [];
    var selectedFiles = e.target.files;
    allFiles.push(selectedFiles[0]);
    this.setState({
      fileName: allFiles,
    });
  }

  fileDrop = (e) => {
    this.setState({ fileName: e.dataTransfer.files[0].name });
    e.preventDefault();
  };
  fileDragOver = (e) => {
    e.preventDefault();
  };
  fileDragEnter = (e) => {
    e.preventDefault();
  };

  handleGetHierarchyGridData = () => {
    var inputData = {};
    inputData.SearchText = "";
    try {
      this.HierarchyService.getHierarchyGridData("GetHierarchy", inputData)
        .then((response) => {
          var message = response.message;
          var hierarchyGridData = response.responseData;
          if (message == "Success") {
            this.setState({ hierarchyGridData });
          } else {
            this.setState({ hierarchyGridData: [] });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

    handleGetProfileData = () => {
        try {
            this.UserService.getUserProfileData("GetUserProfileDetails")
                .then((response) => {
                    var message = response.message;
                    var userData = response.responseData[0];
                    if (message == "Success") {
                        this.setState({
                            userId: userData.userID,
                            fullName: userData.fullName,
                            mobileNo: userData.phoneNumber,
                            emailId: userData.email,
                            designation: userData.designationID
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    handleUpdateProfileData = () => {
        var inputData = {};
        inputData.UserId = this.state.userId;
        inputData.FullName = this.state.fullName;
        inputData.MobileNo = this.state.mobileNo;
        inputData.DesignationId = parseInt(this.state.designation);
        var json = {
            UserId: this.state.userId,
            FullName: this.state.fullName,
            MobileNo: this.state.mobileNo,
            DesignationId: parseInt(this.state.designation),
        };
        const formData = new FormData();

        formData.append("UpdateUserProfiledetailsModel", JSON.stringify(json));
        formData.append("file", this.state.fileName[0]);
        try {
            this.UserService.updateUserProfileData("UpdateUserProfileDetails", formData)
                .then((response) => {
                    var message = response.message;
                    if (message == "Success") {
                        NotificationManager.success("Profile updated successfully.");
                    } else {
                        NotificationManager.error("Profile not updated successfully.");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };
  

  render() {
      return (
          <Fragment>
              <div className="container-fluid">
                  <div className="profile-settings-cntr">
                      <div className="row">
                          <div className="col-md-12">
                              <div className="profilemain">
                                  <div className="half-circle">
                                      <div className="imguserupload">
                                          <img
                                              src={ProfileImg}
                                              alt="UserProfile"
                                              className="userProfile"
                                          />
                                          <div className="uploadtextprofile">
                                            <br></br>
                                            <input
                                              id="file-upload"
                                              className="d-none file-uploadprofile"
                                              type="file"
                                              onChange={this.fileUpload.bind(this)}
                                            />
                                            <label
                                              htmlFor="file-upload"
                                              className="uploadtextprofile1"
                                              onDrop={this.fileDrop}
                                              onDragOver={this.fileDragOver}
                                              onDragEnter={this.fileDragEnter}
                                              onChange={this.fileUpload.bind(this)}
                                            >
                                              {this.state.imgFlag === "" ? "Upload" : "Change"}
                                              &nbsp; Photo
                                            </label>

                                            {this.state.fileName[0] && (
                                              <div className="file-info pb-0">
                                                <div>
                                                  <div className="user-profile-file-dtls">
                                                    <p className="mb-0">
                                                      {this.state.fileName[0].name}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                      </div>
                                  </div>
                                  <div className="centerprofile col-md-5">
                                      <div className="divSpace">
                                          <div>
                                              <label className="designation-name">Full Name</label>
                                              <input
                                                  type="text"
                                                  className="txt-1"
                                                  placeholder="Enter Full Name"
                                                  name="fullName"
                                                  autoComplete="off"
                                                  value={this.state.fullName}
                                                  onChange={this.handleOnChange.bind(this)}
                                              />
                                              {/*{this.state.selectedFirstName.length === 0 && (*/}
                                              {/*    <p style={{ color: "red", marginBottom: "0px" }}>*/}
                                              {/*        {this.state.fileNameCompulsion}*/}
                                              {/*    </p>*/}
                                              {/*)}*/}
                                          </div>
                                      </div>
                                      <div className="divSpace">
                                          <div>
                                              <label className="reports-to">Mobile No.</label>
                                              <input
                                                  type="text"
                                                  className="txt-1 cursor-disabled"
                                                  placeholder="Enter Mobile Number"
                                                  name="mobileNo"
                                                  autoComplete="off"
                                                  disabled
                                                  value={this.state.mobileNo}
                                                  onChange={this.handleOnChange.bind(this)}
                                              />
                                              {/*{this.state.selectedMobile.length === 0 && (*/}
                                              {/*    <p style={{ color: "red", marginBottom: "0px" }}>*/}
                                              {/*        {this.state.MobileCompulsion}*/}
                                              {/*    </p>*/}
                                              {/*)}*/}
                                          </div>
                                      </div>

                                      <div className="divSpace">
                                          <div>
                                              <label className="reports-to">Email ID</label>
                                              <input
                                                  type="text"
                                                  className="txt-1 cursor-disabled"
                                                  placeholder="Enter Email"
                                                  name="emailId"
                                                  disabled
                                                  value={this.state.emailId}
                                                  onChange={this.handleOnChange.bind(this)}
                                              />
                                              {/*{this.state.selectedEmailID.length === 0 && (*/}
                                              {/*    <p style={{ color: "red", marginBottom: "0px" }}>*/}
                                              {/*        {this.state.EmailIDCompulsion}*/}
                                              {/*    </p>*/}
                                              {/*)}*/}
                                          </div>
                                      </div>

                                      <div className="divSpace">
                                          <div>
                                              <label className="reports-to">Designation</label>
                                              <select
                                                  className="add-select-category cursor-disabled"
                                                  name="designation"
                                                  value={this.state.designation}
                                                  onChange={this.handleOnChange.bind(this)}
                                              >
                                                  <option value="">Select Designation</option>
                                                  {this.state.hierarchyGridData &&
                                                      this.state.hierarchyGridData.map((item) => (
                                                          <option value={item.designationID}>
                                                              {item.designationName}
                                                          </option>
                                                   ))}
                                              </select>
                                              {/*{this.state.selectedDesignation === "" && (*/}
                                              {/*    <p style={{ color: "red", marginBottom: "0px" }}>*/}
                                              {/*        {this.state.DesignationCompulsion}*/}
                                              {/*    </p>*/}
                                              {/*)}*/}
                                          </div>
                                      </div>

                                      <div className="chatconfigbtn">
                                          <button
                                              className="CreateADDBtn"
                                              onClick={this.handleUpdateProfileData.bind(this)}
                                          >
                                              SAVE
                                          </button>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </Fragment>
    );
  }
}

export default UserProfile;
