import React, { Fragment, Component } from "react";
import { SUserIcon } from "./../../../helpers/ImagesImport";
import { Empty, Pagination, Spin } from "antd";
import LeadsService from "../../../services/Leads/LeadsService";
import parse from "html-react-parser";

export default class HistoryTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      History: [],
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      leadID: props.LeadID + "",
    };
    this.leadsService = new LeadsService();
  }

  componentDidMount() {
    this.handleGetHistory();
  }

  handleGetHistory = () => {
    try {
      var inputData = {};
      inputData.IDs = this.state.leadID + "";
      inputData.PageNo = this.state.pageNo;
      inputData.PageSize = this.state.pageSize;
      this.setState({
        isLoading: true,
      });
      this.leadsService
        .getLeadDetailsTab("GetLeadHistoryByLeadID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var History = response.responseData.leadHistoryDetails;
          var totalCount = response.responseData.totalCount;

          if (statusCode === 200) {
            this.setState({
              History,
              totalCount,
              isLoading: false,
            });
          } else {
            this.setState({
              History,
              totalCount,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: true,
          });
          console.log(error);
        });
    } catch (error) {
      this.setState({
        isLoading: true,
      });
      console.log(error);
    }
  };
  handlePageNoChange = async (e) => {
    await this.setState({
      pageNo: e,
    });
    this.handleGetHistory();
  };
  handlePageSizeChange = async (e, pageSize) => {
    await this.setState({
      pageNo: e,
      pageSize,
    });
    this.handleGetHistory();
  };
  render() {
    return (
      <Fragment>
        <div className="HistoryMain">
          <ul>
            <Spin spinning={this.state.isLoading}>
              {this.state.History ? (
                this.state.History.map((item, key) => {
                  return (
                    <li>
                      <div className="History">
                        <div className="lefSide">
                          <div className="rightUs">
                            <div className="DateTime">
                              <h4 className="abou">{item.createdDate}</h4>
                              {/* <h4 className="dayago">{item.historytime}</h4> */}
                            </div>
                            <div className="QuContent">
                              {/* <span>
                              {item.isEmail ? (
                                <img src={EmailIcon} alt="User" />
                              ) : (
                                <img src={DollerIcon} alt="User" />
                              )}
                            </span> */}
                              <div className="Quote">
                                <h4 className="abou">{item.leadActionFor}</h4>
                                <h4 className="dayago">
                                  {item.leadAction
                                    ? parse(item.leadAction)
                                    : null}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                            {item.userProfile ? (
                              <img src={item.userProfile} alt="User" />
                            ) : (
                              <img src={SUserIcon} alt="User" />
                            )}
                            <div>
                              <h4 className="abou">{item.createdByName}</h4>
                              {/* <h4 className="dayago">{item.designtion}</h4> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Spin>
          </ul>
          {this.state.History.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : null}
          {this.state.History.length > 0 ? (
            <Pagination
              defaultCurrent={1}
              total={this.state.totalCount}
              onChange={this.handlePageNoChange.bind(this)}
              pageSizeOptions={[10, 20, 30]}
              onShowSizeChange={this.handlePageSizeChange.bind(this)}
              responsive={true}
              showSizeChanger={true}
              current={this.state.pageNo}
              pageSize={this.state.pageSize}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}
