import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Switch, Table, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import { NotificationManager } from "react-notifications";
import StatusService from "./../../../services/AdminSetting/StatusService";

export default class StatusMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAccess: true,
            viewAccess: true,
            deleteAccess: true,
            editAccess: true,
            searchBy: "",
            statusInput: {},
            statusError: {},
            isEdit: false,
            isSubmitLoading: false,
            loading: false,
            statusModel: false,
            stageMasterData: [],
            statusGridData:[]
        };

        this.StatusService = new StatusService();
    }
    componentDidMount() {
        this.handleGetStatusGridData();
    }
    handleStatusModalOpen = () => {
        this.setState({ statusModel: true, statusInput: { status: true } });
    };
    
    handleStatusModalClose = () => {
        this.setState({
            statusModel: false,
            statusInput: {},
            statusError: {},
            isEdit: false,
        });
    };
    handleOnChangeInput = async (name, e) => {
        var statusInput = this.state.statusInput;
        statusInput[name] = e.target.value;
        await this.setState({
            statusInput,
        });
    };

    handleOnChange = (name, e) => {
        var statusInput = this.state.statusInput;

        statusInput[name] = e;
        this.setState({
            statusInput,
        });
    };

    handleOnChangeRadioBtn(task) {
        let statusInput = this.state.statusInput;
        statusInput["task"] = task;
        this.setState({ statusInput });
    };

    handleStatusValidation = () => {
        let statusInput = this.state.statusInput;
        let statusError = {};
        let isValid = true;

        if (!statusInput["statusName"]) {
            isValid = false;
            statusError["statusName"] = "Please enter status name.";
        }
        if (!statusInput["stageName"]) {
            isValid = false;
            statusError["stageName"] = "Please select stage name.";
        }
       
        
        this.setState({
            statusError,
        });
        return isValid;
    };

    handleStatusSubmit = () => {
        if (this.handleStatusValidation()) {
            if (!this.state.isEdit) {
                this.handleCreateStatus();
            } else {
                this.handleUpdateStatus();
            }
        }
    };


    handleCreateStatus = () => {
        debugger;
        var inputData = {};
        inputData.StatusName = this.state.statusInput.statusName;
        inputData.LeadStageID = this.state.statusInput.stageName;
        inputData.IsActive = this.state.statusInput.status;
        inputData.DisableTask = this.state.statusInput.task;

        this.setState({ isSubmitLoading: true });
        try {
            this.StatusService.insertStatus("InsertStatus", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    this.setState({ isSubmitLoading: false });
                    if (statusCode === 200) {
                        NotificationManager.success("Status Created Successfully.");
                       // this.handleGetStatusGridData();
                        this.setState({
                            statusModel: false,
                            statusInput: {},
                        });
                    } else {
                        NotificationManager.error("Status Not Create.");
                    }
                })
                .catch((error) => {
                    this.setState({ isSubmitLoading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    handleSerchInputChange = async (e) => {
        await this.setState({
            searchBy: e.target.value,
        });

        this.handleGetStatusGridData();
    };

    handleGetStatusGridData = () => {
        debugger;
        var inputData = {};

        inputData.SearchText = this.state.searchBy;
        try {
            this.StatusService.getStatus("GetStatus", inputData)
                .then((response) => {
                    debugger;
                    var message = response.message;
                    var statusGridData = response.responseData;
                    if (message == "Success") {
                        this.setState({ statusGridData });
                    } else {
                        this.setState({ statusGridData: [] });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    handleGetEditStatusData = async (e) => {
        debugger;
        var statusInput = this.state.statusInput;
        statusInput["statusID"] = e.leadStatusId;
        statusInput["statusName"] = e.statusName;
        statusInput["stageName"] = e.leadStageID || null;
        statusInput["task"] = e.disableTask;
        statusInput["status"] = e.isActive;

        await this.setState({
            statusModel: true,
            statusInput,
            isEdit: true,
        });
    };


    handleUpdateStatus = () => {
        var inputData = {};
        inputData.LeadStatusId = this.state.statusInput.statusID;
        inputData.StatusName = this.state.statusInput.statusName;
        inputData.LeadStageID = this.state.statusInput.stageName;
        inputData.DisableTask = this.state.statusInput.task;
        inputData.IsActive = this.state.statusInput.status;

        this.setState({ isSubmitLoading: true });
        try {
            this.StatusService.updateStatus("UpdateStatus", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    this.setState({ isSubmitLoading: false });
                    if (statusCode === 200) {
                        NotificationManager.success("Status Updated Successfully.");
                        this.handleGetStatusGridData();
                        this.setState({ statusModel: false, isEdit: false, });
                    } else {
                        NotificationManager.error("Status Not Updated.");
                    }
                })
                .catch((error) => {
                    this.setState({ isSubmitLoading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };


    handleDeleteStatus = (ID) => {
        debugger;
        var inputData = {};
        inputData.IDs = ID + "";

        try {
            this.StatusService.deleteStatus("DeleteStatus", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    var data = response.responseData;
                    if (statusCode === 200) {
                        NotificationManager.success("Record Deleted Successfully.");
                        this.handleGetStatusGridData();
                    } else {
                        NotificationManager.error("Record Not Deleted.");
                    }
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };






    render() {
        const { Option } = Select;
       
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                Status Master
                                {this.state.createAccess &&
                                    <img
                                        src={AddIcon}
                                        alt="add"
                                        onClick={this.handleStatusModalOpen.bind(this)}
                                    />}
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                name="searchByTeam"
                                value={this.state.searchBy}
                                onChange={this.handleSerchInputChange.bind(this)}
                                placeholder="Search by..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    {this.state.viewAccess &&
                        <div className="AlertTable">
                            <Table
                                columns={[
                                    {
                                        title: "Status Name",
                                        dataIndex: "statusName",
                                        key: "statusName",
                                    },
                                    {
                                        title: "Stage Name",
                                        dataIndex: "stageName",
                                        key: "stageName",
                                    },
                                    {
                                        title: "Created By",
                                        dataIndex: "createdByName",
                                        key: "createdByName",
                                    },
                                    {
                                        title: "Modified By",
                                        dataIndex: "modifiedByName",
                                        key: "modifiedByName",
                                },
                                {
                                    title: "Disable Task",
                                    dataIndex: "disableTask",
                                    render: (item, row) => {
                                        return (
                                            <div className="Status" >
                                                <Select value={row.disableTask} showArrow={false} disabled={true}>
                                                    <Option value={true}>Yes</Option>
                                                    <Option value={false}>No</Option>
                                                </Select>
                                            </div>
                                        );
                                    },
                                },
                                    {
                                        title: "Status",
                                        dataIndex: "isActive",
                                        render: (item, row) => {
                                            return (
                                                <div className="Status" >
                                                    <Select value={row.isActive} showArrow={false} disabled={true}>
                                                        <Option value={true}>Active</Option>
                                                        <Option value={false}>Inactive</Option>
                                                    </Select>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: "Actions",
                                        key: "actions",
                                        dataIndex: "actions",
                                        render: (row, item) => {
                                            return (
                                                <div className="Actionic">
                                                    {this.state.editAccess &&
                                                        <img
                                                            src={AddNote}
                                                            alt="Edit"
                                                            onClick={this.handleGetEditStatusData.bind(this, item)}
                                                        />}
                                                    {this.state.deleteAccess &&
                                                        <img
                                                            src={CloseCircle}
                                                            alt="delete"
                                                           onClick={this.handleDeleteStatus.bind(this, item.leadStatusId)}
                                                        />}
                                                </div>
                                            );
                                        },
                                    },
                            ]}
                            dataSource={this.state.statusGridData}
                                pagination={{
                                    position: ["bottomCenter"],
                                    showSizeChanger: true,
                                }}
                            />
                        </div>
                    }






                    <Modal
                        closable={false}
                        title={!this.state.isEdit ? "Create Status" : "Edit Status"}
                        visible={this.state.statusModel}
                        footer={[
                            <Button key="back" onClick={this.handleStatusModalClose.bind(this)}>
                                Cancel
              </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={this.state.isSubmitLoading}
                                onClick={this.handleStatusSubmit.bind(this)}
                            >
                                {this.state.isEdit ? "Update" : "Create"}
                            </Button>,
                        ]}
                    >
                        <div className="createPrior">
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Status Name</label>
                                    <Input
                                        placeholder="Enter Status Name"
                                        name="statusName"
                                        value={this.state.statusInput.statusName}
                                        onChange={this.handleOnChangeInput.bind(this, "statusName")}
                                    />
                                    {this.state.statusError ? (
                                        <div className="text-danger">
                                            {this.state.statusError["statusName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Stage Name</label>

                                    <Select
                                        onChange={this.handleOnChange.bind(this, "stageName")}
                                        value={this.state.statusInput.stageName}
                                        placeholder="Select Stage Name"
                                      
                                    >
                                        {this.state.stageMasterData
                                            ? this.state.stageMasterData.map((item, key) => {
                                                return (
                                                    <Option>
                                                        
                                                    </Option>
                                                );
                                            })
                                            : null}
                                    </Select>
                                    {this.state.statusError ? (
                                        <div className="text-danger">
                                            {this.state.statusError["stageName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Status</label>
                                    <Select
                                        value={this.state.statusInput.status}
                                        onChange={this.handleOnChange.bind(this, "status")}
                                        placeholder={"Select Status"}
                                    >
                                        <Option  value={true} >Active</Option>
                                        <Option value={false}>Inactive</Option>
                                    </Select>
                                    {this.state.statusError ? (
                                        <div className="text-danger">
                                            {this.state.statusError["status"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Disable Task</label>
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        checked={this.state.statusInput.task}
                                        onChange={this.handleOnChangeRadioBtn.bind(this)}
                                           
                                    />

                                </div>
                            </div>




                        </div>

                        </Modal>










                </div>
            </div>
            );
    }
}
