import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Table, Switch, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import { NotificationManager } from "react-notifications";

export default class LeadFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAccess: true,
            viewAccess: true,
            searchBy: "",
            leadFieldGridData: [
                {
                    fieldName: "Lead Code",
                    displayName: "lead Id ",
                    fieldTypeMandatory: true,
                    leadcreatePage:false
                },
                {
                    fieldName: "Mobile Number",
                    displayName: "MB No ",
                    fieldTypeMandatory: true,
                    leadcreatePage: false
                }
            ]
        };
    }
    componentDidMount() {

    }




    render() {
        const { Option } = Select;
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                Lead Fields
                                {/*{this.state.createAccess &&*/}
                                {/*    <img*/}
                                {/*        src={AddIcon}*/}
                                {/*        alt="add"*/}
                                {/*    //onClick={this.handleTeamModalOpen.bind(this)}*/}
                                {/*    />}*/}
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                name="searchByTeam"
                                value={this.state.searchByTeam}
                                //onChange={this.handleSerchInputChange.bind(this)}
                                placeholder="Search by..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    <div className="RoleAccessTable">
                        <Table
                            columns={[
                                {
                                    title: "Field Name",
                                    dataIndex: "fieldName",
                                    key: "fieldName",
                                    render: (row, item) => {
                                        return (
                                            <div className="report">
                                                <label>{item.fieldName}</label>
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: "Display Name",
                                    dataIndex: "displayName",
                                    key: "displayName",
                                    render: (row, item) => {
                                        return (
                                            <div className="col-6 col-md-6">
                                                <Input
                                                    placeholder="Enter display Name"
                                                    name="displayName"
                                                    value={item.displayName}
                                                    
                                                />
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: "FieldType Mandatory",
                                    key: "fieldTypeMandatory",
                                    dataIndex: "fieldTypeMandatory",
                                    render: (row, item) => {
                                        return (
                                            <div className="report">
                                                <Switch
                                                    checkedChildren="On"
                                                    unCheckedChildren="Off"
                                                    checked={item.fieldTypeMandatory}
                                                    
                                                />
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: "Lead Create Page",
                                    key: "leadcreatePage",
                                    dataIndex: "leadcreatePage",
                                    render: (row, item) => {
                                        return (
                                            <div className="report">
                                                <Switch
                                                    checkedChildren="On"
                                                    unCheckedChildren="Off"
                                                    checked={item.leadcreatePage}
                                                   
                                                />
                                            </div>
                                        );
                                    },
                                },
                                
                               
                            ]}
                            dataSource={this.state.leadFieldGridData}
                            pagination={false}
                        />
                    </div>
















                </div>
            </div>
        );
    }
}
