import Configuration from "../../helpers/Configuration";
import axios from "axios";

class AlertService {
  constructor() {
    this.config = new Configuration();
  }
    async getAlertTypeName(url, inputData) {
        const response = await axios(this.config.AlertServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
    }

    async getAlertList(url, inputData) {
        const response = await axios(this.config.AlertServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async createAlert(url, inputData) {
        const response = await axios(this.config.AlertServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getAlertDetails(url, inputData) {
        const response = await axios(this.config.AlertServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async deleteAlert(url, inputData) {
        const response = await axios(this.config.AlertServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }

    async getPlaceholderData(url, inputData) {
        const response = await axios(this.config.AlertServiceURL + url, {
            method: "POST",
            data: inputData,
        });
        return await response.data;
    }


}

export default AlertService;
