import React, { Component } from "react";
import Download from "./../../../assets/images/Download.svg";
import PrintImg from "./../../../assets/images/PrintImg.png";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Input, Switch, Select, Button } from "antd";
import HierarchyService from "./../../../services/AdminSetting/HierarchyService";
import RoleAccessControlService from "./../../../services/AdminSetting/RoleAccessControlService";
import NotificationManager from "react-notifications/lib/NotificationManager";

class RoleAccessControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Single: true,
      Multiple: false,
      hierarchyGridData: [],
      designationID: 0,
      roleInput: {},
    };
    this.HierarchyService = new HierarchyService();
    this.RoleAccessControlService = new RoleAccessControlService();
  }

  componentDidMount() {
    this.handleGetRoleMappingData();
    this.handleGetHierarchyGridData();
    this.handleGetServiceData();
  }

  handleGetHierarchyGridData = () => {
    var inputData = {};
    inputData.SearchText = "";
    this.setState({ isLoading: true });
    try {
      this.HierarchyService.getHierarchyGridData("GetHierarchy", inputData)
        .then((response) => {
          var message = response.message;
          var hierarchyGridData = response.responseData;
          this.setState({ isLoading: false });
          if (message == "Success") {
            this.setState({ hierarchyGridData });
          } else {
            this.setState({ hierarchyGridData: [] });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  handleGetServiceData = () => {
    var inputData = {};
    var serviceGridData = [];
    //var roleInput = this.state.roleInput
    //roleInput.service = undefined;
    inputData.DesignationID = this.state.roleInput.role;
    inputData.ModuleID = 0;
    this.setState({ isLoading: true, serviceGridData });
    try {
      this.RoleAccessControlService.getRoleMappingData(
        "GetRoleMapping",
        inputData
      )
        .then((response) => {
          var message = response.message;
          serviceGridData = response.responseData;
          this.setState({ isLoading: false });
          if (message == "Success") {
            this.setState({ serviceGridData });
          } else {
            this.setState({ serviceGridData: [] });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  handleGetRoleMappingData = () => {
    var inputData = {};
    var roleMappingGridData = [];
    inputData.DesignationID = this.state.roleInput.role;
    inputData.ModuleID = this.state.roleInput.service;
    this.setState({ isLoading: true, roleMappingGridData });
    try {
      this.RoleAccessControlService.getRoleMappingData(
        "GetRoleMapping",
        inputData
      )
        .then((response) => {
          var message = response.message;
          var roleMappingGridData = response.responseData;
          this.setState({ isLoading: false });
          if (message == "Success") {
            this.setState({ roleMappingGridData });
          } else {
            this.setState({ roleMappingGridData: [] });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

    handleOnChangeRadioBtn = (item, type) => {
        debugger;
    let roleMappingGridData = [...this.state.roleMappingGridData],
      isView,
      isCreate,
      isEdit,
      isDelete,
      isDownload;
    for (let i = 0; i < roleMappingGridData.length; i++) {
      if (type === "view") {
        if (roleMappingGridData[i].moduleID === item.moduleID) {
          isView = roleMappingGridData[i].isView;
          roleMappingGridData[i].isView = isView === false ? true : false;
        }
      }
      if (type === "create") {
        if (roleMappingGridData[i].moduleID === item.moduleID) {
          isCreate = roleMappingGridData[i].isCreate;
          roleMappingGridData[i].isCreate = isCreate === false ? true : false;
        }
      }
      if (type === "edit") {
        if (roleMappingGridData[i].moduleID === item.moduleID) {
          isEdit = roleMappingGridData[i].isEdit;
          roleMappingGridData[i].isEdit = isEdit === false ? true : false;
        }
      }
      if (type === "delete") {
        if (roleMappingGridData[i].moduleID === item.moduleID) {
          isDelete = roleMappingGridData[i].isDelete;
          roleMappingGridData[i].isDelete = isDelete === false ? true : false;
        }
      }
      if (type === "download") {
        if (roleMappingGridData[i].moduleID === item.moduleID) {
          isDownload = roleMappingGridData[i].isDownload;
          roleMappingGridData[i].isDownload =
            isDownload === false ? true : false;
        }
      }

      this.setState({
        roleMappingGridData,
      });
    }
  };

  handleInsertUpdateRoleMapping = () => {
    var inputData = {};
    var moduleMapping = [];
    var roleMappingGridData = this.state.roleMappingGridData;
    for (let i = 0; i < roleMappingGridData.length; i++) {
      moduleMapping.push({
        ModuleID: roleMappingGridData[i].moduleID,
        IsView: roleMappingGridData[i].isView,
        IsCreate: roleMappingGridData[i].isCreate,
        IsEdit: roleMappingGridData[i].isEdit,
        IsDelete: roleMappingGridData[i].isDelete,
        IsDownload: roleMappingGridData[i].isDownload,
      });
    }
    inputData.MappingID = 0;
    inputData.DesignationID = this.state.roleInput.role;
    inputData.IsActive = true;
    inputData.ModuleMapping = moduleMapping;
    try {
      this.RoleAccessControlService.insertUpdateRoleMapping(
        "InsertUpdateRoleMapping",
        inputData
      )
        .then((response) => {
          var message = response.message;
          //this.setState({ isSubmitLoading: false });
          if (message === "Success") {
            NotificationManager.success("Record Created Successfully.");
            // createNotification("success", "Priority Created Successfully.");
            //this.handleGetPriorityGridData();
            //this.setState({ priorityModal: false });
          } else {
            NotificationManager.error("Record Not Created.");
          }
        })
        .catch((error) => {
          //this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleSelectInputChange = async (name, e) => {
    var roleInput = this.state.roleInput;
    roleInput[name] = e;
    this.setState({
      roleInput,
    });
    this.handleGetServiceData();
    this.handleGetRoleMappingData();
  };

  render() {
    const { Option } = Select;
    return (
      <div className="roleaccessMain">
        <div className="Hier">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>Role Access Control</h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by role name and service…"
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
          <div className="hierdown1 mb-3">
            <div className="row">
              <div className="col-12 col-md-3">
                <Select
                  defaultValue="Select Role"
                  value={this.state.roleInput.role}
                  onChange={this.handleSelectInputChange.bind(this, "role")}
                >
                  {this.state.hierarchyGridData &&
                    this.state.hierarchyGridData.map((item) => (
                      <Option value={item.designationID}>
                        {item.designationName}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className="col-12 col-md-3">
                <Select
                  defaultValue="Select Service Name"
                  value={this.state.roleInput.service}
                  onChange={this.handleSelectInputChange.bind(this, "service")}
                >
                  <Option value={0}>Select Service Name</Option>
                  {this.state.serviceGridData &&
                    this.state.serviceGridData.map((item) => (
                      <Option value={item.moduleID}>{item.moduleName}</Option>
                    ))}
                </Select>
              </div>
              <div className="col-12 col-md-3">
                <Button
                  style={{marginTop:"5px"}}
                  type="primary"
                  onClick={this.handleInsertUpdateRoleMapping}
                >
                  Save
                </Button>
              </div>
              <div className="col-12 col-md-2">
                <img src={PrintImg} alt="PrintImg" className="printImg" />
              </div>
            </div>
          </div>
          <div className="hierdown">
            <label>
              <img src={Download} alt="Delete" />
              Excel
            </label>
          </div>
          <div className="RoleAccessTable">
            <Table
              columns={[
                {
                  title: "Services",
                  dataIndex: "services",
                  key: "services",
                  render: (row, item) => {
                    return (
                      <div className="report">
                        <label>{item.moduleName}</label>
                      </div>
                    );
                  },
                },
                {
                  title: "View",
                  dataIndex: "view",
                  key: "view",
                  render: (row, item) => {
                    return (
                      <div className="report">
                        <Switch
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          checked={item.isView}
                          onChange={this.handleOnChangeRadioBtn.bind(
                            this,
                            item,
                            "view"
                          )}
                        />
                      </div>
                    );
                  },
                },
                {
                  title: "Create",
                  key: "create",
                  dataIndex: "create",
                  render: (row, item) => {
                    return (
                      <div className="report">
                        <Switch
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          checked={item.isCreate}
                          onChange={this.handleOnChangeRadioBtn.bind(
                            this,
                            item,
                            "create"
                          )}
                        />
                      </div>
                    );
                  },
                },
                {
                  title: "Edit",
                  key: "edit",
                  dataIndex: "edit",
                  render: (row, item) => {
                    return (
                      <div className="report">
                        <Switch
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          checked={item.isEdit}
                          onChange={this.handleOnChangeRadioBtn.bind(
                            this,
                            item,
                            "edit"
                          )}
                        />
                      </div>
                    );
                  },
                },
                {
                  title: "Delete",
                  key: "delete",
                  dataIndex: "delete",
                  render: (row, item) => {
                    return (
                      <div className="Status">
                        <Switch
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          checked={item.isDelete}
                          onChange={this.handleOnChangeRadioBtn.bind(
                            this,
                            item,
                            "delete"
                          )}
                        />
                      </div>
                    );
                  },
                },
                {
                  title: "Download",
                  key: "download",
                  dataIndex: "download",
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        <Switch
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          checked={item.isDownload}
                          onChange={this.handleOnChangeRadioBtn.bind(
                            this,
                            item,
                            "download"
                          )}
                        />
                      </div>
                    );
                  },
                },
              ]}
              dataSource={this.state.roleMappingGridData}
              pagination={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RoleAccessControl;
