import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { InitFacebookSdk } from './helpers/InitFacebookSdk';

InitFacebookSdk();
ReactDOM.render(<App />, document.getElementById('root'));




