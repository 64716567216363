import React, { Component } from "react";
import { Input, Radio, Select, Table, Steps, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Dropzone from "react-dropzone";
import {
  AddIcon,
  AddNoteIcon,
  CloseCircleIcon,
  UploadIcon,
  LeftIcon,
} from "./../../../helpers/ImagesImport";
import LocationService from "../../../services/AdminSetting/LocationService";
import CommonService from "../../../services/Common/CommonService";
import { NotificationManager } from "react-notifications";
import Master from "../../../helpers/Master";
import UserService from "./../../../services/AdminSetting/UserService";
import ImportExport from "../../../helpers/ImportExport";
const { Step } = Steps;
class Location extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationModal: false,
      Single: true,
      Multiple: false,
      CurrentStep: 0,
      fileName: "",
      fileN: [],
      locationInput: {},
      locationError: {},
      isEdit: false,
      Brand: [],
      Country: [],
      State: [],
      City: [],
      Region: [],
      PinCode: [],
      locationGirdData: [],
      searchValue: "",
      loading: false,
      isSubmitLoading: false,
      selectedRowKeys: [],
      createAccess: true,
      viewAccess: true,
      deleteAccess: true,
      editAccess: true,
      downloadAccess: true,
      fileCreateAccess: true,
      fileSize: "",
      file: {},
      fileValidation: ""
    };
    this.locationService = new LocationService();
    this.CommonService = new CommonService();
    this.master = new Master();
    this.UserService = new UserService();
    this.importExport = new ImportExport();
  }
  componentDidMount() {
    this.handleGetLocationGridData();
    this.handleGetBrandList();
    this.handleGetStateByCountryIDList();
    this.handleGetModuleDetails();
  }
    handleLocationModalOpen() {
        this.setState({ locationModal: true, isEdit: false, CurrentStep: 0 });
  }
  handleLocationModalClose() {
    this.setState({
      locationModal: false,
      CurrentStep: 0,
      locationError: {},
      locationInput: {},
      isEdit: false,
    });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
    onSelectChange = (selectedRowKeys) => {
        debugger;
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  handleChangeStep = (CurrentStep) => {
    this.setState({
      CurrentStep,
    });
  };
  handleNextStepClick(check) {
    if (check === "next") {
      if (this.state.CurrentStep === 0) {
        this.setState({
          CurrentStep: 1,
        });
      } else {
        this.setState({
          CurrentStep: 2,
        });
      }
    } else {
      if (this.state.CurrentStep === 1) {
        this.setState({
          CurrentStep: 0,
        });
      } else {
        this.setState({
          CurrentStep: 1,
        });
      }
    }
  }
  fileUpload = (file) => {
    if (file.length > 0) {
          var fileName = file[0].name;
          if (fileName.split('.').pop() === "csv") {
              this.setState({
                  fileName,
                  file: file[0],
                  fileValidation: "",
              });
          } else {
              NotificationManager.error("File accept only csv type.");
          }
      }
  };
  //handle location input change
  handleLocationInputChange = async (name, e) => {
    var locationInput = this.state.locationInput;
    var locationError = this.state.locationError;
    if (name === "ContactNumber") {
      if (!isNaN(e.target.value)) {
        locationInput[name] = e.target.value;
        locationError[name] = "";
      } else {
        if (e.target.value) {
          locationInput[name] = "";
          locationError[name] = "Please enter number only.";
        } else {
          locationInput[name] = "";
          locationError[name] = "";
        }
      }
    } else {
      if (e.target) {
        locationInput[name] = e.target.value;
        locationError[name] = "";
      } else {
        locationInput[name] = e;
        locationError[name] = "";
        if (name === "State") {
          this.handleGetCityByStateIDList();
        }
        if (name === "City") {
          this.handleGetPinCodeList();
        }
        if (name === "PinCode") {
          this.handleGetRegionListByPinCodeID();
        }
      }
    }

    await this.setState({
      locationInput,
      locationError,
    });
  };
  //handle location input validation
  handleLocationInputValidation = () => {
    let locationInput = this.state.locationInput;
    let locationError = {};
    let isValid = true;
    if (!locationInput["Brand"]) {
      isValid = false;
      locationError["Brand"] = "Please select brand.";
    }
    if (!locationInput["LocationCode"]) {
      isValid = false;
      locationError["LocationCode"] = "Please enter location code.";
    }
    if (!locationInput["LocationName"]) {
      isValid = false;
      locationError["LocationName"] = "Please enter location name.";
    }
    // if (!locationInput["LocationType"]) {
    //   isValid = false;
    //   locationError["LocationType"] = "Please select location type.";
    // }
    if (!locationInput["State"]) {
      isValid = false;
      locationError["State"] = "Please select state.";
    }
    if (!locationInput["City"]) {
      isValid = false;
      locationError["City"] = "Please select city.";
    }
    if (!locationInput["Address"]) {
      isValid = false;
      locationError["Address"] = "Please enter address.";
    }
    if (!locationInput["Region"]) {
      isValid = false;
      locationError["Region"] = "Please select region.";
    }
    if (!locationInput["Zone"]) {
      isValid = false;
      locationError["Zone"] = "Please select zone.";
    }
    if (!locationInput["PinCode"]) {
      isValid = false;
      locationError["PinCode"] = "Please select pin code.";
    }
    if (locationInput["EmailAddress"]) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(locationInput["EmailAddress"])) {
        isValid = false;
        locationError["EmailAddress"] = "Please enter valid email address.";
      } else {
        isValid = true;
        locationError["EmailAddress"] = "";
      }
    } else {
      isValid = false;
      locationError["EmailAddress"] = "Please enter email address.";
    }
    if (!locationInput["ContactNumber"]) {
      isValid = false;
      locationError["ContactNumber"] = "Please enter contact number.";
    }
    if (
      locationInput["Status"] === "" ||
      locationInput["Status"] === null ||
      locationInput["Status"] === undefined
    ) {
      isValid = false;
      locationError["ContactNumber"] = "Please enter contact number.";
    }
    this.setState({
      locationError,
    });
    return isValid;
  };
  //handle location submit
  handleLocationSubmit = () => {
    if (!this.state.isEdit) {
      if (this.handleLocationInputValidation()) {
        this.handleCreateUpdatelocation();
      }
    } else {
      if (this.handleLocationInputValidation()) {
        this.handleCreateUpdatelocation();
      }
    }
  };

  //handle get location gird data
  handleGetLocationGridData = () => {
    try {
      this.setState({ loading: true });
      var inputData = {};
      inputData.IDs = "";
      inputData.Value = this.state.searchValue;
      this.locationService
        .getLocaionGirdData("GetLocation", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var locationGirdData = response.responseData;
          var key = 0;
          locationGirdData.forEach((element) => {
            key += 1;
            element.key = key;
          });
          this.setState({ loading: false });
          if (statusCode === 200) {
            this.setState({ locationGirdData });
          } else {
            this.setState({ locationGirdData: [] });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };
  //handle delete location by id
  handleDeleteLocation = (id) => {
    var inputData = {};
    inputData.IDs = id.toString();
    inputData.Value = "";
    try {
      this.setState({ loading: true });
      this.locationService
        .deleteLocation("DeleteLocation", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          if (statusCode === 200) {
            NotificationManager.success("Record Deleted Successfully.");
            this.handleGetLocationGridData();
          } else {
            NotificationManager.error("Record Not Deleted.");
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };
  //handle create location
  handleCreateUpdatelocation = () => {
    var inputData = {};
    inputData.BrandID = this.state.locationInput.Brand.join(",");
    inputData.LocationCode = this.state.locationInput.LocationCode;
    inputData.LocationName = this.state.locationInput.LocationName;
    inputData.LocationTypeID = this.state.locationInput.LocationType;
    inputData.StateID = this.state.locationInput.State;
    inputData.CityID = this.state.locationInput.City;
    inputData.Address = this.state.locationInput.Address;
    inputData.RegionID = this.state.locationInput.Region;
    inputData.EmailID = this.state.locationInput.EmailAddress;
    inputData.Phone = this.state.locationInput.ContactNumber;
    inputData.IsActive = this.state.locationInput.Status;
    inputData.PinCodeID = this.state.locationInput.PinCode;
    inputData.Zone = this.state.locationInput.Zone;
    inputData.LocationID = this.state.locationInput.LocationID || 0;

    this.setState({ isSubmitLoading: true });
    try {
      this.locationService
        .createUpdateLocation("InsertUpdateLocation", inputData)
        .then((response) => {
          this.setState({ isSubmitLoading: false });
          var statusCode = response.statusCode;
          if (statusCode === 200) {
            if (!this.state.isEdit) {
              NotificationManager.success("Location Save Successfully.");
            } else {
              NotificationManager.success("Location Update Successfully.");
            }
            this.handleGetLocationGridData();
            this.setState({
              locationModal: false,
              locationInput: {},
              locationError: {},
              City: [],
              PinCode: [],
              Region: [],
            });
          } else {
            if (!this.state.isEdit) {
              NotificationManager.error("Location Not Save.");
            } else {
              NotificationManager.success("Location Not Update.");
            }
          }
        })
        .catch((error) => {
          this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //handle update location
  handleBulkUploadLocation = () => {
    var formData = new FormData();
    formData.append("file", this.state.file);
    this.setState({ isSubmitLoading: true });
    try {
      this.locationService
        .bulkUploadLocation("BulkUploadLocation", formData)
        .then((response) => {
          this.setState({ isSubmitLoading: false });
            var statusCode = response.statusCode;
            var data = response.responseData;
            if (statusCode === 200) {
                NotificationManager.success("Bulk Uploaded Successfully");
            } else {
                NotificationManager.error("Bulk Uploaded Failed");
            }
        })
        .catch((error) => {
          this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get brand list
  handleGetBrandList = () => {
    try {
      this.CommonService.getBrandList("GetBrandMaster")
        .then((response) => {
          var statusCode = response.statusCode;
          var Brand = response.responseData;
          if (statusCode === 200) {
            this.setState({
              Brand,
            });
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get country list
  handleGetCountryList = () => {
    try {
      this.CommonService.getCountryList("GetCountryMaster")
        .then((response) => {
          var statusCode = response.statusCode;
          var Country = response.responseData;
          if (statusCode === 200) {
            this.setState({
              Country,
            });
          } else {
            this.setState({
              Country: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get state list by country id
  handleGetStateByCountryIDList = () => {
    var inputData = {};
    inputData.IDs = this.state.locationInput.Country;
    inputData.Value = "";
    try {
      this.CommonService.getStateList("GetStateByCountryID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var State = response.responseData;
          if (statusCode === 200) {
            this.setState({
              State,
            });
          } else {
            this.setState({
              State: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get city by state id
  handleGetCityByStateIDList = () => {
    var inputData = {};
    inputData.IDs = this.state.locationInput.State.toString();
    inputData.Value = "";
    try {
      this.CommonService.getCityList("GetCityMasterByStateID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var City = response.responseData;
          if (statusCode === 200) {
            this.setState({
              City,
            });
          } else {
            this.setState({
              City: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get pincode by city id
  handleGetPinCodeList = () => {
    var inputData = {};
    // inputData.IDs = this.state.locationInput.City.toString();
    inputData.IDs = "1";
    inputData.Value = "";
    try {
      this.CommonService.getPinCodeList("GetPincodeMasterByCityID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var PinCode = response.responseData;
          if (statusCode === 200) {
            this.setState({
              PinCode,
            });
          } else {
            this.setState({
              PinCode: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleEditLocation = async (e) => {
    var locationInput = this.state.locationInput;
    locationInput["LocationID"] = e.locationID;
    locationInput["LocationName"] = e.locationName;
    locationInput["LocationCode"] = e.locationCode;
    locationInput["State"] = e.stateID;
    locationInput["City"] = e.cityID;
    locationInput["PinCode"] = e.pinCodeID;
    locationInput["Region"] = e.regionID;
    locationInput["EmailAddress"] = e.emailID;
    locationInput["ContactNumber"] = e.phone;
    locationInput["Zone"] = e.zone;
    locationInput["Address"] = e.address;
    locationInput["Status"] = e.isActive;
    if (e.brandID) {
      var BrandTemp = e.brandID.split(",");
      var BrandTempArrary = [];
      for (let i = 0; i < BrandTemp.length; i++) {
        BrandTempArrary.push(Number(BrandTemp[i]));
      }
      locationInput["Brand"] = BrandTempArrary;
    }
    if (e.stateID) {
      this.handleGetCityByStateIDList();
    }
    if (e.cityID) {
      this.handleGetPinCodeList();
    }
    if (e.regionID) {
      this.handleGetRegionListByPinCodeID();
    }
    await this.setState({
      locationInput,
        locationModal: true,
        isEdit: true,
    });
  };
  //handle get region list by pin code id
  handleGetRegionListByPinCodeID = () => {
    var inputData = {};
    inputData.IDs = this.state.locationInput.PinCode.toString();
    inputData.Value = "";
    try {
      this.CommonService.getRegionList("GetRegionMasterByPincodeID", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var Region = response.responseData;
          if (statusCode === 200) {
            this.setState({
              Region,
            });
          } else {
            this.setState({
              Region: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleSearch = (e) => {
    this.setState({ searchValue: e.target.value });
    setTimeout(() => {
      this.handleGetLocationGridData();
    }, 500);
  };

  handleGetModuleDetails = () => {
    try {
      this.UserService.getUserModuleDetails("GetUserModuleDetails")
          .then((response) => {
              
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            //this.setState({ cityData });
            this.setAccessUser(data);
          } else {
            //this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  setAccessUser(data) {
    if (data !== null) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].moduleName === "Settings") {
          this.setState({
            createAccess: data[i].isCreate,
            viewAccess: data[i].isView,
            deleteAccess: data[i].isDelete,
            editAccess: data[i].isEdit,
            downloadAccess: data[i].isDownload,
          });
        }
        if (data[i].moduleName === "File Upload") {
          this.setState({
            fileCreateAccess: data[i].isCreate
          });
        }
      }
    }
  }
  handleLocationBulkUploadSampleDownload = () => {
    this.importExport.SampleCSV(
      this.master.LocationImportSample[0],
      this.master.LocationImportSample[1],
      "Location_Sample"
    );
  };

  render() {
    const { Option } = Select;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      fixed: "left",
      width: 220,
    };

    return (
      <div className="LocationMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Location
                {this.state.createAccess && (
                  <img
                    src={AddIcon}
                    alt="add"
                    onClick={this.handleLocationModalOpen.bind(this)}
                  />
                )}
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by name, city, state..."
                prefix={<SearchOutlined />}
                onChange={this.handleSearch.bind(this)}
                value={this.state.searchValue}
              />
            </div>
          </div>
          {/*{this.state.deleteAccess &&*/}
          {/*  (this.state.selectedRowKeys.length > 0 ? (*/}
          {/*    <div className="hierdown">*/}
          {/*      <label>*/}
          {/*        <img src={CloseCircleIcon} alt="Delete" />*/}
          {/*        Delete*/}
          {/*      </label>*/}
          {/*      */}{/* <label><img src={Download} alt="Delete" />Excel</label> */}
          {/*    </div>*/}
          {/*  ) : null)}*/}
          {this.state.viewAccess && (
            <div className="LocationTable">
              <Table
                rowSelection={rowSelection}
                columns={[
                  {
                    title: "Location Name",
                    dataIndex: "locationName",
                    width: 150,
                  },
                  {
                    title: "Code",
                    dataIndex: "locationCode",
                  },
                  {
                    title: "Brand Name",
                    dataIndex: "brandName",
                  },
                  {
                    title: "City",
                    dataIndex: "cityName",
                  },
                  {
                    title: "State",
                    dataIndex: "stateName",
                  },
                  {
                    title: "Pincode",
                    dataIndex: "pincode",
                  },
                  {
                    title: "Status",
                    dataIndex: "isActive",
                    render: (item, row) => {
                      return (
                        <div className="Status">
                          <Select
                            value={row.isActive}
                            showArrow={false}
                            disabled={true}
                          >
                            <Option value={true}>Active</Option>
                            <Option value={false}>Inactive</Option>
                          </Select>
                        </div>
                      );
                    },
                  },
                  {
                    title: "Actions",
                    key: "actions",
                    dataIndex: "actions",
                    fixed: "right",
                    width: 100,
                    render: (item, row) => {
                      return (
                        <div className="Actionic">
                          {this.state.editAccess && (
                            <img
                              src={AddNoteIcon}
                              alt="Edit"
                              onClick={this.handleEditLocation.bind(this, row)}
                            />
                          )}
                          {this.state.deleteAccess && (
                            <img
                              src={CloseCircleIcon}
                              alt="delete"
                              onClick={this.handleDeleteLocation.bind(
                                this,
                                row.locationID
                              )}
                            />
                          )}
                        </div>
                      );
                    },
                  },
                ]}
                dataSource={this.state.locationGirdData}
                pagination={true}
                scroll={{ x: 1300 }}
                loading={this.state.loading}
                rowKey={(record) => record.locationID}
              />
            </div>
          )}
          {/* Create Location */}
                <Modal
                    title={!this.state.isEdit ? "Add Location" : "Edit Location"}
            visible={this.state.locationModal}
            footer={null}
            closable={false}
            className="CreateLocationModal"
          >
            <div className="createPrior">
              <div className="createLocation">
                {this.state.CurrentStep !== 0 ? (
                  <img
                    src={LeftIcon}
                    alt="Lefticon"
                    className="previousBtn"
                    onClick={this.handleNextStepClick.bind(this, "previous")}
                  />
                ) : null}
                <div className="Steep">
                  <Steps
                    current={this.state.CurrentStep}
                    onChange={this.handleChangeStep}
                    progressDot={(dot) => {
                      return <>{dot}</>;
                    }}
                  >
                    <Step title="Brand Mapping" />
                    <Step title="Address" />
                    <Step title="Contact Details" />
                  </Steps>
                            </div>
                           
                            

                <div className="mb-2">
                  <Radio
                    type="radio"
                    name="Single"
                    checked={this.state.Single}
                    onChange={this.handleRedioButtonChange.bind(this, "Single")}
                  >
                    Single
                  </Radio>
                  {this.state.fileCreateAccess &&
                  <Radio
                    type="radio"
                    name="Single"
                    checked={this.state.Multiple}
                    onChange={this.handleRedioButtonChange.bind(
                      this,
                      "Multiple"
                    )}
                  >
                    Multiple
                  </Radio>}
                </div>
                {this.state.Single && (
                  <>
                    {this.state.CurrentStep === 0 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Brand</label>
                            <Select
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "Brand"
                              )}
                              value={this.state.locationInput.Brand}
                              placeholder="Select Brand"
                              mode="multiple"
                              maxTagCount="responsive"
                            >
                              {this.state.Brand
                                ? this.state.Brand.map((item, key) => {
                                    return (
                                      <Option value={item.brandID}>
                                        {item.brandName}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["Brand"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Location Code</label>
                            <Input
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "LocationCode"
                              )}
                              value={this.state.locationInput.LocationCode}
                              placeholder="Location Code"
                            />
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["LocationCode"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Location Name</label>
                            <Input
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "LocationName"
                              )}
                              value={this.state.locationInput.LocationName}
                              placeholder="Location Name"
                            />
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["LocationName"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Location Type</label>
                            <Select
                              value={this.state.locationInput.LocationType}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "LocationType"
                              )}
                              placeholder="Select Location Type"
                            >
                              <Option value="jackjoes">India</Option>
                            </Select>
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["LocationType"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div> */}
                      </div>
                    )}
                    {this.state.CurrentStep === 1 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">State</label>
                            <Select
                              value={this.state.locationInput.State}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "State"
                              )}
                              placeholder="Select State"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              optionFilterProp="children"
                            >
                              {this.state.State
                                ? this.state.State.map((item, key) => {
                                    return (
                                      <Option key={key} value={item.stateID}>
                                        {item.stateName}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["State"] || ""}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">City</label>
                            <Select
                              value={this.state.locationInput.City}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "City"
                              )}
                              placeholder="Select City"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              optionFilterProp="children"
                            >
                              {this.state.City
                                ? this.state.City.map((item, key) => {
                                    return (
                                      <Option key={key} value={item.cityID}>
                                        {item.cityName}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["City"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Address</label>
                            <Input.TextArea
                              value={this.state.locationInput.Address}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "Address"
                              )}
                              cols="31"
                              rows="3"
                            ></Input.TextArea>
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["Address"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Pin Code</label>
                            <Select
                              value={this.state.locationInput.PinCode}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "PinCode"
                              )}
                              placeholder="Select Pin Code"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              optionFilterProp="children"
                            >
                              {this.state.PinCode
                                ? this.state.PinCode.map((item, key) => {
                                    return (
                                      <Option key={key} value={item.pincodeID}>
                                        {item.pincode}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["PinCode"] || ""}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Region</label>
                            <Select
                              value={this.state.locationInput.Region}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "Region"
                              )}
                              placeholder="Select Region"
                              showSearch
                            >
                              {this.state.Region
                                ? this.state.Region.map((item, key) => {
                                    return (
                                      <Option value={item.regionID}>
                                        {item.regionName}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["Region"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Zone</label>
                            <Select
                              value={this.state.locationInput.Zone}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "Zone"
                              )}
                              placeholder="Select Zone"
                            >
                              {this.master.Zone
                                ? this.master.Zone.map((item, key) => {
                                    return (
                                      <Option value={item.zoneID}>
                                        {item.zoneName}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["Zone"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.CurrentStep === 2 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">
                              Email Address
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <Input
                              value={this.state.locationInput.EmailAddress}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "EmailAddress"
                              )}
                              placeholder="Enter Email Address"
                            />
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["EmailAddress"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Contact Number</label>
                            <Input
                              value={this.state.locationInput.ContactNumber}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "ContactNumber"
                              )}
                              placeholder="Enter Contact Number"
                            />
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["ContactNumber"] ||
                                  ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Status</label>
                            <Select
                              value={this.state.locationInput.Status}
                              onChange={this.handleLocationInputChange.bind(
                                this,
                                "Status"
                              )}
                              placeholder="Select Status"
                            >
                              <Option value={true}>Active</Option>
                              <Option value={false}>Inactive</Option>
                            </Select>
                            {this.state.locationError ? (
                              <div className="text-danger">
                                {this.state.locationError["Status"] || ""}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {this.state.Multiple && (
                  <>
                    <div className="editor">
                      <div className="mainfileUpload">
                        <Dropzone onDrop={this.fileUpload}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                className="file-upload d-none"
                              />
                              <div className="file-icon">
                                <img src={UploadIcon} alt="file-upload" />
                              </div>
                              <span className={"drop"}>
                                Drop your file to upload
                              </span>
                              {this.state.fileName && (
                                <p className="file-name">{this.state.fileName}</p>)}
                              <button className="fileupload"
                               onClick={this.fileUpload}
                              >
                                Choose File
                              </button>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      <span className="down"
                      onClick={this.handleLocationBulkUploadSampleDownload.bind(
                        this
                      )}
                      >Download Sample File</span>
                    </div>
                  </>
                )}
                {this.state.Single && (
                <div className="editorbtn-loc">
                  <Button
                    type="button"
                    onClick={this.handleLocationModalClose.bind(this)}
                  >
                    Cancel
                  </Button>
                                {this.state.CurrentStep === 2 ? (
                                    !this.state.isEdit ? (<Button
                                        type="primary"
                                        onClick={this.handleLocationSubmit.bind(this)}
                                        loading={this.state.isSubmitLoading}
                                        disabled={this.state.isSubmitLoading}
                                    >
                                    Add
                    </Button>) : (<Button
                                    type="primary"
                                    onClick={this.handleLocationSubmit.bind(this)}
                                    loading={this.state.isSubmitLoading}
                                    disabled={this.state.isSubmitLoading}
                                >
                                    Update
                    </Button>)
                    
                  ) : (
                    <Button
                      type="primary"
                      onClick={this.handleNextStepClick.bind(this, "next")}
                    >
                      Next
                    </Button>
                  )}
                                </div>)}
                {this.state.Multiple && (
                <div className="editorbtn-loc">
                  <button
                    type="button"
                    onClick={this.handleLocationModalClose.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                      type="primary"
                      onClick={this.handleBulkUpload}
                  >
                      Upload
                  </button>   
              </div>)}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Location;
