import React, { Fragment } from "react";
import CKEditor from "ckeditor4-react";
import moment from "moment";
import { Select, DatePicker, TimePicker } from "antd";
const { Option } = Select;
const format = "HH:mm";
export default function name(params) {
  return (
    <Fragment>
      <div className="MeetMain">
        <div className="row mb-3">
          <div className="col-12 col-md-12">
            <div className="ToEmai">
              <label>To</label>
              <input type="text" defaultValue="valeriewalton@linkedin.com" />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-12">
            <div className="ToEmai">
              <label>from</label>
              <input
                type="text"
                defaultValue="Tejas (tejaskadakia@easyrewadz.com)"
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <div className="ToEmai1">
              <label>Date</label>
              <DatePicker />
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="ToEmai1">
              <label>Start Time</label>
              <TimePicker
                defaultValue={moment("12:08", format)}
                format={format}
              />
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="ToEmai1">
              <label>End Time</label>
              <TimePicker
                defaultValue={moment("12:08", format)}
                format={format}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-6">
            <div className="ToEmai1">
              <label>Attendndees</label>
              <Select defaultValue="Arun@linked.com">
                <Option value="jack">Arun@linked.com</Option>
                <Option value="lucy">Richa@linked.com</Option>
              </Select>
            </div>
          </div>
        </div>
        <div className="row mb-3 SendBtn">
          <div className="col-12 col-md-12">
            <CKEditor
              config={{
                toolbar: [
                  {
                    name: "basicstyles",
                    items: ["Bold", "Italic", "Strike"],
                  },
                  {
                    name: "styles",
                    items: ["Styles", "Format"],
                  },
                  {
                    name: "paragraph",
                    items: ["NumberedList", "BulletedList"],
                  },
                  {
                    name: "links",
                    items: ["Link", "Unlink"],
                  },
                  {
                    name: "insert",
                    items: ["Image", "Table"],
                  },
                  {
                    name: "tools",
                    items: ["Maximize"],
                  },
                  {
                    name: "editing",
                    items: ["Scayt"],
                  },
                ],
              }}
            />
            <button>Send</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
