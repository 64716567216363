import React, { Component } from "react";
import "./../../../assets/css/Leads/leadDetail.css";
import { Tabs, Collapse, Select, Button, Radio } from "antd";
import NotificationManager from "react-notifications/lib/NotificationManager";
import LeadsService from "../../../services/Leads/LeadsService";
import ImportExport from "../../../helpers/ImportExport";
import Master from "../../../helpers/Master";
const { Option } = Select;

const FieldMapList = [];
class LeadBulkUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leadAssignToData: [],
      leadColumnData: [],
      csvFielsData: [],
      uploadedFileName: "",
      ignoreDuplicate: true,
      overwriteDuplicate: false,
      skipCheckingDuplicate: false,
      sourceID: 0,
      assignToList: [],
      leadOwner: [],
      errors: {},
    };
    this.LeadsService = new LeadsService();
    this.importExport = new ImportExport();
    this.master = new Master();
  }
  componentDidMount() {
    this.handleGetAssignToData();
    this.handleGetSourceData();
  }

  handleGetAssignToData = () => {
    var inputData = {};
    inputData.SearchText = "";
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GeLeadUsers", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var leadAssignToData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadAssignToData });
          } else {
            this.setState({ leadAssignToData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetSourceData = () => {
    var inputData = {};
    //inputData.LeadStatusID = statusId;
    //inputData.EnquiryTypeID = enquiryTypeId.toString()
    try {
      this.LeadsService.getLeadDropdownData("GetLeadSource")
        .then((response) => {
          var statusCode = response.statusCode;
          var leadSourceData = response.responseData;
          if (statusCode === 200) {
            this.setState({ leadSourceData });
          } else {
            this.setState({ leadSourceData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleFileUploadData = () => {
    this.refs.LeadBulkUpload.click();
  };

  handleFileUploadOnchange = (e) => {
    var imageFile = e.target.files[0];
    // var fileName = imageFile.name;
    if (!imageFile.name.match(/\.(csv)$/)) {
      alert("Only csv file allowed.");
      return false;
    } else {
      this.setState({
        file: imageFile,
      });
      setTimeout(() => {
        this.handleFileUpload();
      }, 50);
    }
  };

  handleFileUpload() {
    let self = this;
    this.setState({
      fileUplaodLoading: true,
    });
    const formData = new FormData();
    formData.append("file", this.state.file);

    this.LeadsService.importFile("GetFilepathNHeader", formData)
      .then((response) => {
        var statusCode = response.statusCode;
        var data = response.responseData;
        this.setState({ isLoading: false });
        if (statusCode === 200) {
          var FinalData = data.leadColumnNamelist;
          this.setState({
            leadColumnData: FinalData,
            csvFielsData: data.csvFiels,
            uploadedFileName: data.fileName,
          });
        } else {
          this.setState({
            campaignColumnData: [],
            csvFielsData: [],
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  handleSystemDropDownChange(e) {
    var value = e.target.value;
    var Id = e.target.id;
    var name = e.target.selectedOptions[0].getAttribute("fiedname");
    var isExist = false;
    if (value !== "0") {
      for (var i = 0; i <= FieldMapList.length - 1; i++) {
        if (FieldMapList[i].ColumnName === name) {
          isExist = true;
        }
      }
    }
    if (!isExist) {
      if (value === "0") {
        for (var i = 0; i <= FieldMapList.length - 1; i++) {
          if (FieldMapList[i].CsvFiels === Id) {
            FieldMapList.splice([i], 1);
          }
        }
      } else {
        var FieldMapData = {};

        FieldMapData["CsvFiels"] = Id;
        FieldMapData["ColumnName"] = name;
        FieldMapData["ColumnAlies"] = value;

        FieldMapList.push(FieldMapData);
      }
    } else {
      document.getElementById(Id).value = "0";
      for (var i = 0; i <= FieldMapList.length - 1; i++) {
        if (FieldMapList[i].ColumnName === name) {
          FieldMapList.splice([i], 1);
        }
      }
      NotificationManager.error("System Field Already Selected.");
    }
  }

  handleSelectInputChange(name, e) {
    this.setState({
      [name]: e,
    });
  }

  handleSelectOwnerInputChange(name, e, event) {
    var assignTo = e;
    var assignToList = [];
    for (var i = 0; i < assignTo.length; i++) {
      assignToList.push({
        AssignTo: parseInt(assignTo[i].split("-")[0]),
        IsTeamUser: event[i].isTeamUser,
      });
    }
    this.setState({
      assignToList,
      leadOwner: e,
    });
  }

  handleValidation = (e) => {
    var errors = {};
    var isValid = true;
    if (this.state.leadOwner.length == 0) {
      errors["leadowner"] = "Please select lead owner";
      isValid = false;
    }
    if (this.state.sourceID == 0) {
      errors["leadsource"] = "Please select lead source";
      isValid = false;
    }

    this.setState({
      errors,
    });
    return isValid;
  };

  handleBulkUpload() {
    if (this.handleValidation()) {
      var inputData = {};
      inputData.FileName = this.state.uploadedFileName;
      inputData.IgnoreDuplicate = this.state.ignoreDuplicate;
      inputData.OverwriteDuplicate = this.state.overwriteDuplicate;
      inputData.SkipCheckingDuplicate = this.state.skipCheckingDuplicate;
      inputData.LeadSource = this.state.sourceID;
      inputData.Fieldmapnameslist = FieldMapList;
      inputData.AssingToList = this.state.assignToList;
      this.LeadsService.fileUpload("BulkLeadUpload", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData;
          this.setState({ isLoading: false });
          if (data) {
            data.forEach((element) => {
              this.importExport.ExportToExcel(
                element,
                element.split("/").pop()
              );
            });
          }
          if (statusCode === 200) {
            NotificationManager.success("Bulk Uploaded Successfully");
            window.location.reload();
          } else {
            NotificationManager.error("Bulk Uploaded Failed");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    }
  }

  handleDuplicateInputChange = async (name, e) => {
    if (name === "ignoreDuplicate") {
      await this.setState({
        ignoreDuplicate: true,
        overwriteDuplicate: false,
        skipCheckingDuplicate: false,
      });
    } else if (name === "overwriteDuplicate") {
      await this.setState({
        ignoreDuplicate: false,
        overwriteDuplicate: true,
        skipCheckingDuplicate: false,
      });
    } else {
      await this.setState({
        ignoreDuplicate: false,
        overwriteDuplicate: false,
        skipCheckingDuplicate: true,
      });
    }
  };

  handleSampleFileDownload = () => {
    this.importExport.SampleCSV(
      this.master.LeadImportSample,
      [],
      "Lead_Sample"
    );
  };
  render() {
    return (
      <div className="LeadBulkUp">
        {/*{this.state.leadSuccess ? "onesuccess" : (this.state.leadDetailsSuccess ? "twosuccess" : (this.state.leadAddDetailsSuccess ? "allsuccess" : "addlead"))}*/}
        <div className="row">
          <div className="col-md-10">
            <h4 className="hand">Handling duplicate lead records</h4>
          </div>
          <div className="col-md-2">
            <label
              className="impor-sample"
              onClick={this.handleSampleFileDownload.bind(this)}
            >
              Sample File
            </label>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-12">
            <div className="Radioo">
              <Radio
                checked={this.state.ignoreDuplicate}
                onChange={this.handleDuplicateInputChange.bind(
                  this,
                  "ignoreDuplicate"
                )}
              >
                <p>Ignore Duplicate</p>
              </Radio>
              <Radio
                checked={this.state.overwriteDuplicate}
                onChange={this.handleDuplicateInputChange.bind(
                  this,
                  "overwriteDuplicate"
                )}
              >
                <p>Overwrite Duplicate</p>
              </Radio>
              <Radio
                checked={this.state.skipCheckingDuplicate}
                onChange={this.handleDuplicateInputChange.bind(
                  this,
                  "skipCheckingDuplicate"
                )}
              >
                <p>Skip Checking Duplicate Record</p>
              </Radio>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <label className="impor">Lead Owner</label>
            <Select
              maxTagCount="responsive"
              mode="multiple"
              placeholder="Lead Owner"
              value={this.state.leadOwner}
              onChange={this.handleSelectOwnerInputChange.bind(
                this,
                "leadowner"
              )}
            >
              {this.state.leadAssignToData &&
                this.state.leadAssignToData.map((item) => (
                  <Option
                    value={item.userID + "-" + item.isTeamUser}
                    isTeamUser={item.isTeamUser}
                  >
                    {item.fullName}
                  </Option>
                ))}
            </Select>
            {this.state.errors && (
              <div className="validationsMsg">
                {this.state.errors["leadowner"] || ""}
              </div>
            )}
          </div>
          <div className="col-12 col-md-6">
            <label className="impor">Lead Source</label>
            <Select
              placeholder="Lead Source"
              name="sourceID"
              className="leadsouce-bulk"
              value={this.state.sourceID}
              onChange={this.handleSelectInputChange.bind(this, "sourceID")}
            >
              <Option value={0}>Select</Option>
              {this.state.leadSourceData &&
                this.state.leadSourceData.map((item) => (
                  <Option value={item.sourceID}>{item.sourceName}</Option>
                ))}
            </Select>
            {this.state.errors && (
              <div className="validationsMsg">
                {this.state.errors["leadsource"] || ""}
              </div>
            )}
          </div>
          <div className="col-12 col-md-6">
            <label className="impor">Import File</label>
            <button
              type="button"
              onClick={this.handleFileUploadData}
              className="choss curshar-pointer"
            >
              Choose File
            </button>

            <input
              type="file"
              accept=".csv"
              ref="LeadBulkUpload"
              style={{ display: "none" }}
              onChange={this.handleFileUploadOnchange}
            />
          </div>
        </div>
        <div className="col-12 col-md-6"></div>
        {this.state.leadColumnData.length > 0 && (
          <div className="row divStoreTaskCnt">
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <label className="csvFileHeaderLbl">CSV File Fields</label>
            </div>
            <div className="col-md-4">
              <label className="csvFileHeaderLbl">System Fields</label>
            </div>
          </div>
        )}
        {this.state.csvFielsData !== null &&
          this.state.csvFielsData.map((item, c) => {
            return (
              <div className="row divStoreTaskCnt" key={c}>
                <div className="col-md-1">
                  <label>{c + 1}.</label>
                </div>
                <div className="col-md-4">
                  <label>{item}</label>
                </div>
                <div className="col-md-4">
                  <select
                    className="store-create-select"
                    id={item}
                    onChange={this.handleSystemDropDownChange.bind(this)}
                  >
                    <option value={0}>Select</option>
                    {this.state.leadColumnData !== null &&
                      this.state.leadColumnData.map((item, c) => (
                        <option
                          key={c}
                          value={item.columnName}
                          className="select-category-placeholder"
                          fiedname={item.columnName}
                        >
                          {item.columnName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            );
          })}
        {this.state.leadColumnData.length > 0 && (
          <div className="row taskCampBtn">
            <div className="col-md-1"></div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <button
                className="butnup"
                onClick={this.handleBulkUpload.bind(this)}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LeadBulkUpload;
