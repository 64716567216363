import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Table, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import { NotificationManager } from "react-notifications";
import ServiceService from "./../../../services/AdminSetting/ServiceService";

export default class ServiceMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAccess: true,
            viewAccess: true,
            deleteAccess: true,
            editAccess: true,
            searchBy: "",
            serviceInput: {},
            serviceError: {},
            isEdit: false,
            isSubmitLoading: false,
            loading: false,
            serviceModel: false,
            designationMasterData: [],
            serviceGridData: []
        };

        this.ServiceService = new ServiceService();
    }
    componentDidMount() {
        this.handleGetServiceGridData();
        this.handleGetDesignationData();
    }


    handleServiceModalOpen = () => {
        this.setState({ serviceModel: true, serviceInput: { status: true } });
    };

    handleServiceModalClose = () => {
        this.setState({
            serviceModel: false,
            serviceInput: {},
            serviceError: {},
            isEdit: false,
        });
    };
    handleOnChangeInput = async (name, e) => {
        var serviceInput = this.state.serviceInput;
        serviceInput[name] = e.target.value;
        await this.setState({
            serviceInput,
        });
    };

    handleOnChange = (name, e) => {
        var serviceInput = this.state.serviceInput;

        serviceInput[name] = e;
        this.setState({
            serviceInput,
        });
    };



    handleServiceValidation = () => {
        let serviceInput = this.state.serviceInput;
        let serviceError = {};
        let isValid = true;

        if (!serviceInput["serviceName"]) {
            isValid = false;
            serviceError["serviceName"] = "Please enter service name.";
        }
        if (!serviceInput["designationName"]) {
            isValid = false;
            serviceError["designationName"] = "Please select designation name.";
        }


        this.setState({
            serviceError,
        });
        return isValid;
    };

    handleGetServiceGridData = () => {
        debugger;
        var inputData = {};

        inputData.SearchText = this.state.searchBy;
        try {
            this.ServiceService.getServiceMaster("GetServiceMaster", inputData)
                .then((response) => {
                    debugger;
                    var message = response.message;
                    var serviceGridData = response.responseData;
                    if (message == "Success") {
                        this.setState({ serviceGridData });
                    } else {
                        this.setState({ serviceGridData: [] });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    handleGetDesignationData = () => {
        debugger;
        
        try {
            this.ServiceService.getDesignationList("GetDesignationList")
                .then((response) => {
                    debugger;
                    var message = response.message;
                    var designationMasterData = response.responseData;
                    if (message == "Success") {
                        this.setState({ designationMasterData });
                    } else {
                        this.setState({ designationMasterData: [] });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    handleSerchInputChange = async (e) => {
        await this.setState({
            searchBy: e.target.value,
        });

        this.handleGetServiceGridData();
    };

    handleServiceSubmit = () => {
        if (this.handleServiceValidation()) {
            if (!this.state.isEdit) {
                this.handleCreateService();
            } else {
                this.handleUpdateService();
            }
        }
    };

    handleCreateService = () => {
        debugger;
        var inputData = {};
        inputData.ServiceName = this.state.serviceInput.serviceName;
        inputData.DesignationID = this.state.serviceInput.designationName;
        inputData.Status = this.state.serviceInput.status;

        this.setState({ isSubmitLoading: true });
        try {
            this.ServiceService.insertServiceMaster("InsertServiceMaster", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    this.setState({ isSubmitLoading: false });
                    if (statusCode === 200) {
                        NotificationManager.success("Service Created Successfully.");
                        this.handleGetServiceGridData();
                        this.setState({
                            serviceModel: false,
                            serviceInput: {},
                        });
                    } else {
                        NotificationManager.error("Service Not Create.");
                    }
                })
                .catch((error) => {
                    this.setState({ isSubmitLoading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    handleGetEditServiceData = async (e) => {
        var serviceInput = this.state.serviceInput;
        serviceInput["serviceID"] = e.serviceID;
        serviceInput["serviceName"] = e.serviceName;

        serviceInput["designationName"] = e.designationID || null;
        serviceInput["status"] = e.status;

        await this.setState({
            serviceModel: true,
            serviceInput,
            isEdit: true,
        });
    };


    handleUpdateService = () => {
        var inputData = {};
        inputData.ServiceID = this.state.serviceInput.serviceID;
        inputData.ServiceName = this.state.serviceInput.serviceName;
        inputData.DesignationID = this.state.serviceInput.designationName;
        inputData.Status = this.state.serviceInput.status;

        this.setState({ isSubmitLoading: true });
        try {
            this.ServiceService.updateServiceMaster("UpdateServiceMaster", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    this.setState({ isSubmitLoading: false });
                    if (statusCode === 200) {
                        NotificationManager.success("Service Updated Successfully.");
                        this.handleGetServiceGridData();
                        this.setState({ serviceModel: false, isEdit: false, });
                    } else {
                        NotificationManager.error("Service Not Updated.");
                    }
                })
                .catch((error) => {
                    this.setState({ isSubmitLoading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    handleDeleteService = (ID) => {
        debugger;
        var inputData = {};
        inputData.IDs = ID + "";

        try {
            this.ServiceService.deleteServiceMaster("DeleteServiceMaster", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    var data = response.responseData;
                    if (statusCode === 200) {
                        NotificationManager.success("Record Deleted Successfully.");
                        this.handleGetServiceGridData();
                    } else {
                        NotificationManager.error("Record Not Deleted.");
                    }
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };



    render() {
        const { Option } = Select;
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                Service Master
                                {this.state.createAccess &&
                                    <img
                                        src={AddIcon}
                                        alt="add"
                                    onClick={this.handleServiceModalOpen.bind(this)}
                                    />}
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                name="searchByTeam"
                                value={this.state.searchByTeam}
                                onChange={this.handleSerchInputChange.bind(this)}
                                placeholder="Search by..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    {this.state.viewAccess &&
                        <div className="AlertTable">
                            <Table
                                columns={[
                                    {
                                        title: "Service Name",
                                        dataIndex: "serviceName",
                                        key: "serviceName",
                                    },
                                    {
                                        title: "Designation Name",
                                        dataIndex: "designationName",
                                        key: "designationName",
                                    },
                                    {
                                        title: "Created By",
                                        dataIndex: "createdByName",
                                        key: "createdByName",
                                    },
                                    {
                                        title: "Modified By",
                                        dataIndex: "modifiedByName",
                                        key: "modifiedByName",
                                    },
                                    {
                                        title: "Status",
                                        dataIndex: "status",
                                        render: (item, row) => {
                                            return (
                                                <div className="Status" >
                                                    <Select value={row.status} showArrow={false} disabled={true}>
                                                        <Option value={true}>Active</Option>
                                                        <Option value={false}>Inactive</Option>
                                                    </Select>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: "Actions",
                                        key: "actions",
                                        dataIndex: "actions",
                                        render: (row, item) => {
                                            return (
                                                <div className="Actionic">
                                                    {this.state.editAccess &&
                                                        <img
                                                            src={AddNote}
                                                            alt="Edit"
                                                           onClick={this.handleGetEditServiceData.bind(this, item)}
                                                        />}
                                                    {this.state.deleteAccess &&
                                                        <img
                                                            src={CloseCircle}
                                                            alt="delete"
                                                        onClick={this.handleDeleteService.bind(this, item.serviceID)}
                                                        />}
                                                </div>
                                            );
                                        },
                                    },
                            ]}
                            dataSource={this.state.serviceGridData}
                                pagination={{
                                    position: ["bottomCenter"],
                                    showSizeChanger: true,
                                }}
                            />
                        </div>
                    }





                    <Modal
                        closable={false}
                        title={!this.state.isEdit ? "Create Service" : "Edit Service"}
                        visible={this.state.serviceModel}
                        footer={[
                            <Button key="back" onClick={this.handleServiceModalClose.bind(this)}>
                                Cancel
              </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={this.state.isSubmitLoading}
                                onClick={this.handleServiceSubmit.bind(this)}
                            >
                                {this.state.isEdit ? "Update" : "Create"}
                            </Button>,
                        ]}
                    >
                        <div className="createPrior">
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Service Name</label>
                                    <Input
                                        placeholder="Enter Service Name"
                                        name="serviceName"
                                        value={this.state.serviceInput.serviceName}
                                        onChange={this.handleOnChangeInput.bind(this, "serviceName")}
                                    />
                                    {this.state.serviceError ? (
                                        <div className="text-danger">
                                            {this.state.serviceError["serviceName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Designation Name</label>

                                    <Select
                                        onChange={this.handleOnChange.bind(this, "designationName")}
                                        value={this.state.serviceInput.designationName}
                                        placeholder="Select Designation Name"
                                       
                                    >
                                        {this.state.designationMasterData
                                            ? this.state.designationMasterData.map((item, key) => {
                                                return (
                                                    <Option value={item.designationID}>
                                                        {item.designationName}
                                                    </Option>
                                                );
                                            })
                                            : null}
                                    </Select>
                                    {this.state.serviceError ? (
                                        <div className="text-danger">
                                            {this.state.serviceError["designationName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Status</label>
                                    <Select
                                        value={this.state.serviceInput.status}
                                        onChange={this.handleOnChange.bind(this, "status")}
                                        placeholder={"Select Status"}
                                    >
                                        <Option value={true} >Active</Option>
                                        <Option value={false}>Inactive</Option>
                                    </Select>
                                    {this.state.serviceError ? (
                                        <div className="text-danger">
                                            {this.state.serviceError["status"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>





                        </div>

                    </Modal>











                </div>
            </div>
        );
    }
}
