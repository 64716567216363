import Configuration from "../../helpers/Configuration";
import axios from "axios";
class SLAService {
  constructor() {
    this.config = new Configuration();
  }
  async getSLAGirdData(url, inputData) {
    const response = await axios(this.config.SLAServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async deleteSLA(url, inputData) {
    const response = await axios(this.config.SLAServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async createSLA(url, inputData) {
    const response = await axios(this.config.SLAServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async updateSLA(url, inputData) {
    const response = await axios(this.config.SLAServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
  async deleteSLA(url, inputData) {
    const response = await axios(this.config.SLAServiceURL + url, {
      method: "POST",
      data: inputData,
    });
    return await response.data;
  }
}

export default SLAService;
