import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Table, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import TeamService from "./../../../services/AdminSetting/TeamService";
import { NotificationManager } from "react-notifications";
import UserService from "./../../../services/AdminSetting/UserService";
export default class LeadTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TeamGridData: [],
      userData: [],
      locationData: [],
      searchByTeam: "",
      teamModal: false,
      teamInput: {},
      teamError: {},
      isEdit: false,
      isSubmitLoading: false,
      loading: false,
      createAccess: true,
      viewAccess: true,
      deleteAccess: true,
      editAccess: true,
      downloadAccess: true,
    };

    this.TeamService = new TeamService();
    this.UserService = new UserService();
  }

  componentDidMount() {
    this.handleGetTeamGridData();
    this.handleLocationData();
    this.handleUserData();
    this.handleGetModuleDetails();
  }

  handleOnChange = (name, e) => {
    var teamInput = this.state.teamInput;

    teamInput[name] = e;
    this.setState({
      teamInput,
    });
  };
  handleOnChangeInput = async (name, e) => {
    var teamInput = this.state.teamInput;
    teamInput[name] = e.target.value;
    await this.setState({
      teamInput,
    });
  };

  handleTeamValidation = () => {
    let teamInput = this.state.teamInput;
    let teamError = {};
    let isValid = true;

    if (!teamInput["teamName"]) {
      isValid = false;
      teamError["teamName"] = "Please enter team name.";
    }
    if (!teamInput["unitID"]) {
      isValid = false;
      teamError["unitID"] = "Please select center.";
    }
    if (!teamInput["teamEmail"]) {
      isValid = false;
      teamError["teamEmail"] = "Please enter email.";
    }
    if (!teamInput["mobile"]) {
      isValid = false;
      teamError["mobile"] = "Please enter mobile no.";
    }
    if (!teamInput["Responsibility"]) {
      isValid = false;
      teamError["Responsibility"] = "Please enter responsibility.";
    }
    if (this.state.isEdit === true) {
      if (teamInput["Members"].length === 0) {
        isValid = false;
        teamError["Members"] = "Please select members.";
      }
    } else {
      if (!teamInput["Members"]) {
        isValid = false;
        teamError["Members"] = "Please select members.";
      }
    }

    this.setState({
      teamError,
    });
    return isValid;
  };

  handleSerchInputChange = async (e) => {
    await this.setState({
      searchByTeam: e.target.value,
    });

    this.handleGetTeamGridData();
  };
  handleTeamSubmit = () => {
    if (this.handleTeamValidation()) {
      if (!this.state.isEdit) {
        this.handleCreateTeam();
      } else {
        this.handleUpdateTeam();
      }
    }
  };
  handleGetEditTeamData = async (e) => {
    var teamInput = this.state.teamInput;
    teamInput["teamName"] = e.teamName;

    teamInput["teamEmail"] = e.email;
    teamInput["mobile"] = e.mobileNo;
    teamInput["Responsibility"] = e.responsibilityFor;

    teamInput["teamID"] = e.teamID;
    if (e.members) {
      var membersTemp = e.members.split(",");
      var memberTempArrary = [];
      for (let i = 0; i < membersTemp.length; i++) {
        memberTempArrary.push(Number(membersTemp[i]));
      }
      teamInput["Members"] = memberTempArrary;
    } else {
      teamInput["Members"] = [];
    }

    if (e.unitId) {
      var unitTemp = e.unitId.split(",");
      var unitTempArrary = [];
      for (let i = 0; i < unitTemp.length; i++) {
        unitTempArrary.push(Number(unitTemp[i]));
      }
      teamInput["unitID"] = unitTempArrary;
    } else {
      teamInput["unitID"] = [];
    }
    await this.setState({
      teamModal: true,
      teamInput,
      isEdit: true,
    });
  };

  handleUpdateTeam = () => {
    var inputData = {};
    inputData.TeamID = this.state.teamInput.teamID;
    inputData.TeamName = this.state.teamInput.teamName;
    inputData.MobileNo = this.state.teamInput.mobile;
    inputData.Email = this.state.teamInput.teamEmail;
    inputData.UnitId = this.state.teamInput.unitID.join(",");
    inputData.ResponsibilityFor = this.state.teamInput.Responsibility;
    inputData.Members = this.state.teamInput.Members.join(",");
    this.setState({ isSubmitLoading: true });
    try {
      this.TeamService.updateData("UpdateTeam", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          this.setState({ isSubmitLoading: false });
          if (statusCode === 200) {
            NotificationManager.success("Team Updated Successfully.");
              this.handleGetTeamGridData();
              this.setState({ teamModal: false, isEdit: false, });
          } else {
            NotificationManager.error("Team Not Updated.");
          }
        })
        .catch((error) => {
          this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleCreateTeam = () => {
    var inputData = {};
    inputData.TeamName = this.state.teamInput.teamName;
    inputData.MobileNo = this.state.teamInput.mobile;
    inputData.Email = this.state.teamInput.teamEmail;
    inputData.UnitId = this.state.teamInput.unitID.join(",");
    inputData.ResponsibilityFor = this.state.teamInput.Responsibility;
    inputData.Members = this.state.teamInput.Members.join(",");
    this.setState({ isSubmitLoading: true });
    try {
      this.TeamService.createTeam("CreateTeam", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          this.setState({ isSubmitLoading: false });
          if (statusCode === 200) {
            NotificationManager.success("Team Created Successfully.");
            this.handleGetTeamGridData();
              this.setState({
                  teamModal: false,
                  teamInput: {},
              });
          } else {
            NotificationManager.error("Team Not Create.");
          }
        })
        .catch((error) => {
          this.setState({ isSubmitLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

    handleGetTeamGridData = () => {
        
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = this.state.searchByTeam;
    try {
      this.TeamService.getTeamGirdData("GetTeam", inputData)
          .then((response) => {
              
          var message = response.message;
          var TeamGridData = response.responseData;
          if (message == "Success") {
            this.setState({ TeamGridData });
          } else {
            this.setState({ TeamGridData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleLocationData = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      this.TeamService.getLocationData("GetLocation", inputData)
        .then((response) => {
          var message = response.message;
          var locationData = response.responseData;
          if (message == "Success") {
            this.setState({ locationData });
          } else {
            this.setState({ locationData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleUserData = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      this.TeamService.getUserData("GetUser", inputData)
        .then((response) => {
          var message = response.message;
          var userData = response.responseData;
          if (message == "Success") {
            this.setState({ userData });
          } else {
            this.setState({ userData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteTeam = (teamID) => {
    var Ids = teamID + "";
    var inputData = {};
    inputData.TeamIDs = Ids;

    try {
      this.TeamService.deleteData("DeleteTeam", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            NotificationManager.success("Record Deleted Successfully.");
            this.handleGetTeamGridData();
          } else {
            NotificationManager.error("Record Not Deleted Successfully.");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleTeamModalOpen = () => {
    this.setState({ teamModal: true });
  };
  //handle priority model close
  handleTeamModalClose = () => {
    this.setState({
      teamModal: false,
      teamInput: {},
      teamError: {},
      isEdit: false,
    });
  };

  handleGetModuleDetails = () => {
    try {
        this.UserService.getUserModuleDetails("GetUserModuleDetails")
        .then((response) => {
            
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            //this.setState({ cityData });
              this.setAccessUser(data);
          } else {
            //this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  setAccessUser(data) {
      if (data !== null) {
          for (var i = 0; i < data.length; i++) {
              if (data[i].moduleName === "Settings") {
                   this.setState({
                      createAccess: data[i].isCreate,
                      viewAccess: data[i].isView,
                      deleteAccess: data[i].isDelete,
                      editAccess: data[i].isEdit,
                      downloadAccess: data[i].isDownload
                   });
              }
          }
      }
    }

  render() {
    const { Option } = Select;
    return (
      <div className="PriorityMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Lead Teams
                {this.state.createAccess &&
                <img
                  src={AddIcon}
                  alt="add"
                  onClick={this.handleTeamModalOpen.bind(this)}
                />}
              </h4>
            </div>
            <div className="hierright">
              <Input
                name="searchByTeam"
                value={this.state.searchByTeam}
                onChange={this.handleSerchInputChange.bind(this)}
                placeholder="Search by Team..."
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
          {this.state.viewAccess &&
          <div className="AlertTable">
            <Table
              columns={[
                {
                  title: "Team Name",
                  dataIndex: "teamName",
                  key: "teamName",
                            },
                {
                  title: "Unit",
                  dataIndex: "unitName",
                    key: "unitName",
                    render: (row, item) => {
                        return (
                            <div className="report">
                                <label style={{ width: "200px" }}>
                                    {item.unitName}
                                </label>
                            </div>
                        );
                    },
                },
                {
                  title: "Team Email",
                  dataIndex: "email",
                  key: "email",
                },
                {
                  title: "Responsible For",
                  dataIndex: "responsibilityFor",
                  key: "responsibilityFor",
                },
                {
                  title: "Mobile No",
                  dataIndex: "mobileNo",
                  key: "mobileNo",
                },
                {
                  title: "CreatedBy",
                  dataIndex: "createdByName",
                  key: "createdByName",
                },
                {
                  title: "Actions",
                  key: "actions",
                  dataIndex: "actions",
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        {this.state.editAccess &&
                        <img
                          src={AddNote}
                          alt="Edit"
                          onClick={this.handleGetEditTeamData.bind(this, item)}
                        />}
                        {this.state.deleteAccess &&
                        <img
                          src={CloseCircle}
                          alt="delete"
                          onClick={this.handleDeleteTeam.bind(
                            this,
                            item.teamID
                          )}
                        />}
                      </div>
                    );
                  },
                },
              ]}
              dataSource={this.state.TeamGridData}
              pagination={false}
            />
          </div>
          }

          <Modal
            closable={false}
            title={!this.state.isEdit ? "Create Team" : "Edit Team"}
            visible={this.state.teamModal}
            footer={[
              <Button key="back" onClick={this.handleTeamModalClose.bind(this)}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={this.state.isSubmitLoading}
                onClick={this.handleTeamSubmit.bind(this)}
              >
                {this.state.isEdit ? "Update" : "Create"}
              </Button>,
            ]}
          >
            <div className="createPrior">
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Team Name</label>
                  <Input
                    placeholder="Enter Team Name"
                    name="teamName"
                    value={this.state.teamInput.teamName}
                    onChange={this.handleOnChangeInput.bind(this, "teamName")}
                  />
                  {this.state.teamError ? (
                    <div className="text-danger">
                      {this.state.teamError["teamName"] || ""}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Unit</label>

                  <Select
                    onChange={this.handleOnChange.bind(this, "unitID")}
                    value={this.state.teamInput.unitID}
                    placeholder="Select Unit"
                    mode="multiple"
                    maxTagCount="responsive"
                  >
                    {this.state.locationData
                      ? this.state.locationData.map((item, key) => {
                          return (
                            <Option value={item.locationID}>
                              {item.locationName}
                            </Option>
                          );
                        })
                      : null}
                  </Select>
                  {this.state.teamError ? (
                    <div className="text-danger">
                      {this.state.teamError["unitID"] || ""}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Email</label>

                  <Input
                    placeholder="Enter Email Name"
                    name="teamEmail"
                    value={this.state.teamInput.teamEmail}
                    onChange={this.handleOnChangeInput.bind(this, "teamEmail")}
                  />
                  {this.state.teamError ? (
                    <div className="text-danger">
                      {this.state.teamError["teamEmail"] || ""}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Mobile</label>

                  <Input
                    placeholder="Enter Mobile Name"
                    name="mobile"
                    value={this.state.teamInput.mobile}
                    onChange={this.handleOnChangeInput.bind(this, "mobile")}
                  />
                  {this.state.teamError ? (
                    <div className="text-danger">
                      {this.state.teamError["mobile"] || ""}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Responsibility For</label>

                  <Input
                    placeholder="Enter Responsibility Name"
                    name="Responsibility"
                    value={this.state.teamInput.Responsibility}
                    onChange={this.handleOnChangeInput.bind(
                      this,
                      "Responsibility"
                    )}
                  />
                  {this.state.teamError ? (
                    <div className="text-danger">
                      {this.state.teamError["Responsibility"] || ""}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Members</label>
                  <Select
                    onChange={this.handleOnChange.bind(this, "Members")}
                    value={this.state.teamInput.Members}
                    placeholder="Select Members"
                    mode="multiple"
                    maxTagCount="responsive"
                  >
                    {this.state.userData
                      ? this.state.userData.map((item, key) => {
                          return (
                            <Option value={item.userID}>{item.fullName}</Option>
                          );
                        })
                      : null}
                  </Select>
                  {this.state.teamError ? (
                    <div className="text-danger">
                      {this.state.teamError["Members"] || ""}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
