import Configuration from "../../helpers/Configuration";
import axios from "axios";


class TemplateService {
  constructor() {
    this.config = new Configuration();
  }
  async getTemplate(url,inputData) {
    const response = await axios(this.config.TemplateServiceURL + url, {
      method: "POST",
      data:inputData ,
    });
    return await response.data;
  }
}
export default TemplateService;