import React, { Component } from "react";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Select, Table, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { AddIcon } from "./../../../helpers/ImagesImport";
import { NotificationManager } from "react-notifications";
import DNDService from "./../../../services/AdminSetting/DNDService";

export default class DNDMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAccess: true,
            viewAccess: true,
            deleteAccess: true,
            editAccess: true,
            searchBy: "",
            dndInput: {},
            dndError: {},
            isEdit: false,
            isSubmitLoading: false,
            loading: false,
            dndModel: false,
            dndGridData:[]
        };

        this.DNDService = new DNDService();
    }
    componentDidMount() {
        this.handleGetDNDGridData();
    }


    handleDndModalOpen = () => {
        this.setState({ dndModel: true, dndInput: { status: true } });
    };

    handleDndModalClose = () => {
        this.setState({
            dndModel: false,
            dndInput: {},
            dndError: {},
            isEdit: false,
        });
    };
    handleOnChangeInput = async (name, e) => {
        var dndInput = this.state.dndInput;
        dndInput[name] = e.target.value;


        if (
            name === "contactNumber" 
        ) {
            if (!isNaN(e.target.value)) {
                dndInput[name] = e.target.value;
            } else {
                if (dndInput[name]) {
                    dndInput[name] = "";
                } else {
                    dndInput[name] = dndInput[name];
                }
            }
        } else {
            dndInput[name] = e.target.value;
        }


        await this.setState({
            dndInput,
        });
    };

    handleOnChange = (name, e) => {
        var dndInput = this.state.dndInput;

        dndInput[name] = e;
        this.setState({
            dndInput,
        });
    };

    handleDNDSubmit = () => {
        if (this.handleDndValidation()) {
            if (!this.state.isEdit) {
                this.handleCreateDND();
            } else {
               
            }
        }
    };
    


    handleDndValidation = () => {
        let dndInput = this.state.dndInput;
        let dndError = {};
        let isValid = true;

        if (!dndInput["contactName"]) {
            isValid = false;
            dndError["contactName"] = "Please enter contact name.";
        }
        if (!dndInput["emailID"]) {
            isValid = false;
            dndError["emailID"] = "Please enter email id.";
        }
        if (!dndInput["contactNumber"]) {
            isValid = false;
            dndError["contactNumber"] = "Please enter contact number.";
        }


        this.setState({
            dndError,
        });
        return isValid;
    };








    handleGetDNDGridData = () => {
        debugger;
        var inputData = {};

        inputData.SearchText = this.state.searchBy;
        try {
            this.DNDService.getDNDFeatMaster("GetDNDFeatMaster", inputData)
                .then((response) => {
                    debugger;
                    var message = response.message;
                    var dndGridData = response.responseData;
                    if (message == "Success") {
                        this.setState({ dndGridData });
                    } else {
                        this.setState({ dndGridData: [] });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };



    handleCreateDND = () => {
        debugger;
        var inputData = {};
        inputData.ContactName = this.state.dndInput.contactName;
        inputData.EmailID = this.state.dndInput.emailID;
        inputData.ContactNumber = this.state.dndInput.contactNumber;
        inputData.Status = this.state.dndInput.status;

        this.setState({ isSubmitLoading: true });
        try {
            this.DNDService.insertDNDFeatMaster("InsertDNDFeatMaster", inputData)
                .then((response) => {
                    var statusCode = response.statusCode;
                    this.setState({ isSubmitLoading: false });
                    if (statusCode === 200) {
                        NotificationManager.success("DND Created Successfully.");
                        this.handleGetDNDGridData();
                        this.setState({
                            dndModel: false,
                            dndInput: {},
                        });
                    } else {
                        NotificationManager.error("DND Not Create.");
                    }
                })
                .catch((error) => {
                    this.setState({ isSubmitLoading: false });
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };


    handleGetEditDNDData = async (e) => {
        var dndInput = this.state.dndInput;
        dndInput["dndID"] = e.dndid;
        dndInput["contactName"] = e.contactName;
        dndInput["emailID"] = e.emailID;
        dndInput["contactNumber"] = e.contactNumber;
        dndInput["status"] = e.status;

        await this.setState({
            dndModel: true,
            dndInput,
            isEdit: true,
        });
    };


    handleSerchInputChange = async (e) => {
        await this.setState({
            searchBy: e.target.value,
        });

        this.handleGetDNDGridData();
    };


    render() {
        const { Option } = Select;
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>
                                DND Master
                                {this.state.createAccess &&
                                    <img
                                        src={AddIcon}
                                        alt="add"
                                    onClick={this.handleDndModalOpen.bind(this)}
                                    />}
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input
                                name="searchByTeam"
                                value={this.state.searchByTeam}
                                onChange={this.handleSerchInputChange.bind(this)}
                                placeholder="Search by..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    {this.state.viewAccess &&
                        <div className="AlertTable">
                            <Table
                                columns={[
                                    {
                                        title: "Contact Name",
                                        dataIndex: "contactName",
                                        key: "contactName",
                                    },
                                    {
                                        title: "Email ID",
                                        dataIndex: "emailID",
                                        key: "emailID",
                                },
                                {
                                    title: "Contact Number",
                                    dataIndex: "contactNumber",
                                    key: "contactNumber",
                                },
                                    {
                                        title: "Created By",
                                        dataIndex: "createdByName",
                                        key: "createdByName",
                                    },
                                    {
                                        title: "Modified By",
                                        dataIndex: "modifiedByName",
                                        key: "modifiedByName",
                                    },
                                    {
                                        title: "Status",
                                        dataIndex: "status",
                                        render: (item, row) => {
                                            return (
                                                <div className="Status" >
                                                    <Select value={row.status} showArrow={false} disabled={true}>
                                                        <Option value={true}>Active</Option>
                                                        <Option value={false}>Inactive</Option>
                                                    </Select>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: "Actions",
                                        key: "actions",
                                        dataIndex: "actions",
                                        render: (row, item) => {
                                            return (
                                                <div className="Actionic">
                                                    {this.state.editAccess &&
                                                        <img
                                                            src={AddNote}
                                                            alt="Edit"
                                                        onClick={this.handleGetEditDNDData.bind(this, item)}
                                                        />}
                                                    {this.state.deleteAccess &&
                                                        <img
                                                            src={CloseCircle}
                                                            alt="delete"
                                                            //onClick={this.handleDeleteSpeciality.bind(this, item.specialityID)}
                                                        />}
                                                </div>
                                            );
                                        },
                                    },
                            ]}
                            dataSource={this.state.dndGridData}
                                pagination={{
                                    position: ["bottomCenter"],
                                    showSizeChanger: true,
                                }}
                            />
                        </div>
                    }


                    <Modal
                        closable={false}
                        title={!this.state.isEdit ? "Create DND" : "Edit DND"}
                        visible={this.state.dndModel}
                        footer={[
                            <Button key="back" onClick={this.handleDndModalClose.bind(this)}>
                                Cancel
              </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={this.state.isSubmitLoading}
                                onClick={this.handleDNDSubmit.bind(this)}
                            >
                                {this.state.isEdit ? "Update" : "Create"}
                            </Button>,
                        ]}
                    >
                        <div className="createPrior">
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Contact Name</label>
                                    <Input
                                        placeholder="Enter Contact Name"
                                        name="contactName"
                                        value={this.state.dndInput.contactName}
                                        onChange={this.handleOnChangeInput.bind(this, "contactName")}
                                    />
                                    {this.state.dndError ? (
                                        <div className="text-danger">
                                            {this.state.dndError["contactName"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Email ID</label>
                                    <Input
                                        placeholder="Enter Email ID"
                                        name="emailID"
                                        value={this.state.dndInput.emailID}
                                        onChange={this.handleOnChangeInput.bind(this, "emailID")}
                                    />
                                    {this.state.dndError ? (
                                        <div className="text-danger">
                                            {this.state.dndError["emailID"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Contact Number</label>
                                    <Input
                                        placeholder="Enter Contact Number"
                                        name="contactNumber"
                                        value={this.state.dndInput.contactNumber}
                                        onChange={this.handleOnChangeInput.bind(this, "contactNumber")}
                                    />
                                    {this.state.dndError ? (
                                        <div className="text-danger">
                                            {this.state.dndError["contactNumber"] || ""}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Status</label>
                                    <Select
                                        value={this.state.dndInput.status}
                                        onChange={this.handleOnChange.bind(this, "status")}
                                        placeholder={"Select Status"}
                                    >
                                        <Option value={true} >Active</Option>
                                        <Option value={false}>Inactive</Option>
                                    </Select>

                                </div>
                            </div>





                        </div>

                    </Modal>















                </div>
            </div>
        );
    }
}
