import React, { Fragment, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { UserIcon } from "./../../../helpers/ImagesImport";
export default function CallTab(props) {
    
  const [Call, setCourses] = useState([
    {
      calldate: "12 December",
      calltime: "5:30 PM",
      title: "Phone Call",
      comment: "Contact +919873470074",
      createdBy: "Tejas Kadakia",
      designtion: "Lead Owner",
      recordUrl:
        "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3",
      imageUrl: UserIcon,
    },
    {
      calldate: "12 December",
      calltime: "5:30 PM",
      title: "Phone Call",
      comment: "Contact +919873470074",
      createdBy: "Tejas Kadakia",
      designtion: "Lead Owner",
      recordUrl: "",
      imageUrl: UserIcon,
    },
    {
      calldate: "12 December",
      calltime: "5:30 PM",
      title: "Phone Call",
      comment: "Contact +919873470074",
      createdBy: "Tejas Kadakia",
      designtion: "Lead Owner",
      recordUrl:
        "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3",
      imageUrl: UserIcon,
    },
  ]);
  useEffect(() => {
    handleGetCall();
  }, []);
  function handleGetCall() {}
  return (
    <Fragment>
      <div className="CallMain">
        <ul>
          {Call
            ? Call.map((item, key) => {
                return (
                  <li kye={key}>
                    <div className="Calll">
                      <div className="lefSide">
                        <div className="rightUs">
                          <div className="DateTime">
                            <h4 className="abou">{item.calldate}</h4>
                            <h4 className="dayago">{item.calltime}</h4>
                          </div>
                          <div className="QuContent">
                            <div className="Quote">
                              <h4 className="abou">{item.title}</h4>
                              <h4 className="dayago">{item.comment}</h4>
                            </div>
                            <div className="CallRecord">
                              {item.recordUrl ? (
                                <ReactPlayer
                                  style={{ outline: "0" }}
                                  url={item.recordUrl}
                                  width="260px"
                                  height="35px"
                                  playing={false}
                                  controls={true}
                                  config={!props.state.download && { file: { attributes: { controlsList: 'nodownload' } } }}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="RiSide">
                        <div className="rightUs">
                          <img src={item.imageUrl} alt="User" />
                          <div>
                            <h4 className="abou">{item.createdBy}</h4>
                            <h4 className="dayago">{item.designtion}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
    </Fragment>
  );
}
