import React, { Component, Fragment } from "react";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { Input, Radio, Select, Table, Checkbox, Modal, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone";
import {
  AddIcon,
  AddNoteIcon,
  CloseCircleIcon,
  UploadIcon,
} from "./../../../helpers/ImagesImport";
import SLAService from "../../../services/AdminSetting/SLAService";
import EnquiryService from "../../../services/AdminSetting/EnquiryService";
import PriorityService from "../../../services/AdminSetting/PriorityService";
import { NotificationManager } from "react-notifications";
import UserService from "./../../../services/AdminSetting/UserService";
class SlaMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slaMastermodal: false,
      Single: true,
      Multiple: false,
      fileName: "",
      fileN: [],
      isSubmitLoading: false,
      isEdit: false,
      isIssuTypeClick: false,
      slaGirdData: [],
      EquirySubType: [],
      PrioritySLA: [],
      EnquirySubTypeId: [],
      DNDTiming: false,
      status: "",
      slaID: 0,
      slaErrors: {},
      createAccess: true,
      viewAccess: true,
      deleteAccess: true,
      editAccess: true,
      downloadAccess: true,
      isLoading: false,
    };
    this.enquiryService = new EnquiryService();
    this.slaService = new SLAService();
    this.priorityService = new PriorityService();
    this.UserService = new UserService();
  }

  componentDidMount() {
    this.hadnleGetSLAGridData();
    this.handleGetEquirySubTypeList();
    this.handleGetModuleDetails();
  }

  handleSearchOnChange = async (event) => {
    this.hadnleGetSLAGridData(event.target.value);
  };

  handleSlaMasterModalOpen(slaid) {
    this.setState({ loading: true, EnquirySubTypeId: [] });
    var inputData = {};
    inputData.IDs = slaid.toString();
    inputData.Value = "";
    try {
      this.slaService
        .getSLAGirdData("GetSLA", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var slaGirdData = response.responseData[0];

          this.setState({ loading: false });
          if (statusCode === 200) {
            var slaID = slaGirdData.slaid;
            var status = slaGirdData.isSLAActive == "Active" ? true : false;
            var DNDTiming = slaGirdData.considerDND;
            var EnquirySubTypeId = slaGirdData.enquirySubTypeID;
            var tempData = slaGirdData.slaTarget;
            var tempFinalData = [];
            if (tempData.length > 0) {
              for (let i = 0; i < tempData.length; i++) {
                var tempObj = {};
                tempObj.PriorityID = tempData[i].priorityID;
                tempObj.PriorityName = tempData[i].priorityName;
                tempObj.SLABreachPercent = tempData[i].slaBreachPercent;
                tempObj.PriorityRespondValue = tempData[i].priorityRespondValue;
                tempObj.PriorityRespondDuration =
                  tempData[i].priorityResponseDuration;
                tempObj.PriorityResolutionValue =
                  tempData[i].priorityResolveValue;
                tempObj.PriorityResolutionDuration =
                  tempData[i].priorityResolutionDuration;
                tempObj.SLATargetID = tempData[i].slaTargetID;
                tempFinalData.push(tempObj);
              }
            }
            this.setState({
              slaID,
              status,
              DNDTiming,
              EnquirySubTypeId,
              PrioritySLA: tempFinalData,
              slaMastermodal: true,
              isEdit: true,
            });
          } else {
            this.setState({ slaGirdData: [] });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  handleSlaMasterModalClose() {
    this.setState({ slaMastermodal: false });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
        fileName: "",
        fileN: [],
      });
    }
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };
  handleIsIssueTypeClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isIssuTypeClick: !this.state.isIssuTypeClick,
    });
  };
  //handle get sla grid list data
  hadnleGetSLAGridData = (search) => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = search;
    try {
      this.setState({ isLoading: true });

      this.slaService
        .getSLAGirdData("GetSLA", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var slaGirdData = response.responseData;

          this.setState({ isLoading: false });
          if (statusCode === 200) {
            this.setState({ slaGirdData });
          } else {
            this.setState({ slaGirdData: [] });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };
  //handle get priority list
  //hadnleGetPriorityList = () => {
  //  var inputData = {};
  //  inputData.IDs = "";
  //  inputData.Value = "";
  //  try {
  //    this.slaService
  //      .getSLAGirdData("GetPriority", inputData)
  //      .then((response) => {
  //        var statusCode = response.statusCode;
  //        var PrioritySLA = [];
  //        if (response.responseData.lenght > 0) {
  //          response.responseData.forEach((element) => {
  //            var tempObj = {};
  //            tempObj.PriorityID = element.priorityID;
  //            tempObj.PriorityName = element.priorityName;
  //            tempObj.SLABreachPercent = "";
  //            tempObj.PriorityRespondValue = "";
  //            tempObj.PriorityRespondDuration = "M";
  //            tempObj.PriorityResolutionValue = "";
  //            tempObj.PriorityResolutionDuration = "M";
  //            PrioritySLA.push(tempObj);
  //          });
  //        }
  //        if (statusCode === 200) {
  //          this.setState({ PrioritySLA });
  //        } else {
  //          this.setState({ PrioritySLA: [] });
  //        }
  //      })
  //      .catch((error) => {
  //        this.setState({ loading: false });
  //        console.log(error);
  //      });
  //  } catch (error) {
  //    console.log(error);
  //  }
  //  };

  hadnleGetPriorityList = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      this.priorityService
        .getPriorityGirdData("GetPriority", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var PrioritySLA = [];
          if (statusCode === 200) {
            if (response.responseData.length > 0) {
              response.responseData.forEach((element) => {
                var tempObj = {};
                tempObj.PriorityID = element.priorityID;
                tempObj.PriorityName = element.priorityName;
                tempObj.SLABreachPercent = "";
                tempObj.PriorityRespondValue = "";
                tempObj.PriorityRespondDuration = "M";
                tempObj.PriorityResolutionValue = "";
                tempObj.PriorityResolutionDuration = "M";
                PrioritySLA.push(tempObj);
              });
            }
            this.setState({ PrioritySLA });
          } else {
            this.setState({ PrioritySLA: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle get Equiry Sub Type list
  handleGetEquirySubTypeList = () => {
    var inputData = {};
    inputData.IDs = "";
    inputData.Value = "";
    try {
      this.enquiryService
        .getEnquirySubType("GetEnquirySubType", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          var EquirySubType = response.responseData;
          if (statusCode === 200) {
            this.setState({ EquirySubType });
          } else {
            this.setState({ EquirySubType: [] });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handleCreateSLA = () => {
  //  var inputData = {};
  //  inputData.SlaID = "";
  //  inputData.EnquirySubTypeIds = "";
  //  inputData.ConsiderDND = "";
  //  inputData.SLATarget = this.state.PrioritySLA;

  //  try {
  //    this.enquiryService
  //      .getEnquirySubType("GetEnquirySubType", inputData)
  //      .then((response) => {
  //        var statusCode = response.statusCode;
  //        var EquirySubType = response.responseData;
  //        if (statusCode === 200) {
  //          this.setState({ EquirySubType });
  //        } else {
  //          this.setState({ EquirySubType: [] });
  //        }
  //      })
  //      .catch((error) => {
  //        this.setState({ loading: false });
  //        console.log(error);
  //      });
  //  } catch (error) {
  //    console.log(error);
  //  }
  //  };

  handleSlaTargets = (i, name, e) => {
    if (
      name === "SLABreachPercent" ||
      name === "PriorityRespondValue" ||
      name === "PriorityResolutionValue"
    ) {
      var reg = /^[0-9\b]+$/;
      if (e.target.value === "" || reg.test(e.target.value)) {
        const { name, value } = e.target;
        let PrioritySLA = [...this.state.PrioritySLA];
        PrioritySLA[i] = {
          ...PrioritySLA[i],
          [name]: value,
        };
        this.setState({ PrioritySLA });
      } else {
        e.target.value = "";
      }
    } else {
      let PrioritySLA = [...this.state.PrioritySLA];
      PrioritySLA[i] = {
        ...PrioritySLA[i],
        [name]: e.target.value,
      };
      this.setState({ PrioritySLA });
    }
  };

  handleCreateSLA = () => {
    if (this.handleValidation()) {
      var self = this;
      var PrioritySLA = this.state.PrioritySLA;

      var inputData = {};
      var paramData = [];
      for (let i = 0; i < PrioritySLA.length; i++) {
        var temp = {};
        temp.SLATargetID = 0;
        temp.PriorityID = PrioritySLA[i].PriorityID;
        temp.SLABreachPercent =
          PrioritySLA[i].SLABreachPercent !== ""
            ? parseInt(PrioritySLA[i].SLABreachPercent)
            : 0;
        temp.PriorityRespondValue =
          PrioritySLA[i].PriorityRespondValue !== ""
            ? parseInt(PrioritySLA[i].PriorityRespondValue)
            : 0;
        temp.PriorityRespondDuration = PrioritySLA[i].PriorityRespondDuration;
        temp.PriorityResolutionValue =
          PrioritySLA[i].PriorityResolutionValue !== ""
            ? parseInt(PrioritySLA[i].PriorityResolutionValue)
            : 0;
        temp.PriorityResolutionDuration =
          PrioritySLA[i].PriorityResolutionDuration;
        paramData.push(temp);
      }
      inputData.SlaID = 0;
      inputData.EnquirySubTypeIds = this.state.EnquirySubTypeId.toString();
      inputData.isSLAActive = this.state.status;
      inputData.ConsiderDND = this.state.DNDTiming;
      inputData.SLATarget = paramData;

      try {
        this.slaService
          .createSLA("CreateSLA", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
            if (statusCode === 200) {
              self.setState({ slaMastermodal: false });
              NotificationManager.success("Record Created Successfully.");
              this.hadnleGetSLAGridData();
            } else {
              NotificationManager.error("Record Not Created.");
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleUpdateSLA = () => {
    if (this.handleValidation()) {
      var self = this;
      var PrioritySLA = this.state.PrioritySLA;

      var inputData = {};
      var paramData = [];
      for (let i = 0; i < PrioritySLA.length; i++) {
        var temp = {};
        temp.SLATargetID = PrioritySLA[i].SLATargetID;
        temp.PriorityID = PrioritySLA[i].PriorityID;
        temp.SLABreachPercent =
          PrioritySLA[i].SLABreachPercent !== ""
            ? parseInt(PrioritySLA[i].SLABreachPercent)
            : 0;
        temp.PriorityRespondValue =
          PrioritySLA[i].PriorityRespondValue !== ""
            ? parseInt(PrioritySLA[i].PriorityRespondValue)
            : 0;
        temp.PriorityRespondDuration = PrioritySLA[i].PriorityRespondDuration;
        temp.PriorityResolutionValue =
          PrioritySLA[i].PriorityResolutionValue !== ""
            ? parseInt(PrioritySLA[i].PriorityResolutionValue)
            : 0;
        temp.PriorityResolutionDuration =
          PrioritySLA[i].PriorityResolutionDuration;
        paramData.push(temp);
      }
      inputData.SlaID = this.state.slaID;
      inputData.EnquirySubTypeIds = "1";
      inputData.isSLAActive = this.state.status;
      inputData.ConsiderDND = this.state.DNDTiming;
      inputData.SLATarget = paramData;

      try {
        this.slaService
          .updateSLA("UpdateSLA", inputData)
          .then((response) => {
            var statusCode = response.statusCode;
            if (statusCode === 200) {
              self.setState({ slaMastermodal: false });
              NotificationManager.success("Record Updated Successfully.");
              this.hadnleGetSLAGridData();
            } else {
              NotificationManager.error("Record Not Updated.");
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleOnChange = (name, e) => {
    if (name == "dnd") {
      this.setState({
        DNDTiming: !this.state.DNDTiming,
      });
    } else if (name == "EnquirySubType") {
      this.setState({
        EnquirySubTypeId: e,
      });
    }
    else if (name == "stutus") {
        this.setState({
            status: e,
        });
    }
    else {
      this.setState({
        [name]: [e],
      });
    }
  };

  handleValidation = (e) => {
    var slaErrors = {};
    var isValid = true;
    if (this.state.EnquirySubTypeId.length == 0) {
      slaErrors["EnquirySubTypeId"] = "Please select enquiry sub type";
      isValid = false;
    }
    if (this.state.status == "" || this.state.status.length == 0) {
      slaErrors["status"] = "Please select status";
      isValid = false;
    }

    this.setState({
      slaErrors,
    });
    return isValid;
  };

  handleCheckSLAMaster() {
    /*   if (this.handleCheckValidations()) {*/
    if (!this.state.isEdit) {
      this.handleCreateSLA();
    } else {
      this.handleUpdateSLA();
    }
    //}
  }

  handleDeleteSLA = (slaid) => {
    var self = this;
    var inputData = {};
    inputData.IDs = slaid.toString();
    inputData.Value = "";

    try {
      this.slaService
        .deleteSLA("DeleteSLA", inputData)
        .then((response) => {
          var statusCode = response.statusCode;
          if (statusCode === 1010) {
            self.setState({ slaMastermodal: false });
            NotificationManager.success("Record Deleted Successfully.");
            this.hadnleGetSLAGridData();
          } else {
            NotificationManager.error("Record Not Deleted.");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleGetModuleDetails = () => {
    try {
      this.UserService.getUserModuleDetails("GetUserModuleDetails")
        .then((response) => {
          var statusCode = response.statusCode;
          var data = response.responseData;
          if (statusCode === 200) {
            //this.setState({ cityData });
            this.setAccessUser(data);
          } else {
            //this.setState({ cityData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  setAccessUser(data) {
    if (data !== null) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].moduleName === "Settings") {
          this.setState({
            createAccess: data[i].isCreate,
            viewAccess: data[i].isView,
            deleteAccess: data[i].isDelete,
            editAccess: data[i].isEdit,
            downloadAccess: data[i].isDownload,
          });
        }
      }
    }
  }

  render() {
    const columns = [
      {
        title: "Issue Type",
        dataIndex: "enquirySubTypeName",
        key: "enquirySubTypeName",
        render: (row, item) => {
          return (
            <div className="report">
              <label>{item.enquirySubTypeName}</label>
            </div>
          );
        },
      },
      {
        title: "Sla By Priority",
        dataIndex: "slaPri",
        key: "slaPri",
        render: (row, item) => {
          return (
            <div className="report">
              <label style={{ width: "200px" }}>
                {item.slaTarget.map((x) => x.priorityName).join()}
              </label>
            </div>
          );
        },
      },
      {
        title: "Created by",
        key: "createdby",
        dataIndex: "createdby",
        render: (row, item) => {
          return (
            <div className="report">
              <label>{item.createdByName}</label>
            </div>
          );
        },
      },
      {
        title: "Updated by",
        key: "updatedby",
        dataIndex: "updatedby",
        render: (row, item) => {
          return (
            <div className="report">
              <label>{item.modifiedByName}</label>
            </div>
          );
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (row, item) => {
          return (
            <div className="Status">
              <Select
                value={item.isSLAActive == "Active" ? true : false}
                showArrow={false}
                disabled={true}
              >
                <Option value={true}>Active</Option>
                <Option value={false}>Inactive</Option>
              </Select>
            </div>
          );
        },
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (row, item) => {
          return (
            <div className="Actionic">
              {this.state.editAccess && (
                <img
                  src={AddNoteIcon}
                  alt="Edit"
                  onClick={this.handleSlaMasterModalOpen.bind(this, item.slaid)}
                />
              )}
              {this.state.deleteAccess && (
                <img
                  src={CloseCircleIcon}
                  alt="delete"
                  onClick={this.handleDeleteSLA.bind(this, item.slaid)}
                />
              )}
            </div>
          );
        },
      },
    ];
    const { Option } = Select;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div className="SlaMasterMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                SLA Master
                {this.state.createAccess && (
                  <img
                    src={AddIcon}
                    alt="add"
                    onClick={() => {
                      this.hadnleGetPriorityList();
                      this.setState({
                          status: true,
                          EnquirySubTypeId: [],
                        PrioritySLA: [],
                          slaMastermodal: true,
                          isEdit: false
                      });
                    }}
                  />
                )}
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by Name..."
                prefix={<SearchOutlined />}
                onChange={this.handleSearchOnChange.bind(this)}
              />
            </div>
          </div>
          {this.state.viewAccess && (
            <div className="SlaMasterTable">
              <Table
                loading={this.state.isLoading}
                            rowSelection={rowSelection}
                            rowKey={(record) => record.slaid}
                columns={columns}
                dataSource={this.state.slaGirdData}
                pagination={{
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                }}
              />
            </div>
          )}
          {/* Create Sla Master */}
          <Modal
            visible={this.state.slaMastermodal}
            closable={false}
            footer={[
              <Button
                key="back"
                onClick={this.handleSlaMasterModalClose.bind(this)}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={this.state.isSubmitLoading}
                onClick={this.handleCheckSLAMaster.bind(this)}
              >
                {this.state.isEdit ? "Update" : "Create"}
              </Button>,
            ]}
            className="slaMastermodalModal"
          >
            <div className="createSlaMa">
              <div className="hier mb-3">
                <label>{this.state.isEdit?"Edit SLA":"Create SLA"}</label>
                <label className="dndChe">
                  <Checkbox
                    onChange={this.handleOnChange.bind(this, "dnd")}
                    checked={this.state.DNDTiming}
                  >
                    Consider DND Timeing
                  </Checkbox>
                </label>
              </div>
              {/* <div className="mb-2">
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Single}
                  onChange={this.handleRedioButtonChange.bind(this, "Single")}
                >
                  Single
                </Radio>
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Multiple}
                  onChange={this.handleRedioButtonChange.bind(this, "Multiple")}
                >
                  Multiple
                </Radio>
              </div> */}
              {this.state.Single && (
                <>
                  <div className="row mb-2">
                    <div className="col-12 col-md-12">
                      <label className="desig">Equiry Sub Type</label>
                      <Select
                        mode="multiple"
                        maxTagCount="responsive"
                        placeholder="Select Equiry Sub Type"
                        style={{ width: "100%" }}
                        value={this.state.EnquirySubTypeId}
                        onChange={this.handleOnChange.bind(
                          this,
                          "EnquirySubType"
                        )}
                      >
                        {this.state.EquirySubType.map((item, key) => {
                          return (
                            <Option key={key} value={item.enquirySubTypeID}>
                              {item.enquirySubTypeName}
                            </Option>
                          );
                        })}
                      </Select>
                      {this.state.slaErrors && (
                        <div className="validationsMsg">
                          {this.state.slaErrors["EnquirySubTypeId"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-12 col-md-3">
                            <label className="pro">Priority</label>
                          </div>
                          <div className="col-12 col-md-3">
                            <label className="pro">%SLA Breach</label>
                          </div>
                          <div className="col-12 col-md-3 pl-0">
                            <label className="pro">SLA Response Time</label>
                          </div>
                          <div className="col-12 col-md-3 pl-0">
                            <label className="pro">SLA Resolution Time</label>
                          </div>
                        </div>
                        {this.state.PrioritySLA
                          ? this.state.PrioritySLA.map((item, key) => {
                              return (
                                <Fragment key={key}>
                                  <div className="row">
                                    <div className="col-12 col-md-3">
                                      <label className="pro">
                                        {item.PriorityName}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-3">
                                      <label className="high">
                                        <Input
                                          className=""
                                          name="SLABreachPercent"
                                          value={item.SLABreachPercent}
                                          onChange={this.handleSlaTargets.bind(
                                            this,
                                            key,
                                            "SLABreachPercent"
                                          )}
                                        />
                                        <input className="" value="%" />
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-3 pl-0">
                                      <label className="high">
                                        <Input
                                          name="PriorityRespondValue"
                                          value={item.PriorityRespondValue}
                                          onChange={this.handleSlaTargets.bind(
                                            this,
                                            key,
                                            "PriorityRespondValue"
                                          )}
                                        />
                                        <select
                                          value={item.PriorityRespondDuration}
                                          onChange={this.handleSlaTargets.bind(
                                            this,
                                            key,
                                            "PriorityRespondDuration"
                                          )}
                                        >
                                          <option value="M">M</option>
                                          <option value="H">H</option>
                                          <option value="D">D</option>
                                        </select>
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-3 pl-0">
                                      <label className="high">
                                        <Input
                                          name="PriorityResolutionValue"
                                          value={item.PriorityResolutionValue}
                                          onChange={this.handleSlaTargets.bind(
                                            this,
                                            key,
                                            "PriorityResolutionValue"
                                          )}
                                        />
                                        <select
                                          name="PriorityResolutionDuration"
                                          value={
                                            item.PriorityResolutionDuration
                                          }
                                          onChange={this.handleSlaTargets.bind(
                                            this,
                                            key,
                                            "PriorityResolutionDuration"
                                          )}
                                        >
                                          <option value="M">M</option>
                                          <option value="H">H</option>
                                          <option value="D">D</option>
                                        </select>
                                      </label>
                                    </div>
                                  </div>
                                </Fragment>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Status</label>
                      <Select
                        value={this.state.status}
                        onChange={this.handleOnChange.bind(this, "status")}
                        placeholder={"Select Status"}
                      >
                        <Option value={true}>Active</Option>
                        <Option value={false}>Inactive</Option>
                      </Select>
                      {this.state.slaErrors && (
                        <div className="validationsMsg">
                          {this.state.slaErrors["status"] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {this.state.Multiple && (
                <>
                  <div className="editor">
                    <div className="mainfileUpload">
                      <Dropzone onDrop={this.fileUpload.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              className="file-upload d-none"
                            />
                            <div className="file-icon">
                              <img src={UploadIcon} alt="file-upload" />
                            </div>
                            <span className={"drop"}>
                              Drop your file to upload
                            </span>
                            <button className="fileupload">Choose File</button>
                            <span className="down">Download Sample File</span>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* <div className="editorbtn">
                            <button type="button" onClick={this.handleSlaMasterModalClose.bind(this)}>Cancel</button>
                            <button type="button">CREATE</button>
                        </div> */}
          </Modal>
        </div>
      </div>
    );
  }
}

export default SlaMaster;
