import * as fs from "file-saver";
import { Workbook } from "exceljs";
class ImportExport {
  
  ExportToExcel(url, fileName) {
    fs.saveAs(url, fileName);
  }
  SampleCSV(header, sampleData,filename) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);
    worksheet.addRow(header);
    if (sampleData) {
      sampleData.forEach((d) => {
        worksheet.addRow(d);
      });
    }
    workbook.csv.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, filename+".csv");
    });
  }
}

export default ImportExport;
