import React, { Component } from "react";
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Radio, Select, Table, Checkbox, Button, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import FileUpload from "./../../../assets/images/upload.png";
import Dropzone from "react-dropzone";

class Dnd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createDnd: false,
      Single: true,
      Multiple: false,
      fileName: "",
      fileN: [],
      Checkk: false,
    };
  }
  handleCreateDndOpen() {
    this.setState({ createDnd: true });
  }
  handleCreateDndClose() {
    this.setState({ createDnd: false, Checkk: false });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  handleCheckBtn() {
    this.setState({ Checkk: !this.state.Checkk });
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };
  render() {
    const columns = [
      {
        title: "Contact Number",
        dataIndex: "cont",
        key: "cont",
        render: (row, item) => {
          return (
            <div className="report">
              <label>8750185777</label>
            </div>
          );
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (row, item) => {
          return (
            <div className="report">
              <label>Test@123.com</label>
            </div>
          );
        },
      },
      {
        title: "Created by",
        key: "createdby",
        dataIndex: "createdby",
        render: (row, item) => {
          return (
            <div className="report">
              <label>Vikash Sharma</label>
            </div>
          );
        },
      },
      {
        title: "Updated by",
        key: "updatedby",
        dataIndex: "updatedby",
        render: (row, item) => {
          return (
            <div className="report">
              <label>Vikash Sharma</label>
            </div>
          );
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (row, item) => {
          return (
            <div className="Status">
              <Select defaultValue="Active">
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>
            </div>
          );
        },
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (row, item) => {
          return (
            <div className="Actionic">
              <img
                src={AddNote}
                alt="Edit"
                onClick={this.handleCreateDndOpen.bind(this)}
              />
              <img src={CloseCircle} alt="delete" />
            </div>
          );
        },
      },
    ];
    const data = [
      {
        key: "1",
      },
      {
        key: "2",
      },
      {
        key: "3",
      },
    ];
    const { Option } = Select;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div className="DndMain">
        <div className="Dnd">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                DND
                <img
                  src={Add}
                  alt="add"
                  onClick={this.handleCreateDndOpen.bind(this)}
                />
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by brand name, category..."
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
          <div className="DndTable">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>
          {/* Create DND */}
          <Modal
            visible={this.state.createDnd}
            closable={false}
            title="Create to DND"
            className="CreateDndModal"
            footer={[
              <Button key="back" onClick={this.handleCreateDndClose.bind(this)}>
                Cancel
              </Button>,
              <Button key="submit" type="primary">
                CREATE
              </Button>,
            ]}
          >
            <div className="createDnd">
              <div className="mb-2">
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Single}
                  onChange={this.handleRedioButtonChange.bind(this, "Single")}
                >
                  Single
                </Radio>
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Multiple}
                  onChange={this.handleRedioButtonChange.bind(this, "Multiple")}
                >
                  Multiple
                </Radio>
              </div>
              {this.state.Single && (
                <>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Contact Name</label>
                      <input type="text" placeholder="Like Manager" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">
                        Email ID<span style={{ color: "red" }}>*</span>
                      </label>
                      <input type="text" placeholder="Like Manager" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">
                        Contact Number<span style={{ color: "red" }}>*</span>
                      </label>
                      <input type="text" placeholder="Like Manager" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <Checkbox
                        checked={this.state.Checkk}
                        onChange={this.handleCheckBtn.bind(this)}
                        className="desig"
                      >
                        Choose DND time slots to avoid disturbing uses at odd
                        times
                      </Checkbox>
                      {this.state.Checkk && (
                        <>
                          <div className="row mb-3 dndDropDown1 mt-3">
                            <div className="col-12 col-md-12">
                              <Select
                                defaultValue="Daily"
                                className="dndDropDown"
                              >
                                <Option value="daily">Daily</Option>
                                <Option value="saturday">Saturday</Option>
                                <Option value="sunday">Sunday</Option>
                              </Select>
                              <label className="desig">from</label>
                              <Select defaultValue="10" className="dndDropDown">
                                <Option value="10">10</Option>
                              </Select>
                              <label className="desig">:</label>
                              <Select
                                defaultValue="00"
                                className="dndDropDown mr-2"
                              >
                                <Option value="00">00</Option>
                              </Select>
                              <Select defaultValue="pm" className="dndDropDown">
                                <Option value="pm">Pm</Option>
                                <Option value="am">Am</Option>
                              </Select>
                              <label className="desig">to</label>
                              <Select defaultValue="10" className="dndDropDown">
                                <Option value="10">10</Option>
                              </Select>
                              <label className="desig">:</label>
                              <Select
                                defaultValue="00"
                                className="dndDropDown mr-2"
                              >
                                <Option value="00">00</Option>
                              </Select>
                              <Select defaultValue="pm" className="dndDropDown">
                                <Option value="pm">Pm</Option>
                                <Option value="am">Am</Option>
                              </Select>
                            </div>
                          </div>
                          <div className="addnew mt-2">
                            <button type="button">Add New Slot</button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
              {this.state.Multiple && (
                <>
                  <div className="editor">
                    <div className="mainfileUpload">
                      <Dropzone onDrop={this.fileUpload.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              className="file-upload d-none"
                            />
                            <div className="file-icon">
                              <img src={FileUpload} alt="file-upload" />
                            </div>
                            <span className={"drop"}>
                              Drop your file to upload
                            </span>
                            <button className="fileupload">Choose File</button>
                            <span className="down">Download Sample File</span>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Dnd;
